import { callExternalApi } from '../callExternalApi';
import { LineItem } from '../../store/LineItem';

export const getLineItemsApi = async (outside_inventory_id: string) => {
  return callExternalApi<LineItem[]>({
    url: `api/line_items?outside_inventory_id=${outside_inventory_id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const createLineItemApi = (lineItem: LineItem) => {
  return callExternalApi<LineItem>({
    url: 'api/line_items',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: lineItem
  });
};

export const updateLineItemApi = async (lineItem: LineItem) => {
  return await callExternalApi<LineItem>({
    url: `api/line_items/${lineItem.id}`,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: lineItem
  });
};

export const deleteLineItemApi = (id: string) => {
  return callExternalApi<void>({
    url: `api/line_items/${id}`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    }
  });
};
