import { UserPreferences } from '../../store/User';
import { callExternalApi } from '../callExternalApi';

export const updateTableStateApi = async (settings: UserPreferences, type?: string) => {
  const params = type ? `?type=${type}` : '';
  return callExternalApi<UserPreferences>(
    {
      url: `${process.env.REACT_APP_API_SERVER_URL}/api/preferences/user_customizations/${settings.id}${params}`,
      method: 'PUT',
      headers: {
        'content-type': 'application/json'
      },
      data: JSON.stringify(settings)
    },
    true
  );
};
