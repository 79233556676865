import {
  SharedAccountCustomizationContent,
  SharedAccountCustomizationData
} from '../../../store/SharedAccountCustomizations';
import { callExternalApi } from '../../callExternalApi';
import { Account } from '../../../types';

export type PostSharedAccountCustomizationRequest = {
  account_id: string;
  account_name: string;
  content: SharedAccountCustomizationContent;
};

export const postSharedAccountCustomizationApi = async (requestBody: PostSharedAccountCustomizationRequest) => {
  return await callExternalApi<SharedAccountCustomizationData>({
    url: '/api/preferences/shared_account_customizations',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: requestBody
  });
};

export const fetchSharedAccountCustomizationsApi = async () => {
  return await callExternalApi<SharedAccountCustomizationData[]>({
    url: '/api/preferences/shared_account_customizations',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const updateSharedAccountCustomizationApi = async (
  id: string,
  requestBody: PostSharedAccountCustomizationRequest
) => {
  return await callExternalApi<SharedAccountCustomizationData>({
    url: `api/preferences/shared_account_customizations/${id}`,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: requestBody
  });
};

export const bulkUpdateSharedAccountCustomizationApi = async (
  accts: Account[],
  data: SharedAccountCustomizationData
) => {
  return await callExternalApi<SharedAccountCustomizationData>({
    url: 'api/preferences/shared_account_customizations/bulk/update',
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: { accounts: accts, content: data.content }
  });
};
