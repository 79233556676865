import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { fetchSuppliersApi } from '../Api/Admin/Suppliers/suppliersApi';
import { Supplier } from '../types';

const suppliersSubject = new BehaviorSubject<Supplier[]>([]);
const suppliersLoadingSubject = new BehaviorSubject<boolean>(false);

export const getSupplierSubject = (): BehaviorSubject<Supplier[]> => suppliersSubject;
export const getSupplierLoadingSubject = (): BehaviorSubject<boolean> => suppliersLoadingSubject;

export interface UseSuppliersStoreReturn {
  fetchSuppliers: () => Promise<void>;
  clear: () => void;
  setSuppliers: (suppliers: Supplier[]) => void;
}

export const getSupplierById = (id: string) => {
  const suppliers = suppliersSubject.getValue();
  return suppliers.find((sup) => sup.id === id);
};

export const useSuppliersStore = (): UseSuppliersStoreReturn => {
  const fetchSuppliers = async (): Promise<void> => {
    suppliersLoadingSubject.next(true);

    const { data, error } = await fetchSuppliersApi();

    if (data) suppliersSubject.next(data);
    if (error) addError('supplier', error.message);

    suppliersLoadingSubject.next(false);
  };

  const setSuppliers = (suppliers: Supplier[]) => suppliersSubject.next(suppliers);

  const clear = () => suppliersSubject.next([]);

  return {
    fetchSuppliers,
    clear,
    setSuppliers
  };
};
