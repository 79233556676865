import { GridColDef, DataGridPro, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import { DEFAULT_TABLE_STYLES } from '../UpstackDataGrid/UpstackDataGrid';
/* eslint-disable @typescript-eslint/no-explicit-any */

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    showToolbar: boolean;
  }
}

export interface Column {
  title: string;
}

export interface BasicDataGridProps {
  rows: any[];
  columns: GridColDef[];
  className?: string;
  showToolbar?: boolean;
  dataCy?: string;
}

export interface CustomToolbarProps {
  showToolbar: boolean;
}

const CustomToolbar = ({ showToolbar }: CustomToolbarProps) => {
  return (
    <GridToolbarContainer>
      {showToolbar && (
        <GridToolbarExport
          slotProps={{
            button: {
              color: 'secondary'
            }
          }}
        />
      )}
    </GridToolbarContainer>
  );
};

export const BasicDataGrid = ({ rows, columns, className, showToolbar = false, dataCy = '' }: BasicDataGridProps) => {
  return (
    <div className={className}>
      <DataGridPro
        rows={rows || []}
        disableRowSelectionOnClick
        disableColumnFilter
        columns={columns}
        hideFooterPagination
        hideFooterRowCount
        autoHeight={true}
        columnHeaderHeight={DEFAULT_TABLE_STYLES.headerHeight}
        rowHeight={DEFAULT_TABLE_STYLES.rowHeight}
        sx={DEFAULT_TABLE_STYLES.sx}
        slots={{
          toolbar: CustomToolbar
        }}
        slotProps={{
          toolbar: {
            showToolbar
          }
        }}
        data-cy={dataCy}
      />
    </div>
  );
};
