import createActionCableConsumer from './actionCableConsumer';

interface AppConfigChannelParams {
  connectedHandler?: () => void;
  disconnectedHandler?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  receivedHandler?: (data: any) => void;
}

const appConfigChannel = ({ connectedHandler, disconnectedHandler, receivedHandler }: AppConfigChannelParams) => {
  const consumer = createActionCableConsumer('', '', '');

  return consumer.subscriptions.create(
    {
      channel: 'AppConfigChannel'
    },
    {
      connected: connectedHandler,
      disconnected: disconnectedHandler,
      received: receivedHandler
    }
  );
};

export default appConfigChannel;
