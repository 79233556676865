import { SupportTicketsItem } from '../../types';
import { callExternalApi } from '../callExternalApi';

export type CaseRequest = {
  Status: string;
  AccountId: string | undefined;
  ContactId: string | undefined;
  Description: string;
  Type: string;
  RecordTypeId: string;
  Reason: string;
  Priority: string;
  OrderProductId: string;
  AdditionalRecipients: string;
  supplierTicketNumber: string;
};

export async function postTicket(requestBody: CaseRequest) {
  const url = 'api/cases';
  return await callExternalApi<SupportTicketsItem[]>({
    url,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: requestBody
  });
}
