import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { fetchCommissionsApi, postCommissionApi } from '../Api/Commissions/commissionsApi';
import { Commission } from '../types';
import { useSnackbar } from '../Context/SnackbarContext';

const commissionsSubject = new BehaviorSubject<Commission[]>([]);
const commissionsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getCommissionSubject = (): BehaviorSubject<Commission[]> => commissionsSubject;
export const getCommissionLoadingSubject = (): BehaviorSubject<boolean> => commissionsLoadingSubject;

export interface UseCommissionsStoreReturn {
  fetchCommissions: () => Promise<void>;
  createCommission: () => Promise<void>;
  createRequest: () => Promise<void>;
  clear: () => void;
  setCommissions: (commissions: Commission[]) => void;
}

export const useCommissionsStore = (): UseCommissionsStoreReturn => {
  const { setSnack } = useSnackbar();

  const fetchCommissions = async (): Promise<void> => {
    commissionsLoadingSubject.next(true);

    const { data, error } = await fetchCommissionsApi();

    if (data) commissionsSubject.next(data);
    if (error) addError('commission', error.message);

    commissionsLoadingSubject.next(false);
  };

  const createRequest = async () => {
    const { data, error } = await postCommissionApi();
    if (data) {
      setSnack({ message: 'Sync has been started successfully', type: 'success', open: true });
    }

    if (error) {
      setSnack({ message: 'Sync is already in progress', type: 'error', open: true });
    }
  };

  const createCommission = async () => {
    return await createRequest();
  };

  const setCommissions = (commissions: Commission[]) => commissionsSubject.next(commissions);

  const clear = () => commissionsSubject.next([]);

  return {
    fetchCommissions,
    createCommission,
    createRequest,
    clear,
    setCommissions
  };
};
