import { callExternalApi } from '../callExternalApi';

export async function logoutUser() {
  return callExternalApi<void>({
    url: 'auth/sign_out',
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    }
  });
}
