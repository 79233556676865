import { Order } from '../../types';
import { callExternalApi, CallResult } from '../callExternalApi';

export const fetchOrders = async (accountIds?: string[]): Promise<CallResult<Order[]>> => {
  if (!accountIds?.length) return { data: null, error: { message: 'Must provide account IDs to fetch asset' } };

  return await callExternalApi<Order[]>({
    url: `api/inventory?account_ids=${accountIds.join(',')}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
