import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { useSnackbar } from '../Context/SnackbarContext';
import { createLineItemApi, deleteLineItemApi, getLineItemsApi, updateLineItemApi } from '../Api/LineItems/lineItemApi';

export type LineItem = {
  id: string;
  outside_inventory_id: string;
  product_id: string;
  description: string;
  mrc: number;
  nrc: number;
};

const lineItemsSubject = new BehaviorSubject<LineItem[]>([]);
const lineItemsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getLineItemSubject = (): BehaviorSubject<LineItem[]> => lineItemsSubject;
export const getLineItemLoadingSubject = (): BehaviorSubject<boolean> => lineItemsLoadingSubject;
export const getLineItemTotal = () =>
  Number(
    getLineItemSubject()
      .getValue()
      .reduce((sum, item) => sum + (Number(item.mrc) || 0), 0)
      .toFixed(2)
  );

export interface UseLineItemsStoreReturn {
  fetchLineItems: (outside_inventory_id: string) => Promise<void>;
  createLineItem: (lineItem: LineItem) => Promise<void>;
  updateLineItem: (lineItem: LineItem) => Promise<void>;
  deleteLineItem: (id: string) => Promise<void>;
  setLineItems: (lineItem: LineItem[]) => void;
}

export const useLineItemsStore = (): UseLineItemsStoreReturn => {
  const { setSnack } = useSnackbar();

  const fetchLineItems = async (outside_inventory_id: string): Promise<void> => {
    lineItemsLoadingSubject.next(true);

    const { data, error } = await getLineItemsApi(outside_inventory_id);

    if (data) lineItemsSubject.next(data);
    if (error) addError('line-item', error.message);

    lineItemsLoadingSubject.next(false);
  };

  const createLineItem = async (lineItem: LineItem) => {
    const { data, error } = await createLineItemApi(lineItem);
    if (data) {
      const currentState = [...getLineItemSubject().getValue()];
      setLineItems([...currentState, data]);
      setSnack({ message: 'Line item saved successfully', type: 'success', open: true });
    }

    if (error) {
      setSnack({ message: 'Error saving line item', type: 'error', open: true });
    }
  };

  const updateLineItem = async (lineItem: LineItem) => {
    const { data, error } = await updateLineItemApi(lineItem);
    if (data) {
      const currentState = [...getLineItemSubject().getValue()];
      const updatedLineItems = currentState.map((l: LineItem) => (l.id === data.id ? data : l));
      setLineItems(updatedLineItems);
      setSnack({ message: 'Line item updated successfully', type: 'success', open: true });
    }
    if (error) {
      setSnack({ message: 'Error updating comment', type: 'error', open: true });
    }
  };

  const deleteLineItem = async (id: string) => {
    const { error } = await deleteLineItemApi(id);
    if (error) return setSnack({ message: 'Error deleting line item', type: 'error', open: true });

    const currentState = [...getLineItemSubject().getValue()];
    const lineItems = currentState.filter((l: LineItem) => l.id !== id);
    setLineItems(lineItems);
    setSnack({ message: 'Line item deleted successfully', type: 'success', open: true });
  };

  const setLineItems = (lineItems: LineItem[]) => lineItemsSubject.next(lineItems);

  return {
    fetchLineItems,
    createLineItem,
    updateLineItem,
    deleteLineItem,
    setLineItems
  };
};
