import { BehaviorSubject } from 'rxjs';
import { Account, ContactUserData, User } from '../types';
import { useState } from 'react';
import { getAccounts as apiGetAccounts } from '../Api/Admin/Accounts';
import { fetchContacts, fetchSfUsersApi } from '../Api/Admin/PortalUsers/fetchContacts';
import { addError } from './Error';
import {
  AccountCustomizationData,
  defaultAccountCustomizationData,
  getAccountCustomizationsById
} from './AccountCustomization';
import { getUsersByAccount } from './PortalUser';
import { getAccountHistoryApi } from '../Api/Admin/Accounts/getAccountHistoryApi';

export interface UseAccountsReturn {
  fetchAccounts: (updateAccountData?: boolean) => Promise<void>;
  getAccountContacts: () => Promise<void>;
  getSfUsers: () => Promise<void>;
  accountsLoading: boolean;
}

export interface AccountData {
  id: string;
  name: string;
  accountCustomizations: AccountCustomizationData;
  sub_accounts: number;
  users: number;
  last_updated: string;
}

export interface AccountHistory {
  account_id: string;
  action: string;
  created_at: string;
  updated_at: string;
  event_data: string;
  user: string;
  id: number;
  impersonator?: User;
  impersonated?: User;
}

export const defaultAccount: Account = {
  id: '',
  name: ''
};

const defaultAccountData: Account[] = [];
const defaultAccountContactData: ContactUserData[] = [];

const accountSubject = new BehaviorSubject<Account[]>(defaultAccountData);
const accountDataSubject = new BehaviorSubject<AccountData[]>([]);
const accountHistorySubject = new BehaviorSubject<AccountHistory[]>([]);
const loadingSubject = new BehaviorSubject<boolean>(false);
const accountContactsSubject = new BehaviorSubject<ContactUserData[]>(defaultAccountContactData);
const sfUserSubject = new BehaviorSubject<ContactUserData[]>(defaultAccountContactData);
const emitErrorState = (message?: string): void => addError('account', message);

export const getAccountSubject = (): BehaviorSubject<Account[]> => accountSubject;
export const getAccountLoadingSubject = (): BehaviorSubject<boolean> => loadingSubject;
export const getAccountDataSubject = (): BehaviorSubject<AccountData[]> => accountDataSubject;
export const getAccountHistorySubject = (): BehaviorSubject<AccountHistory[]> => accountHistorySubject;

export const getAccountContactSubject = (): BehaviorSubject<ContactUserData[]> => accountContactsSubject;
export const getSfUsersSubject = (): BehaviorSubject<ContactUserData[]> => sfUserSubject;
export const getAccounts = (): Account[] => accountSubject.value;

export const getAccountName = (id: string) => {
  const accounts = accountSubject.getValue();
  const account = accounts.find((acc) => acc.id === id);
  return account?.name;
};

export const fetchAccountHistory = async (account_id: string) => {
  const { data, error } = await getAccountHistoryApi(account_id);
  if (data) {
    const formattedData = data.map((d) => {
      const user = d?.impersonator || d?.impersonated;
      d.user = `${user?.first_name} ${user?.last_name}`;
      return d;
    });

    accountHistorySubject.next(formattedData);
  }
  if (error) emitErrorState(error.message);
};

export const buildAccountData = () => {
  const accounts = getAccountSubject().value;

  const data = accounts.map((acc) => {
    const customizations = getAccountCustomizationsById(acc.id) || defaultAccountCustomizationData(acc?.id, acc?.name);
    return {
      id: acc.id,
      name: acc.name,
      accountCustomizations: customizations,
      sub_accounts: customizations?.content?.sub_accounts?.length || 0,
      users: getUsersByAccount(acc.id).length,
      last_updated: customizations?.updated_at
    } as AccountData;
  });

  accountDataSubject.next(data);
};

export const useAccounts = (): UseAccountsReturn => {
  const [accountsLoading, setAccountsLoading] = useState<boolean>(false);

  const fetchAccounts = async (updateAccountData?: boolean): Promise<void> => {
    loadingSubject.next(true);
    const { data, error } = await apiGetAccounts();

    if (data) {
      emitErrorState();
      accountSubject.next(data);
      if (updateAccountData) buildAccountData();
    }

    if (error) emitErrorState(error.message);
    loadingSubject.next(false);
  };

  const getAccountContacts = async () => {
    setAccountsLoading(true);
    const { data, error } = await fetchContacts();
    if (data) accountContactsSubject.next(data);
    if (error) emitErrorState(error.message);
    setAccountsLoading(false);
  };

  const getSfUsers = async () => {
    const { data, error } = await fetchSfUsersApi();
    if (data) sfUserSubject.next(data);
    if (error) emitErrorState(error.message);
  };

  return {
    fetchAccounts,
    getAccountContacts,
    getSfUsers,
    accountsLoading
  };
};
