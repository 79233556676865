import { TextSpan } from '../../Atoms/Text';
import { Switch } from '@mui/material';
import React from 'react';
import { AccountCustomizationData } from '../../../store/AccountCustomization';

export interface SettingsModelContentParams {
  columnsCustom: boolean;
  onEditColumns: () => void;
  widgetsCustom: boolean;
  onEditWidgets: () => void;
  handleCommentChange: () => void;
  newCustomizations: AccountCustomizationData;
}

export const SettingsModalContent = ({
  columnsCustom,
  onEditColumns,
  widgetsCustom,
  onEditWidgets,
  handleCommentChange,
  newCustomizations
}: SettingsModelContentParams) => {
  return (
    <div>
      <div className="flex mt-2">
        <div className="grow">
          <TextSpan
            className="flex items-center"
            color="grey10"
            size="md">
            Table Columns:
          </TextSpan>
        </div>
        <div className="flex-none">
          <TextSpan
            className="flex items-center"
            color="grey10"
            size="sm">
            {columnsCustom ? 'Custom' : 'Default'}
          </TextSpan>
        </div>
        <div className="flex-none">
          <button
            data-testid="edit-columns"
            data-cy="edit-columns-btn"
            className="mx-2 flex items-center text-sm text-indigo hover:text-indigo-mid"
            onClick={onEditColumns}>
            Edit
          </button>
        </div>
      </div>
      <div className="border-b-2 pb-3.5 mb-3.5">
        <TextSpan
          color="grey5"
          dataTestId="table-columns-title"
          size="xs">
          Set the default order and visibility of columns for users of this Account.
        </TextSpan>
      </div>

      <div className="flex mt-2">
        <div className="grow">
          <TextSpan
            className="flex items-center"
            color="grey10"
            size="md">
            Widgets:
          </TextSpan>
        </div>
        <div className="flex-none">
          <TextSpan
            className="flex items-center"
            color="grey10"
            size="sm">
            {widgetsCustom ? 'Custom' : 'Default'}
          </TextSpan>
        </div>
        <div className="flex-none">
          <button
            className="mx-2 flex items-center text-sm text-indigo hover:text-indigo-mid"
            onClick={onEditWidgets}>
            Edit
          </button>
        </div>
      </div>
      <div className="border-b-2 pb-3.5 mb-2">
        <TextSpan
          color="grey5"
          dataTestId="table-columns-title"
          size="xs">
          Set the default order and visibility of widgets for users of this Account.
        </TextSpan>
      </div>

      <div className="flex mt-2">
        <div className="grow">
          <TextSpan
            className="flex items-center"
            color="grey10"
            size="md">
            Comments:
          </TextSpan>
        </div>
        <div className="flex-none">
          <div>
            <Switch
              sx={{
                '.Mui-checked': {
                  color: '#2E01A4 !important'
                },
                '.MuiSwitch-track': {
                  backgroundColor: '#878EBE !important'
                }
              }}
              size="small"
              checked={newCustomizations.content.comments || false}
              onChange={handleCommentChange}
            />
          </div>
        </div>
      </div>
      <TextSpan
        color="grey5"
        dataTestId="table-columns-title"
        size="xs">
        Enable comments on Orders and Inventory items for users of this Account.
      </TextSpan>
    </div>
  );
};
