import { callExternalApi } from '../../callExternalApi';

export const deletePortalUserApi = async (portalUserId: string) => {
  return await callExternalApi<void>({
    url: `api/admin/portal_users/${portalUserId}`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    }
  });
};
