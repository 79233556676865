import { InventoryAsset } from '../../../types';
import { formatDate } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { UpcomingExpirationsTableFooter } from './UpcomingExpirationsTableFooter';

export interface UpcomingExpirationsTableProps {
  filteredByQuarterInventoryAssets: InventoryAsset[];
  handleMoreClick: () => void;
}

export function UpcomingExpirationsTable({
  filteredByQuarterInventoryAssets,
  handleMoreClick
}: UpcomingExpirationsTableProps) {
  const navigate = useNavigate();
  const tableOverflowClass = filteredByQuarterInventoryAssets.length > 5 ? 'h-[295px] overflow-y-scroll' : '';

  const handleClick = (id: string) => {
    navigate(`inventory/${id}?detailId=${id}`);
  };

  return (
    <>
      <hr />
      <div className={`py-[12px] px-[20px] ${tableOverflowClass}`}>
        {filteredByQuarterInventoryAssets.map((asset) => (
          <div
            className="border-b py-2"
            key={asset.id}
            role="row">
            <div className="text-[12px] leading-[15px] flex text-indigo pb-2">
              <button
                className="cursor-pointer shrink-0"
                onClick={() => handleClick(asset.id)}>
                {asset.productDisplay}
              </button>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <button
                className="cursor-pointer truncate"
                onClick={() => handleClick(asset.id)}>
                {asset.supplier?.name}
              </button>
            </div>
            <div className="text-[12px] leading-[15px] flex gap-x-2">
              <div className="shrink-0 text-grey-8">{formatDate(asset.contractEndDate)}</div>
              <div
                className="truncate text-grey-5"
                title={asset.address}>
                {asset.address}
              </div>
            </div>
          </div>
        ))}
      </div>
      <UpcomingExpirationsTableFooter
        filteredByQuarterInventoryAssets={filteredByQuarterInventoryAssets}
        handleMoreClick={handleMoreClick}
      />
    </>
  );
}
