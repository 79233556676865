import { Icon } from '../../Atoms/Icon';

export interface SortRowProps {
  colId: string;
  chosenColId: string;
  title: string;
  handleSortClick: (colId: string) => void;
}

export const SortRow = ({ colId, title, chosenColId, handleSortClick }: SortRowProps) => {
  const isChecked = colId == chosenColId;
  const checkStyle = isChecked ? 'checked' : 'hidden';
  return (
    <div className="w-full px-3 pr-5 py-2 text-black border-b last:border-b-0">
      <Icon type={checkStyle} />
      <button onClick={() => handleSortClick(colId)}>{title}</button>
    </div>
  );
};
