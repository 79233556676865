import { useEffect, useState } from 'react';
import { Commission } from '../../../types';
import { PageLayout } from '../../PageLayout';
import { PageCard } from '../../Compounds/CardWithTitle';
import { getCommissionLoadingSubject, getCommissionSubject, useCommissionsStore } from '../../../store/Commission';
import {
  GridPreferences,
  getDefaultPreferences,
  getPreferencesByKey,
  getUserPreferencesSubject
} from '../../../store/User';
import { UpstackDataGrid } from '../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { DefaultCommissionGridColumnDefs } from './CommissionGridColumnDefs';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { Permission } from '../../../store/GeneralStore';
import { TextSpan } from '../../Atoms/Text';
import { getGridColumns } from '../../Compounds/UpstackDataGrid/helpers';

export const CommissionsPage = () => {
  const [commissions, setCommissions] = useState<Commission[]>([]);
  const page = 'Commissions';
  const { fetchCommissions, createRequest } = useCommissionsStore();
  const [commissionColumns, setCommissionColumns] = useState<GridSingleSelectColDef[]>(DefaultCommissionGridColumnDefs);
  const [commissionLoading, setCommissionLoading] = useState<boolean>(false);
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences().content['commission_grid'] as GridPreferences
  );

  useEffect(() => {
    const subscription = getCommissionSubject().subscribe((commissions) => {
      setCommissions(commissions);
      setCommissionColumns(getGridColumns(commissions, DefaultCommissionGridColumnDefs, 'commission_grid'));
    });

    const settingsSub = getUserPreferencesSubject().subscribe(() => {
      const gridPrefs = getPreferencesByKey('commission_grid') as GridPreferences;
      if (gridPrefs) setGridSettings(gridPrefs);
    });
    const commissionLoadingSub = getCommissionLoadingSubject().subscribe((loadingState) =>
      setCommissionLoading(loadingState)
    );
    fetchCommissions();

    return () => {
      if (subscription) subscription.unsubscribe();
      if (settingsSub) settingsSub.unsubscribe();
      if (commissionLoadingSub) commissionLoadingSub.unsubscribe();
    };
  }, []);

  const pageActions = () => (
    <PermissibleRender requiredPermissions={[Permission.CREATE_COMMISSION_SYNC]}>
      <TextSpan className="flex items-center space-x-2">
        <button
          className="text-[indigo] text-sm"
          onClick={() => createRequest()}>
          Sync Now
        </button>
      </TextSpan>
    </PermissibleRender>
  );

  return (
    <PageLayout
      pageTitle={page}
      pageActions={pageActions()}>
      <div className="mt-2 text-lg">
        Syncing RPM Redshift data to UPSTACK will take 15-30 minutes. Please refresh the page and check back shortly.
      </div>
      <PageCard
        childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
        title="Sync Log">
        <UpstackDataGrid
          title="Commissions"
          rows={commissions}
          columns={commissionColumns}
          loadingData={commissionLoading}
          page="commission_grid"
          gridSettings={gridSettings}
          showSearch={false}
        />
      </PageCard>
    </PageLayout>
  );
};
