import { useEffect, useMemo, useState } from 'react';
import { InventoryAsset } from '../../../types';
import { SelectMenu } from '../../Atoms/SelectMenu';
import { CollapseButton } from '../../Atoms/CollapseButton';
import { Icon } from '../../Atoms/Icon';
import { Tooltip as UpstackTooltip } from '@mui/material';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine } from 'recharts';
import {
  daysDifference,
  lineDataWithSimplifiedKey,
  filteredByQuarterInventoryAssets,
  parseQuarterToDates,
  calculateFutureDate
} from '../../../utils/helpers';
import { UpcomingExpirationsTable } from './UpcomingExpirationsTable';
import { useNavigate } from 'react-router-dom';
import { BORDER_STYLE } from '../../../utils/styleHelpers';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { getInventoryLoadingSubject } from '../../../store/Inventory';
import { TextBlock } from '../../Atoms/Text';
import { v4 } from 'uuid';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { UserPreferences, getCurrentPreferences, setPreferences } from '../../../store/User';

export interface UpcomingExpirationsWidgetProps {
  inventoryAssets: InventoryAsset[];
  chartWidth?: number;
  isHome?: boolean;
}

interface CategoricalChartState {
  activeLabel?: string;
}

const quartersOptions = [{ value: '1', text: '1 Quarter' }].concat(
  [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n) => {
    return { value: String(n), text: `${n} Quarters` };
  })
);

export function daysUntilEndOfQuarters(numQuarters: number | 1) {
  const today = new Date();
  let endOfQuarterDate, daysUntilEndOfQuarter, endOfQuarterMonth;

  for (let i = 0; i < numQuarters + 1; i++) {
    const currentQuarter = Math.floor(today.getMonth() / 3) + i + 1;
    endOfQuarterMonth = currentQuarter * 3;
    endOfQuarterDate = new Date(today.getFullYear(), endOfQuarterMonth, 0);
    daysUntilEndOfQuarter = Math.floor((endOfQuarterDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));
  }

  return daysUntilEndOfQuarter || 0;
}

export function UpcomingExpirationsWidget({ inventoryAssets, chartWidth, isHome }: UpcomingExpirationsWidgetProps) {
  const navigate = useNavigate();
  const [quarters, setQuarters] = useState<number>(12);
  const [chartQuarter, setChartQuarter] = useState<string>('');
  const [collapse, setCollapse] = useState<boolean>(false);
  const today = useMemo(() => new Date(), []);
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [startDate, endDate] = [today, calculateFutureDate(quarters, today)];
  const [chartFilter, setChartFilter] = useState<GridFilterItem[]>([
    {
      id: v4(),
      field: 'contractEndDate',
      value: [startDate.getTime(), endDate.getTime()],
      operator: 'between'
    }
  ]);

  const updatePreferences = (filter: GridFilterItem[]) => {
    const update: UserPreferences = JSON.parse(JSON.stringify(getCurrentPreferences()));

    if (update.content.inventory_grid_All.muiConfig.filter?.filterModel) {
      update.content.inventory_grid_All.muiConfig.filter.filterModel.items = filter;
    }

    setPreferences(update);
  };

  useEffect(() => {
    const loadingSub = getInventoryLoadingSubject().subscribe((loading) => setInventoryLoading(loading));

    return () => {
      if (loadingSub) loadingSub.unsubscribe();
    };
  }, []);

  const filteredInventoryAssets = inventoryAssets.filter((inventoryAsset) => {
    const contractEndDate = new Date(inventoryAsset.contractEndDate);
    return today < contractEndDate && daysDifference(today, contractEndDate) <= daysUntilEndOfQuarters(quarters);
  });

  const handleMoreClick = () => {
    updatePreferences(chartFilter);
    navigate('/inventory');
  };

  const filterQuarter = async (startDate: Date, endDate: Date, activeLabel: string) => {
    const newFilter = {
      id: v4(),
      field: 'contractEndDate',
      value: [startDate.getTime(), endDate.getTime()],
      operator: 'between'
    };

    setChartFilter([newFilter]);
    setChartQuarter(activeLabel);

    if (isHome) setCollapse(true);
    if (!isHome) updatePreferences([newFilter]);
  };

  const lineChartClick = (chartState: CategoricalChartState) => {
    if (!chartState?.activeLabel) return;

    filterQuarter(...parseQuarterToDates(chartState.activeLabel), chartState.activeLabel);
  };

  // This method is for testing purposes only.
  // It is being used in the actual component, any filter will be overwritten by lineChartClick which is run after every lineClick.
  // This is due to recharts not supporting the onClick event on the LineChart component correctly.
  // Or providing access active dots.
  type lineChartClickEvent = { payload: { name: string } };
  const lineClick = (lineState: unknown) => {
    if (lineState === null) return;

    const { points } = lineState as { points: lineChartClickEvent[] };
    const {
      payload: { name }
    } = points[0];
    filterQuarter(...parseQuarterToDates(name), name);
  };

  const handleSelectClick = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setQuarters(Number(e.target.value));
    setChartQuarter('');
  };

  const isAssetsList = filteredInventoryAssets.length > 0;

  return (
    <div className={`${BORDER_STYLE} pt-3`}>
      <p
        aria-level={2}
        role="heading"
        className="text-sm font-medium tracking-[0.6px] text-grey-5 text-center leading-[15px]">
        UPCOMING EXPIRATIONS
        <UpstackTooltip
          placement="top"
          title="Most suppliers require up to 90 days notice prior to disconnect or auto-renewal">
          <button className="h-[18px]">
            <Icon
              type="info"
              className="p-2"
            />
          </button>
        </UpstackTooltip>
      </p>
      {inventoryLoading && (
        <div className={`flex ${isHome ? 'h-32' : 'h-44 w-64'} items-center justify-center`}>
          <ComponentSpinner />
        </div>
      )}
      {!isAssetsList && !inventoryLoading && (
        <div className={`flex ${isHome ? 'h-32' : 'h-44 w-64'} items-center justify-center`}>
          <TextBlock size="sm14">No data available</TextBlock>
        </div>
      )}
      {!inventoryLoading && isAssetsList && (
        <>
          <div className="text-center mt-3 mb-2 widget-container m-auto text-[10px]">
            <LineChart
              aria-label="expirations-line-chart-label"
              onClick={lineChartClick}
              data={lineDataWithSimplifiedKey(filteredInventoryAssets)}
              layout="horizontal"
              width={isHome ? chartWidth : 300}
              height={isHome ? 175 : 117}
              margin={{ top: 0, right: 20, bottom: -10, left: -25 }}>
              <CartesianGrid
                horizontalPoints={[0]}
                stroke="#ccc"
              />
              <XAxis
                dataKey="name"
                interval="preserveStartEnd"
                tickLine={false}
              />
              <YAxis
                domain={[0, 'dataMax + 3']}
                tickLine={false}
              />
              <Tooltip />
              <Line
                activeDot={{
                  'aria-label': 'expirations-active-dot-line-label',
                  stroke: '#000000',
                  strokeWidth: 5,
                  r: 2.5
                }}
                aria-label="expirations-line-label"
                dataKey="Expirations"
                dot={{
                  'aria-label': 'expirations-dot-line-label',
                  stroke: '#2E01A4',
                  strokeWidth: 4.5,
                  r: 2.5
                }}
                onClick={lineClick}
                stroke="#2E01A4"
                strokeWidth={3}
                type="linear"
              />
              {!!chartQuarter && (
                <ReferenceLine
                  x={chartQuarter}
                  stroke="#2E01A4"
                />
              )}
            </LineChart>
          </div>
          <hr />
          <div className="flex flex-row gap-x-[5px]">
            <div className="w-full mx-3.5 mt-[5px] mb-[9px]">
              <SelectMenu
                dataCy="quarters"
                defaultValue={String(quarters)}
                handleSelect={handleSelectClick}
                name="quarters"
                options={quartersOptions}
                selectClasses="text-[10px]"
              />
            </div>
            {isHome && (
              <CollapseButton
                classNames="px-6 border-l"
                testId="upcomingExpirationsWidget"
                collapse={collapse}
                setCollapse={setCollapse}
              />
            )}
          </div>
          {isHome && collapse && (
            <UpcomingExpirationsTable
              filteredByQuarterInventoryAssets={filteredByQuarterInventoryAssets(filteredInventoryAssets, chartQuarter)}
              handleMoreClick={handleMoreClick}
            />
          )}
        </>
      )}
    </div>
  );
}
