import { UserEvent } from '../../types';
import { callExternalApi } from '../callExternalApi';

export interface UserEventsApiResponse {
  data: UserEvent[] | null;
  error: null | { message: string };
}

export interface UserEventsApiReturn {
  fetchUserEvents: (impersonatedId: string) => Promise<UserEventsApiResponse>;
}

export const UserEventsApi = (): UserEventsApiReturn => {
  const fetchUserEvents = async (impersonatedId: string) => {
    return await callExternalApi<UserEvent[]>({
      url: `api/admin/user_events?impersonated_id=${impersonatedId}&actions=Password Reset Sent,Impersonated`,
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      }
    });
  };

  return { fetchUserEvents };
};
