import { InventoryAsset } from '../../types';
import { callExternalApi, CallResult } from '../callExternalApi';

export async function fetchAggregateInventory(accountIds?: string[]): Promise<CallResult<InventoryAsset[]>> {
  if (!accountIds?.length) return { data: null, error: { message: 'Must provide account ID to fetch asset' } };

  return await callExternalApi<InventoryAsset[]>({
    url: `api/aggregate_inventory?account_ids=${accountIds.join(',')}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
}

export async function fetchAggregateInventoryById(
  assetId: string,
  accountIds?: string[]
): Promise<CallResult<InventoryAsset[]>> {
  if (!accountIds?.length) return { data: null, error: { message: 'Must provide account IDs to fetch asset' } };
  return await callExternalApi<InventoryAsset[]>({
    url: `api/aggregate_inventory/${assetId}?account_ids=${accountIds.join(',')}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
}
