import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import { PortalUserData } from '../../../types';
import {
  clearForm,
  clearFormSubmitStateSubject,
  getFormSubject,
  getFormSubmitStateSubject,
  initAddUsersToTeamForm
} from '../../../store/AddPortalUsersToTeamFormState';
import { PageLayout } from '../../PageLayout';
import { adminUrlForRole, getUser, isTeamManager } from '../../../store/User';
import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { TextBlock, TextSpan } from '../../Atoms/Text';
import { capitalizeText } from '../../../utils/helpers';
import { PageCard } from '../../Compounds/CardWithTitle';
import { BasicDataGrid } from '../../Compounds/Table/BasicDataGrid';
import { usePortalUsers } from '../../../store/PortalUser';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Icon } from '../../Atoms/Icon';

export interface BaseDisplayData {
  id?: string;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  button?: boolean;
}

export interface DisplayData {
  successData: BaseDisplayData[];
  failureData: BaseDisplayData[];
}

export const AddTeamMemberSummary = () => {
  const navigate = useNavigate();
  const user = getUser();
  const [displayData, setDisplayData] = useState<DisplayData>({ successData: [], failureData: [] });
  const [form, setForm] = useState(getFormSubject().getValue());

  const getHeaderCopy = () => {
    return `Team ${capitalizeText(form.type)}s Added`;
  };

  const getLinks = (): { to: string; label: string; onClick: () => void }[] => {
    const links = [
      {
        to: 'new-team',
        label: 'New Team',
        onClick: () => {
          resetForm();
          navigate(`${adminUrlForRole()}/new-team`);
        }
      }
    ];

    if (form.type === 'manager') {
      const managerLinks = [
        {
          to: 'teams/add-users',
          label: 'Add Team Members',
          onClick: () => {
            resetForm();
            initAddUsersToTeamForm(form.team_id);
            navigate(`${adminUrlForRole()}/teams/add-users`);
          }
        }
      ];
      links.push(...managerLinks);
    }

    if (form.type === 'member') {
      const managerLinks = [
        {
          to: 'teams/add-managers',
          label: 'Add Team Managers',
          onClick: () => {
            resetForm();
            initAddUsersToTeamForm(form.team_id, 'manager');
            navigate(`${adminUrlForRole()}/teams/add-managers`);
          }
        }
      ];
      links.push(...managerLinks);
    }

    return links;
  };

  const getSectionHeaderCopy = (data: BaseDisplayData[]): string => {
    return `${data.length} Team ${form.type === 'manager' ? 'Manager' : 'Member'}(s)`;
  };

  const pageActions = () => (
    <button
      onClick={() => {
        resetForm();
        navigate(adminUrlForRole());
      }}
      className={DEFAULT_ADVANCE_BUTTON_STYLE}>
      Back To {`${isTeamManager(user) ? 'Team Settings' : 'Admin'}`}
    </button>
  );

  const resetForm = () => {
    clearFormSubmitStateSubject();
    clearForm();
  };

  useEffect(() => {
    const formSub = getFormSubject().subscribe((f) => setForm(f));
    const formSubmitStateSub = getFormSubmitStateSubject().subscribe((state) => {
      const failures = state.filter((item) => item.status === 'failed');
      const success = state.filter((item) => item.status === 'success');

      const successData = success.map((r) => {
        const data = r.data as PortalUserData;
        return {
          id: data?.id,
          firstName: data?.first_name,
          lastName: data?.last_name,
          email: data.email
        };
      });

      const failureData = failures.map((r) => {
        const req = r.data as PortalUserData;
        return {
          id: v4(),
          firstName: req?.first_name,
          lastName: req?.last_name,
          email: req.email,
          error: r.error,
          button: true,
          requestBody: r.requestBody
        };
      });

      setDisplayData({ successData, failureData });
    });

    return () => {
      if (formSub) formSub.unsubscribe();
      if (formSubmitStateSub) formSubmitStateSub.unsubscribe();
    };
  }, []);

  const { addUserToTeam } = usePortalUsers();

  const successCols: GridColDef[] = [
    { headerName: 'First', field: 'firstName', width: 150 },
    { headerName: 'Last', field: 'lastName', width: 150 },
    { headerName: 'Email', field: 'email', width: 150 }
  ];

  const failureCols: GridColDef[] = [
    { headerName: 'First', field: 'firstName', width: 150 },
    { headerName: 'Last', field: 'lastName', width: 150 },
    { headerName: 'Email', field: 'email', width: 150 },
    {
      headerName: '',
      field: 'button',
      renderCell: (params) => (
        <button
          className="flex items-center"
          onClick={() => addUserToTeam(params?.row?.requestBody, form.type === 'manager')}>
          <Icon
            className="mr-2"
            type="retry"
          />
          Retry
        </button>
      )
    }
  ];

  return (
    <PageLayout
      width="sm:w-3/4 lg:w-1/2 2xl:w-1/3"
      pageActions={pageActions()}
      actionLocation="bottom">
      <div className="pt-4 w-30">
        <TextBlock
          color="grey6"
          size="base">
          {form.account_name} - {form.team_name}
        </TextBlock>
      </div>
      <div className="pt-2 pb-6 border-b-2 w-30">
        <TextBlock
          color="black"
          weight="medium"
          size="xxl">
          {getHeaderCopy()}
        </TextBlock>
      </div>
      <PageCard
        childrenWrapperClass="flex justify-center"
        title={`${getSectionHeaderCopy(displayData.successData)} Added`}
        titleIcon="greenCheckmark">
        <BasicDataGrid
          rows={displayData.successData}
          columns={successCols}
        />
      </PageCard>

      {!!displayData.failureData.length && (
        <PageCard
          childrenWrapperClass="flex justify-center"
          title={`${getSectionHeaderCopy(displayData.failureData)} were not added`}
          titleIcon="alert">
          <BasicDataGrid
            rows={displayData.failureData}
            columns={failureCols}
          />
        </PageCard>
      )}
      <hr className="mt-4 h-0.5 border-t-2" />
      <TextBlock
        weight="medium"
        className="my-4">
        Next Steps
      </TextBlock>
      <div className="mb-4">
        {getLinks().map((link) => {
          return (
            <button
              role="link"
              key={link.to}
              onClick={link.onClick}
              className="flex space-x-2 items-center"
              data-cy={link.to}>
              <Icon
                type="plus"
                className="p-2"
              />
              <TextSpan
                color="indigo"
                size="sm14">
                {link.label}
              </TextSpan>
            </button>
          );
        })}
      </div>
      <hr className="mt-4 h-0.5 border-t-2" />
    </PageLayout>
  );
};
