import { callExternalApi } from '../callExternalApi';

export interface FilePublicUrlResponse {
  url: string;
}

export const getAttachmentPublicUrl = async (id: string, filename: string) => {
  return await callExternalApi<FilePublicUrlResponse>({
    url: `api/files/${id}/public-link/${filename}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
