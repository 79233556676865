import { useSearchParams } from 'react-router-dom';
import { CardButton } from '../../Buttons/CardButton';

export type DetailCTARendererProps = {
  data: object;
  value: string;
  valueFormatted: string;
  onClick?: () => void;
};

export function DetailCTARenderer({ data, value, valueFormatted, onClick }: DetailCTARendererProps) {
  const valueString: string = valueFormatted || value;
  const [params, setParams] = useSearchParams();
  const currentId = data['id' as keyof typeof data];

  const handleDetailClick = () => {
    params.get('detailId') === currentId ? params.delete('detailId') : params.set('detailId', currentId);
    setParams(params, { replace: true });
    if (onClick) onClick();
  };

  return (
    <div className="mt-3">
      <div className="desc-wrap">{valueString}</div>
      <CardButton
        data-testid="detail-cta-button"
        data-cy="detail-cta-button"
        onClick={handleDetailClick}
        className="h-4"
        icon="details"
      />
    </div>
  );
}
