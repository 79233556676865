import { usaCurrency } from '../../../utils/helpers';
import { InventoryAsset } from '../../../types';
import { Icon } from '../../../components/Atoms/Icon';

export interface InfoWindowContentProps {
  onClick: () => void;
  inventoryAssets: InventoryAsset[];
}

export const InfoWindowContent = ({ inventoryAssets, onClick }: InfoWindowContentProps) => {
  return (
    <div className="w-[370px] info-window text-black font-[400]">
      <div className="flex mb-[5px]">
        <Icon
          className="p-[4.5px] ml-[32.5px] mr-[10px]"
          type="pin"
        />
        <div className="text-[14px] font-medium tracking-normal leading-[18px] truncate">
          {inventoryAssets?.[0]?.addressInfo?.locationName}
        </div>
      </div>
      <button
        className="ml-[51.5px] flex mb-[15px] text-[12px] leading-[15px] text-indigo"
        onClick={onClick}>
        {inventoryAssets?.[0]?.address}
      </button>
      <hr />
      <div className="mt-[15px] ml-[15px] text-left">
        <div className="flex text-[10px] leading-3 text-grey-4 tracking-[0.4px]">
          <div className="w-2/5 py-2 font-medium">SERVICE</div>
          <div className="w-2/5 py-2 font-medium">PROVIDER</div>
          <div className="w-1/5 py-2 font-medium">MRC</div>
        </div>
        {inventoryAssets.map((asset) => {
          return (
            <div
              key={asset.id}
              className="flex border-b border-gray-2 leading-[13px] text-black text-[12px]">
              <div className="w-2/5 py-2 font-normal">{asset?.product?.name}</div>
              <div className="w-2/5 py-2 font-normal">{asset?.supplier?.name}</div>
              <div className="w-1/5 py-2 font-normal">{usaCurrency.format(asset.mRC)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
