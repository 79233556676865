import { callExternalApi } from '../callExternalApi';

export type ContactRequest = {
  subject: string;
  message: string;
};

export type ContactResponse = {
  errors?: string[];
  message?: string;
};

export async function postContactRequest(requestBody: ContactRequest, accountId: string | undefined) {
  const url = 'api/contact_requests';
  return await callExternalApi<ContactResponse>({
    url,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      AccountScope: accountId
    },
    data: { contact_request: requestBody }
  });
}
