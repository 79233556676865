import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DetailsDisplay } from '../../Compounds/DetailsDrawer/DetailsDisplay';
import { Text } from '../../Atoms/Text';
import { CardButton } from '../../Compounds/Buttons/CardButton';
import { useEffect, useState } from 'react';
import { InventoryAsset, Order, PortalUserData, SupportTicketsItem } from '../../../types';
import {
  ComponentName,
  DetailsMeta,
  getComponentName,
  getMetaData
} from '../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { Subscription } from 'rxjs';
import { getInventorySubject, useInventory } from '../../../store/Inventory';
import { getOrderSubject, useOrders } from '../../../store/Order';
import { getTicketSubject, useSupportTickets } from '../../../store/SupportTicket';

export function DetailsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
  const [title, setTitle] = useState<string | undefined>('');
  const [componentName, setComponentName] = useState<ComponentName | undefined>(undefined);
  const [data, setData] = useState<InventoryAsset | SupportTicketsItem | Order | PortalUserData | null>(null);
  const [search] = useSearchParams();
  const detailId = search.get('detailId');
  const { fetchInventory } = useInventory();
  const { fetchTickets } = useSupportTickets();
  const { fetchOrders } = useOrders();

  let subject: Subscription;

  const loadData = async () => {
    switch (componentName) {
      case 'inventory':
        await fetchInventory();
        break;
      case 'order':
        await fetchOrders();
        break;
      case 'support':
        await fetchTickets();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setData(null);
    if (subject) subject.unsubscribe();

    const segment = getComponentName(location?.pathname);
    const { componentName, title }: DetailsMeta = getMetaData(segment);
    setTitle(title);
    setComponentName(componentName);

    switch (componentName) {
      case 'inventory':
        subject = getInventorySubject().subscribe((dataCollection: Array<InventoryAsset>) => getData(dataCollection));
        break;
      case 'order':
        subject = getOrderSubject().subscribe((dataCollection: Array<Order>) => getData(dataCollection));
        break;
      case 'support':
        subject = getTicketSubject().subscribe((dataCollection: Array<SupportTicketsItem>) => getData(dataCollection));
        break;
      default:
        break;
    }

    return () => {
      if (subject) subject.unsubscribe();
    };
  }, [detailId, backClicked]);

  const toggleBackClicked = () => {
    setBackClicked(true);
    navigate(-1);
  };

  const getData = (dataCollection: Array<InventoryAsset | SupportTicketsItem | Order | PortalUserData>) => {
    if (detailId) {
      const dataItem = dataCollection.find((item) => item?.id == detailId);
      if (dataItem) setData(dataItem);
    }
  };

  useEffect(() => {
    if (detailId && componentName && !data) loadData();
  }, [detailId, componentName]);

  return (
    <div className="px-12 pt-10">
      {componentName !== undefined ? (
        <>
          <div className="flex items-center p-3">
            <Text
              className="font-semibold text-lg"
              dataTestId="details-view-title">
              {title}
            </Text>
            <CardButton
              text="Back"
              onClick={toggleBackClicked}
              className="h-8 px-2 pl-4 ml-auto border border-grey-500 rounded-lg shadow"
            />
          </div>
          <DetailsDisplay
            data={data}
            componentName={componentName}
            setShow={() => false}
          />
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
}
