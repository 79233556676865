import { useEffect, useState } from 'react';
import { getAccountScope, getSelectedAccountsSubject, getUser, updateAccountContext } from '../../../store/User';
import { Account } from '../../../types';
import { useInventory } from '../../../store/Inventory';
import { useOrders } from '../../../store/Order';
import { useSupportTickets } from '../../../store/SupportTicket';
import { AccountMultiSelect } from './AccountMultiSelect';
import { userInSkipRoles } from '../../../store/Role';
import { AccountSelectionForm } from '../UserCreation';
import { useNavigate } from 'react-router-dom';

export const AccountDropdown = () => {
  const [selectedAccounts, setSelectedAccounts] = useState<Account[] | undefined>(getAccountScope());
  const user = getUser();
  const { fetchInventory } = useInventory();
  const { fetchOrders } = useOrders();
  const { fetchTickets } = useSupportTickets();
  const navigate = useNavigate();

  const handleAccountSelect = async (accounts: Account[] | null) => {
    await updateAccountContext(accounts || []);

    if (accounts?.length) {
      await Promise.all([fetchInventory(true), fetchOrders(true), fetchTickets(true)]);
    }

    setSelectedAccounts(accounts || undefined);
    if (!accounts?.length) navigate('');
  };

  useEffect(() => {
    const acctSub = getSelectedAccountsSubject().subscribe((accts) => {
      if (accts && accts.length) {
        setSelectedAccounts(accts);
        Promise.all([fetchInventory(true), fetchOrders(true), fetchTickets(true)]);
      }
    });

    return () => {
      if (acctSub) acctSub.unsubscribe();
    };
  }, []);

  return (
    <>
      {!userInSkipRoles(user.roles.map((r) => r.name)) && (
        <AccountMultiSelect
          accounts={user.accounts}
          handleAccountSelect={handleAccountSelect}
        />
      )}
      {selectedAccounts && userInSkipRoles(user.roles.map((r) => r.name)) && (
        <AccountSelectionForm
          accounts={user.accounts}
          setSelectedAccount={(v) => handleAccountSelect(v ? [v] : null)}
          selectedAccount={selectedAccounts?.[0] || null}
          styles="flex items-center w-72"
          showLabel={false}
          inputStyles="h-[1.75rem]"
          styleOverride={{
            '.MuiInputBase-input': {
              height: '0.75rem'
            },
            'MuiFormControl-root': {
              height: 'unset'
            }
          }}
        />
      )}
    </>
  );
};
