import { callExternalApi } from '../../callExternalApi';

export type ClientRequest = {
  userId: string;
};

export type ClientResponse = {
  userId?: string;
};

export async function postClientMail(requestBody: ClientRequest) {
  const url = 'api/send-client-email';
  return await callExternalApi<ClientResponse>({
    url,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: { userId: requestBody.userId }
  });
}
