import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';

interface DetailsCommentsCTAProps {
  setIsFormOpen: (isFormOpen: boolean) => void;
}

export function DetailsCommentsCTA({ setIsFormOpen }: DetailsCommentsCTAProps) {
  return (
    <>
      <TextSpan className="flex items-center space-x-2">
        <Icon type="plus" />
        <button
          className="text-[indigo] text-sm"
          data-cy="new-attachment"
          onClick={() => setIsFormOpen(true)}>
          New Comment
        </button>
      </TextSpan>
    </>
  );
}
