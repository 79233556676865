/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Notification, updateNotification } from '../../../../store/Notifications';
import { SupportTicketsItem } from '../../../../types';
import { TextBlock } from '../../../Atoms/Text';

export const SupportTicketNotification = ({ notification }: { notification: Notification }) => {
  const message = notification.notification_message;
  const entity = message.content.entity as SupportTicketsItem;

  return (
    <div
      className="space-y-1 hover:cursor-pointer ml-9"
      onClick={() =>
        updateNotification({
          ...notification,
          is_read: true
        })
      }>
      <TextBlock
        size="sm14"
        weight="bold">
        Support {message?.message_type}
      </TextBlock>
      <TextBlock
        size="sm"
        weight="normal">
        {entity?.description}
      </TextBlock>
    </div>
  );
};
