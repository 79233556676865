/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Notification, updateNotification } from '../../../../store/Notifications';
import { TextBlock } from '../../../Atoms/Text';

export const InstallUpdate = ({ notification }: { notification: Notification }) => {
  const message = notification.notification_message.content;
  return (
    <div
      className="space-y-1 hover:cursor-pointer ml-9"
      onClick={() =>
        updateNotification({
          ...notification,
          is_read: true
        })
      }>
      <TextBlock
        size="sm14"
        weight="bold">
        Install Update
      </TextBlock>
      <pre className="font-sans text-sm whitespace-pre-line">{message.after_val || ''}</pre>
    </div>
  );
};
