import { Draggable } from '@hello-pangea/dnd';
import { Card } from '../../Atoms/Card';
import { SrOnlyText, Text } from '../../Atoms/Text';
import { Toggle } from '../../Atoms/Toggle';
import { ChangeEventHandler } from 'react';

export interface DraggableCardProps {
  draggableId: string;
  index: number;
  label: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export function DraggableCard({ draggableId, index, label, checked, onChange }: DraggableCardProps) {
  return (
    <Draggable
      draggableId={draggableId}
      index={index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}>
          <Card
            className="flex items-center bg-white p-3 my-0 mb-4"
            enableShadow>
            <span
              {...provided.dragHandleProps}
              className="drag-button p-2 mr-3">
              <SrOnlyText>drag icon</SrOnlyText>
            </span>
            <label className="flex grow items-center">
              <Text
                size="sm"
                dataTestId={label}>
                {label}
              </Text>
              <Toggle
                id={draggableId}
                className="ml-auto float-right mr-[5px] inline-block"
                checked={checked}
                onChange={onChange}
                dataTestId={label}
              />
            </label>
          </Card>
        </div>
      )}
    </Draggable>
  );
}
