import { BehaviorSubject } from 'rxjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { S3Attachment } from '../types';
import { v4 } from 'uuid';
import { addError } from './Error';
import { initS3Attachment } from '../Api/Attachments/useAttachmentsHelpers';
import {
  deleteS3AttachmentApi,
  downloadS3AttachmentApi,
  getS3AttachmentsApi,
  uploadS3AttachmentApi
} from '../Api/Admin/S3Attachments/s3AttachmentApi';
import { useSnackbar } from '../Context/SnackbarContext';

export interface S3AttachmentReturn {
  queuedS3Attachments: S3Attachment[];
  uploadedS3Attachments: S3Attachment[];
  updateS3Attachments: (files: File[]) => void;
  cancelS3Upload: (attachmentId: string) => void;
  retryS3Upload: (attachmentId: string) => void;
  deleteS3Attachment: (attachmentId: string) => Promise<boolean | undefined>;
  uploadS3Attachment: () => void;
  getS3Attachments: (id: string, entityType: string) => Promise<void>;
  setS3EntityId: Dispatch<SetStateAction<string>>;
  setS3EntityType: Dispatch<SetStateAction<string>>;
}

export interface S3AttachmentState {
  queuedAttachments: S3Attachment[];
  attachments: S3Attachment[];
}

const defaultS3AttachmentState: S3AttachmentState = {
  queuedAttachments: [],
  attachments: []
};

const emitErrorState = (message?: string): void => addError('s3-attachments', message);
const loadingSubject = new BehaviorSubject<boolean>(false);
const uploadingSubject = new BehaviorSubject<boolean>(false);
const s3AttachmentSubject = new BehaviorSubject<S3AttachmentState>(defaultS3AttachmentState);

export const getS3AttachmentsUploadingSubject = (): BehaviorSubject<boolean> => uploadingSubject;
export const getS3AttachmentsLoadingSubject = (): BehaviorSubject<boolean> => loadingSubject;
export const get3AttachmentSubject = (): BehaviorSubject<S3AttachmentState> => s3AttachmentSubject;
export const getQueuedAttachments = (): S3Attachment[] => s3AttachmentSubject.value.queuedAttachments;
export const getAttachments = (): S3Attachment[] => s3AttachmentSubject.value.attachments;
export const getAttachmentsByEntityId = (id: string): S3Attachment[] =>
  s3AttachmentSubject?.value?.attachments?.filter((att: S3Attachment) => att.entity_id === id);

export const cancelQueuedAttachment = (id: string) =>
  updateS3AttachmentState({ queuedAttachments: getQueuedAttachments().filter((att) => att.id !== id) });

export const removeAttachment = (id: string) =>
  updateS3AttachmentState({
    attachments: getAttachments().filter((att) => att.id !== id)
  });

export const updateS3AttachmentState = (val: Partial<S3AttachmentState>) => {
  const update = { ...s3AttachmentSubject.value, ...val };
  s3AttachmentSubject.next(update);
};

export const downloadS3Attachment = async (attachmentId: string): Promise<string | null | undefined> => {
  const response = await downloadS3AttachmentApi(attachmentId);

  if (response.error) return null;

  return URL.createObjectURL(response.data);
};

export function useS3Attachments(): S3AttachmentReturn {
  const [s3EntityId, setS3EntityId] = useState<string>('');
  const [s3EntityType, setS3EntityType] = useState<string>('');
  const [queuedS3Attachments, setQueuedS3Attachments] = useState<S3Attachment[]>([]);
  const [uploadedS3Attachments, setUploadedS3Attachments] = useState<S3Attachment[]>([]);
  const { setSnack } = useSnackbar();

  useEffect(() => {
    uploadS3Attachment();
  }, [queuedS3Attachments]);

  useEffect(() => {
    const attachmentSubscription = get3AttachmentSubject().subscribe(({ queuedAttachments }: S3AttachmentState) => {
      setQueuedS3Attachments(queuedAttachments);
    });

    return () => {
      if (attachmentSubscription) attachmentSubscription.unsubscribe();
    };
  }, []);

  const updateS3Attachments = (files: File[]) => {
    const newQueuedAttachments = files?.map((file) => initS3Attachment(file, v4(), s3EntityId, s3EntityType));
    if (newQueuedAttachments) {
      updateS3AttachmentState({ queuedAttachments: [...getQueuedAttachments(), ...newQueuedAttachments] });
    }
  };

  const cancelS3Upload = (attachmentId: string) => {
    const attachment = queuedS3Attachments?.find((a) => a.id === attachmentId);
    if (attachment) {
      attachment.cancelSource.cancel();
      cancelQueuedAttachment(attachmentId);
    }
  };

  const retryS3Upload = (attachmentId: string) => {
    const newQueuedAttachments = [...queuedS3Attachments];
    const attachmentIndex = newQueuedAttachments?.findIndex((a) => a.id === attachmentId);

    // Re-initialize attachment to update all fields.
    const file: File = newQueuedAttachments[attachmentIndex].file;
    newQueuedAttachments[attachmentIndex] = initS3Attachment(file, attachmentId, s3EntityId, s3EntityType);

    updateS3AttachmentState({ queuedAttachments: newQueuedAttachments });
  };

  const deleteS3Attachment = async (attachmentId: string): Promise<boolean | undefined> => {
    const response = await deleteS3AttachmentApi(attachmentId);

    if (response.error) {
      return false;
    }

    removeAttachment(attachmentId);
    setUploadedS3Attachments(getAttachmentsByEntityId(s3EntityId));
    return true;
  };

  const updateQueuedS3Attachments = (a: S3Attachment) => {
    const attachmentUpdate = [...queuedS3Attachments];
    const attachmentIndex = attachmentUpdate.indexOf(a);
    attachmentUpdate[attachmentIndex] = a;
    updateS3AttachmentState({ queuedAttachments: attachmentUpdate });
  };

  const uploadS3Attachment = async () => {
    queuedS3Attachments
      ?.filter((queuedAttachment) => queuedAttachment.status === 'queued')
      ?.map(async (attachment) => {
        if (attachment.file) {
          uploadingSubject.next(true);
          attachment.status = 'uploading';
          updateQueuedS3Attachments(attachment);

          const progressHandler = (progressEvent: { loaded: number; total: number }) => {
            const { loaded, total } = progressEvent;
            // Stop at 95 to account for SF upload on server side.
            attachment.progress = Math.min(Math.round((loaded / total) * 100), 95);
            updateQueuedS3Attachments(attachment);
          };

          const res = await uploadS3AttachmentApi(attachment, progressHandler);

          if (res.error) {
            const isCancel = res.error.message === 'canceled';
            attachment.status = isCancel ? 'canceled' : 'error';
          } else {
            attachment.progress = 100;
            attachment.status = 'success';
            attachment.id = res.data.id;
            attachment.created_at = res.data.created_at;
            attachment.filename = res.data.filename;
            attachment.presigned_url = res.data.presigned_url;
            updateS3AttachmentState({ attachments: [...getAttachments(), ...[attachment]] });
            setUploadedS3Attachments([...getAttachments(), ...[attachment]]);
            setSnack({ message: 'Your attachments have been uploaded successfully', type: 'success', open: true });
          }

          updateQueuedS3Attachments(attachment);
          uploadingSubject.next(false);
        }
      });
  };

  const getS3Attachments = async (id: string, entityType: string) => {
    if (!id) return;

    emitErrorState();
    loadingSubject.next(true);
    setUploadedS3Attachments([]);
    updateS3AttachmentState({ queuedAttachments: [] });

    const relatedAttachments = getAttachmentsByEntityId(id);
    if (relatedAttachments?.length) {
      setUploadedS3Attachments(relatedAttachments);
    } else {
      const { error, data } = await getS3AttachmentsApi(id, entityType);

      if (error) emitErrorState(error.message);

      if (data) {
        updateS3AttachmentState({ attachments: [...getAttachments(), ...data] });
        setUploadedS3Attachments(getAttachmentsByEntityId(id));
      }
    }

    loadingSubject.next(false);
  };

  return {
    queuedS3Attachments,
    uploadedS3Attachments,
    updateS3Attachments,
    cancelS3Upload,
    retryS3Upload,
    deleteS3Attachment,
    uploadS3Attachment,
    getS3Attachments,
    setS3EntityId,
    setS3EntityType
  };
}
