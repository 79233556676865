import React, { ChangeEvent } from 'react';
import { Box, Checkbox, Chip, FormControlLabel, TextField } from '@mui/material';
import { emailRegex } from '../../../utils/helpers';

interface AdditionalRecipientsProps {
  additionalRecipients: string[] | undefined;
  handleAdd: () => void;
  additionalRecipInput: string | undefined;
  setAdditionalRecipInput: (newVal: string) => void;
  saveDefault: boolean;
  setSaveDefault?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void | undefined;
  onAdditionalRecipientsDelete: (email: string) => () => void;
  showDefaultBox: boolean;
  styles?: {
    divClassName?: string;
    textField?: object;
    box?: object;
    chip?: object;
    formControlLabel?: object;
  };
}

const AdditionalRecipients: React.FC<AdditionalRecipientsProps> = ({
  additionalRecipients,
  handleAdd,
  additionalRecipInput,
  setAdditionalRecipInput,
  saveDefault,
  setSaveDefault,
  onAdditionalRecipientsDelete,
  showDefaultBox,
  styles
}) => {
  const isInvalid: boolean = !!additionalRecipInput && !emailRegex.test(additionalRecipInput || '');
  return (
    <div>
      <div className={styles?.divClassName}>
        <TextField
          error={isInvalid}
          placeholder="Additional Recipients"
          className="w-full"
          size="small"
          helperText={isInvalid ? 'Enter valid email address' : null}
          sx={styles?.textField}
          value={additionalRecipInput || ''}
          onChange={(e) => setAdditionalRecipInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAdd();
            }
          }}
          onBlur={handleAdd}
        />
      </div>

      <div className={styles?.divClassName}>
        {showDefaultBox && (
          <FormControlLabel
            sx={styles?.formControlLabel}
            control={
              <Checkbox
                checked={saveDefault}
                onChange={setSaveDefault}
              />
            }
            label="Set these additional recipients as default"
          />
        )}
        <Box sx={styles?.box}>
          {additionalRecipients?.map((v, index) => (
            <Chip
              sx={styles?.chip}
              key={`${index}-${v}`}
              label={v}
              onDelete={onAdditionalRecipientsDelete(v)}
            />
          ))}
        </Box>
      </div>
    </div>
  );
};

export default AdditionalRecipients;
