import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { TextBlock } from '../../Atoms/Text';

export interface ConfirmationModalProps {
  close: () => void;
  items: number;
  ctaAction: () => void;
  type: 'Save' | 'Delete';
  wrapperClassName?: string;
  modalClassName?: string;
}

export const ConfirmationModal = ({
  close,
  items,
  type,
  wrapperClassName,
  modalClassName,
  ctaAction
}: ConfirmationModalProps) => {
  const defaultWrapperClassName = 'p-4 border-2 border-[F7F7F7] rounded-lg bg-white';
  const defaultModalClassName = 'pb-2 border-b-2';
  const getHeaderText = () => {
    if (type === 'Save') return 'Confirm New Inventory';
    return 'Are you sure you want to permanently delete this inventory record?';
  };

  const getBodyText = () => {
    if (type === 'Save')
      return `You've entered ${items} new services and products. Save to add them to your inventory.`;
    return '';
  };

  return (
    <div className={wrapperClassName || defaultWrapperClassName}>
      <div className={modalClassName || defaultModalClassName}>
        <TextBlock
          size="lg"
          className="mb-4">
          {getHeaderText()}
        </TextBlock>
        {type === 'Save' && <TextBlock className=" my-4">{getBodyText()}</TextBlock>}
      </div>
      <div className="flex flex-row justify-end mt-4">
        <button
          className={`${DEFAULT_CANCEL_BUTTON_STYLE} mr-2`}
          onClick={close}>
          Cancel
        </button>
        <button
          className={`${DEFAULT_ADVANCE_BUTTON_STYLE} ml-2`}
          onClick={() => ctaAction()}>
          {type}
        </button>
      </div>
    </div>
  );
};
