import { useNavigate } from 'react-router-dom';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';

export const TeamSettingsPageLink = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate('/team-settings')}
      role="link"
      className="flex items-center space-x-1">
      <Icon type="settings" />
      <TextSpan
        size="xs"
        color="grey5">
        Team Settings
      </TextSpan>
    </button>
  );
};
