import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { DetailViewCard } from '../../../../Compounds/CardWithTitle';
import { BasicDataGrid } from '../../../../Compounds/Table/BasicDataGrid';
import { Team } from '../../../../../store/Team';
import { updateState } from '../../../../../store/GeneralStore';
import { DetailCTARenderer } from '../../../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { Link } from 'react-router-dom';
import { adminUrlForRole } from '../../../../../store/User';
import { Icon } from '../../../../Atoms/Icon';
import { TextSpan } from '../../../../Atoms/Text';

export interface TeamParams {
  teams: Team[];
}

export const Teams = ({ teams }: TeamParams) => {
  const columns: GridColDef[] = [
    {
      field: 'details',
      headerName: '',
      sortable: false,
      width: 65,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const onClick = () => updateState({ admin: { selectedTab: 1 } });
        return (
          <DetailCTARenderer
            data={params}
            value={''}
            valueFormatted={''}
            onClick={onClick}
          />
        );
      },
      hideable: false,
      disableReorder: true
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'team_members',
      headerName: 'Members',
      flex: 1,
      valueGetter: (row, value) => value?.team_members?.length
    }
  ];

  const teamActions = () => {
    const state = {
      prevpath: location.pathname
    };

    return (
      <Link
        role="link"
        title="New Team"
        className="flex space-x-2 items-center"
        data-cy="account-details-new-team"
        state={state}
        to={`${adminUrlForRole()}/new-team`}>
        <Icon
          type="plus"
          className="p-2"
        />
        <TextSpan
          color="indigo"
          size="sm14">
          New Team
        </TextSpan>
      </Link>
    );
  };

  return (
    <DetailViewCard
      title="Teams"
      enableCollapse
      collapsed
      cardActions={teamActions()}>
      <BasicDataGrid
        rows={teams}
        columns={columns}
        className="h-96 overflow-auto"
      />
    </DetailViewCard>
  );
};
