import { TeamResponse } from '../../../store/Team';
import { callExternalApi, CallResult } from '../../callExternalApi';

export type PostTeamRequest = {
  name: string;
  account_id?: string | null;
};

export const fetchTeamsApi = async (): Promise<CallResult<TeamResponse[]>> => {
  return await callExternalApi<TeamResponse[]>({
    url: 'api/admin/teams',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const createTeamApi = async (requestBody: PostTeamRequest) => {
  return await callExternalApi<TeamResponse>({
    url: 'api/admin/teams',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: requestBody
  });
};

export const deleteTeamApi = async (teamId: string) => {
  return await callExternalApi<void>({
    url: `api/admin/teams/${teamId}`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    }
  });
};
