import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';
import { useNavigate } from 'react-router-dom';

export const AdminPageLink = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('/admin')}
      role="link"
      className="flex items-center space-x-1">
      <Icon type="settings" />
      <TextSpan
        size="xs"
        color="grey5">
        Admin
      </TextSpan>
    </button>
  );
};
