import { AccountCustomizationData, AccountCustomizationContent } from '../../../store/AccountCustomization';
import { callExternalApi } from '../../callExternalApi';

export type PostAccountCustomizationRequest = {
  account_id: string;
  account_name: string;
  content: AccountCustomizationContent;
};

export const postAccountCustomizationApi = async (requestBody: PostAccountCustomizationRequest) => {
  return await callExternalApi<AccountCustomizationData>({
    url: 'api/preferences/account_customizations',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: requestBody
  });
};

export const fetchAccountCustomizationsApi = async () => {
  return await callExternalApi<AccountCustomizationData[]>({
    url: 'api/preferences/account_customizations',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const updateAccountCustomizationApi = async (
  id: string,
  requestBody: PostAccountCustomizationRequest,
  type?: string
) => {
  const params = type ? `?type=${type}` : '';
  return await callExternalApi<{ account: AccountCustomizationData; emails: string[] }>({
    url: `api/preferences/account_customizations/${id}${params}`,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: requestBody
  });
};
