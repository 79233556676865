import { DeleteBtn } from './DeleteBtn';

export interface AttachmentDeleteBtnProps {
  attachmentId: string;
  deleteAttachment: (attachmentId: string) => Promise<boolean | undefined>;
}

export function AttachmentDeleteBtn({ attachmentId, deleteAttachment }: AttachmentDeleteBtnProps) {
  return (
    <DeleteBtn
      copyMap={{
        default: 'Are you sure you want to permanently delete this attachment?',
        deleting: 'Deleting attachment...',
        error: 'Something went wrong while deleting your file. Would you like to try again?'
      }}
      deleteItem={deleteAttachment}
      deleteItemId={attachmentId}
      deleteTriggerDescription="delete attachment button"
    />
  );
}
