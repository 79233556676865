import { Menu, MenuItem, Typography } from '@mui/material';
import { DetailComponentProps } from '../MultiselectDropdown';
import { Icon } from '../../Atoms/Icon';

export const MultiSelectRoleExpand: React.FC<DetailComponentProps> = ({
  anchorEl,
  onClose,
  title,
  options
}: DetailComponentProps) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => onClose({ optionAnchor: null, showOptionsFor: null })}>
      <MenuItem sx={{ pl: 2 }}>
        <div className="flex justify-between w-[100%]">
          <Typography variant="h6">{title}</Typography>
          <button
            onClick={() => onClose({ optionAnchor: null, showOptionsFor: null })}
            className="text-sm text-gray-400 hover:text-gray-950">
            X
          </button>
        </div>
      </MenuItem>
      {options.map((name) => (
        <MenuItem
          key={name}
          sx={{ pl: 3 }}>
          <div className="flex items-center">
            <Icon
              className="p-2 mr-[10px] box-border"
              type="greenCheckmark"
            />
            <Typography>{name}</Typography>
          </div>
        </MenuItem>
      ))}
    </Menu>
  );
};
