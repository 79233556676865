import {
  DataGridPro,
  GridColDef,
  GridPinnedColumnFields,
  GridSingleSelectColDef,
  GridValidRowModel,
  useGridApiRef
} from '@mui/x-data-grid-pro';

import { ComponentSpinner } from '../Loading/ComponentSpinner';
import {
  GridPreferences,
  PrefKey,
  UserPreferences,
  getCurrentPreferences,
  updateTableState
} from '../../../store/User';
import { CustomToolbar } from './CustomToolbar';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { CustomFilterPanel } from './CustomFilterPanel';
import { Icon } from '../../Atoms/Icon';
import { updateGridRef } from '../../../store/GeneralStore';

export interface UpstackDataGridProps {
  title: string;
  columns: GridSingleSelectColDef[] | GridColDef[] | undefined;
  rows: GridValidRowModel[];
  loadingData: boolean;
  page: PrefKey;
  gridSettings: GridPreferences;
  showSearch: boolean;
  pinnedColumns?: GridPinnedColumnFields;
  dataCy?: string;
  triggerSave?: boolean;
  handleNewTabModalOpen?: () => void;
}

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    title: string;
    showSearch: boolean;
    setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    saveState: () => void;
  }
}

export const DEFAULT_TABLE_STYLES = {
  sx: {
    overflowX: 'scroll',
    overflowY: 'hidden',
    border: 'unset',
    '.MuiDataGrid-cell': {
      fontFamily: 'sans-serif',
      borderLeft: '.5px solid lightgrey',
      '&:first-child': {
        borderLeft: 'unset'
      },
      '&:last-child': {
        borderRight: 'unset'
      }
    },
    '.MuiDataGrid-overlayWrapper': {
      minHeight: '10rem'
    },
    '.MuiDataGrid-columnHeader': {
      fontFamily: 'sans-serif',
      fontSize: '.75rem',
      color: '#878EBE',
      borderLeft: '.5px solid lightgrey',
      borderTop: '.5px solid lightgrey',
      '&:first-child': {
        borderLeft: 'unset !important'
      },
      '&:last-child': {
        borderRight: 'unset'
      }
    }
  },
  headerHeight: 40,
  rowHeight: 40
};

const columnsIcon = () => {
  return <Icon type="columnsIcon" />;
};

const exportIcon = () => {
  return <Icon type="exportIcon" />;
};

const filterIcon = () => {
  return <Icon type="filterIcon" />;
};

export const UpstackDataGrid = ({
  title,
  rows,
  columns,
  loadingData,
  page,
  gridSettings = {} as GridPreferences,
  showSearch = false,
  pinnedColumns,
  dataCy = '',
  triggerSave = false,
  handleNewTabModalOpen
}: UpstackDataGridProps) => {
  const apiRef = useGridApiRef();
  const [initialState, setInitialState] = useState<GridInitialStatePro>();
  const [hiddenColumns, setHiddenColumns] = useState<string[]>();
  const [filterableColumns, setFilterableColumns] = useState<GridSingleSelectColDef[] | GridColDef[]>();
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const [stateDirty, setStateDirty] = useState(false);

  const saveState = useCallback(async () => {
    if (apiRef?.current?.exportState) {
      const currentState = apiRef.current.exportState();
      const settingsCopy = JSON.parse(JSON.stringify(gridSettings));
      const update = { ...settingsCopy, muiConfig: currentState };
      const currentSettings: UserPreferences = JSON.parse(
        JSON.stringify(getCurrentPreferences() || ({} as UserPreferences))
      );

      if (currentSettings?.content) {
        (currentSettings.content[page] as GridPreferences) = update;
        updateTableState(currentSettings, page);
        setStateDirty(false);
      }
    }
  }, [apiRef]);

  useEffect(() => {
    updateGridRef(page, apiRef);
  }, [apiRef]);

  useEffect(() => {
    if (triggerSave) {
      saveState();
      if (handleNewTabModalOpen) handleNewTabModalOpen();
    }
  }, [triggerSave]);

  useEffect(() => {
    const confirmNavigation = (event: { preventDefault: () => void; returnValue: string }) => {
      if (stateDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved table changes. Are you sure you want to leave?';
      }
    };

    window.addEventListener('beforeunload', confirmNavigation);
    return () => {
      window.removeEventListener('beforeunload', confirmNavigation);
    };
  }, [stateDirty]);

  useLayoutEffect(() => {
    window.addEventListener('beforeunload', saveState);
    return () => {
      window.removeEventListener('beforeunload', saveState);
      saveState();
    };
  }, [saveState]);

  useEffect(() => {
    if (apiRef.current) {
      const state: GridInitialStatePro = JSON.parse(
        JSON.stringify(gridSettings.muiConfig || ({} as GridInitialStatePro))
      );

      if (pinnedColumns) {
        state.pinnedColumns = pinnedColumns;
        const { columns = {} } = state;
        const { left = [], right = [] } = pinnedColumns;
        const orderedFields = [
          ...left,
          ...(state?.columns?.orderedFields || []).filter((field) => !left.includes(field) && !right.includes(field)),
          ...right
        ];
        columns.orderedFields = orderedFields;
        state.columns = columns;
      }
      setInitialState(state);

      const hiddenColumns = state?.columns?.columnVisibilityModel
        ? Object.keys(state?.columns?.columnVisibilityModel).filter(
            (field) => !(state?.columns?.columnVisibilityModel || {})[field]
          )
        : [];
      setHiddenColumns(hiddenColumns);
    }
  }, [gridSettings, apiRef.current]);

  useEffect(() => {
    if (initialState && apiRef?.current?.restoreState) {
      apiRef.current.restoreState(initialState);
      setStateDirty(false);
    }
  }, [initialState]);

  useEffect(() => {
    if (columns && hiddenColumns) {
      const updatedColumns = columns.map((column) => ({
        ...column,
        filterable: !hiddenColumns.includes(column.field) && !['details', 'detailCTA'].includes(column.field)
      }));
      setFilterableColumns(updatedColumns);
    } else {
      setFilterableColumns([]);
    }
  }, [columns, hiddenColumns?.length]);

  return (
    <>
      {(loadingData || !initialState) && (
        <div className="h-[45rem] p-32">
          <ComponentSpinner />
        </div>
      )}
      {!loadingData && initialState && filterableColumns && (
        <div className="h-[45rem]">
          <DataGridPro
            rows={rows}
            data-cy={dataCy}
            data-testid="data-grid"
            columns={filterableColumns}
            apiRef={apiRef}
            columnHeaderHeight={DEFAULT_TABLE_STYLES.headerHeight}
            rowHeight={DEFAULT_TABLE_STYLES.rowHeight}
            sx={DEFAULT_TABLE_STYLES.sx}
            pagination
            disableRowSelectionOnClick
            initialState={initialState}
            slots={{
              toolbar: CustomToolbar,
              filterPanel: CustomFilterPanel,
              columnMenuIcon: columnsIcon,
              exportIcon: exportIcon,
              openFilterButtonIcon: filterIcon
            }}
            slotProps={{
              panel: {
                anchorEl: filterButtonEl
              },
              toolbar: {
                showSearch,
                title,
                setFilterButtonEl,
                saveState
              }
            }}
            onFilterModelChange={() => setStateDirty(true)}
            onSortModelChange={() => setStateDirty(true)}
            onColumnOrderChange={() => setStateDirty(true)}
            onColumnVisibilityModelChange={(columnVisibility) => {
              const updatedHiddenColumns = Object.keys(columnVisibility).filter((field) => !columnVisibility[field]);
              setHiddenColumns(updatedHiddenColumns);
              setStateDirty(true);
            }}
            pageSizeOptions={[50, 100, 200]}
          />
        </div>
      )}
    </>
  );
};
