import Form, { IChangeEvent, FormProps } from '@rjsf/core';
import { RJSFSchema, RJSFValidationError, UiSchema, ValidatorType } from '@rjsf/utils';
import defaultValidator from '@rjsf/validator-ajv8';

export interface JSONSchemaFormProps<T> {
  schema: RJSFSchema;
  formData?: T;
  formKey: string;
  uiSchema?: UiSchema;
  validator?: ValidatorType;
  rjsfProps?: Partial<FormProps>;
  onChangeHandler?: (formData: T | null) => void;
  onErrorHandler?: (errors: RJSFValidationError[]) => void;
  onSubmitHandler?: (formData: T | null) => void;
  onBlurHandler?: (formData: T | null) => void;
  onFocusHandler?: (formData: T | null) => void;
}

export const JSONSchemaForm = <T,>({
  formData,
  formKey,
  schema,
  uiSchema,
  validator = defaultValidator,
  rjsfProps,
  onChangeHandler,
  onErrorHandler,
  onSubmitHandler,
  onBlurHandler,
  onFocusHandler
}: JSONSchemaFormProps<T>) => {
  const onChange = (e: IChangeEvent) => {
    onChangeHandler?.(e.formData);
  };

  const onError = (e: RJSFValidationError[]) => {
    onErrorHandler?.(e);
  };

  const onSubmit = (e: IChangeEvent) => {
    onSubmitHandler?.(e.formData);
  };

  const onBlur = (id: string, formData: T) => {
    onBlurHandler?.(formData);
  };

  const onFocus = (id: string, formData: T) => {
    onFocusHandler?.(formData);
  };

  return (
    <Form
      key={formKey}
      formData={formData}
      schema={schema}
      uiSchema={uiSchema}
      validator={validator}
      onChange={onChange}
      onError={onError}
      onSubmit={onSubmit}
      onBlur={onBlur}
      onFocus={onFocus}
      {...rjsfProps}
    />
  );
};
