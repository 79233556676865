import { BehaviorSubject } from 'rxjs';
import {
  bulkUpdateSharedAccountCustomizationApi,
  fetchSharedAccountCustomizationsApi,
  postSharedAccountCustomizationApi,
  PostSharedAccountCustomizationRequest,
  updateSharedAccountCustomizationApi
} from '../Api/Preferences/SharedAccountCustomizations/sharedAccountCustomizationsApi';
import { addError } from './Error';
import { Account } from '../types';

export type SharedAccountCustomizationContent = {
  additionalRecipients?: string[];
};

export type SharedAccountCustomizationData = {
  id?: string;
  account_name: string | undefined;
  account_id: string | undefined;
  updated_at: string;
  content: SharedAccountCustomizationContent;
};

export const defaultSharedAccountCustomizationData = (account_id?: string, account_name?: string) => ({
  account_id: account_id || '',
  account_name: account_name || '',
  content: {
    additionalRecipients: []
  },
  updated_at: ''
});

const sharedAccountCustomizationsSubject = new BehaviorSubject<SharedAccountCustomizationData[]>([]);
const sharedAccountCustomizationsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getSharedAccountCustomizationsLoadingSubject = (): BehaviorSubject<boolean> =>
  sharedAccountCustomizationsLoadingSubject;
export const getSharedAccountCustomizationsSubject = (): BehaviorSubject<SharedAccountCustomizationData[]> =>
  sharedAccountCustomizationsSubject;

export const fetchSharedAccountCustomizations = async (): Promise<void> => {
  sharedAccountCustomizationsLoadingSubject.next(true);

  const { data, error } = await fetchSharedAccountCustomizationsApi();

  if (data) sharedAccountCustomizationsSubject.next(data);
  if (error) addError('shared-account-customization', error.message);

  sharedAccountCustomizationsLoadingSubject.next(false);
};

export const updateSharedAccountCustomizations = async (
  id: string,
  requestBody: PostSharedAccountCustomizationRequest
) => {
  const { error } = await updateSharedAccountCustomizationApi(id, requestBody);
  if (error) {
    addError('shared-account-customization', error.message);
    return false;
  }
  return true;
};

export const createSharedAccountCustomizations = async (requestBody: PostSharedAccountCustomizationRequest) => {
  const { error } = await postSharedAccountCustomizationApi(requestBody);
  if (error) {
    addError('shared-account-customization', error?.message);
    return false;
  }
  return true;
};

export const bulkUpdateSharedAccountCustomizations = async (accts: Account[], data: SharedAccountCustomizationData) => {
  const { error } = await bulkUpdateSharedAccountCustomizationApi(accts, data);
  if (error) {
    addError('shared-account-customization', error.message);
    return false;
  }
  return true;
};
