import { useState } from 'react';
import { TextSpan, TextBlock } from '../../Atoms/Text';
import { useForm } from 'react-hook-form';
import { PostTeamRequest, createTeamApi } from '../../../Api/Admin/Team/teamApi';
import { Icon } from '../../Atoms/Icon';
import { useSnackbar } from '../../../Context/SnackbarContext';
import {
  DEFAULT_ADVANCE_BUTTON_STYLE,
  DEFAULT_CANCEL_BUTTON_STYLE,
  SELECT_INPUT_STYLE
} from '../../../utils/styleHelpers';
import { useTeamsStore } from '../../../store/Team';

export interface FormData {
  name: string;
}

export interface CreateTeamFormProps {
  accountId: string;
  handleTeamCreate?: () => void;
  onClose: () => void;
}

export function CreateTeamForm({ accountId, handleTeamCreate, onClose }: CreateTeamFormProps) {
  const { register, handleSubmit, formState } = useForm<FormData>();
  const [statusMessage, setStatusMessage] = useState('');
  const { setSnack } = useSnackbar();
  const { errors } = formState;
  const { fetchTeams } = useTeamsStore();

  async function handlePostTeamRequest(requestBody: PostTeamRequest) {
    const resp = await createTeamApi(requestBody);
    if (resp.data) {
      setStatusMessage('success');
      if (handleTeamCreate) handleTeamCreate();
      await fetchTeams();
      onClose();
      setSnack({ message: 'Team has been created successfully.', type: 'success', open: true });
    } else {
      setStatusMessage('errors');
      setSnack({ message: 'There was an error sending your request.', type: 'error', open: true });
    }
  }

  const handleSubmitForm = (formData: FormData): void => {
    setStatusMessage('inProgress');
    const requestBody: PostTeamRequest = {
      account_id: accountId,
      name: formData.name
    };
    handlePostTeamRequest(requestBody);
  };

  const disabled = statusMessage == 'inProgress';

  return (
    <>
      <TextBlock className="text-xl mb-2">Add Team</TextBlock>
      {statusMessage == 'errors' && [
        <TextSpan
          className="py-2 text-[12px] px-1 bg-yellow flex block"
          size="sm"
          weight="light"
          color="grey8"
          key="alert-message">
          <Icon
            className="p-2.5 m-2.5 h-5 w-5"
            type="alert"
          />
          Something went wrong on our end. &nbsp;
          <br />
          Please try again.
        </TextSpan>,
        <TextSpan
          className="py-2 text-[12px] block"
          size="sm"
          weight="light"
          color="grey8"
          key="alert-notice">
          If you continue to have trouble, please &nbsp;
          <br />
          contact us at &nbsp;
          <a
            className="underline"
            href="mailto:support@upstack.com">
            support@upstack.com
          </a>
        </TextSpan>
      ]}
      <form
        data-cy="add-team"
        aria-label="Add Team"
        onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="form-group">
          <input
            className={`${SELECT_INPUT_STYLE} ${errors.name && 'border border-[red]'}`}
            data-cy="name"
            id="name"
            {...register('name', {
              required: true
            })}
            placeholder="Name"
            type="text"
          />
          {errors.name && (
            <div
              className="invalid-feedback m-0"
              data-cy="invalid-feedback">
              Please enter a name
            </div>
          )}
        </div>
        <hr className="mt-3 mb-5" />
        <div className="flex justify-between">
          <button
            className={DEFAULT_CANCEL_BUTTON_STYLE}
            disabled={disabled}
            onClick={onClose}
            type="button">
            Cancel
          </button>
          <button
            type="submit"
            disabled={disabled}
            className={DEFAULT_ADVANCE_BUTTON_STYLE}>
            Create
          </button>
        </div>
      </form>
    </>
  );
}
