import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser, isAdmin, isSupport, updateAccountContext } from '../../../store/User';
import { CardButton } from '../Buttons';
import { TextBlock } from '../../Atoms/Text';
import { Account } from '../../../types';
import { SearchGlass } from '../../partials/SearchGlass';
import { INPUT_STYLE, RAISED_DIALOG } from '../../../utils/styleHelpers';
import { useInventory } from '../../../store/Inventory';
import { useOrders } from '../../../store/Order';
import { useSupportTickets } from '../../../store/SupportTicket';

export interface ChangeClientCTAProps {
  asModal?: boolean;
  onClose?: () => void;
}

export function AccountModal({ asModal, onClose }: ChangeClientCTAProps) {
  const { accounts, first_name, last_name } = getUser();
  const navigate = useNavigate();
  const [sortAsc, setSortAsc] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [displayAccounts, setDisplayAccounts] = useState<Account[]>([]);
  const { fetchInventory } = useInventory();
  const { fetchOrders } = useOrders();
  const { fetchTickets } = useSupportTickets();

  const sortedAccounts = sortAsc
    ? accounts.sort((a, b) => (a.name < b.name ? -1 : 1))
    : accounts.sort((a, b) => (b.name < a.name ? -1 : 1));

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const search = e?.currentTarget?.value;
    setTimeout(() => setSearchValue(search), 500);
  };

  const handleAccountSelect = async (account: Account) => {
    await updateAccountContext([account]);

    await Promise.all([fetchInventory(true), fetchOrders(true), fetchTickets(true)]);

    if (asModal && onClose) {
      onClose();
    }

    navigate('');
  };

  useEffect(() => {
    if (searchValue) {
      setDisplayAccounts(
        sortedAccounts.filter((account) => account.name.toLowerCase().includes(searchValue.toLowerCase()))
      );
    } else setDisplayAccounts(sortedAccounts);
  }, [sortedAccounts, searchValue, sortAsc]);

  return (
    <div
      data-testid="account-modal"
      className="overflow-y-auto overflow-x-hidden flex justify-center h-full">
      <div className="grid place-items-center w-[45vw]">
        <div className={asModal ? 'p-5' : RAISED_DIALOG}>
          <div className={asModal ? '' : 'pb-12 p-8'}>
            <TextBlock className="text-xxl font-medium">Select a Client</TextBlock>
            <TextBlock className="text-sm14 py-4 w-4/5">
              Choose which client&#39;s data you would like to view.
            </TextBlock>
            <form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
              className="py-4">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <SearchGlass />
                </div>
                <input
                  onChange={handleSearch}
                  type="search"
                  id="search"
                  name="search"
                  className={INPUT_STYLE}
                  style={{ paddingLeft: '2.5rem', paddingRight: '1rem' }}
                  placeholder="Search"
                />
              </div>
            </form>
            <div className="flex justify-between py-2 mt-2 border-t-2">
              <TextBlock
                color="blue2"
                className="justify-self-start text-sm">
                {first_name} {last_name} - {displayAccounts.length.toLocaleString()}
              </TextBlock>
              <CardButton
                name="sort"
                icon={'sort'}
                role="button"
                onClick={() => setSortAsc(!sortAsc)}
              />
            </div>
            <ul className={`max-h-[43vh] min-h-[23vh] overflow-auto ${asModal ? '' : 'min-w-[40vw] max-w-[40vw]'}`}>
              {!!displayAccounts.length &&
                displayAccounts.map((account) => (
                  <li
                    className="py-2 border-t-2"
                    key={account.id}>
                    <button
                      role={isAdmin(getUser()) || isSupport(getUser()) ? 'button' : 'link'}
                      className="ml-auto"
                      onClick={() => handleAccountSelect(account)}
                      title={`Inspect Account ${account.name}`}>
                      <TextBlock
                        color="indigo"
                        className="text-md">{`${account.name}`}</TextBlock>
                    </button>
                  </li>
                ))}
              {!displayAccounts.length && (
                <li className="mt-12">
                  <TextBlock className="text-center text-sm14">
                    Sorry, there are no clients that match your search criteria.
                  </TextBlock>
                </li>
              )}
            </ul>
            <div className="px-2 mt-2 border-t-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
