import { useNavigate } from 'react-router-dom';
import { TextColor } from '../../../Atoms/Text';
import { adminUrlForRole } from '../../../../store/User';
import DropdownMenu from '../../../Compounds/Menu/DropdownMenu';
import { initAddUsersToTeamForm } from '../../../../store/AddPortalUsersToTeamFormState';

export interface MemberActionsProps {
  teamId: string;
}

export const MemberActions = ({ teamId }: MemberActionsProps) => {
  const navigate = useNavigate();
  const memberMenuOptions = [
    {
      title: 'Create New User',
      fontColor: 'indigo' as TextColor,
      onClick: () => navigate(`${adminUrlForRole()}/new-user`)
    },
    {
      title: 'Add Existing User To Team',
      fontColor: 'indigo' as TextColor,
      onClick: () => {
        initAddUsersToTeamForm(teamId);
        navigate('/admin/teams/add-users');
      }
    }
  ];
  return (
    <DropdownMenu
      icon="plus"
      buttonText="Add Team Member"
      buttonTextColor="indigo"
      options={memberMenuOptions}
      slotProps={{
        paper: {
          style: {
            width: 190
          }
        }
      }}
    />
  );
};
