import { SrOnlyText } from './Text';
import { Text } from './Text';

export interface TooltipProps {
  show?: boolean;
  column?: string;
  value: string;
}

export function Tooltip(props: TooltipProps) {
  const { show = true, column, value } = props;
  return (
    <div role="tooltip">
      <SrOnlyText>tooltip</SrOnlyText>
      {show && (
        <div className="bg-grey-1 border border-grey-2 p-3">
          <pre>
            <Text size="xs">{column ? `${column}: ${value}` : value}</Text>
          </pre>
        </div>
      )}
    </div>
  );
}
