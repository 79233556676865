import { useEffect, useState } from 'react';
import { InventoryAsset } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { MapTableFooter } from './MapTableFooter';

export interface MapTableProps {
  handleAllClick: () => void;
  inventoryAssets: InventoryAsset[];
  checkMapWidth: string;
}

export function MapTable({ handleAllClick, inventoryAssets, checkMapWidth }: MapTableProps) {
  const navigate = useNavigate();
  const assets = inventoryAssets.filter((asset) => !!asset.address);
  const [search, setSearch] = useState('');
  const [displayAssets, setDisplayAssets] = useState<InventoryAsset[]>(assets);
  const tableOverflowClass = displayAssets.length > 5 ? 'h-[250px] overflow-y-scroll' : '';

  useEffect(() => {
    if (search) {
      setDisplayAssets(assets.filter((asset) => asset.address.toUpperCase().includes(search.toUpperCase())));
    } else {
      setDisplayAssets(assets);
    }
  }, [search]);

  const handleClick = (id: string) => {
    navigate(`inventory/${id}?detailId=${id}`);
  };

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const text = e?.currentTarget?.value;
    setTimeout(() => setSearch(text), 500);
  };
  return (
    <>
      <div className="flex flex-row gap-x-[5px]">
        <div className="w-full mx-3.5 mt-[5px] mb-[9px]">
          <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}>
            <input
              onChange={handleSearch}
              type="search"
              id="search"
              name="search"
              className="w-full h-[26px] pl-[10px] text-[10px] text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              data-cy="find-location"
              placeholder="Find a Location..."
            />
          </form>
        </div>
      </div>
      <hr />
      <div
        className={`py-[12px] px-[20px] ${tableOverflowClass}`}
        style={{ width: checkMapWidth }}>
        {displayAssets.map((asset) => (
          <div
            className="border-b py-[15px]"
            data-cy="map-table-row"
            key={asset.id}
            role="row">
            <div className="text-[12px] leading-[15px] flex text-indigo">
              <button
                className="cursor-pointer truncate"
                onClick={() => handleClick(asset.id)}>
                {asset.address}
              </button>
            </div>
          </div>
        ))}
      </div>
      <MapTableFooter handleAllClick={handleAllClick} />
    </>
  );
}
