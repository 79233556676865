import { Product } from '../../types';
import { callExternalApi } from '../callExternalApi';

export const fetchProductsApi = async () => {
  return await callExternalApi<Product[]>({
    url: 'api/products',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
