import { MyTeam } from '../../types';
import { callExternalApi, CallResult } from '../callExternalApi';

export async function fetchMyTeam(accountId?: string): Promise<CallResult<MyTeam>> {
  if (!accountId) return { data: null, error: { message: 'Must provide account ID to fetch asset' } };

  return await callExternalApi<MyTeam>({
    url: 'api/user/my-team',
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      AccountScope: accountId
    }
  });
}
