import { Supplier } from '../../../types';
import { callExternalApi } from '../../callExternalApi';

export const fetchSuppliersApi = async () => {
  return await callExternalApi<Supplier[]>({
    url: 'api/admin/suppliers',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
