import { useMemo, useState } from 'react';
import Text from './Text';

const DEFAULT_DATEPICKER_CLASSES = [
  'ag-custom-component-popup',
  'border',
  'border-grey-2',
  'bg-white',
  'flex',
  'flex-col',
  'p-4',
  '[&>*]:w-full'
].join(' ');

export const abbreviatedMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const abbreviatedDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export interface DatePickerProps {
  date: Date | null;
  handleDateChange: (selectedDate: Date) => void;
}

export function DatePicker({ date, handleDateChange }: DatePickerProps) {
  const selectedDate = date ?? new Date();

  const [visibleDate, setVisibleDate] = useState(selectedDate);
  const visibileYear = visibleDate.getFullYear();
  const visibleMonthIndex = visibleDate.getMonth();

  const visibleCalendarDays: Date[] = useMemo(() => {
    const days = [];
    let i;
    const day = new Date(visibileYear, visibleMonthIndex, 1);
    // Add visible days of the previous month
    for (i = day.getDay() - 1; i >= 0; i--) {
      days.push(new Date(visibileYear, visibleMonthIndex, 0 - i));
    }
    // Then add the current month's days + visible days of the next month
    i = 1;
    while (days.length < 42) {
      days.push(new Date(visibileYear, visibleMonthIndex, i));
      i++;
    }
    return days;
  }, [visibleDate]);

  const handleDecrementMonth = () =>
    setVisibleDate((visibleDate) => {
      const newDate = new Date(visibleDate);
      newDate.setMonth(newDate.getMonth() - 1);
      return newDate;
    });

  const handleIncrementMonth = () =>
    setVisibleDate((visibleDate) => {
      const newDate = new Date(visibleDate);
      newDate.setMonth(newDate.getMonth() + 1);
      return newDate;
    });

  return (
    <div className={DEFAULT_DATEPICKER_CLASSES}>
      <div className="flex items-center justify-between p-2">
        <Text
          className="grow"
          weight="semibold"
          size="sm">
          {`${abbreviatedMonths[visibleMonthIndex]} ${visibileYear}`}
        </Text>
        <div className="flex items-center gap-x-3">
          <button
            className="border-l-0 border-r-[6px] border-r-indigo border-y-[6px] border-y-transparent"
            aria-label="decrement month"
            onClick={() => handleDecrementMonth()}
          />
          <button
            className="h-2 aspect-square rounded-full bg-indigo"
            aria-label="return to current month"
            onClick={() => setVisibleDate(selectedDate)}
          />
          <button
            className="border-r-0 border-l-[6px] border-l-indigo border-y-[6px] border-y-transparent"
            aria-label="increment month"
            onClick={() => handleIncrementMonth()}
          />
        </div>
      </div>
      <div className="flex">
        {abbreviatedDays.map((abbreviatedDay) => (
          <div
            className="w-8 text-center"
            key={abbreviatedDay}>
            <Text
              weight="medium"
              color="grey4"
              size="sm">
              {abbreviatedDay}
            </Text>
          </div>
        ))}
      </div>
      <hr className="my-1" />
      <div className="grid grid-cols-7 grid-rows-6 justify-items-center">
        {visibleCalendarDays.map((calendarDay) => {
          const currentFill = calendarDay.toLocaleDateString() === selectedDate.toLocaleDateString() ? 'bg-grey-2' : '';
          return (
            <button
              key={calendarDay.toLocaleDateString()}
              disabled={calendarDay.getMonth() !== visibleMonthIndex}
              onClick={() => handleDateChange(calendarDay)}
              className={`${currentFill} rounded-md w-8 p-1 hover:bg-grey-2 hover:disabled:bg-white`}>
              <Text
                color={calendarDay.getMonth() !== visibleMonthIndex ? 'grey3' : 'black'}
                size="sm">
                {calendarDay.getDate()}
              </Text>
            </button>
          );
        })}
      </div>
    </div>
  );
}
