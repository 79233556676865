export const validateTokenApi = async (token: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/user/validate_token`, {
    method: 'GET',
    headers: new Headers({
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`
    }),
    keepalive: true
  });
  const data = await response.json();
  const headers = response.headers;
  return response.ok ? { data, headers, error: null } : { data: null, headers, error: data };
};
