import { NavLink } from 'react-router-dom';
import { Text, TextProps } from '../../Atoms/Text';
import { Icon, IconProps } from '../../Atoms/Icon';

interface navLinkProps {
  to: string;
  className?: string;
}

export interface SideBarItemProps {
  className?: string;
  navLinkProps: navLinkProps;
  iconProps: IconProps;
  textProps: TextProps;
  active: boolean;
  dataCy?: string;
}

export const SideBarItem = ({
  className = 'w-full',
  navLinkProps,
  iconProps,
  textProps,
  active,
  dataCy
}: SideBarItemProps) => {
  const defaultNavLinkProps =
    'bg group flex items-center space-x-4 m-2 p-2 pl-1 text-gray-600 overflow-hidden whitespace-nowrap hover:min-w-max hover:bg-sky-100 rounded';

  const navLinkClassName = navLinkProps.className || `${defaultNavLinkProps}${active ? ' bg-sky-100' : ''}`;
  const textClassName = textProps.className || 'group-hover:text-gray-700';
  return (
    <li className={className}>
      <NavLink
        {...navLinkProps}
        data-cy={dataCy}
        className={navLinkClassName}>
        <Icon {...iconProps} />
        <Text
          {...textProps}
          className={textClassName}
          weight={textProps.weight || 'normal'}
        />
      </NavLink>
    </li>
  );
};
