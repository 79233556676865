import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { fetchTeamsApi, PostTeamRequest, createTeamApi, deleteTeamApi } from '../Api/Admin/Team/teamApi';
import { User } from '../types';
import { getAccountName } from './Accounts';
import { isTeamManager } from './User';
import { FormStateStatus, TeamFormProps, getFormCreateStateSubject, getFormsSubject } from './BulkForm';

export interface TeamResponse {
  id: number;
  account_id: string;
  name: string;
  portal_users: User[];
  created_at: string;
  updated_at: string;
}

export interface Team {
  id: string;
  account_name: string | undefined;
  account_id: string | undefined;
  name: string;
  user_count: number | null;
  team_managers_text: string;
  team_members: User[];
  team_managers: User[];
  created_at: string;
}

const teamsSubject = new BehaviorSubject<Team[]>([]);
const teamsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getTeamsSubject = (): BehaviorSubject<Team[]> => teamsSubject;
export const getTeamsLoadingSubject = (): BehaviorSubject<boolean> => teamsLoadingSubject;

export const getTeam = (id: string) => {
  const teams = teamsSubject.getValue();
  const team = teams.find((tm) => tm.id === id);
  return team;
};

export const getTeamsByAccount = (account_id: string) => {
  const teams = teamsSubject.getValue();
  return teams.filter((t) => t.account_id === account_id);
};

export const deleteTeam = async (teamId: string): Promise<boolean | undefined> => {
  const { error } = await deleteTeamApi(teamId);

  if (error) return false;

  const updatedTeams = getTeamsSubject()
    .getValue()
    .filter((team) => team.id !== teamId);
  teamsSubject.next(updatedTeams);
  return true;
};

export interface UseTeamsStoreReturn {
  fetchTeams: () => Promise<void>;
  createTeams: () => Promise<PromiseSettledResult<void>[]>;
  createRequest: (requestBody: PostTeamRequest) => Promise<void>;
}
export const useTeamsStore = (): UseTeamsStoreReturn => {
  const fetchTeams = async (): Promise<void> => {
    teamsLoadingSubject.next(true);

    const { data, error } = await fetchTeamsApi();

    if (data) {
      const formattedTeams: Team[] = [];
      for (const team of data) {
        const managers = team.portal_users.filter((u) => isTeamManager(u));
        const teamMembers = team.portal_users.filter((u) => !isTeamManager(u));

        formattedTeams.push({
          id: String(team.id),
          account_name: getAccountName(team.account_id),
          account_id: team.account_id,
          name: team.name,
          user_count: team?.portal_users?.length,
          team_members: teamMembers,
          team_managers: managers,
          team_managers_text: managers.map((u) => u.email).join(', '),
          created_at: team.created_at
        });
      }
      teamsSubject.next(formattedTeams);
    }

    if (error) addError('teams', error.message);

    teamsLoadingSubject.next(false);
  };

  const createRequest = async (requestBody: PostTeamRequest) => {
    const { data, error } = await createTeamApi(requestBody);
    const currentState = [...getFormCreateStateSubject().getValue()];

    if (error) {
      const update = { status: FormStateStatus.FAILED, data: undefined, error: error.message, requestBody };
      const errorIndex = currentState.findIndex((i) => (i.requestBody as PostTeamRequest).name === requestBody.name);

      if (errorIndex > -1) {
        currentState[errorIndex] = update;
        getFormCreateStateSubject().next([...currentState]);
      } else {
        getFormCreateStateSubject().next([...currentState, update]);
      }
    }

    if (data) {
      getFormCreateStateSubject().next([
        ...currentState,
        { status: FormStateStatus.SUCCESS, data, error: undefined, requestBody }
      ]);
    }
  };

  const createTeams = async () => {
    const forms = getFormsSubject().getValue();

    const promises: Promise<void>[] = forms.map((form) => {
      const { name, account_id } = form as TeamFormProps;
      return createRequest({ name, account_id });
    });

    return await Promise.allSettled(promises);
  };

  return { fetchTeams, createTeams, createRequest };
};
