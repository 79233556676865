import { SupportTicketsItem } from '../../../../types';
import { Link } from 'react-router-dom';
import { Icon } from '../../../Atoms/Icon';
import { getMetaData } from '../../DetailsDrawer/detailsDrawersHelpers';

export type TicketLinkProps = {
  data: SupportTicketsItem;
  classNames?: string;
};

export function TicketLink({ classNames, data }: TicketLinkProps) {
  return (
    <Link
      state={{
        data,
        ...getMetaData('support')
      }}
      className={classNames || 'flex items-center absolute justify-center h-full ml-[7px]'}
      title="Open Ticket"
      to={`/support-ticketing/${data.id}?detailId=${data.id}`}>
      <Icon
        type="details"
        className="p-3 ml-auto cursor-pointer"
      />
    </Link>
  );
}
