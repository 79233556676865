import { callExternalApi } from '../callExternalApi';

export const downloadAttachment = async (attachmentId: string) => {
  return callExternalApi<Blob>({
    url: `api/files/${attachmentId}/download`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    },
    responseType: 'blob'
  });
};
