import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { InventoryAsset, SupportTicketsItem } from '../../../../types';
import { Text } from '../../../Atoms/Text';
import { DetailViewCard } from '../../../Compounds/CardWithTitle';
import { getMetaData } from '../../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { getInventoryByIdSubject, getInventoryLoadingSubject, useInventory } from '../../../../store/Inventory';
import { getTicketLoadingSubject } from '../../../../store/SupportTicket';
import { AttachmentTable } from '../../../Compounds/AttachmentTable';
import { ComponentSpinner } from '../../../Compounds/Loading/ComponentSpinner';

export interface SupportTicketDetailsProps {
  data: SupportTicketsItem;
}

export function SupportTicketDetails({ data }: SupportTicketDetailsProps) {
  const location = useLocation();
  const [inventory, setInventory] = useState<InventoryAsset | undefined>(undefined);
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [ticketsLoading, setTicketsLoading] = useState<boolean>(false);
  const { dateTimeOpened, dateTimeClosed, priority, status, subject, ticketType, issue, submittedBy } = data;
  const state = {
    inventory,
    prevpath: location.pathname,
    ...getMetaData('inventory')
  };
  const { fetchInventoryById } = useInventory();

  useEffect(() => {
    const subscription = getInventoryByIdSubject().subscribe((asset) => {
      setInventory(asset);
    });
    const ticketLoadingSub = getTicketLoadingSubject().subscribe((loading: boolean) => {
      setTicketsLoading(loading);
    });

    const inventoryLoadingSub = getInventoryLoadingSubject().subscribe((loading: boolean) => {
      setInventoryLoading(loading);
    });

    return () => {
      if (subscription) subscription.unsubscribe();
      if (ticketLoadingSub) ticketLoadingSub.unsubscribe();
      if (inventoryLoadingSub) inventoryLoadingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (data?.Associated_Inventory__c) {
      fetchInventoryById(data?.Associated_Inventory__c);
    }
  }, [data.id]);

  return (
    <>
      <DetailViewCard
        collapsed={false}
        title="Details">
        <div className="px-5">
          {(ticketsLoading || inventoryLoading) && (
            <div className="mt-6">
              <ComponentSpinner />
            </div>
          )}
          {!ticketsLoading && !inventoryLoading && (
            <>
              <div className="flex">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Address'}
                </Text>
                <Link
                  className="w-2/3"
                  state={state}
                  to={`/inventory/${inventory?.id}?detailId=${inventory?.id}`}>
                  <Text
                    className="font-sans font-normal text-indigo"
                    size="sm">
                    {inventory?.address || inventory?.id}
                  </Text>
                </Link>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Supplier'}
                </Text>
                <Text
                  className="font-sans font-normal w-2/3"
                  size="sm">
                  {inventory?.supplier?.name || ''}
                </Text>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Product'}
                </Text>
                <Text
                  className="font-sans font-normal"
                  size="sm">
                  {inventory?.product?.name || ''}
                </Text>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Subject'}
                </Text>
                <Text
                  className="font-sans font-normal w-2/3"
                  size="sm">
                  {subject}
                </Text>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Status'}
                </Text>
                <Text
                  className="font-sans font-normal"
                  size="sm">
                  {status}
                </Text>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Ticket Type'}
                </Text>
                <Text
                  className="font-sans font-normal"
                  size="sm">
                  {ticketType}
                </Text>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Issue'}
                </Text>
                <Text
                  className="font-sans font-normal"
                  size="sm">
                  {issue}
                </Text>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Priority'}
                </Text>
                <Text
                  className="font-sans font-normal"
                  size="sm">
                  {priority}
                </Text>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Submitted By'}
                </Text>
                <Text
                  className="font-sans font-normal"
                  size="sm">
                  {submittedBy}
                </Text>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Date/Time Opened'}
                </Text>
                <Text
                  className="font-sans font-normal"
                  size="sm">
                  {dateTimeOpened}
                </Text>
              </div>

              <div className="flex mt-10">
                <Text
                  className="font-sans text-grey-4 w-1/3"
                  size="sm14">
                  {'Date/Time Closed'}
                </Text>
                <Text
                  className="font-sans font-normal"
                  size="sm">
                  {dateTimeClosed}
                </Text>
              </div>
            </>
          )}
        </div>
      </DetailViewCard>
      {data.id && <AttachmentTable id={data.id} />}
    </>
  );
}
