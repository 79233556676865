const TOGGLE_CONTAINER_STYLES = [
  'inline-flex',
  'shrink-0',
  'h-full',
  'w-full',
  'cursor-pointer',
  'rounded-full',
  'border-2',
  'border-transparent',
  'transition-colors',
  'duration-200',
  'ease-in-out'
].join(' ');

export interface ToggleProps {
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  width?: number;
  height?: number;
  className?: string;
  disabled?: boolean;
  id?: string;
  dataTestId?: string;
}

const getBackgroundClass = (isChecked: boolean, disabled: boolean) => {
  if (disabled) return 'bg-grey-5 pointer-events-none';
  return isChecked ? 'bg-indigo' : 'bg-gray-200';
};

const getGrowClass = (isChecked: boolean) => {
  return isChecked ? 'grow' : 'grow-0';
};

export const Toggle = ({
  checked = false,
  onChange,
  width = 7,
  height = 4,
  className = '',
  disabled = false,
  id,
  dataTestId
}: ToggleProps) => {
  const isChecked = checked && !disabled;

  const backgroundClass = getBackgroundClass(isChecked, disabled);
  const growClass = getGrowClass(isChecked);
  const defaultWidth = `w-${width}`;
  const defaultHeight = `h-${height}`;

  return (
    <span
      className={`${defaultWidth} ${defaultHeight} ${className}`}
      role="switch"
      data-testid={`${dataTestId} toggle`}
      aria-checked={isChecked}>
      <input
        id={id}
        className="hidden"
        disabled={disabled}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <span className={`${TOGGLE_CONTAINER_STYLES} ${backgroundClass}`}>
        <span className={`${growClass} transition-all duration-200 ease-in-out`} />
        <span
          className="h-full aspect-square rounded-full bg-white shadow"
          aria-hidden="true"
        />
      </span>
    </span>
  );
};
