// eslint-disable-next-line @typescript-eslint/no-explicit-any
import { callExternalApi } from '../../callExternalApi';
import { S3Attachment } from '../../../types';
import { ProgressHandler } from '../../Attachments/uploadAttachment';

export const getS3AttachmentsApi = async (entityId: string, entityType: string) => {
  return callExternalApi<S3Attachment[]>({
    url: `api/attachments?entity_id=${entityId}&entity_type=${entityType}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const uploadS3AttachmentApi = (attachment: S3Attachment, progressHandler: ProgressHandler) => {
  const formData = new FormData();
  formData.append('file', attachment.file);
  formData.append('entity_id', attachment.entity_id);
  formData.append('entity_type', attachment.entity_type);

  return callExternalApi<S3Attachment>({
    url: 'api/attachments',
    method: 'POST',
    headers: {
      'content-type': 'multipart/form-data'
    },
    cancelToken: attachment.cancelSource.token,
    data: formData,
    onUploadProgress: progressHandler
  });
};

export const downloadS3AttachmentApi = async (attachmentId: string) => {
  return callExternalApi<Blob>({
    url: `api/attachments/download/${attachmentId}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    },
    responseType: 'blob'
  });
};

export const deleteS3AttachmentApi = (attachmentId: string) => {
  return callExternalApi<void>({
    url: `api/attachments/${attachmentId}`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    }
  });
};
