import { useRef } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Icon } from '../../Atoms/Icon';

export interface AttachmentCTAProps {
  addAttachments: (files: File[]) => void;
}

export function AttachmentCTA({ addAttachments }: AttachmentCTAProps) {
  const addAttachmentRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Tooltip
        title="Add Attachment"
        placement="top">
        <IconButton
          className="self-center"
          aria-label="Add Attachment"
          data-testid="addAttachmentButton"
          onClick={() => addAttachmentRef.current?.click()}>
          <Icon type="newDoc" />
        </IconButton>
      </Tooltip>
      <input
        hidden
        ref={addAttachmentRef}
        type="file"
        multiple={true}
        data-testid="attachment-input"
        onChange={(e) => addAttachments(Array.from(e.target.files as FileList))}
      />
    </>
  );
}
