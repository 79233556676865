import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { GridFilterInputValueProps, GridSingleSelectColDef, useGridApiContext } from '@mui/x-data-grid-pro';

export const AutoCompleteFilter = (props: GridFilterInputValueProps) => {
  const { applyValue, item } = props;
  const apiRef = useGridApiContext();
  const { valueOptions = [] } = apiRef.current.getColumn(item.field) as GridSingleSelectColDef;

  return (
    <Autocomplete
      id="autocomplete"
      options={valueOptions as string[]}
      value={item.value}
      sx={{ marginTop: 'auto', width: '15rem' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="select value"
        />
      )}
      size="small"
      onChange={(event, newValue) => {
        applyValue({ ...item, value: newValue });
      }}
    />
  );
};
