/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import DOMPurify from 'dompurify';
import { Notification, updateNotification } from '../../../../store/Notifications';
import { TextBlock } from '../../../Atoms/Text';
import { Comment } from '../../../../store/Comment';

export const CommentMentionNotification = ({ notification }: { notification: Notification }) => {
  const message = notification.notification_message;
  const entity = message.content.entity as Comment;
  const messageText =
    entity.comment_text.length > 250 ? `${entity.comment_text.substring(0, 250)}<span>...</span>` : entity.comment_text;

  return (
    <div
      className="space-y-1 hover:cursor-pointer ml-9"
      onClick={() =>
        updateNotification({
          ...notification,
          is_read: true
        })
      }>
      <TextBlock
        size="sm14"
        weight="bold">
        @{entity.portal_user_name} mentioned you
      </TextBlock>
      <TextBlock
        size="sm"
        weight="normal">
        {DOMPurify.sanitize(messageText, { RETURN_DOM: true }).innerText}
      </TextBlock>
    </div>
  );
};
