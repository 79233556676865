// eslint-disable-next-line @typescript-eslint/no-explicit-any

import { callExternalApi } from '../callExternalApi';
import { Comment } from '../../store/Comment';

export const getCommentsApi = async (entityId: string, entityType: string) => {
  return callExternalApi<Comment[]>({
    url: `api/comments?entity_id=${entityId}&entity_type=${entityType}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const createCommentApi = (comment: Comment) => {
  return callExternalApi<Comment>({
    url: 'api/comments',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: comment
  });
};

export const updateCommentApi = async (comment: Comment) => {
  return await callExternalApi<Comment>({
    url: `api/comments/${comment.id}`,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: comment
  });
};

export const archiveCommentApi = (id: string) => {
  return callExternalApi<Comment>({
    url: `api/comments/archive/${id}`,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    }
  });
};
