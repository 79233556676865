import { GridCellParams, GridColumnHeaderParams, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DetailCTARenderer } from '../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { getDate, usaCurrency } from '../../../utils/helpers';
import { format } from 'date-fns';
import { allOperators, booleanOperators, standardOperators } from '../../Compounds/UpstackDataGrid/helpers';
import { UpstackSourcedRenderer } from '../../Compounds/UpstackSourcedRenderer';
import { getAccountName } from '../../../store/Accounts';

export const DefaultInventoryGridColumnDefs: GridSingleSelectColDef[] = [
  {
    field: 'details',
    headerName: 'Details',
    sortable: false,
    width: 65,
    filterable: false,
    type: 'singleSelect',
    valueOptions: [],
    renderCell: (params: GridCellParams) => (
      <DetailCTARenderer
        data={params}
        value={''}
        valueFormatted={''}
      />
    ),
    hideable: false,
    disableReorder: true
  },
  {
    field: 'account_id',
    headerName: 'Account',
    sortable: true,
    width: 300,
    type: 'singleSelect',
    valueOptions: [],
    valueFormatter: (value) => {
      return getAccountName(value);
    },
    valueGetter: (value, row) => getAccountName(row?.account_id),
    disableReorder: true,
    filterable: true,
    filterOperators: standardOperators()
  },
  {
    field: 'id',
    headerName: 'Id',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'addressInfo.number',
    headerName: 'Location Number',
    sortable: true,
    width: 200,
    filterable: true,
    valueGetter: (value, row) => row?.addressInfo?.number,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'address',
    headerName: 'Address',
    sortable: true,
    width: 300,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'orderType',
    headerName: 'Order Type',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'productDisplay',
    headerName: 'Product',
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'supplier.name',
    headerName: 'Supplier',
    valueGetter: (value, row) => row?.supplier?.name,
    sortable: true,
    width: 112,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'accessProvider',
    headerName: 'Local LEC',
    sortable: true,
    width: 140,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'mRC',
    headerName: 'Monthly Cost',
    sortable: true,
    valueFormatter: (value) => {
      return `${usaCurrency.format(value)}`;
    },
    width: 125,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'nRC',
    headerName: 'One-Time Cost',
    sortable: true,
    valueFormatter: (value) => {
      return `${usaCurrency.format(value)}`;
    },
    width: 125,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'term',
    headerName: 'Term',
    sortable: true,
    valueFormatter: (value: string) => {
      return !value || value.includes('Month') ? value : `${value} Months`;
    },
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'completionDate',
    headerName: 'Install Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 190,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'serviceActivationDate',
    headerName: 'Activation Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 190,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'contractEndDate',
    headerName: 'Est. Contract End Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'providerAccountNumber',
    headerName: 'Provider Account #',
    sortable: true,
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'UPSTACK Managed',
    renderHeader: (params: GridColumnHeaderParams) => {
      return (
        <img
          src="/UPSTACK_logo_black.svg"
          className="w-4"
          alt="upstack logo"
        />
      );
    },
    sortable: true,
    renderCell: (params: GridCellParams) => <UpstackSourcedRenderer upstackManaged={!!params.value} />,
    width: 50,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: booleanOperators(),
    disableReorder: true,
    disableColumnMenu: true
  },
  {
    field: 'supplierOrderNumber',
    headerName: 'Supplier Order #',
    sortable: true,
    width: 160,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'disconnectedReason',
    headerName: 'Disconnected Reason',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'accessType',
    headerName: 'Access Type',
    sortable: true,
    width: 145,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'bandwidth',
    headerName: 'Bandwidth',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'portSpeed',
    headerName: 'Port Speed',
    sortable: true,
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'accessSpeed',
    headerName: 'Access Speed',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'equipmentDetails',
    headerName: 'Equipment Details',
    sortable: true,
    width: 170,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'circuitIDs',
    headerName: 'Carrier Circuit ID',
    sortable: true,
    width: 350,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'lecCircuitID',
    headerName: 'Local Circuit ID',
    sortable: true,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'iPDetails',
    headerName: 'IP Addresses',
    sortable: true,
    width: 600,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'phoneNumbers',
    headerName: 'Assigned Phone Numbers',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'rackType',
    headerName: 'Rack Type',
    sortable: true,
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'circuitType',
    headerName: 'Circuit Type',
    sortable: true,
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'kwRack',
    headerName: 'kW Rack',
    sortable: true,
    width: 135,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'cageRackID',
    headerName: 'Cage Rack ID',
    sortable: true,
    width: 175,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationALecCircuitId',
    headerName: 'Location A LEC Circuit ID',
    sortable: true,
    width: 260,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationALecDemarc',
    headerName: 'Location A LEC Demarc',
    sortable: true,
    width: 250,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationALocalLec',
    headerName: 'Location A Local LEC',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationAText',
    headerName: 'Location A',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationZLecCircuitId',
    headerName: 'Location Z LEC Circuit ID',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationZLecDemarc',
    headerName: 'Location Z LEC Demarc',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationZLocalLec',
    headerName: 'Location Z Local LEC',
    sortable: true,
    width: 120,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationZText',
    headerName: 'Location Z',
    sortable: true,
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'connectivityA',
    headerName: 'Connectivity A',
    sortable: true,
    width: 115,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'connectivityB',
    headerName: 'Connectivity B',
    sortable: true,
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationContact.name',
    headerName: 'Location Contact Name',
    valueGetter: (value, row) => row?.locationContact?.name,
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationContact.phone',
    headerName: 'Location Contact Phone',
    valueGetter: (value, row) => row?.locationContact?.phone,
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'locationContact.email',
    headerName: 'Location Contact Email',
    valueGetter: (value, row) => row?.locationContact?.email,
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'equipmentManufacturer',
    headerName: 'Equipment Manufacturer',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'equipmentType',
    headerName: 'Equipment Type',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'firstInvoiceReviewedByDate',
    headerName: 'First Invoice Reviewed By Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 250,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'disconnectedDate',
    headerName: 'Disconnected Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 130,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'lecDemarc',
    headerName: 'LEC Demarc',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'line_item_count',
    headerName: 'Line Items',
    sortable: true,
    width: 160,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  }
];
