import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { PageLayout } from '../../PageLayout';
import { PageCard } from '../../Compounds/CardWithTitle';
import { BasicDataGrid } from '../../Compounds/Table/BasicDataGrid';
import {
  clearFormCreateSubject,
  getOutsideInventoryCreateStateSubject,
  resubmitIndividualitem
} from '../../../store/OutsideInventory';
import { FormStateStatus } from '../../../store/BulkForm';
import { InventoryAsset } from '../../../types';
import { Icon } from '../../Atoms/Icon';
import { useInventory } from '../../../store/Inventory';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';

export interface BaseDisplayData {
  product: string;
  supplier: string;
  providerAccountNumber: string | undefined;
  button?: boolean;
}

export interface DisplayData {
  successData: BaseDisplayData[];
  failureData: BaseDisplayData[];
}

export const AddInventorySummary = () => {
  const [displayData, setDisplayData] = useState<DisplayData>({ successData: [], failureData: [] });
  const [refetching, setRefetching] = useState(false);
  const navigate = useNavigate();
  const { fetchInventory } = useInventory();

  const handleContinue = async () => {
    setRefetching(true);
    await fetchInventory(true);
    setRefetching(false);
    navigate('/inventory');
  };

  useEffect(() => {
    const outsideInvCreateStateSub = getOutsideInventoryCreateStateSubject().subscribe((state) => {
      const failures = state.filter((item) => item.status === FormStateStatus.FAILED);
      const success = state.filter((item) => item.status === FormStateStatus.SUCCESS);

      const successData = success.map((r) => {
        const data = r.data as InventoryAsset;
        return {
          id: data?.id,
          product: data.product.name,
          supplier: data.supplier.name,
          providerAccountNumber: data.providerAccountNumber
        };
      });

      const failureData = failures.map((r) => {
        const req = r.requestBody as InventoryAsset;
        return {
          id: r.id,
          product: req.product.name,
          supplier: req.supplier.name,
          providerAccountNumber: req.providerAccountNumber,
          error: r.error,
          button: true,
          requestBody: r.requestBody
        };
      });

      setDisplayData({ successData, failureData });
    });

    return () => {
      if (outsideInvCreateStateSub) outsideInvCreateStateSub.unsubscribe();
      clearFormCreateSubject();
    };
  }, []);

  const successCols: GridColDef[] = [
    { headerName: 'Product', field: 'product', width: 250 },
    { headerName: 'Supplier', field: 'supplier', width: 250 },
    { headerName: 'Provider Account #', field: 'providerAccountNumber', width: 250 }
  ];

  const failureCols: GridColDef[] = [
    { headerName: 'Product', field: 'product', width: 250 },
    { headerName: 'Supplier', field: 'supplier', width: 250 },
    { headerName: 'Provider Account #', field: 'providerAccountNumber', width: 250 },
    {
      headerName: '',
      field: 'button',
      renderCell: (params) => (
        <button
          className="flex items-center"
          onClick={() => resubmitIndividualitem(params?.row?.id, params?.row?.requestBody)}>
          <Icon
            className="mr-2"
            type="retry"></Icon>
          Retry
        </button>
      )
    }
  ];

  const pageActions = () => (
    <button
      onClick={() => handleContinue()}
      className={DEFAULT_ADVANCE_BUTTON_STYLE}>
      Continue
    </button>
  );

  return (
    <PageLayout
      width="sm:w-3/4 lg:w-3/4 2xl:w-1/2"
      pageTitle="Inventory Added"
      pageActions={!refetching ? pageActions() : undefined}
      actionLocation="bottom">
      {refetching && <ComponentSpinner />}
      {!refetching && (
        <>
          <PageCard
            title={`${displayData.successData.length} inventory created successfully`}
            titleIcon="greenCheckmark">
            <BasicDataGrid
              rows={displayData.successData}
              columns={successCols}
            />
          </PageCard>

          {!!displayData.failureData.length && (
            <PageCard
              title={`${displayData.failureData.length} inventory were not created`}
              titleIcon="alert">
              <BasicDataGrid
                rows={displayData.failureData}
                columns={failureCols}
              />
            </PageCard>
          )}
          <hr className="mt-4 h-0.5 border-t-2" />
        </>
      )}
    </PageLayout>
  );
};
