import { callExternalApi } from '../callExternalApi';
import { Configuration } from '../../store/Configuration';

export const getConfigurationApi = async () => {
  return callExternalApi<Configuration>({
    url: 'api/configuration',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
