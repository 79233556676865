import React, { useEffect, useState, createRef } from 'react';
import Form from '@rjsf/core';
import { RJSFSchema, RegistryWidgetsType } from '@rjsf/utils';
import { Account, InventoryAsset, OutsideInventorySchema, Product } from '../../../types';
import {
  CurrencyInput,
  DateInput,
  JSONSchemaForm,
  TextInput,
  SupplierSelectionInput,
  ProductAddressInput,
  TermSelectionInput,
  ShowAdvancedButton
} from '../JSONSchemaForm';
import { getProductSubject } from '../../../store/Product';
import { getOutsideInventorySchemaSubject } from '../../../store/OutsideInventorySchema';
import { getAccounts, getAccountScope } from '../../../store/User';
import { defaultOutsideInventoryForm, updateFormProduct } from '../../../store/OutsideInventory';
import { ProductSelectionForm } from '../ProductSelectionForm';
import { AccountSelectionForm } from '../UserCreation/AccountSelectionForm';
export interface OutsideInventoryFormComponentProps {
  formId: string;
  updateForm: (id: string, formData: InventoryAsset, dirty?: boolean, valid?: boolean) => void;
  formData: InventoryAsset;
  validateForm: boolean;
}

export const OutsideInventoryForm = ({
  formId,
  formData,
  validateForm,
  updateForm
}: OutsideInventoryFormComponentProps) => {
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [productSearchQuery, setProductSearchQuery] = useState('');
  const [product, setProduct] = useState<Product | null>(null);
  const [allSchemas, setAllSchemas] = useState<OutsideInventorySchema[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(
    getAccountScope()?.length === 1 ? getAccountScope()?.[0] : undefined
  );

  const [schema, setSchema] = useState<OutsideInventorySchema | null>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = createRef<Form<any, RJSFSchema, any>>();

  useEffect(() => {
    const productSub = getProductSubject().subscribe((products) => setAllProducts(products));
    const schemasSub = getOutsideInventorySchemaSubject().subscribe((schemas) => setAllSchemas(schemas));

    return () => {
      if (productSub) productSub.unsubscribe();
      if (schemasSub) schemasSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (product) {
      const matchedSchema = updateFormProduct(formId, product, allSchemas, selectedAccount?.id);
      if (matchedSchema) setSchema(matchedSchema);
      else updateForm(formId, defaultOutsideInventoryForm().formData, false);
    }
  }, [product, selectedAccount]);

  const handleInputChange = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    setProductSearchQuery(value || '');
  };

  const handleProductSelectionChange = (e: React.SyntheticEvent<Element, Event>, value: Product | null) => {
    setProduct(value);
  };

  const handleChange = (form: InventoryAsset | null) => {
    const update: InventoryAsset | null = { ...formData, ...form };
    const formValid = formRef.current?.validateForm();
    updateForm(formId, update, true, formValid);
  };

  const widgets: RegistryWidgetsType = {
    SupplierSelectionInput: SupplierSelectionInput,
    TermSelectionInput: TermSelectionInput,
    ProductAddressInput: ProductAddressInput,
    TextInput: TextInput,
    DateInput: DateInput,
    CurrencyInput: CurrencyInput,
    ShowAdvancedButton: ShowAdvancedButton
  };

  return (
    <div className="w-full border-b-2">
      <div className="flex space-x-4">
        <AccountSelectionForm
          accounts={getAccounts() || []}
          setSelectedAccount={(v) => setSelectedAccount(v)}
          selectedAccount={selectedAccount || null}
          styles="mb-7 w-72 mt-4"
          showLabel={true}
          inputStyles="h-[1.75rem]"
          size="medium"
        />
        <ProductSelectionForm
          disabled={!selectedAccount}
          product={product}
          allProducts={allProducts}
          productSearchQuery={productSearchQuery}
          handleInputChange={handleInputChange}
          handleProductSelectionChange={handleProductSelectionChange}
          classNames="mb-7 w-72 mt-4"
        />
      </div>
      {product && schema && (
        <JSONSchemaForm
          formKey={formData.id}
          schema={schema.schema.validation_schema}
          uiSchema={schema.schema.ui_schema}
          formData={formData}
          onChangeHandler={handleChange}
          rjsfProps={{
            widgets: widgets,
            idPrefix: 'outside_inventory_form',
            idSeparator: `_${formId}_`,
            showErrorList: false,
            liveValidate: validateForm,
            ref: formRef
          }}
        />
      )}
    </div>
  );
};
