import { PageCard } from '../../../Compounds/CardWithTitle';
import { useEffect, useState } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DefaultAccountGridColumnDefs } from '../ColumnDefs/AccountGridColumnDefs';
import {
  GridPreferences,
  getDefaultPreferences,
  getPreferencesByKey,
  getUserPreferencesSubject
} from '../../../../store/User';
import { UpstackDataGrid } from '../../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { getGridColumns } from '../../../Compounds/UpstackDataGrid/helpers';
import { AccountData, getAccountDataSubject, getAccountLoadingSubject } from '../../../../store/Accounts';
import { combineLatest } from 'rxjs';
import { getPortalUsersLoadingSubject } from '../../../../store/PortalUser';
import { getAccountCustomizationsLoadingSubject } from '../../../../store/AccountCustomization';
import { DetailsDrawer } from '../../../Compounds/DetailsDrawer';
import { useSearchParams } from 'react-router-dom';
import { getMetaData } from '../../../Compounds/DetailsDrawer/detailsDrawersHelpers';

export const AccountsTab = () => {
  const [accountColumns, setAccountColumns] = useState<GridSingleSelectColDef[]>();
  const [accountData, setAccountData] = useState<AccountData[]>([]);
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences().content['account_grid'] as GridPreferences
  );
  const [accountLoading, setAccountLoading] = useState<boolean>(false);
  const [search] = useSearchParams();
  const detailId = search.get('detailId');

  useEffect(() => {
    const loadingSubscription = combineLatest([
      getAccountLoadingSubject(),
      getPortalUsersLoadingSubject(),
      getAccountCustomizationsLoadingSubject()
    ]).subscribe((data) => setAccountLoading(data.every((i) => i)));

    const settingsSub = getUserPreferencesSubject().subscribe(() => {
      const gridPrefs = getPreferencesByKey('account_grid') as GridPreferences;
      if (gridPrefs) {
        setGridSettings(gridPrefs);
      }
    });

    const accountDataSub = getAccountDataSubject().subscribe((d) => setAccountData(d));

    return () => {
      if (settingsSub) settingsSub.unsubscribe();
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (accountDataSub) accountDataSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (accountData.length > 0) {
      setAccountColumns(getGridColumns(accountData, DefaultAccountGridColumnDefs, 'account_grid'));
    }
  }, [accountData, accountData.length]);

  return (
    <PageCard
      title="Accounts"
      dataCy="admin-accounts-tab">
      <UpstackDataGrid
        title="Accounts"
        rows={accountData}
        columns={accountColumns}
        loadingData={accountLoading}
        page="account_grid"
        gridSettings={gridSettings}
        showSearch={true}
      />
      <DetailsDrawer
        data={accountData?.find((a) => a?.id === detailId)}
        showDrawer={!!detailId}
        {...getMetaData('account')}
      />
    </PageCard>
  );
};
