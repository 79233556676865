import { InventoryAsset } from '../../types';
import { ProgressHandler } from '../Attachments/uploadAttachment';
import { callExternalApi } from '../callExternalApi';

export interface ValidationError {
  error: string;
  column: string;
  row: number;
  external_id: string;
  id: string;
  color?: string;
}

export interface ValidationSuccess {
  id: string;
  account_id: string;
  supplier_id: string;
  product_id: string;
  content: InventoryAsset;
  created_at: string;
  updated_at: string;
  external_id: string;
}
export interface FileValidationResponse {
  success: ValidationSuccess[];
  errors: ValidationError[];
  warnings: ValidationError[];
}

export interface FileUploadResponse {
  success: ValidationSuccess[];
}

export interface BulkUploadRequest {
  file: File;
  progressHandler: ProgressHandler;
  accountId: string;
  accountName: string;
  message?: string;
}

export const createOutsideInventory = async (body: InventoryAsset) => {
  return await callExternalApi<InventoryAsset>({
    url: 'api/outside_inventory',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: body
  });
};

export const updateOutsideInventory = async (id: string, body: InventoryAsset) => {
  return await callExternalApi<InventoryAsset>({
    url: `api/outside_inventory/${id}`,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: body
  });
};

export const deleteOutsideInventory = async (id: string) => {
  return await callExternalApi<void>({
    url: `api/outside_inventory/${id}`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const uploadXLFileValidation = (file: File, progressHandler: ProgressHandler, accountId?: string) => {
  if (!accountId) return { data: null, error: { message: 'Must provide account ID to upload files.' } };
  // Build form object
  const formData = new FormData();
  formData.append('file', file);

  return callExternalApi<FileValidationResponse>({
    url: 'api/oi/validate',
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data',
      AccountScope: accountId
    },
    data: formData,
    onUploadProgress: progressHandler
  });
};

export const uploadXLFile = (file: File, progressHandler: ProgressHandler, accountId?: string) => {
  if (!accountId) return { data: null, error: { message: 'Must provide account ID to upload files.' } };
  // Build form object
  const formData = new FormData();
  formData.append('file', file);

  return callExternalApi<FileUploadResponse>({
    url: 'api/oi/upload',
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data',
      AccountScope: accountId
    },
    data: formData,
    onUploadProgress: progressHandler
  });
};

export const bulkUploadRequestApi = (data: BulkUploadRequest) => {
  if (!data.accountId) return { data: null, error: { message: 'Must provide account ID to upload files.' } };
  // Build form object
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('account_id', data.accountId);
  formData.append('account_name', data.accountName);
  if (data.message) formData.append('message', data.message);

  return callExternalApi<FileUploadResponse>({
    url: 'api/oi/upload/request',
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data',
      AccountScope: data.accountId
    },
    data: formData,
    onUploadProgress: data.progressHandler
  });
};
