import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { TextBlock } from '../../Atoms/Text';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { User } from '../../../types';

export interface AddPortalUserToTeamProps {
  account: string;
  team: string;
  header: string;
  users: User[];
  selectedUsers: User[];
  valid: boolean;
  addUsers: (user: User[]) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const AddPortalUserToTeam = ({
  account,
  header,
  team,
  users,
  selectedUsers,
  valid,
  addUsers,
  onSave,
  onCancel
}: AddPortalUserToTeamProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSave = () => {
    setSearchQuery('');
    return onSave();
  };

  const handleCancel = () => {
    setSearchQuery('');
    return onCancel();
  };

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: User[]) => {
    addUsers(value);
  };

  return (
    <div className="flex flex-row justify-center h-[95vh] w-full">
      <div className="flex flex-col items-center h-[90vh]">
        <div className="pt-4 w-[30vw]">
          <TextBlock
            color="grey6"
            size="base">
            {account} - {team}
          </TextBlock>
        </div>
        <div className="pt-2 pb-6 border-b-2 w-[30vw]">
          <TextBlock
            color="black"
            weight="medium"
            size="xxl">
            {header}
          </TextBlock>
        </div>
        <div className="w-[30vw] pb-4 border-b-2 mt-6">
          <Autocomplete
            id="user-contact-selection-input"
            autoSelect={false}
            value={selectedUsers}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disablePortal={true}
            disableCloseOnSelect={true}
            inputValue={searchQuery}
            onInputChange={(e, val: string) => setSearchQuery(val)}
            onChange={handleChange}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            options={users}
            getOptionLabel={(option: User) => `${option.first_name} ${option.last_name}`}
            onBlur={() => null}
            disabled={false}
            filterSelectedOptions={true}
            freeSolo={false}
            multiple={true}
            size={'small'}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User List"
                type="input"
                InputProps={{ ...params.InputProps, style: { fontSize: '.875rem' } }}
                error={false}
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                key={`${option.id}-${option.email}`}>
                {option.first_name} {option.last_name}
              </li>
            )}
          />
        </div>
        <div className="py-4 w-[30vw]">
          <div className="flex flex-row justify-between">
            <button
              className={DEFAULT_CANCEL_BUTTON_STYLE}
              onClick={handleCancel}>
              Cancel
            </button>
            <button
              className={DEFAULT_ADVANCE_BUTTON_STYLE}
              disabled={!valid}
              onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
