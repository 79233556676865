import { useState } from 'react';
import { TextBlock } from '../../Atoms/Text';
import { Account } from '../../../types';
import { Autocomplete, TextField } from '@mui/material';
import { INPUT_STYLE } from '../../../utils/styleHelpers';

export interface AccountSelectionFormProps {
  accounts: Account[];
  selectedAccount: Account | null;
  setSelectedAccount: (acct: Account | undefined) => void;
  showHeader?: boolean;
  size?: 'small' | 'medium' | undefined;
  styles?: string;
  disabled?: boolean;
  showLabel?: boolean;
  inputStyles?: string;
  styleOverride?: object;
}

export const AccountSelectionForm = ({
  accounts,
  selectedAccount,
  setSelectedAccount,
  showHeader = false,
  size = 'small',
  styles,
  disabled = false,
  showLabel = true,
  inputStyles = '',
  styleOverride
}: AccountSelectionFormProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: Account | null) => {
    setSelectedAccount(value || undefined);
  };

  return (
    <>
      {showHeader && <TextBlock className="text-md">Select the account associated with this user.</TextBlock>}
      <div
        id="user-account-selection-form"
        className={styles || ''}>
        <Autocomplete
          id="user-account-selection-input"
          data-testid="user-account-selection-input"
          data-cy="user-account-dropdown"
          isOptionEqualToValue={(option, value) => option?.name === value?.name}
          value={selectedAccount || undefined}
          disabled={disabled}
          disableClearable
          className="w-full"
          inputValue={searchQuery}
          onInputChange={(e, val: string) => setSearchQuery(val)}
          ListboxProps={{ style: { maxHeight: 150 } }}
          onChange={handleChange}
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          options={accounts}
          getOptionLabel={(option: Account) => option?.name}
          size={size || 'small'}
          sx={styleOverride}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, style: { fontSize: '.875rem' } }}
              label={showLabel ? 'Account' : ''}
              className={inputStyles || INPUT_STYLE}
              data-cy="account-input"
              placeholder={showLabel ? '' : 'Account'}
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
              data-cy={option.id}
              className="text-sm14 py-1 pl-4 cursor-pointer hover:bg-grey-1"
              key={`${option.id}-${option.name}`}>
              {option.name}
            </li>
          )}
        />
      </div>
    </>
  );
};
