import { SetStateAction, useEffect, useState } from 'react';
import { ContactUserData } from '../../../types';
import { TextSpan } from '../../Atoms/Text';
import { Autocomplete, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { updateForm, updateFormsValidity } from '../../../store/BulkForm';
import validator from 'validator';
import { adminUrlForRole } from '../../../store/User';
import { UserFormProps } from '../../../store/PortalUser';

export interface UserContactDetailsProps {
  userDetails: Partial<UserFormProps> | undefined;
  accountContacts: ContactUserData[];
  accountId: string | undefined;
  disableAll?: boolean;
  formId: string;
}

interface InputErrors {
  first_name: string;
  last_name: string;
  email: string | React.ReactElement;
}

export const UserContactDetails = ({
  accountContacts,
  userDetails,
  accountId,
  disableAll = false,
  formId
}: UserContactDetailsProps) => {
  const [selectedContact, setSelectedContact] = useState<ContactUserData | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAccountContacts, setSelectedAccountContacts] = useState<ContactUserData[]>([]);
  const [hasPortalUserId, setHasPortalUserId] = useState<boolean>(false);
  const [inputErrors, setInputErrors] = useState<InputErrors>({ first_name: '', last_name: '', email: '' });

  const validate = (type: string, value: string) => {
    const errorsUpdate = { ...inputErrors, [type]: '' };
    switch (type) {
      case 'email': {
        const roleNames = userDetails?.roles_attributes?.map((role) => role.name);
        const skipValidation = roleNames?.some((item) => ['Admin', 'Support', 'Executive'].includes(item));
        const userId =
          selectedContact?.portal_user_id ||
          accountContacts.find((c) => c.email === userDetails?.email)?.portal_user_id;
        const isEmailInSelectedContacts = selectedAccountContacts.some((contact) => contact.email === value);

        if (!validator.isEmail(value)) {
          errorsUpdate[type] = 'Enter a valid email address.';
          break;
        }

        if (userId) {
          errorsUpdate[type] = (
            <TextSpan
              color="red"
              className="text-sm">
              User already exists. &nbsp;
              <Link
                title="Admin"
                to={`${adminUrlForRole()}?detailId=${userId}`}
                target="_blank"
                className="text-[indigo] text-sm">
                View
              </Link>
            </TextSpan>
          );
          break;
        }

        if (!hasPortalUserId && value && !isEmailInSelectedContacts && !skipValidation) {
          errorsUpdate[type] = `${value} is not a user in Salesforce.`;
          break;
        }
        break;
      }
      case 'last_name':
      case 'first_name':
        if (!validator.isAlpha(value.replace(/\s|-/g, ''))) errorsUpdate[type] = 'Enter a valid name.';
        break;
    }

    if (Object.keys(errorsUpdate).some((item) => errorsUpdate[item as keyof typeof errorsUpdate])) {
      updateFormsValidity(false);
    }

    setInputErrors(errorsUpdate);
  };

  const handleEmailChange = (
    _e: React.SyntheticEvent<Element, Event>,
    value: ContactUserData | string | null | SetStateAction<string>
  ) => {
    // String - user entered value.
    if (typeof value === 'string') {
      setSearchQuery(value as SetStateAction<string>);
      updateForm(formId, { email: value }, 'user');
      if (value === '') updateForm(formId, { first_name: '', last_name: '' }, 'user');
      setSelectedContact(null);
      return;
    }

    // Object - existing contact
    let update = {} as Partial<UserFormProps>;
    const contact = value as ContactUserData;

    if (contact) {
      setSelectedContact(contact || null);
      update = {
        first_name: contact?.first_name || '',
        last_name: contact?.last_name || '',
        email: contact?.email || ''
      };
      setInputErrors({ ...inputErrors, first_name: '', last_name: '' });
    } else {
      update = { email: undefined };
    }

    updateForm(formId, update, 'user');
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    updateForm(formId, { [name]: value }, 'user');
  };

  const getOptionLabel = (option: ContactUserData | string) => {
    if (typeof option === 'string') return option;
    return option.email;
  };

  const handleEmailBlur = (e: React.BaseSyntheticEvent) => {
    validate('email', userDetails?.email || '');
    const matchingContact = selectedAccountContacts.find((c) => c.email === e.target.value);
    const enteredValue = matchingContact || e.target.value;
    handleEmailChange(e as React.SyntheticEvent, enteredValue);
  };

  useEffect(() => {
    if (accountContacts && accountContacts.length) {
      setHasPortalUserId('portal_user_id' in (accountContacts && accountContacts[0]));
      setSelectedAccountContacts(
        'portal_user_id' in accountContacts[0]
          ? accountContacts.filter((c) => c.account_id === accountId)
          : accountContacts
      );
    }
  }, [accountContacts, selectedContact]);

  return (
    <div className="flex space-x-4 my-1">
      <div className="w-[10rem] lg:w-[12rem]">
        <Autocomplete
          id="user-contact-selection-input"
          data-cy="user-email"
          autoSelect={true}
          value={selectedContact || userDetails?.email || ''}
          disablePortal={true}
          inputValue={searchQuery}
          onInputChange={handleEmailChange}
          onChange={handleEmailChange}
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          options={selectedAccountContacts}
          getOptionLabel={getOptionLabel}
          onBlur={handleEmailBlur}
          disabled={disableAll}
          freeSolo={true}
          size={'small'}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Email"
              type="email"
              InputProps={{ ...params.InputProps, style: { fontSize: '.875rem' } }}
              error={!!inputErrors.email}
              helperText={inputErrors.email}
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
              key={typeof option === 'string' ? `email-option-${option}` : `${option.id}-${option.email}`}>
              {typeof option === 'string' ? option : option.email}
            </li>
          )}
        />
      </div>
      <div className="w-[9rem] lg:w-[12rem]">
        <TextField
          data-testid="contact-fn"
          data-cy="user-first-name"
          type="text"
          label="First Name"
          className="w-full"
          name="first_name"
          size={'small'}
          value={userDetails?.first_name || ''}
          placeholder="First Name"
          required={true}
          disabled={disableAll}
          onBlur={() => validate('first_name', userDetails?.first_name || '')}
          onChange={handleNameChange}
          error={!!inputErrors.first_name}
          helperText={inputErrors.first_name}
          InputProps={{ style: { fontSize: '.875rem', height: '2.3rem' } }}
        />
      </div>
      <div className="w-[10rem] lg:w-[12rem]">
        <TextField
          data-testid="contact-ln"
          data-cy="user-last-name"
          type="text"
          label="Last Name"
          className="w-full"
          name="last_name"
          size={'small'}
          value={userDetails?.last_name || ''}
          placeholder="Last Name"
          required={true}
          disabled={disableAll}
          onBlur={() => validate('last_name', userDetails?.last_name || '')}
          onChange={handleNameChange}
          error={!!inputErrors.last_name}
          InputProps={{ style: { fontSize: '.875rem', height: '2.3rem' } }}
          helperText={inputErrors.last_name}
        />
      </div>
    </div>
  );
};
