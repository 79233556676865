import { useEffect, useState } from 'react';
import {
  SortedNotifications,
  getSortedNotificationsSubject,
  fetchNotifications,
  createSocket
} from '../../../store/Notifications';
import { NotificationDropdown } from './NotificationDropdown';

export const NotificationCenter = () => {
  const [notifications, setNotifications] = useState<SortedNotifications>({} as SortedNotifications);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    createSocket();
    const notificationsSub = getSortedNotificationsSubject().subscribe((notificationItems) =>
      setNotifications(notificationItems)
    );

    return () => {
      if (notificationsSub) notificationsSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchNotifications(page);
  }, [page]);

  const fetchMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <NotificationDropdown
      fetchMore={fetchMore}
      notifications={notifications}
    />
  );
};
