import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { Session, getSessionSubject } from '../Api/useSession';
import { useSnackbar } from '../Context/SnackbarContext';
import { getConfigurationApi } from '../Api/Configuration/configurationApi';

export type Configuration = {
  REACT_APP_GOOGLE_MAPS_API_KEY: string;
};

export interface UseSharedConfigurationReturn {
  fetchConfiguration: () => Promise<void>;
}
export const defaultConfigurationData: Configuration = {
  REACT_APP_GOOGLE_MAPS_API_KEY: ''
};

const configurationSubject = new BehaviorSubject<Configuration>(defaultConfigurationData);
const configurationLoadingSubject = new BehaviorSubject<boolean>(false);

export const getConfiguration = (): Configuration => configurationSubject.value;

export const useSharedConfiguration = (): UseSharedConfigurationReturn => {
  const [session, setSession] = useState<Session | undefined>(undefined);
  const { setSnack } = useSnackbar();

  useEffect(() => {
    const sessionSub = getSessionSubject().subscribe((session) => setSession(session));

    return () => {
      if (sessionSub) sessionSub.unsubscribe();
    };
  }, []);

  const fetchConfiguration = async () => {
    configurationLoadingSubject.next(true);
    const { data, error } = await getConfigurationApi();
    if (data) {
      configurationSubject.next({ ...data });
    }

    if (error) {
      const errorMsg = error?.message?.includes('401')
        ? 'Session expired, please login again.'
        : 'Something went wrong on our end. Please login again.';

      setSnack({
        type: 'error',
        message: errorMsg,
        open: true,
        duration: 5000
      });
    }

    configurationLoadingSubject.next(false);
  };

  useEffect(() => {
    if (session?.authenticated) {
      fetchConfiguration();
    }
  }, [session?.authenticated]);

  return { fetchConfiguration };
};
