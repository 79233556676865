import { Commission } from '../../types';
import { callExternalApi } from '../callExternalApi';

export const postCommissionApi = async () => {
  return await callExternalApi<Commission>({
    url: 'api/commission_sync_jobs',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const fetchCommissionsApi = async () => {
  return await callExternalApi<Commission[]>({
    url: 'api/commission_sync_jobs',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
