import { useEffect, useState } from 'react';
import { LineItem } from '../../../store/LineItem';
import { TextBlock } from '../../Atoms/Text';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { ProductSelectionForm } from '../../Compounds/ProductSelectionForm';
import { getProductSubject } from '../../../store/Product';
import { Product } from '../../../types';
import { Dialog, DialogActions, DialogContent, TextField } from '@mui/material';

export interface LineItemModalProps {
  lineItem: LineItem;
  handleSend: (lineItem: LineItem) => void;
  handleCancel: () => void;
  isModalOpen: boolean;
}

export function LineItemModal({ lineItem, handleSend, handleCancel, isModalOpen }: LineItemModalProps) {
  const [description, setDescription] = useState<string>(lineItem.description);
  const [mrc, setMrc] = useState<number | null>(lineItem.mrc);
  const [nrc, setNrc] = useState<number | null>(lineItem.nrc);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [productSearchQuery, setProductSearchQuery] = useState('');
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    const productSub = getProductSubject().subscribe((products) => setAllProducts(products));

    return () => {
      if (productSub) productSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (allProducts.length) {
      const matchedProduct = findProduct(lineItem.product_id || '');
      if (matchedProduct) {
        setProduct(matchedProduct);
        setProductSearchQuery(`${matchedProduct?.family} - ${matchedProduct?.name}`);
      }
    }
  }, [allProducts]);

  const findProduct = (id: string) => allProducts.find((prod) => prod.id === id);

  const handleInputChange = (_e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    setProductSearchQuery(value || '');
  };

  const handleProductSelectionChange = (_e: React.SyntheticEvent<Element, Event>, value: Product | null) => {
    setProduct(value);
  };

  const handleSubmit = (): void => {
    handleSend({
      ...lineItem,
      product_id: product?.id || '',
      description: description,
      mrc: mrc || 0.0,
      nrc: nrc || 0.0
    });
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handleMrcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMrc(Number(e.target.value));
  };

  const handleNrcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNrc(Number(e.target.value));
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleCancel}>
      <DialogContent>
        <div className="min-w-[20vw]">
          <TextBlock className="text-xl mb-2 mt-2">Add a Line Item</TextBlock>
          <form>
            <ProductSelectionForm
              allProducts={allProducts}
              classNames="mb-5 w-full mt-7"
              handleInputChange={handleInputChange}
              handleProductSelectionChange={handleProductSelectionChange}
              product={product}
              productSearchQuery={productSearchQuery}
              size="small"
            />
            <div className="mb-5">
              <TextField
                data-testid="line-description"
                type="textarea"
                label="Description"
                className="w-full"
                name="description"
                size="small"
                value={description || ''}
                placeholder="Description"
                required={true}
                onChange={handleDescriptionChange}
                error={!description}
                InputProps={{ style: { fontSize: '.875rem', height: '2.3rem' } }}
              />
            </div>
            <div className="mb-5">
              <TextField
                data-testid="line-mrc"
                type="number"
                label="Monthly Cost"
                className="w-full"
                name="mrc"
                size="small"
                value={mrc || ''}
                placeholder="Monthly Cost"
                required={true}
                onChange={handleMrcChange}
                error={!mrc}
                InputProps={{ style: { fontSize: '.875rem', height: '2.3rem' } }}
              />
            </div>
            <div className="mb-5">
              <TextField
                data-testid="line-nrc"
                type="number"
                label="One Time Cost"
                className="w-full"
                name="nrc"
                size="small"
                value={nrc || ''}
                placeholder="One Time Cost"
                onChange={handleNrcChange}
                InputProps={{ style: { fontSize: '.875rem', height: '2.3rem' } }}
              />
            </div>
            <hr className="mt-3" />
          </form>
        </div>
      </DialogContent>
      <DialogActions className="flex mx-4 mb-3 space-x-4">
        <button
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={handleCancel}>
          Cancel
        </button>
        <button
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          disabled={!product?.id || !description || !mrc}
          onClick={handleSubmit}>
          Save Line Item
        </button>
      </DialogActions>
    </Dialog>
  );
}
