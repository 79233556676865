import { createConsumer } from '@rails/actioncable';
const httpRegex = /^http:\/\//;
const httpsRegex = /^https:\/\//;
const BASE_SOCKET_URL = `${process.env.REACT_APP_API_SERVER_URL?.replace(httpRegex, 'ws://').replace(
  httpsRegex,
  'wss://'
)}/cable`;

const createActionCableConsumer = (uid: string, token: string, client: string) => {
  const URL = `${BASE_SOCKET_URL}?uid=${uid}&token=${token}&client=${client}`;
  return createConsumer(URL);
};

export default createActionCableConsumer;
