import { ResetPasswordModal } from '../../Compounds/Modals/ResetPassword';
import { PageLayout } from '../../PageLayout';

export const ResetPasswordPage = () => {
  return (
    <PageLayout>
      <ResetPasswordModal />
    </PageLayout>
  );
};
