import { useEffect, useRef, useState } from 'react';
import { Icon } from '../../Atoms/Icon';
import Menu from '@mui/material/Menu';
import { TextSpan, TextColor } from '../../Atoms/Text';
import { SortedNotifications, updateMany } from '../../../store/Notifications';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import DropdownMenu from '../Menu/DropdownMenu';
import { NotificationItem } from './NotificationItem';
import { useNavigate } from 'react-router-dom';

export interface MenuOption {
  title: string;
  fontColor?: TextColor;
  onClick: () => void;
}

export interface MenuProps {
  notifications: SortedNotifications;
  fetchMore: () => void;
}

export const NotificationDropdown = ({ notifications, fetchMore }: MenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const noNotifications = !notifications?.read?.length && !notifications?.unread?.length;
  const [showAll, setShowAll] = useState(true);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const navigate = useNavigate();
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const handleScroll = () => {
      if (listRef.current) {
        const { clientHeight, scrollHeight, scrollTop } = listRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 30) fetchMore();
      }
    };

    timeoutId = setTimeout(() => {
      listRef.current?.addEventListener('scroll', handleScroll);
    }, 50);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      listRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  const handleDismissAll = async () => {
    const currentNotify = { ...notifications };
    const update = [...currentNotify.read, ...currentNotify.unread];

    update.forEach((notify) => {
      notify.is_archived = true;
    });

    await updateMany(update);
  };

  const handleMarkAllRead = async () => {
    const update = { ...notifications };

    update.unread.forEach((notify) => {
      notify.is_read = true;
    });

    await updateMany(update.unread);
  };

  const menuOptions = [
    {
      title: 'Dismiss all',
      onClick: () => handleDismissAll(),
      fontColor: 'indigo' as TextColor,
      dataCy: 'notification-dismiss-all'
    },
    {
      title: 'Mark all as read',
      onClick: () => handleMarkAllRead(),
      fontColor: 'indigo' as TextColor,
      dataCy: 'notification-mark-all'
    },
    {
      title: 'Notification settings',
      onClick: () => navigate('/user-settings'),
      fontColor: 'indigo' as TextColor,
      dataCy: 'notification-settings'
    }
  ];

  return (
    <>
      <button
        className="flex items-center space-x-2"
        id="menu-button"
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-cy="notification-center">
        <Icon type={notifications?.unread?.length ? 'hasNotifications' : 'noNotifications'} />
        <TextSpan
          className="text-xs"
          color="grey5">
          Notifications
        </TextSpan>
      </button>
      <Menu
        slotProps={{
          paper: {
            style: {
              width: 300,
              marginTop: 5
            }
          }
        }}
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button'
        }}>
        <div className="w-11/12 m-auto flex mb-3 space-x-3">
          <button
            onClick={() => setShowAll(true)}
            className={showAll ? DEFAULT_ADVANCE_BUTTON_STYLE : DEFAULT_CANCEL_BUTTON_STYLE}>
            All
          </button>
          <button
            onClick={() => setShowAll(false)}
            className={!showAll ? DEFAULT_ADVANCE_BUTTON_STYLE : DEFAULT_CANCEL_BUTTON_STYLE}>
            Unread
          </button>
          <div
            className="self-center !ml-auto"
            data-cy="notification-action">
            <DropdownMenu
              icon="more"
              options={menuOptions}
              slotProps={{
                paper: {
                  style: {
                    width: 150
                  }
                }
              }}
            />
          </div>
        </div>
        <div
          className="max-h-[40rem] overflow-auto"
          ref={listRef}>
          {!!notifications?.unread?.length && (
            <ul>
              {notifications?.unread?.map((notification) => (
                <NotificationItem
                  handleClose={handleClose}
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </ul>
          )}
          {!!notifications?.read?.length && showAll && (
            <ul>
              {notifications?.read?.map((notification) => (
                <NotificationItem
                  handleClose={handleClose}
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </ul>
          )}
        </div>
        {noNotifications && (
          <ul className="flex h-[5rem]">
            <li className="m-auto">No notifications</li>
          </ul>
        )}
      </Menu>
    </>
  );
};
