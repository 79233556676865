import React, { useEffect, useState } from 'react';
import { Text, TextBlock } from '../../Atoms/Text';
import { formatSupportTicketDate } from '../../../utils/helpers';
import { UserEvent, PortalUserData } from '../../../types';
import { UserEventsApi } from '../../../Api/Admin/userEventsApi';
import { ComponentSpinner } from '../Loading/ComponentSpinner';

interface EventProps {
  userData: PortalUserData;
}

export const UserEventsSection: React.FC<EventProps> = ({ userData }) => {
  const [userEvents, setUserEvents] = useState<UserEvent[]>([]);
  const [eventsLoading, setEventsLoading] = useState<boolean>(false);
  const [errorMessageData, setErrorMessageData] = useState<string | undefined>(undefined);
  const { fetchUserEvents } = UserEventsApi();

  useEffect(() => {
    getUserEvents();
  }, [userData.id]);

  const getUserEvents = async () => {
    setEventsLoading(true);
    if (!userData.id) return;

    const { data, error } = await fetchUserEvents(userData.id);
    setUserEvents(data || []);
    setErrorMessageData(error ? error.message : undefined);
    setEventsLoading(false);
  };

  const eventUser = (event: UserEvent) => {
    const user = event.impersonator || event.impersonated;
    return `${user.first_name} ${user.last_name}`;
  };

  return (
    <div>
      {eventsLoading ? (
        <ComponentSpinner />
      ) : errorMessageData ? (
        <TextBlock>Error: {errorMessageData}</TextBlock>
      ) : !userData.current_sign_in_at && userEvents.length === 0 ? (
        <TextBlock>No events available</TextBlock>
      ) : (
        <>
          <div key={userData.id}>
            <div className="flex mb-2 mt-2 h-full">
              <Text
                className="font-sans w-full"
                size="sm">
                User Last Login
              </Text>
              <Text
                className="font-sans w-full"
                size="sm">
                {userData.current_sign_in_at && formatSupportTicketDate(userData.current_sign_in_at)}
              </Text>
            </div>
            <hr />
          </div>
          {userEvents.map((event) => (
            <div key={event.id}>
              <div className="flex mb-2 mt-2 h-full">
                <Text
                  className="font-sans w-full"
                  size="sm">
                  {event.action} by {eventUser(event)}
                </Text>
                <Text
                  className="font-sans w-full"
                  size="sm">
                  {formatSupportTicketDate(event.created_at)}
                </Text>
              </div>
              <hr />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
