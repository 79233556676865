import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { ForgotFormData, LoginFormData } from '../components/Compounds/Modals/Login';
import { useLocation, useNavigate } from 'react-router-dom';
import { logoutUser } from './User/Logout';
import { PasswordFormData } from '../components/Compounds/Modals/ResetPassword';
import { resetPasswordApi, ResetPasswordResponse, userForgotPassword } from './User/passwordApi';
import { CallResult } from './callExternalApi';
import { clearUserData, getCurrentPreferences, getSelectedAccountsSubject, updateUserSettings } from '../store/User';
import { datadogLogs } from '@datadog/browser-logs';
import { useSnackbar } from '../Context/SnackbarContext';
import { addError, resetErrorStore } from '../store/Error';
import { clearNotificationStore } from '../store/Notifications';
import { loginUser } from './User/Login';
import { getInventorySubject } from '../store/Inventory';
import { getTicketSubject } from '../store/SupportTicket';
import { getOrderSubject } from '../store/Order';

export interface UseSessionReturn {
  fetchToken: (body: LoginFormData) => Promise<void>;
  tokenLoading: boolean;
  clearSession: (message?: string) => void;
  resetPassword: (body: PasswordFormData) => Promise<CallResult<ResetPasswordResponse>>;
  forgotPassword: (body: ForgotFormData) => Promise<CallResult<void>>;
  getToken: () => string | undefined;
}

export interface Session {
  authenticated: boolean;
  token: string;
}

export const defaultSession = {
  authenticated: false,
  token: ''
};

const sessionSubject = new BehaviorSubject<Session>(defaultSession);
const emitErrorState = (message?: string): void => addError('session', message);

export const getSessionSubject = (): BehaviorSubject<Session> => sessionSubject;
export const updateSession = (update: Partial<Session>) => sessionSubject.next({ ...sessionSubject.value, ...update });
export const getSession = () => sessionSubject.value;

export const useSession = (): UseSessionReturn => {
  const { setSnack } = useSnackbar();

  const [tokenLoading, setTokenLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchToken = async (body: LoginFormData) => {
    setTokenLoading(true);
    emitErrorState();

    const { data, headers, error } = await loginUser(body);

    if (data && headers) {
      const { authorization, client } = headers;
      const token = authorization.replace('Bearer ', '');

      // Store session in local storage.
      localStorage.setItem('token', token);

      // Store action cable auth credentials in local storage
      const actionCableCredentials = {
        client,
        accessToken: headers['access-token'],
        uid: data?.uid
      };
      localStorage.setItem('action-cable-credentials', JSON.stringify(actionCableCredentials));

      updateSession({ authenticated: true, token });
    }

    if (error) {
      emitErrorState(error?.message);
      datadogLogs.logger.error('Error when fetching token: ', error);
    }

    setTokenLoading(false);
  };

  const getToken = () => {
    const token = localStorage.getItem('token');

    if (!token) return;

    updateSession({ token, authenticated: true });

    return token;
  };

  const clearSession = async (message?: string) => {
    const token = localStorage.getItem('token');
    resetErrorStore();
    updateSession(defaultSession);

    if (token) {
      // Store user prefs first.
      const currentPreferences = getCurrentPreferences();
      if (currentPreferences.id) await updateUserSettings(currentPreferences);
      localStorage.removeItem('token');
      await logoutUser();
    }

    clearUserData();
    clearNotificationStore();
    getInventorySubject().next([]);
    getTicketSubject().next([]);
    getOrderSubject().next([]);
    getSelectedAccountsSubject().next([]);

    const { pathname, search } = location;
    const validRedirectPath = pathname.includes('login') || pathname.includes('reset') ? '/' : `${pathname}${search}`;
    const authRoute = `/login?redirectTo=${validRedirectPath}`;
    navigate(authRoute);
    if (message) setSnack({ message, type: 'error', open: true });
  };

  // Check local storage for existing session on init.
  useEffect(() => {
    getToken();
  }, []);

  const resetPassword = async (body: PasswordFormData): Promise<CallResult<ResetPasswordResponse>> => {
    setTokenLoading(true);

    const res = await resetPasswordApi(body);

    setTokenLoading(false);

    return res;
  };

  const forgotPassword = async (body: ForgotFormData): Promise<CallResult<void>> => {
    setTokenLoading(true);

    const res = await userForgotPassword(body);
    if (res.error) emitErrorState(res.error.message);

    setTokenLoading(false);

    return res;
  };

  return { fetchToken, tokenLoading, clearSession, getToken, resetPassword, forgotPassword };
};
