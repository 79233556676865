import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { DraggableWidgetCard } from '../DraggableCard';
import { Toggle } from '../../Atoms/Toggle';
import { Text } from '../../Atoms/Text';
import {
  getUser,
  isVisibleWidgetByPermissions,
  UserPreferences,
  WidgetKey,
  WidgetPreferences,
  Widgets
} from '../../../store/User';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { User } from '../../../types';
import { arrayMove } from '../../../utils/helpers';
import { AccountCustomizationData } from '../../../store/AccountCustomization';

export interface CustomizeDashboardWidgetsProps {
  userPreferences: UserPreferences | AccountCustomizationData;
  drawer: boolean;
  widgetKey: WidgetKey;
  setUserPreferences: Dispatch<SetStateAction<UserPreferences | AccountCustomizationData>>;
  type?: string;
}

const getAllChecked = (widgets: WidgetPreferences[]) => {
  return widgets.every((w) => !w.hide);
};

const getAvailableWidgets = (widgets: WidgetPreferences[], user: User) => {
  return widgets.filter((w) => !w.disabled && isVisibleWidgetByPermissions(w.widgetId, user));
};

export const CustomizeDashboardWidgets = ({
  userPreferences,
  drawer,
  widgetKey,
  setUserPreferences,
  type
}: CustomizeDashboardWidgetsProps) => {
  const [widgetPreferences, setWidgetPreferences] = useState<WidgetPreferences[]>(
    type === 'account'
      ? userPreferences.content.widgets[widgetKey]
      : getAvailableWidgets([...userPreferences.content.widgets[widgetKey]], getUser())
  );
  const [loading, setLoading] = useState(false);
  const [toggleAllChecked, setToggleAllChecked] = useState(getAllChecked(widgetPreferences));

  useEffect(() => {
    const prefs =
      type === 'account'
        ? userPreferences.content.widgets[widgetKey]
        : getAvailableWidgets([...userPreferences.content.widgets[widgetKey]], getUser());
    setWidgetPreferences(prefs);
    setToggleAllChecked(getAllChecked(prefs));
  }, [userPreferences]);

  const updateUserPrefs = (update: WidgetPreferences[]) => {
    const prefUpdate = { ...userPreferences };
    prefUpdate.content = {
      ...prefUpdate.content,
      widgets: {
        ...prefUpdate.content.widgets,
        [widgetKey]: update
      }
    };
    setUserPreferences(prefUpdate);
  };

  const findMatchingCol = (id: string, customizations: WidgetPreferences[]) => {
    return customizations.find((col) => col.widgetId === id);
  };

  const updateAllWidgets = (widgetId: string, newVal: boolean) => {
    const updatedCustomizations = { ...userPreferences.content.widgets };
    Object.keys(updatedCustomizations).forEach((widget) => {
      const matchingCol = findMatchingCol(widgetId, updatedCustomizations[widget as keyof Widgets]);
      if (matchingCol) matchingCol.disabled = newVal;
    });
  };

  const setWidgetVisible = (widgetId: string, visible: boolean) => {
    const update = [...widgetPreferences];
    const index = update.findIndex((widget) => widget.widgetId === widgetId);
    if (index > -1) {
      update[index].hide = !visible;

      if (type === 'account') {
        update[index].disabled = !visible;
        updateAllWidgets(widgetId, !visible);
      }
    }

    setWidgetPreferences(update);
    updateUserPrefs(update);
    setToggleAllChecked(getAllChecked(update));
  };

  const handleOnDragEnd = (draggedCard: DropResult) => {
    if (!draggedCard.destination || draggedCard.source.index === draggedCard.destination.index) return;
    setLoading(true);

    const update = [...widgetPreferences];
    arrayMove(update, draggedCard.source.index, draggedCard.destination.index);
    update.forEach((w, i) => {
      w.index = i;
    });

    setWidgetPreferences(update);
    updateUserPrefs(update);

    setLoading(false);
  };

  const handleToggleAll = () => {
    setLoading(true);

    const prefUpdate = [...widgetPreferences];
    prefUpdate.forEach((p: WidgetPreferences) => {
      p.hide = toggleAllChecked;
      if (type === 'account') {
        p.disabled = toggleAllChecked;
        updateAllWidgets(p.widgetId, toggleAllChecked);
      }
    });

    setWidgetPreferences(prefUpdate);
    updateUserPrefs(prefUpdate);
    setToggleAllChecked(!toggleAllChecked);

    setLoading(false);
  };

  return (
    <>
      <div className="pb-1 border-b border-grey-4 text-end items-center">
        <label
          htmlFor="widget-toggle-all"
          className="mr-2 ml-auto">
          <Text
            size="sm14"
            className="text-gray-700"
            dataTestId="all_widgets"
            isSpan>
            Select All
          </Text>
          <Toggle
            id="widget-toggle-all"
            className="ml-2 inline-block"
            checked={toggleAllChecked}
            onChange={handleToggleAll}
            width={7}
            height={4}
          />
        </label>
      </div>
      {!loading && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="columns">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`h-full ${drawer ? 'w-fill' : 'w-[24rem]'} grow flex flex-col mt-4`}
                data-testid="menu-group">
                {widgetPreferences.map(({ widgetId, hide, headerName }, index) => {
                  return (
                    <DraggableWidgetCard
                      key={widgetId}
                      widgetId={widgetId}
                      index={index}
                      headerName={headerName}
                      hide={hide}
                      setWidgetVisible={setWidgetVisible}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};
