import { BehaviorSubject } from 'rxjs';
import { Attachment } from '../types';
import { useEffect, useState } from 'react';

export interface UseAttachmentReturn {
  clear: () => void;
  currentState: AttachmentState;
}

export interface AttachmentState {
  queuedAttachments: Attachment[];
  attachments: Attachment[];
}

const defaultAttachmentData: Attachment[] = [];
const defaultAttachmentState: AttachmentState = {
  queuedAttachments: defaultAttachmentData,
  attachments: defaultAttachmentData
};

const subject = new BehaviorSubject<AttachmentState>(defaultAttachmentState);

export const getSubject = (): BehaviorSubject<AttachmentState> => subject;
export const getQueuedAttachments = (): Attachment[] => subject.value.queuedAttachments;
export const getAttachments = (): Attachment[] => subject.value.attachments;
export const getAttachmentsByParentId = (id: string): Attachment[] =>
  subject?.value?.attachments?.filter((att: Attachment) => att.parentId === id);

export const addQueuedAttachments = (attachments: Attachment[]): void =>
  updateAttachmentState({ queuedAttachments: [...getQueuedAttachments(), ...attachments] });

export const addAttachments = (attachments: Attachment[]): void => {
  updateAttachmentState({ attachments: [...getAttachments(), ...attachments] });
};

export const cancelQueuedAttachment = (id: string) =>
  updateAttachmentState({ queuedAttachments: getQueuedAttachments().filter((att) => att.id !== id) });

export const removeAttachment = (id: string) =>
  updateAttachmentState({
    attachments: getAttachments().filter((att) => att.id !== id)
  });

export const updateAttachmentState = (val: Partial<AttachmentState>) => {
  const update = { ...subject.value, ...val };
  subject.next(update);
};

export const useAttachmentStore = (): UseAttachmentReturn => {
  const [currentState, setCurrentState] = useState<AttachmentState>(defaultAttachmentState);

  useEffect(() => {
    const subscription = subject.subscribe((message: AttachmentState) => {
      setCurrentState(message);
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  const clear = () => subject.next(defaultAttachmentState);

  return { currentState, clear };
};
