import { InventoryAsset, Order, PortalUserData, SupportTicketsItem } from '../../../types';
import { useFetching } from '../../../Context/FetchingContext';
import { SupportTicketDetails } from '../../Pages/SupportTickets';
import { InventoryDetails } from '../../Pages/Inventory/InventoryDetails';
import { OrderDetails } from '../../Pages/Orders/OrderDetails';
import { UserDetails } from '../../Pages/Admin/Tabs/UserDetails';
import { DrawerActions } from './DrawerActions';
import { Team } from '../../../store/Team';
import { TeamDetails } from '../../Pages/Admin/Tabs/TeamDetails';
import { AccountData } from '../../../store/Accounts';
import { AccountDetails } from '../../Pages/Admin/Tabs/Accounts/AccountDetails';
import { ComponentName } from './detailsDrawersHelpers';

export interface DetailsDisplayProps {
  data: InventoryAsset | SupportTicketsItem | Order | PortalUserData | Team | AccountData | null;
  componentName?: ComponentName;
  setShow: (show: boolean) => void;
}

export const DetailsDisplay = ({ data, componentName, setShow }: DetailsDisplayProps) => {
  const { isFetching } = useFetching();
  if (isFetching || !data || !componentName) return <div></div>;

  const getComponentByName = () => {
    switch (componentName) {
      case 'support':
        return <SupportTicketDetails data={data as SupportTicketsItem} />;
      case 'inventory':
        return <InventoryDetails data={data as InventoryAsset} />;
      case 'order':
        return <OrderDetails data={data as Order} />;
      case 'user':
        return (
          <UserDetails
            userData={data as PortalUserData}
            setShow={setShow}
          />
        );
      case 'team':
        return <TeamDetails data={data as Team} />;
      case 'account':
        return <AccountDetails data={data as AccountData} />;
      default:
        return null;
    }
  };

  return (
    <div className="overflow-auto">
      <DrawerActions
        componentName={componentName}
        data={data}
        setShow={setShow}
      />
      {getComponentByName()}
    </div>
  );
};
