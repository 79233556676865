import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { DraggableColumnCard } from '../DraggableCard';
import { Toggle } from '../../Atoms/Toggle';
import { Text } from '../../Atoms/Text';
import { arrayMove } from '../../../utils/helpers';
import { DefaultInventoryGridColumnDefs } from '../../Pages/Inventory/inventoryGridColumnDefs';
import { GridPreferences } from '../../../store/User';

export interface CustomizeTableColumnsProps {
  gridPreferences: GridPreferences;
  setGridPreferences: Dispatch<SetStateAction<GridPreferences>>;
  type: string;
}

const getAllChecked = (gridPreferences: GridPreferences) => {
  const visibilityModel = gridPreferences?.muiConfig?.columns?.columnVisibilityModel;
  return Object.keys(visibilityModel || {}).every((k) => visibilityModel?.[k]);
};

export const CustomizeTableColumns = ({ gridPreferences, setGridPreferences, type }: CustomizeTableColumnsProps) => {
  const [columns, setColumns] = useState(DefaultInventoryGridColumnDefs);
  const [loading, setLoading] = useState(false);
  const [toggleAllChecked, setToggleAllChecked] = useState(getAllChecked(gridPreferences));

  const handleOnDragEnd = (draggedCard: DropResult) => {
    if (!draggedCard.destination) return;
    if (draggedCard.source.index === draggedCard.destination.index) return;

    setLoading(true);
    const update = { ...gridPreferences };
    const order = update?.muiConfig?.columns?.orderedFields || [];

    arrayMove(order, draggedCard.source.index, draggedCard.destination.index);
    setGridPreferences(update);

    const columnUpdate = [...columns];
    columnUpdate.sort(
      (a: GridSingleSelectColDef, b: GridSingleSelectColDef) => order.indexOf(a.field) - order.indexOf(b.field)
    );

    setColumns(columnUpdate);
    setToggleAllChecked(getAllChecked(update));
    setLoading(false);
  };

  const setDisabled = (prefUpdate: GridPreferences) => {
    const model = prefUpdate?.muiConfig?.columns?.columnVisibilityModel;
    prefUpdate.disabledFields = Object.keys(model as object).reduce((acc, k) => {
      if (model && !model[k]) acc.push(k);
      return acc;
    }, [] as string[]);
  };

  const handleColumnVisibilityChange = (colId: string, visible: boolean) => {
    setLoading(true);

    const prefUpdate = { ...gridPreferences };
    const visibilityModel = prefUpdate?.muiConfig?.columns?.columnVisibilityModel;
    if (visibilityModel) {
      visibilityModel[colId] = visible;
      if (type === 'account') setDisabled(prefUpdate);
      setGridPreferences(prefUpdate);
    }
    setLoading(false);
  };

  const handleToggleAll = () => {
    setLoading(true);

    const prefUpdate = { ...gridPreferences };
    const visibilityModel = prefUpdate?.muiConfig?.columns?.columnVisibilityModel;

    columns.forEach(({ field }: GridSingleSelectColDef) => {
      if (visibilityModel) visibilityModel[field] = !toggleAllChecked;
    });
    if (type === 'account') setDisabled(prefUpdate);
    setGridPreferences(prefUpdate);
    setToggleAllChecked(!toggleAllChecked);

    setLoading(false);
  };

  useEffect(() => {
    const { disabledFields, muiConfig } = gridPreferences;
    const visibilityModel = muiConfig?.columns?.columnVisibilityModel || {};
    const visibleColumns = columns.filter(
      (col: GridSingleSelectColDef) =>
        !disabledFields.includes(col?.field) && !['details', 'detailCTA'].includes(col?.field)
    );

    if (type === 'user') {
      setColumns(visibleColumns);
    }

    if (!Object.keys(visibilityModel).length) {
      visibleColumns.forEach(({ field }) => {
        visibilityModel[field] = true;
      });
    }

    if (muiConfig?.columns && !muiConfig?.columns?.orderedFields?.length) {
      muiConfig.columns.orderedFields = visibleColumns.map(({ field }) => field);
    }
  }, []);

  return (
    <>
      <div className="pb-1 border-b border-grey-4 text-end items-center">
        <label
          className="space-x-2"
          htmlFor="toggle-all">
          <Text
            size="sm"
            className="text-gray-700"
            dataTestId="all_fields"
            isSpan>
            Select All Fields
          </Text>
          <Toggle
            id="toggle-all"
            className="inline-block"
            checked={toggleAllChecked}
            onChange={() => handleToggleAll()}
            width={7}
            height={4}
          />
        </label>
      </div>
      {!loading && (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="columns">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="h-[24rem] w-[24rem] grow flex flex-col mt-4"
                data-testid="menu-group">
                {columns.map(({ headerName, field }, index) => {
                  const hide = !gridPreferences?.muiConfig?.columns?.columnVisibilityModel?.[field];
                  return (
                    <DraggableColumnCard
                      key={field}
                      colId={field}
                      index={index}
                      headerName={headerName as string}
                      hide={hide}
                      setColumnVisible={(key, visible) => handleColumnVisibilityChange(key, visible)}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};
