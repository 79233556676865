import { SupportTicketsItem } from '../../types';
import { callExternalApi, CallResult } from '../callExternalApi';

export interface FetchTicketQueryAttributes {
  key: string;
  value: string;
}

export interface Opts {
  query?: FetchTicketQueryAttributes;
  caseId?: string;
}

export async function fetchTickets(accountIds?: string[], opts?: Opts): Promise<CallResult<SupportTicketsItem[]>> {
  if (!accountIds?.length) return { data: null, error: { message: 'Must provide account ID to fetch tickets' } };

  let url = 'api/cases';

  if (opts?.caseId) {
    url += `/${opts.caseId}`;
  }

  if (opts?.query) {
    const query = opts.query;
    url += `?${query.key}=${encodeURIComponent(query.value)}&account_ids=${accountIds.join(',')}`;
  } else {
    url += `?account_ids=${accountIds.join(',')}`;
  }

  return await callExternalApi<SupportTicketsItem[]>({
    url: url,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
}
