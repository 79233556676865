import { DraggableCard } from './DraggableCard';

export interface DraggableColumnCardProps {
  colId: string;
  index: number;
  headerName: string;
  hide: boolean;
  setColumnVisible: (key: string, visible: boolean) => void;
  disabled?: boolean;
}

export function DraggableColumnCard({ colId, index, headerName, hide, setColumnVisible }: DraggableColumnCardProps) {
  return (
    <DraggableCard
      draggableId={colId}
      index={index}
      label={headerName}
      checked={!hide}
      onChange={() => setColumnVisible(colId, hide)}
    />
  );
}
