import { SortRow } from '../SortRow/SortRow';
import { FILTER_SERVICE_CATEGORIES } from '../../../utils/helpers';

export interface FilterBarProps {
  selectedServiceTypes: string[];
  handleClick: (serviceType: string) => void;
  productFamilies: Set<string>;
}

export const FilterBar = ({ selectedServiceTypes, handleClick, productFamilies }: FilterBarProps) => {
  const currentServiceCategories = FILTER_SERVICE_CATEGORIES.filter((c) => productFamilies.has(c));

  return (
    <div className="absolute top-[17px] left-[17px] z-10 w-[215px] h-[69px]">
      <div className="flex flex-col items-start">
        <div className="bg-white px-1 border-[1px] rounded rounded-bl-none rounded-br-none border-transparent z-20 border-l-grey-2 border-t-grey-2 border-r-grey-2">
          <button className="flex items-center">
            <div className="menu-filter-button p-3" />
            <span className="font-sans text-slate-500 text-xs font-normal text-center mr-2">Service Type</span>
          </button>
        </div>
        <div className="absolute z-10 bg-white text-xs -mt-[1px] translate-y-[1.61rem] border-1 rounded w-52">
          {currentServiceCategories.map((serviceType) => {
            return (
              <SortRow
                key={`bar-sort-${serviceType}`}
                colId={serviceType}
                chosenColId={selectedServiceTypes.includes(serviceType) ? serviceType : ''}
                title={serviceType}
                handleSortClick={() => handleClick(serviceType)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
