import { GridFilterPanel } from '@mui/x-data-grid-pro';

export const CustomFilterPanel = () => {
  return (
    <GridFilterPanel
      sx={{
        display: 'flex',
        width: '50rem',
        paddingTop: '1rem',
        '.MuiFormControl-root': {
          marginLeft: '.5rem'
        }
      }}
    />
  );
};
