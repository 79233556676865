import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { getUser } from '../../../store/User';

interface RestrictedRouteProps {
  requiredPermissions: string[][];
  children?: ReactNode;
}

/**
 * @info The required permissions prop is an array of arrays, with items being a string representation of a role. The idea of this data structure to
 * provide the component with support for OR and AND operators. You can think of the top level array as the AND expression, with each array within as a series of ORs
 *
 * i.e. requiredPermissions = [[perm1, perm2], [perm3, perm4], [perm5]] is equal to the conditional (perm1 || perm2) && (perm3 || perm4) && perm5
 *
 */
export const RestrictedRoute = ({ requiredPermissions, children }: RestrictedRouteProps) => {
  const permissions = getUser().permissions.map((permission) => permission?.name || permission);
  const isAllowed = requiredPermissions.every((permGroup) => permGroup.some((p) => permissions.includes(p)));
  if (!isAllowed)
    return (
      <Navigate
        to={'/'}
        replace
      />
    );

  return <>{children}</>;
};

export default RestrictedRoute;
