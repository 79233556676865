import React from 'react';
import createActionCableConsumer from './actionCableConsumer';
import { Comment, getCommentSubject } from '../../store/Comment';
import { useSnackbar } from '../../Context/SnackbarContext';
import { getUser } from '../../store/User';

export const useCommentChannel = (entityId: string, entityType: string) => {
  const { setSnack } = useSnackbar();

  React.useEffect(() => {
    const actionCableCredentials = JSON.parse(localStorage.getItem('action-cable-credentials') || 'null');

    if (!actionCableCredentials) {
      console.error('Unable to start comment channel, invalid credentials');
      return;
    }

    const { uid, accessToken, client } = actionCableCredentials;
    const commentConsumer = createActionCableConsumer(uid, accessToken, client);

    commentConsumer.subscriptions.create(
      {
        channel: 'CommentChannel',
        entity_id: entityId,
        entity_type: entityType
      },
      {
        connected: () => {},
        disconnected: () => {
          console.log('Subscription disconnected');
        },
        received: (data: { type: string; comments: Comment[] }) => {
          if (data.comments[0].portal_user_id?.toString() !== getUser().id) {
            const commentsSubject = getCommentSubject();
            commentsSubject.next([...commentsSubject.getValue(), ...data.comments]);
            setSnack({ message: 'New comment received', type: 'info', open: true });
          }
        }
      }
    );

    return () => {
      commentConsumer.disconnect();
    };
  }, [entityId, entityType]);

  return null;
};
