import { Attachment } from '../../types';
import { callExternalApi } from '../callExternalApi';
import { AttachmentUploadResponse } from './useAttachments';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ProgressHandler = (progressEvent: any) => void;

export const uploadAttachment = (attachment: Attachment, progressHandler: ProgressHandler, parentId: string) => {
  // Build form object
  const formData = new FormData();
  formData.append('file', attachment.file);
  formData.append('parent_id', parentId);

  return callExternalApi<AttachmentUploadResponse>({
    url: 'api/files',
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data'
    },
    cancelToken: attachment.cancelSource.token,
    data: formData,
    onUploadProgress: progressHandler
  });
};
