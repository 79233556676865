import { useEffect, useState } from 'react';
import { PageCard } from '../../Compounds/CardWithTitle';
import { PageLayout } from '../../PageLayout';
import { usePortalUsers } from '../../../store/PortalUser';
import { clearFormCreateStateSubject, getFormCreateStateSubject } from '../../../store/BulkForm';
import { TextBlock } from '../../Atoms/Text';
import { BasicDataGrid } from '../../Compounds/Table/BasicDataGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { v4 } from 'uuid';
import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { adminUrlForRole, getUser, isTeamManager } from '../../../store/User';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../Atoms/Icon';
import { PortalUserData } from '../../../types';
import { PostPortalUserRequest } from '../../../Api/Admin/PortalUsers/postPortalUser';

export interface BaseDisplayData {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
}

export interface SuccessData extends BaseDisplayData {
  roles: string;
}

export interface FailureData extends BaseDisplayData {
  error: string | undefined;
  button?: boolean;
}

export interface DisplayData {
  successData: SuccessData[];
  failureData: FailureData[];
}

export const UserCreationSummaryPage = () => {
  const { createRequest } = usePortalUsers();
  const [displayData, setDisplayData] = useState<DisplayData>({ successData: [], failureData: [] });
  const user = getUser();
  const navigate = useNavigate();

  useEffect(() => {
    const userCreateStateSub = getFormCreateStateSubject().subscribe((state) => {
      const failures = state.filter((item) => item.status === 'failed');
      const success = state.filter((item) => item.status === 'success');

      const successData = success.map((r) => {
        const data = r.data as PortalUserData;
        const req = r.requestBody as PostPortalUserRequest;

        return {
          id: data?.id,
          firstName: data?.first_name,
          lastName: data?.last_name,
          email: data?.email,
          roles: req.roles_attributes.map((role) => role.name).join(', ')
        };
      });

      const failureData = failures.map((r) => {
        const req = r.requestBody as PostPortalUserRequest;

        return {
          id: v4(),
          firstName: req?.first_name,
          lastName: req?.last_name,
          email: req?.email,
          error: r.error,
          button: true,
          requestBody: r.requestBody
        };
      });

      setDisplayData({ successData, failureData });
    });

    return () => {
      if (userCreateStateSub) userCreateStateSub.unsubscribe();
      clearFormCreateStateSubject();
    };
  }, []);

  const successCols: GridColDef[] = [
    { headerName: 'First Name', field: 'firstName', width: 150 },
    { headerName: 'Last Name', field: 'lastName', width: 150 },
    { headerName: 'Email', field: 'email', width: 150 },
    { headerName: 'Roles', field: 'roles', flex: 1 }
  ];

  const failureCols: GridColDef[] = [
    { headerName: 'First Name', field: 'firstName', width: 150 },
    { headerName: 'Last Name', field: 'lastName', width: 150 },
    { headerName: 'Email', field: 'email', width: 150 },
    { headerName: 'Error', field: 'error', flex: 1 },
    {
      headerName: '',
      field: 'button',
      renderCell: (params) => (
        <button
          className="flex items-center"
          onClick={() => createRequest(params?.row?.requestBody, params?.row.data?.userCustomizations)}>
          <Icon
            className="mr-2"
            type="retry"></Icon>
          Retry
        </button>
      )
    }
  ];

  const pageActions = () => (
    <button
      onClick={() => navigate(adminUrlForRole())}
      className={DEFAULT_ADVANCE_BUTTON_STYLE}
      data-cy="back-to-table-btn">
      Back To {`${isTeamManager(user) ? 'Team Settings' : 'Admin'}`}
    </button>
  );

  return (
    <PageLayout
      pageTitle="New Users"
      dataCy="new-user-summary"
      pageActions={pageActions()}>
      <PageCard
        childrenWrapperClass="flex min-h-80 max-w-[60vw] min-w-full"
        title={`${displayData.successData.length} user(s) created successfully`}
        titleIcon="greenCheckmark"
        dataCy="new-user-summary-success-card">
        <div className="w-full">
          {!!displayData.successData.length && (
            <TextBlock className="mb-4">The following users were created successfully</TextBlock>
          )}
          <BasicDataGrid
            rows={displayData.successData}
            columns={successCols}
          />
        </div>
      </PageCard>

      {!!displayData.failureData.length && (
        <PageCard
          childrenWrapperClass="flex min-h-80 max-w-[60vw] min-w-full"
          className="w-11/12"
          title={`${displayData.failureData.length} user(s) were not created`}
          titleIcon="alert"
          dataCy="new-user-summary-failure-card">
          <div className="w-full">
            <TextBlock className="mb-4">The following users were not created</TextBlock>
            <BasicDataGrid
              rows={displayData.failureData}
              columns={failureCols}
            />
          </div>
        </PageCard>
      )}
    </PageLayout>
  );
};
