import { Link, useSearchParams } from 'react-router-dom';
import { Icon } from '../../../Atoms/Icon';
import { TextSpan } from '../../../Atoms/Text';
import { PageCard } from '../../../Compounds/CardWithTitle';
import { getMetaData } from '../../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { PortalUserData } from '../../../../types';
import { useEffect, useState } from 'react';
import {
  GridPreferences,
  adminUrlForRole,
  getDefaultPreferences,
  getPreferencesByKey,
  getUserPreferencesSubject
} from '../../../../store/User';
import { DetailsDrawer } from '../../../Compounds/DetailsDrawer';
import { getPortalUsersLoadingSubject, usePortalUsers, updatePortalUser } from '../../../../store/PortalUser';
import { UpstackDataGrid } from '../../../Compounds/UpstackDataGrid/UpstackDataGrid';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DefaultUserGridColumnDefs } from '../ColumnDefs/UserGridColumnDefs';
import { getGridColumns } from '../../../Compounds/UpstackDataGrid/helpers';

export interface UserTabProps {
  portalUsers: PortalUserData[];
}

export const UserTab = ({ portalUsers }: UserTabProps) => {
  const [search] = useSearchParams();
  const detailId = search.get('detailId');
  const { updateUser } = usePortalUsers();
  const [portalUsersLoading, setPortalUsersLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<PortalUserData>();
  const [userColumns, setUserColumns] = useState<GridSingleSelectColDef[]>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences().content['user_grid'] as GridPreferences
  );

  useEffect(() => {
    const loadingSubscription = getPortalUsersLoadingSubject().subscribe((loading) => setPortalUsersLoading(loading));
    const settingsSub = getUserPreferencesSubject().subscribe(() => {
      const gridPrefs = getPreferencesByKey('user_grid') as GridPreferences;
      if (gridPrefs) {
        setGridSettings(gridPrefs);
      }
    });

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (settingsSub) settingsSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!portalUsersLoading && portalUsers.length > 0) {
      const currentData = portalUsers.find((t) => t.id.toString() === detailId);
      setUserData(currentData);
      updatePortalUser(currentData || ({} as PortalUserData));
      setUserColumns(getGridColumns(portalUsers, DefaultUserGridColumnDefs, 'user_grid'));
    }
  }, [detailId, portalUsersLoading, portalUsers.length]);

  const cardActions = () => {
    const state = {
      prevpath: location.pathname
    };
    return (
      <Link
        role="link"
        title="New User"
        className="flex space-x-2 items-center"
        data-cy="new-user"
        state={state}
        to={`${adminUrlForRole()}/new-user`}>
        <Icon
          type="plus"
          className="p-2"
        />
        <TextSpan
          color="indigo"
          size="sm14">
          New User
        </TextSpan>
      </Link>
    );
  };

  return (
    <>
      <PageCard
        dataCy="user-management-card"
        childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
        title="User Management"
        cardActions={cardActions()}>
        <UpstackDataGrid
          title="Users"
          rows={portalUsers}
          columns={userColumns}
          loadingData={portalUsersLoading}
          page="user_grid"
          gridSettings={gridSettings}
          showSearch={true}
        />
      </PageCard>
      <DetailsDrawer
        showButtons={true}
        data={userData}
        showDrawer={!!detailId}
        saveAction={() => updateUser()}
        {...getMetaData('user')}
      />
    </>
  );
};
