import { TextBlock } from '../../Atoms/Text';

export const MaintenancePage = () => {
  return (
    <div className="text-center">
      <img
        src="/UPSTACK_logo_hrz-1.5_color.svg"
        className="m-auto h-[80px] mt-24"
        alt="UPSTACK Logo"
      />
      <TextBlock
        className="my-8"
        weight="black"
        size="xl">
        We Shall Return
      </TextBlock>
      <TextBlock
        weight="normal"
        size="lg">
        We are currently working on adding some great new features for you. <br></br>
        We&apos;ll be back up and running shortly. Thank you for your patience!
      </TextBlock>
    </div>
  );
};
