import { DeleteBtn } from './DeleteBtn';
import { deletePortalUser } from '../../../store/PortalUser';

export interface PortalUserDeleteBtnProps {
  portalUserId: string;
  setShow: (show: boolean) => void;
}

export function PortalUserDeleteBtn({ portalUserId, setShow }: PortalUserDeleteBtnProps) {
  return (
    <DeleteBtn
      copyMap={{
        default: 'Are you sure you want to permanently delete this user?',
        deleting: 'Deleting user...',
        error: 'Something went wrong while deleting current user. Would you like to try again?'
      }}
      deleteItem={deletePortalUser}
      deleteItemId={portalUserId}
      deleteTriggerDescription="delete portal user button"
      onSuccess={() => setShow(false)}
    />
  );
}
