import { useEffect, useState } from 'react';
import { Account } from '../../../types';
import { getAccountSubject } from '../../../store/Accounts';
import { TeamFormProps, getFormsSubject, updateForm } from '../../../store/BulkForm';
import { AccountSelectionForm } from '../../Compounds/UserCreation';
import { TextField } from '@mui/material';

export interface TeamFormArgs {
  formId: string;
}

interface InputErrors {
  name: string;
}

export const TeamForm = ({ formId }: TeamFormArgs) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [formData, setFormData] = useState<Partial<TeamFormProps | undefined>>(undefined);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>();
  const [inputErrors, setInputErrors] = useState<InputErrors>({ name: '' });

  // Component init
  useEffect(() => {
    const accountSub = getAccountSubject().subscribe((accounts) => {
      setAccounts(accounts.sort((a, b) => (a.name > b.name ? 1 : -1)));
    });
    const formDataSub = getFormsSubject().subscribe((data) => {
      const formIndex = data.findIndex((item) => item?.id === formId);
      setFormData(data[formIndex]);
    });

    return () => {
      if (accountSub) accountSub?.unsubscribe();
      if (formDataSub) formDataSub?.unsubscribe();
    };
  }, []);

  const handleAccountChange = (acct: Account | undefined) => {
    setSelectedAccount(acct);
    const update: Partial<TeamFormProps> = {
      ...formData,
      account_id: acct?.id
    };
    updateForm(formId, update, 'team');
  };

  const handleNameUpdate = (val: string) => {
    setInputErrors({ name: '' });

    const update: Partial<TeamFormProps> = {
      ...formData,
      name: val
    };

    updateForm(formId, update, 'team');
  };

  return (
    <>
      <form
        data-cy="bulk-team-form"
        className="flex flex-wrap flex-row grow"
        data-testid="user-form">
        <AccountSelectionForm
          accounts={accounts}
          selectedAccount={selectedAccount || null}
          setSelectedAccount={handleAccountChange}
          size="small"
          styles="w-52 lg:w-72 my-1 pr-3"
        />
        <div className="w-[10rem] lg:w-[15rem] my-1">
          <TextField
            data-testid="contact-fn"
            data-cy="team-name"
            type="text"
            label="Team Name"
            className="w-full"
            name="name"
            size="small"
            value={formData?.name || ''}
            placeholder="Team Name"
            required={true}
            onChange={(e) => handleNameUpdate(e.target.value)}
            error={!!inputErrors.name}
            helperText={inputErrors.name}
            InputProps={{ style: { fontSize: '.875rem', height: '2.3rem' } }}
          />
        </div>
      </form>
    </>
  );
};
