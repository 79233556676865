import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { fetchOutsideInventorySchemasApi } from '../Api/OutsideInventorySchemas/outsideInventorySchemasApi';
import { OutsideInventorySchema } from '../types';

const outsideInventorySchemasSubject = new BehaviorSubject<OutsideInventorySchema[]>([]);
const outsideInventorySchemasLoadingSubject = new BehaviorSubject<boolean>(false);

export const getOutsideInventorySchemaSubject = (): BehaviorSubject<OutsideInventorySchema[]> =>
  outsideInventorySchemasSubject;
export const getOutsideInventorySchemaLoadingSubject = (): BehaviorSubject<boolean> =>
  outsideInventorySchemasLoadingSubject;

export interface UseOutsideInventorySchemasStoreReturn {
  fetchOutsideInventorySchemas: () => Promise<void>;
  clear: () => void;
  setOutsideInventorySchemas: (outsideInventorySchemas: OutsideInventorySchema[]) => void;
}

export const useOutsideInventorySchemasStore = (): UseOutsideInventorySchemasStoreReturn => {
  const fetchOutsideInventorySchemas = async (): Promise<void> => {
    outsideInventorySchemasLoadingSubject.next(true);

    const { data, error } = await fetchOutsideInventorySchemasApi();

    if (data) outsideInventorySchemasSubject.next(data);
    if (error) addError('outside-inventory-schema', error.message);

    outsideInventorySchemasLoadingSubject.next(false);
  };

  const setOutsideInventorySchemas = (outsideInventorySchemas: OutsideInventorySchema[]) =>
    outsideInventorySchemasSubject.next(outsideInventorySchemas);

  const clear = () => outsideInventorySchemasSubject.next([]);

  return {
    fetchOutsideInventorySchemas,
    clear,
    setOutsideInventorySchemas
  };
};
