import { useEffect, useState } from 'react';
import { CustomGroupRenderer, MultiSelectDropdown, MultiSelectGroup } from '../MultiselectDropdown';
import {
  AdminPermission,
  AdvisorPermission,
  CsmPermission,
  InventoryPermission,
  SdmPermission,
  SupportPermission,
  SupportTicketPermission,
  TeamManagerPermission,
  ExecutivePermission,
  CommissionsPermission
} from '../../../store/GeneralStore';
import { TextBlock } from '../../Atoms/Text';
import { Checkbox, MenuItem, Typography } from '@mui/material';
import { MultiSelectRoleExpand } from './MultiSelectRoleExpand';
import { getUser, isAdmin, isSupport } from '../../../store/User';
import { useWindowDimensions } from '../../../utils/WindowDimensions';

export interface RoleSelectionFormProps {
  selectedRoles: string[];
  updateRoles: (domains: string[]) => void;
  drawer?: boolean;
  size?: 'small' | 'medium' | undefined;
  styles?: string;
  hasError?: boolean;
  errorText?: string;
}

export const roleGroups: MultiSelectGroup[] = [
  {
    title: 'Inventory',
    items: [
      {
        label: 'Full Access',
        options: Object.values(InventoryPermission),
        mutualExclusiveWith: ['Inventory - View']
      },
      {
        label: 'View Only',
        options: Object.values(InventoryPermission),
        mutualExclusiveWith: ['Inventory - Full']
      }
    ]
  },
  {
    title: 'Support Tickets',
    items: [
      {
        label: 'Full Access',
        options: Object.values(SupportTicketPermission),
        mutualExclusiveWith: ['Support Tickets - View']
      },
      {
        label: 'View Only',
        options: Object.values(SupportTicketPermission),
        mutualExclusiveWith: ['Support Tickets - Full']
      }
    ]
  },
  {
    title: 'Admin',
    items: [
      {
        label: 'Full Access',
        options: Object.values(AdminPermission),
        mutualExclusiveWith: [
          'Support',
          'Advisor',
          'Customer Success Manager',
          'Service Delivery Manager',
          'Executive',
          'Team Manager'
        ]
      }
    ]
  },
  {
    title: 'Advisor',
    items: [
      {
        label: 'Full Access',
        options: Object.values(AdvisorPermission),
        mutualExclusiveWith: ['Support', 'Admin', 'Executive']
      }
    ]
  },
  {
    title: 'SDM',
    items: [
      {
        label: 'Full Access',
        options: Object.values(SdmPermission) as string[],
        mutualExclusiveWith: ['Support', 'Admin', 'Executive']
      }
    ]
  },
  {
    title: 'CSM',
    items: [
      {
        label: 'Full Access',
        options: Object.values(CsmPermission) as string[],
        mutualExclusiveWith: ['Support', 'Admin', 'Executive']
      }
    ]
  },
  {
    title: 'Support',
    items: [
      {
        label: 'Full Access',
        options: Object.values(SupportPermission) as string[],
        mutualExclusiveWith: [
          'Admin',
          'Advisor',
          'Customer Success Manager',
          'Service Delivery Manager',
          'Executive',
          'Team Manager'
        ]
      }
    ]
  },
  {
    title: 'Team Manager',
    items: [
      {
        label: 'Full Access',
        options: Object.values(TeamManagerPermission),
        mutualExclusiveWith: ['Admin', 'Support', 'Executive', 'Commissions']
      }
    ]
  },
  {
    title: 'Executive',
    items: [
      {
        label: 'Full Access',
        options: Object.values(ExecutivePermission) as string[],
        mutualExclusiveWith: [
          'Admin',
          'Advisor',
          'Customer Success Manager',
          'Service Delivery Manager',
          'Support',
          'Team Manager'
        ]
      }
    ]
  },
  {
    title: 'Commissions',
    items: [
      {
        label: 'Full Access',
        options: Object.values(CommissionsPermission) as string[],
        mutualExclusiveWith: ['Team Manager']
      }
    ]
  }
];

export const RoleSelectionForm = ({
  selectedRoles,
  updateRoles,
  drawer = false,
  size,
  styles,
  hasError = false,
  errorText
}: RoleSelectionFormProps) => {
  const [selectedRoleLabels, setSelectedRoleLabels] = useState<string[]>(selectedRoles);
  const { width } = useWindowDimensions();

  useEffect(() => {
    setSelectedRoleLabels(selectedRoles);
  }, [JSON.stringify(selectedRoles)]);

  const onHandleOptionClick = (option: string) => {
    const existingRoles = [...selectedRoles];
    if (existingRoles.some((role) => role === option)) {
      updateRoles(existingRoles.filter((role) => role !== option));
    } else {
      updateRoles([...existingRoles, option]);
    }
  };

  const customTitle = (title: string) => {
    const titles: { [key: string]: string } = {
      CSM: 'Customer Success Manager',
      SDM: 'Service Delivery Manager'
    };
    return titles[title] || title;
  };

  const customLabel = (title: string, label: string) => {
    return ['Support Tickets', 'Inventory'].includes(title) ? `${title} - ${label.split(' ')[0]}` : customTitle(title);
  };

  const handleIsOptionDisabled = (title: string, label: string, selectedItems: string[]): boolean => {
    const roleGroup = roleGroups.find((group) => group.title === title);
    const role = roleGroup?.items.find((item) => item.label === label);
    return role?.mutualExclusiveWith?.some((mew) => selectedItems.includes(mew)) || false;
  };

  const renderRoleOptions = (group: MultiSelectGroup, selectedItems: string[], clickHandler: CustomGroupRenderer) => {
    return group.items.map((item) => (
      <MenuItem
        key={`${group.title}-${item.label}`}
        onClick={(e) => {
          e.stopPropagation();
          clickHandler(group.title, item.label);
        }}
        value={customLabel(group.title, item.label)}
        data-testid={group.title}
        data-cy={`${group.title.toLowerCase()}-${item.label.toLowerCase().replace(' ', '-')}`}
        disabled={handleIsOptionDisabled(group.title, item.label, selectedItems)}>
        <Checkbox checked={selectedItems?.includes(customLabel(group.title, item.label))} />
        <Typography>{item.label}</Typography>
      </MenuItem>
    ));
  };

  const roleGroupsByRole = () => {
    if (isAdmin(getUser())) return roleGroups;

    const allowedRoles = isSupport(getUser())
      ? ['Support Tickets', 'Team Manager', 'Inventory']
      : ['Support Tickets', 'Inventory'];

    return roleGroups.filter((role) => allowedRoles.includes(role.title));
  };
  const menuPropsOverride = width < 1023 ? { width: '13rem', minWidth: 'unset' } : {};

  return (
    <div className={styles}>
      {!drawer && <TextBlock className="my-[4%] px-[4%] text-md">Select the roles for this user.</TextBlock>}
      <MultiSelectDropdown
        data-testid="role-select"
        dataCy="role-dropdown"
        groups={roleGroupsByRole()}
        selectedItems={selectedRoleLabels}
        onHandleOptionClick={onHandleOptionClick}
        placeholderText="Select roles..."
        customTitleHandler={customTitle}
        customLabelHandler={customLabel}
        groupDetailComponent={MultiSelectRoleExpand}
        customGroupRenderer={renderRoleOptions}
        size={size}
        menuPropsOverride={menuPropsOverride}
        hasError={hasError}
        errorText={errorText}
      />
    </div>
  );
};
