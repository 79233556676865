import { forwardRef, useState, createContext, useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

export interface Snack {
  message: string;
  type?: AlertColor;
  duration?: number;
  open: boolean;
}

interface SnackbarContextType {
  snack: Snack;
  setSnack: (snack: Snack) => void;
}

const SnackbarContext = createContext<SnackbarContextType>({} as SnackbarContextType);

interface SnackbarProviderProps {
  children?: React.ReactNode;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
    />
  );
});

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const DEFAULT_SNACK: Snack = {
    message: '',
    type: 'success',
    open: false,
    duration: 5000
  };
  const [snack, setSnack] = useState<Snack>(DEFAULT_SNACK);

  return (
    <SnackbarContext.Provider value={{ snack, setSnack }}>
      <Snackbar
        autoHideDuration={snack?.duration || 3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snack?.open}
        onClose={() => setSnack({ ...snack, open: false })}>
        <Alert
          onClose={() => setSnack({ ...snack, open: false })}
          severity={snack?.type}
          sx={{ width: '100%' }}>
          {snack?.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { snack, setSnack } = useContext(SnackbarContext);
  return { snack, setSnack };
};
