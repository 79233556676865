import { AccountData } from '../../../store/Accounts';
import { Team } from '../../../store/Team';
import { InventoryAsset, Order, PortalUserData, SupportTicketsItem } from '../../../types';
import { IconType } from '../../Atoms/Icon';
import { Text } from '../../Atoms/Text';

export type ComponentName = 'inventory' | 'order' | 'support' | 'user' | 'team' | 'account';
export interface DetailsMeta {
  title?: string;
  subtitleKey?: string;
  titleKey?: string;
  componentName?: ComponentName;
  icon?: IconType;
  idKey?: string;
  error?: string;
}

export const getComponentName = (pathname: string) => {
  if (/\/inventory/.test(pathname)) return 'inventory';
  if (/\/orders/.test(pathname)) return 'order';
  if (/\/support-ticketing/.test(pathname)) return 'support';
  if (/\/user/.test(pathname)) return 'user';
};

export const getMetaData = (name?: ComponentName): DetailsMeta => {
  if (!name) return { error: 'invalid component name' };

  switch (name) {
    case 'support':
      return {
        title: 'Support Ticket',
        subtitleKey: 'caseNumber',
        componentName: 'support'
      };
    case 'inventory':
      return {
        title: 'Inventory Details',
        subtitleKey: 'address',
        componentName: 'inventory'
      };
    case 'order':
      return {
        title: 'Order Details',
        subtitleKey: 'address',
        componentName: 'order'
      };
    case 'user':
      return {
        title: 'User',
        subtitleKey: 'first_name',
        componentName: 'user'
      };
    case 'team':
      return {
        subtitleKey: 'name',
        titleKey: 'account_name',
        componentName: 'team'
      };
    case 'account':
      return {
        title: 'Account Details',
        subtitleKey: 'name',
        componentName: 'account'
      };
    default:
      return {
        error: 'invalid component name'
      };
  }
};

export const subTitle = (
  componentName: ComponentName,
  data: InventoryAsset | SupportTicketsItem | Order | PortalUserData | Team | AccountData,
  subtitleKey: string
) => {
  switch (componentName) {
    case 'user': {
      return (
        <Text size="lg">
          {`${data['first_name' as keyof typeof data] || ''} ${data['last_name' as keyof typeof data] || ''}`}
        </Text>
      );
    }
    case 'inventory': {
      return (
        <span className="flex space-x-2">
          {!!(data as InventoryAsset)['UPSTACK Managed'] && (
            <img
              src="/UPSTACK_logo_black.svg"
              data-testid="upstack-logo"
              className="w-4"
              alt="upstack logo"
            />
          )}
          <Text
            className="self-center"
            size="lg">
            {data[subtitleKey as keyof typeof data]}
          </Text>
        </span>
      );
    }
    default: {
      return <Text size="lg">{data[subtitleKey as keyof typeof data]}</Text>;
    }
  }
};

export const getTitle = (
  title: string | undefined,
  data: InventoryAsset | SupportTicketsItem | Order | PortalUserData | Team | AccountData,
  titleKey: string | undefined
) => {
  if (title) return title;
  return data[titleKey as keyof typeof data];
};
