import { Box } from '@mui/material';
import { GridFilterInputValueProps, GridFilterItem, useGridRootProps } from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'util';

export const applyFilter = (filterItem: GridFilterItem) => {
  if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
    return null;
  }
  if (!filterItem.value[0] || !filterItem.value[1]) {
    return null;
  }
  return ({ value }: GridFilterItem): boolean => {
    return value != null && filterItem.value[0] <= value && value <= filterItem.value[1];
  };
};

export const InputNumberInterval = (props: GridFilterInputValueProps) => {
  const rootProps = useGridRootProps();
  const { item, applyValue } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<[number | null, number | null]>(item.value ?? '');

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound: number, upperBound: number) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    filterTimeout.current = setTimeout(() => {
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, rootProps.filterDebounceMs);
  };

  const handleUpperFilterChange = (newUpperBound: string) => {
    updateFilterValue(new Date(filterValueState[0] || '').getTime(), new Date(newUpperBound).getTime());
  };
  const handleLowerFilterChange = (newLowerBound: string) => {
    updateFilterValue(new Date(newLowerBound).getTime(), new Date(filterValueState[1] || '').getTime());
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: '3rem',
        width: '25rem',
        pl: '1rem'
      }}>
      <DatePicker
        value={new Date(filterValueState[0] || '')}
        onChange={(newValue) => handleLowerFilterChange(format(newValue))}
        slotProps={{ textField: { placeholder: 'start', size: 'small' } }}
        sx={{ mr: 2, mt: 2 }}
      />
      <DatePicker
        value={new Date(filterValueState[1] || '')}
        onChange={(newValue) => handleUpperFilterChange(format(newValue))}
        slotProps={{ textField: { placeholder: 'end', size: 'small' } }}
        sx={{ mr: 2, pt: 2 }}
      />
    </Box>
  );
};
