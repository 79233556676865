import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { DocumentPreview, DocumentPreviewTool } from '../../Atoms/DocumentPreviewTool';
import { getFileExtension } from '../../../Api/Attachments/useAttachmentsHelpers';
import { Icon } from '../../Atoms/Icon';
import { SrOnlyText } from '../../Atoms/Text';

export interface DocumentPreviewerProps {
  id: string;
  name?: string;
  downloadAttachment: (attachmentId: string) => Promise<string | null | undefined>;
  isS3?: boolean;
}

const contentStyle = {
  height: '90%',
  width: '50%'
};

const overlayStyle = { background: 'rgba(0,0,0,0.5)' };

const closeBtnStyles = [
  'top-2',
  'right-2',
  'rounded',
  'py-1',
  'px-4',
  'bg-white',
  'text-indigo',
  'text-lg',
  'font-light'
];

export const DocumentPreviewer = ({ id, name, downloadAttachment, isS3 = false }: DocumentPreviewerProps) => {
  const [open, setOpen] = useState(false);
  const [document, setDocument] = useState<DocumentPreview | null>(null);

  const fetchDocument = async () => {
    const documentUrl = await downloadAttachment(id);
    if (documentUrl) {
      setDocument({ uri: documentUrl, fileName: name || '', fileType: getFileExtension(name || ''), id, isS3 });
    }
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const handleClose = () => setOpen(false);

  if (!document) return null;

  return (
    <div className="relative h-[100px] w-[100px] border-[1px] border-solid border-[#D8D8D8] box-content">
      {!open && (
        <DocumentPreviewTool
          documents={[document]}
          fileType={document.fileType}
          className="[&_#pdf-controls]:hidden"
          styles={{
            height: '100px',
            width: '100px'
          }}
          configOverride={{
            pdfZoom: {
              defaultZoom: 0.1,
              zoomJump: 0.1
            }
          }}
        />
      )}
      <button
        className="absolute bottom-1 right-2 rounded bg-white"
        onClick={() => setOpen(true)}>
        <Icon
          type="expand"
          className="p-3 ml-auto cursor-pointer"
        />
        <SrOnlyText>Open document preview modal</SrOnlyText>
      </button>
      <Popup
        open={open}
        modal={true}
        contentStyle={contentStyle}
        overlayStyle={overlayStyle}
        onClose={handleClose}>
        <div className="h-full w-full flex-col">
          <div className="w-full flex flex-row justify-end">
            <button
              className={closeBtnStyles.join(' ')}
              onClick={() => handleClose()}>
              X<SrOnlyText>Close document preview modal</SrOnlyText>
            </button>
          </div>
          <DocumentPreviewTool
            documents={[document]}
            fileType={document.fileType}
            styles={{
              height: '95%'
            }}
          />
        </div>
      </Popup>
    </div>
  );
};
