import { useEffect, useState } from 'react';
import {
  AccountData,
  AccountHistory,
  fetchAccountHistory,
  getAccountHistorySubject
} from '../../../../../store/Accounts';
import { getUsersByAccount } from '../../../../../store/PortalUser';
import { Team, getTeamsByAccount } from '../../../../../store/Team';
import { User } from '../../../../../types';
import { AuditTrail } from './AuditTrail';
import { AccountCustomizations } from './Customizations';
import { SubAccounts } from './SubAccounts';
import { Teams } from './Teams';
import { Users } from './Users';
import {
  AccountCustomizationData,
  defaultAccountCustomizationData,
  getAccountCustomizationsSubject
} from '../../../../../store/AccountCustomization';

export interface AccountDetailsParams {
  data: AccountData;
}

export const AccountDetails = ({ data }: AccountDetailsParams) => {
  const teams: Team[] = getTeamsByAccount(data.id);
  const users: User[] = getUsersByAccount(data.id);
  const [acctCust, setAcctCust] = useState<AccountCustomizationData>(data?.accountCustomizations);
  const [auditTrail, setAuditTrail] = useState<AccountHistory[]>([]);

  useEffect(() => {
    const auditSub = getAccountHistorySubject().subscribe((h) => setAuditTrail(h));
    const accountCustSub = getAccountCustomizationsSubject().subscribe((acc) => {
      setAcctCust(acc.find((a) => a.account_id === data.id) || defaultAccountCustomizationData(data.id, data.name));
    });

    return () => {
      if (auditSub) auditSub.unsubscribe();
      if (accountCustSub) accountCustSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    fetchAccountHistory(data.id);
    setAcctCust(data?.accountCustomizations);
  }, [data.id]);

  return (
    <div
      className="w-11/12 m-auto"
      data-testid="account-details"
      data-cy="account-details">
      <SubAccounts
        accountCustomizations={acctCust}
        accountId={data.id}
      />
      <AccountCustomizations data={acctCust} />
      <Users users={users} />
      <Teams teams={teams} />
      <AuditTrail data={auditTrail} />
    </div>
  );
};
