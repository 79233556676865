import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemButton,
  ListSubheader,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export interface MultiSelectProps {
  groups: MultiSelectGroup[];
  selectedItems: string[];
  onHandleOptionClick: (addedOption: string) => void;
  placeholderText?: string;
  groupDetailComponent?: React.FC<DetailComponentProps>;
  customTitleHandler?: (title: string) => string;
  customLabelHandler?: (title: string, label: string) => string;
  customGroupRenderer?: (group: MultiSelectGroup, selected: string[], clickHandler: CustomGroupRenderer) => ReactNode[];
  size?: 'small' | 'medium' | undefined;
  menuPropsOverride?: object;
  hasError?: boolean;
  errorText?: string;
  dataCy?: string;
}

export interface DetailComponentProps {
  anchorEl: null | HTMLElement;
  onClose: (multiSelectState: MultiSelectState) => void;
  title: string;
  options: string[];
}

export type MultiSelectGroup = {
  title: string;
  items: MultiSelectItem[];
};

export type MultiSelectItem = {
  label: string;
  options: string[];
  mutualExclusiveWith?: string[];
};

type MultiSelectState = {
  showOptionsFor: string | null;
  optionAnchor: null | HTMLElement;
};

export type CustomGroupRenderer = (title: string, label: string) => void;

export const MultiSelectDropdown: React.FC<MultiSelectProps> = ({
  groups,
  selectedItems,
  onHandleOptionClick,
  placeholderText,
  groupDetailComponent,
  customTitleHandler,
  customLabelHandler,
  customGroupRenderer,
  size,
  menuPropsOverride,
  hasError,
  errorText,
  dataCy = ''
}: MultiSelectProps) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        ...menuPropsOverride
      }
    }
  };
  const [menuState, setMenuState] = useState<MultiSelectState>({
    showOptionsFor: null as string | null,
    optionAnchor: null as null | HTMLElement
  });
  const [itemsSelected, setItemsSelected] = useState(selectedItems);

  useEffect(() => {
    setItemsSelected(selectedItems);
  }, [JSON.stringify(selectedItems)]);

  const handleTitle = (title: string) => customTitleHandler?.(title) || title;

  const handleOptionLabel = (title: string, label: string) =>
    customLabelHandler?.(title, label) || `${title} - ${label}`;

  const handleOptionClick = (title: string, itemLabel: string) => {
    const optionLabel = handleOptionLabel(title, itemLabel);
    const updatedItems = itemsSelected.includes(optionLabel)
      ? itemsSelected.filter((item) => item !== optionLabel)
      : [...itemsSelected, optionLabel];
    setItemsSelected(updatedItems);
    onHandleOptionClick(optionLabel);
  };

  const renderMenuItems = (group: MultiSelectGroup) => {
    if (customGroupRenderer) return customGroupRenderer(group, itemsSelected, handleOptionClick);

    return group.items.map((item) => (
      <MenuItem
        key={`${group.title}-${item.label}`}
        onClick={(e) => {
          e.stopPropagation();
          handleOptionClick(group.title, item.label);
        }}
        value={handleOptionLabel(group.title, item.label)}
        data-testid={group.title}>
        <Checkbox checked={itemsSelected.includes(handleOptionLabel(group.title, item.label))} />
        <Typography>{item.label}</Typography>
      </MenuItem>
    ));
  };

  const renderDetailsExpandButton = () => {
    if (groupDetailComponent)
      return (
        <button className="px-2 py-0 border border-grey box-border ml-3 rounded text-xs leading-[15px] text-indigo hover:bg-gray-100">
          Details
        </button>
      );
    return null;
  };

  const renderGroupDetailComponent = () => {
    if (groupDetailComponent)
      return groupDetailComponent({
        anchorEl: menuState.optionAnchor,
        onClose: setMenuState,
        title: handleTitle(menuState.showOptionsFor || ''),
        options: groups.find((group) => group.title === menuState.showOptionsFor)?.items[0].options || []
      });

    return null;
  };

  return (
    <FormControl
      size={size}
      fullWidth
      error={hasError}>
      <InputLabel htmlFor="multi-select">{placeholderText || 'Select Options...'}</InputLabel>
      <Select
        name="select-options"
        value={itemsSelected || ''}
        label={placeholderText || 'Select Options...'}
        labelId="multi-select"
        data-cy={dataCy}
        id="multi-select"
        sx={{ fontSize: '.875rem' }}
        renderValue={(selected) => (selected as string[]).join(', ')}
        MenuProps={MenuProps}>
        {groups.map((group) => (
          <div key={group.title}>
            <ListItemButton
              onClick={(e) => {
                e.stopPropagation();
                setMenuState({ optionAnchor: e.currentTarget, showOptionsFor: group.title });
              }}>
              <ListSubheader>
                {handleTitle(group.title)}
                {renderDetailsExpandButton()}
              </ListSubheader>
            </ListItemButton>
            {renderMenuItems(group)}
          </div>
        ))}
      </Select>
      {renderGroupDetailComponent()}
      {hasError && <FormHelperText error>{errorText}</FormHelperText>}
    </FormControl>
  );
};
