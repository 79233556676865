import { CardWithTitle } from '../CardWithTitle';

export interface DetailViewCardProps {
  title: string;
  children: JSX.Element | JSX.Element[];
  childrenWrapperClass?: string;
  cardActions?: JSX.Element | JSX.Element[];
  enableCollapse?: boolean;
  className?: string;
  collapsed?: boolean;
  dataCy?: string;
  onCollapseClick?: (collapse: boolean) => void;
}

export function DetailViewCard({
  title,
  children,
  childrenWrapperClass,
  cardActions,
  enableCollapse,
  className,
  collapsed = false,
  dataCy = '',
  onCollapseClick
}: DetailViewCardProps) {
  return (
    <CardWithTitle
      cardActions={cardActions}
      title={title}
      enableCollapse={enableCollapse}
      onCollapseClick={onCollapseClick}
      titlePadding="p-3"
      className={className}
      childrenWrapperClass={childrenWrapperClass}
      collapsed={collapsed}
      dataCy={dataCy}>
      {children}
    </CardWithTitle>
  );
}
