import { useCallback, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { PortalUserData } from '../../../types';

export type AtMentionData = {
  id: string;
  value: string;
};
export interface TextEditorProps {
  commentText: string;
  handleCancel: () => void;
  handleSend: (commentText: string) => void;
  placeholder: string;
  portalUsers?: PortalUserData[];
  readonly?: boolean;
}

export function TextEditor({
  commentText,
  handleCancel,
  handleSend,
  placeholder,
  portalUsers = [],
  readonly = false
}: TextEditorProps) {
  const [comment, setComment] = useState<string>(commentText);
  const quillRef = useRef(null);

  const sourceFunction = (searchTerm: string, renderList: (vals: AtMentionData[], search: string) => void) => {
    const values = portalUsers.map(
      (user) => ({ id: user.email, value: `${user.first_name} ${user.last_name}` }) as AtMentionData
    );

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = values.filter((val) => val.value.toLowerCase().includes(searchTerm.toLowerCase()));
      renderList(matches, searchTerm);
    }
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'mention',
    'code-block'
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic'],
      ['link'],
      [{ list: 'bullet' }, { list: 'ordered' }],
      ['code-block']
    ],
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'],
      source: useCallback(sourceFunction, []),
      mentionContainerClass:
        'border-2 rounded bg-grey-0 min-w-[25%] max-w-[50%] max-h-[25vh] bg-white z-40 overflow-auto',
      listItemClass: 'px-2 py-2 h-full w-full text-sm text-indigo cursor-pointer text-ellipsis'
    }
  };

  return (
    <div data-testid="quillcontainer">
      <ReactQuill
        modules={modules}
        formats={formats}
        ref={quillRef}
        onChange={(e) => setComment(e)}
        placeholder={placeholder}
        theme="snow"
        readOnly={readonly}
        value={comment}
      />
      <div className="flex mt-auto justify-end gap-5 pt-4 pb-4 self-end w-full">
        <button
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={() => handleCancel()}>
          Cancel
        </button>
        <button
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          onClick={() => handleSend(comment)}
          disabled={!comment}>
          Send
        </button>
      </div>
    </div>
  );
}
