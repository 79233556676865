import React, { useEffect, useState } from 'react';
import { ImpersonationApi } from '../../../Api/Admin/Impersonations/impersonationApi';
import { datadogLogs } from '@datadog/browser-logs';
import { User } from '../../../types';
import { getUserSubject } from '../../../store/User';

export const ImpersonationBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const { exitImpersonate } = ImpersonationApi();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const userSubscription = getUserSubject().subscribe((user) => setUser(user));

    return () => {
      if (userSubscription) userSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user && user.impersonated) {
      const { email, first_name, last_name } = user;
      setShowBanner(true);
      setMessage(`You are now impersonating ${first_name} ${last_name} (${email})`);
    }
  }, [user?.impersonated]);

  const stopImpersonating = async () => {
    const impersonated_user_id = localStorage.getItem('impersonated_user_id');
    const isImpersonatorTeamManager = localStorage.getItem('is_impersonator_team_manager');
    const { error } = await exitImpersonate();
    if (error) {
      datadogLogs.logger.error('Exit user impersonation error: ', error);
    } else {
      setShowBanner(false);
      setMessage(undefined);
      const url = isImpersonatorTeamManager == 'true' ? 'team-settings' : 'admin';
      const redirectTo = encodeURIComponent(`/${url}?detailId=${impersonated_user_id}`);
      window.location.href = `?redirectTo=${redirectTo}`;
    }
  };

  return (
    <div>
      {showBanner && (
        <div className="impersonate-banner">
          <p className="impersonate-banner-text">{message}</p>
          <button
            className="impersonate-exit-button"
            onClick={stopImpersonating}>
            Exit
          </button>
        </div>
      )}
    </div>
  );
};
