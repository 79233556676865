import axios, { CancelTokenSource } from 'axios';
import { Attachment, S3Attachment } from '../../types';
import SUPPORTED_FILE_TYPES from './fileTypes.json';
const MAX_FILE_SIZE = 104857600; // 100 MB

function getFileExtension(fileName: string) {
  return fileName.substring(fileName.lastIndexOf('.') + 1);
}

function baseAttachment(file: File, id: string, cancelSource: CancelTokenSource, additionalProperties: object) {
  const baseAttachment = {
    id: id,
    file: file,
    status: 'queued',
    progress: 0,
    cancelSource
  };

  if (file.size > MAX_FILE_SIZE) baseAttachment.status = 'limited';
  if (!SUPPORTED_FILE_TYPES.includes(getFileExtension(file.name))) baseAttachment.status = 'unsupported';

  return { ...baseAttachment, ...additionalProperties };
}

function initAttachment(file: File, id: string, parentId: string): Attachment {
  const cancelToken = axios.CancelToken;
  const cancelSource: CancelTokenSource = cancelToken.source();

  const additionalProperties = {
    uploadDate: new Date().toLocaleString(),
    name: file.name,
    parentId: parentId
  };

  return baseAttachment(file, id, cancelSource, additionalProperties) as Attachment;
}

function initS3Attachment(file: File, id: string, entityId: string, entityType: string): S3Attachment {
  const cancelToken = axios.CancelToken;
  const cancelSource: CancelTokenSource = cancelToken.source();

  const additionalProperties = { entity_id: entityId, entity_type: entityType };

  return baseAttachment(file, id, cancelSource, additionalProperties) as S3Attachment;
}

export { MAX_FILE_SIZE, SUPPORTED_FILE_TYPES, getFileExtension, initAttachment, initS3Attachment };
