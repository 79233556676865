import { useEffect, useRef } from 'react';

export interface ProgressBarProps {
  progress: number;
  className?: string;
  progressBarStyleOverride?: string;
  fullWidth?: boolean;
}

export function ProgressBar({ progress, className, progressBarStyleOverride, fullWidth = false }: ProgressBarProps) {
  const percentageBar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (percentageBar.current) percentageBar.current.style.width = `${progress}%`;
  }, [progress]);

  return (
    <div
      className={`${fullWidth ? 'w-full' : 'w-24'} h-2 bg-grey-2 overflow-hidden ${className}`}
      data-testid="progress-bar">
      <div
        className={progressBarStyleOverride || 'h-full bg-sky-500 transition'}
        data-testid="progress-bar-fill"
        ref={percentageBar}
      />
    </div>
  );
}
