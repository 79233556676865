// Initialize DataDog
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { User } from './types';

const initializeDataDog = () => {
  const {
    REACT_APP_DATADOG_CLIENT_TOKEN,
    REACT_APP_DATADOG_RUM_APP_ID,
    REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    REACT_APP_DATADOG_SITE,
    REACT_APP_DATADOG_ENV
  } = process.env;

  if (REACT_APP_DATADOG_CLIENT_TOKEN && REACT_APP_DATADOG_SITE) {
    datadogLogs.init({
      clientToken: REACT_APP_DATADOG_CLIENT_TOKEN as string,
      site: REACT_APP_DATADOG_SITE as string,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: 'upstack-web',
      env: REACT_APP_DATADOG_ENV as string
    });
  }

  if (REACT_APP_DATADOG_RUM_APP_ID && REACT_APP_DATADOG_RUM_CLIENT_TOKEN && REACT_APP_DATADOG_SITE) {
    datadogRum.init({
      applicationId: REACT_APP_DATADOG_RUM_APP_ID as string,
      clientToken: REACT_APP_DATADOG_RUM_CLIENT_TOKEN as string,
      site: REACT_APP_DATADOG_SITE as string,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      service: 'upstack-web',
      defaultPrivacyLevel: 'mask-user-input',
      env: REACT_APP_DATADOG_ENV as string
    });

    datadogRum.startSessionReplayRecording();
  }
};

export default initializeDataDog;
export const setDataDogUser = (user: User) => {
  datadogRum.setUser({
    id: user.id,
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    account: user.account_name,
    accountId: user.account_id,
    role: user.role,
    roles: user.roles,
    permissions: user.permissions,
    first_name: user.first_name,
    last_name: user.last_name
  });
};
