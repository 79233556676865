import { useEffect, useState } from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Tooltip } from '@mui/material';
import { Account } from '../../../../../types';
import { DetailViewCard } from '../../../../Compounds/CardWithTitle';
import { BasicDataGrid } from '../../../../Compounds/Table/BasicDataGrid';
import { useSearchParams } from 'react-router-dom';
import { CardButton } from '../../../../Compounds/Buttons/CardButton';
import { AccountCustomizationData, useAccountCustomizationsStore } from '../../../../../store/AccountCustomization';
import { PostAccountCustomizationRequest } from '../../../../../Api/Preferences/AccountCustomizations/accountCustomizationsApi';
import { useSnackbar } from '../../../../../Context/SnackbarContext';
import { MultiSelect } from '../../../../Compounds/Selects/MultiSelect';
import { getAccountSubject, useAccounts } from '../../../../../store/Accounts';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../../../utils/styleHelpers';
import { TextSpan } from '../../../../Atoms/Text';
import { Icon } from '../../../../Atoms/Icon';

export interface SubAccountsParams {
  accountId: string;
  accountCustomizations: AccountCustomizationData;
}

export const SubAccounts = ({ accountCustomizations, accountId }: SubAccountsParams) => {
  const [subAccounts, setSubAccounts] = useState<Account[]>(accountCustomizations?.content?.sub_accounts || []);
  const [params, setParams] = useSearchParams();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const { updateRequest, createRequest, fetchAccountCustomizations } = useAccountCustomizationsStore();
  const { setSnack } = useSnackbar();
  const { fetchAccounts } = useAccounts();

  useEffect(() => {
    const accountsSub = getAccountSubject().subscribe((a) => setAccounts(a));

    return () => {
      if (accountsSub) accountsSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setSubAccounts(accountCustomizations?.content?.sub_accounts || []);
  }, [accountCustomizations.account_id]);

  const handleDetailClick = (id: string) => {
    params.get('detailId') === id ? params.delete('detailId') : params.set('detailId', id);
    setParams(params, { replace: true });
  };

  const handleDeleteClick = async (id: string) => {
    const update: PostAccountCustomizationRequest = JSON.parse(JSON.stringify(accountCustomizations));
    update.content.sub_accounts = update?.content?.sub_accounts?.filter((acc) => acc?.id !== id);

    handleUpdate(update, 'removed');
  };

  const handleAccountSelect = (e: React.SyntheticEvent<Element, Event>, value: string[] | null) => {
    setSelectedAccounts(accounts.filter((a) => value?.includes(a.name)) || []);
  };

  const handleSave = () => {
    const update: PostAccountCustomizationRequest = JSON.parse(JSON.stringify(accountCustomizations));
    update.content.sub_accounts = [...(update?.content?.sub_accounts || []), ...selectedAccounts];

    handleUpdate(update, 'saved');
  };

  const handleUpdate = async (update: PostAccountCustomizationRequest, action: string) => {
    const res = accountCustomizations.id
      ? await updateRequest(accountCustomizations.id, update)
      : await createRequest(update);
    if (res?.error) {
      setSnack({
        message: res.error.message,
        open: true,
        type: 'error'
      });
    } else {
      setSnack({
        message: `Account ${action}`,
        open: true,
        type: 'success'
      });
      setSubAccounts(update?.content?.sub_accounts || []);
      setSelectedAccounts([]);

      await fetchAccountCustomizations();
      await fetchAccounts(true);
    }
  };

  const getAccountOptions = () => {
    return accounts.filter((acct) => subAccounts.every((subAcct) => subAcct.id !== acct.id)).map((acct) => acct.name);
  };

  const addExistingSubAccounts = () => {
    setCollapsed(false);
    const accountsMatchingParentIdNotYetAdded = accounts.filter((acct) => {
      return (
        acct.parentId === accountId &&
        !selectedAccounts.some((selAcct) => selAcct.id === acct.id) &&
        !subAccounts.some((subAcct) => subAcct.id === acct.id)
      );
    });

    setSelectedAccounts([...selectedAccounts, ...accountsMatchingParentIdNotYetAdded]);
  };

  const cardActions = () => (
    <Tooltip
      placement="top"
      title="Link existing sub accounts from Salesforce">
      <button
        className="flex space-x-2 items-center"
        onClick={() => addExistingSubAccounts()}
        data-cy="link-existing-sub-accounts">
        <Icon
          type="plus"
          className="p-2"
        />
        <TextSpan
          color="indigo"
          size="sm14">
          Link Existing Sub Accounts
        </TextSpan>
      </button>
    </Tooltip>
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Account Name',
      width: 350
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Actions',
      renderCell: (params: GridCellParams) => {
        return (
          <div className="flex space-x-3">
            <CardButton
              disabled={!!selectedAccounts.length}
              className="hover:bg-slate-200 rounded disabled:opacity-25"
              data-testid="view-account"
              icon="details"
              text="View"
              onClick={() => {
                handleDetailClick(params.row.id);
              }}
            />
            <CardButton
              disabled={!!selectedAccounts.length}
              data-testid="delete-account"
              className="hover:bg-slate-200 rounded disabled:opacity-25"
              icon="trash"
              text="Delete"
              onClick={() => {
                handleDeleteClick(params.row.id);
              }}
            />
          </div>
        );
      },
      hideable: false,
      flex: 1
    }
  ];

  return (
    <DetailViewCard
      collapsed={collapsed}
      dataCy="sub-accounts-card"
      title={`Sub Accounts (${subAccounts?.length || 0})`}
      cardActions={cardActions()}
      enableCollapse>
      <MultiSelect
        sx={{
          marginBottom: '1rem',
          marginRight: 'auto',
          marginLeft: 'auto'
        }}
        selectedValues={selectedAccounts.map((a) => a.name)}
        options={getAccountOptions()}
        handleChange={handleAccountSelect}
        inputLabel="Add Sub Accounts"
      />
      <BasicDataGrid
        rows={subAccounts}
        columns={columns}
        dataCy="account-details-sub-account-tbl"
      />
      <div className="text-end space-x-3 mt-4">
        <button
          disabled={!selectedAccounts.length}
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={() => setSelectedAccounts([])}>
          Cancel
        </button>
        <button
          disabled={!selectedAccounts.length}
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          onClick={handleSave}>
          Save
        </button>
      </div>
    </DetailViewCard>
  );
};
