import { Icon } from '../../Atoms/Icon';
import { ProgressBar } from '../../Atoms/ProgressBar';
import { SrOnlyText, Text } from '../../Atoms/Text';
import { truncateStr } from '../../../utils/helpers';

export interface AttachmentRowTemplateProps {
  id: string;
  statusIcon: 'greyCheckmark' | 'greenCheckmark' | 'alert';
  fileName: string;
  statusMessage: string;
  progress?: number;
  handleAttachmentIcon?: 'retry' | 'cancel';
  handleAttachmentOnClick?: (attachmentId: string) => void;
}

export function AttachmentRowTemplate({
  id,
  statusIcon,
  fileName,
  statusMessage,
  progress,
  handleAttachmentIcon,
  handleAttachmentOnClick
}: AttachmentRowTemplateProps) {
  return (
    <div className="flex items-center w-full gap-2">
      <Icon
        className="p-3"
        type={statusIcon}
      />
      <Text
        className="max-w-[9rem] truncate"
        size="sm"
        weight="medium">
        {truncateStr(fileName)}
      </Text>
      <Text
        className="grow"
        size="sm"
        weight="medium">
        {statusMessage}
      </Text>
      {progress !== undefined && <ProgressBar progress={progress} />}
      {handleAttachmentIcon && handleAttachmentOnClick && (
        <button onClick={() => handleAttachmentOnClick(id)}>
          <Icon
            className="p-3"
            type={handleAttachmentIcon}
          />
          <SrOnlyText>{`${handleAttachmentIcon} button`}</SrOnlyText>
        </button>
      )}
    </div>
  );
}
