export type UserParams = {
  first_name?: string;
  last_name?: string;
  email?: string;
  tos_read?: boolean;
};

export const updateUsersApi = async (userParams: UserParams, userId: string) => {
  const token = localStorage.getItem('token');

  if (token) {
    const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/user/${userId}`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
      body: JSON.stringify({ user: userParams }),
      keepalive: true
    });
    const data = await response.json();
    return response.ok ? { data, error: null } : { data: null, error: data };
  }

  return { data: null, error: { message: 'Not authenticated or missing token' } };
};
