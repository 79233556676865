import { Dispatch, SetStateAction, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { SettingsForm } from './SettingsModalForm';
import { SettingsModalMain } from './SettingsModalMain';
import { UserPreferences, columnsDefault, widgetsDefault } from '../../../store/User';
import { updateForm } from '../../../store/BulkForm';

export interface SettingsModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  userPreferences: UserPreferences;
  userName: string | undefined;
  id: string;
}

export const SettingsModal = ({ open, setOpen, userPreferences, userName, id }: SettingsModalProps) => {
  const [modalViewState, setModalViewState] = useState<{ main: boolean; column: boolean; widget: boolean }>({
    main: true,
    column: false,
    widget: false
  });
  const [newUserPreferences, setNewUserPreferences] = useState<UserPreferences>(userPreferences);

  const handleSave = () => {
    updateForm(id, { userCustomizations: newUserPreferences }, 'user');
    navigateModal('main');
    setOpen(false);
  };

  const navigateModal = (view: 'main' | 'column' | 'widget') => {
    switch (view) {
      case 'column':
        return setModalViewState({ main: false, widget: false, column: true });
      case 'widget':
        return setModalViewState({ main: false, widget: true, column: false });
      case 'main':
      default:
        return setModalViewState({ main: true, widget: false, column: false });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}>
      {modalViewState.column && (
        <SettingsForm
          setNewUserPreferences={setNewUserPreferences}
          customizations={newUserPreferences}
          title="Inventory"
          onBack={() => navigateModal('main')}
          onSave={() => navigateModal('main')}
        />
      )}
      {modalViewState.widget && (
        <SettingsForm
          customizations={newUserPreferences}
          setNewUserPreferences={setNewUserPreferences}
          title="Widgets"
          onBack={() => navigateModal('main')}
          onSave={() => navigateModal('main')}
        />
      )}
      {modalViewState.main && (
        <SettingsModalMain
          username={userName || ''}
          columnsCustom={!columnsDefault(newUserPreferences.content.inventory_grid_All.muiConfig)}
          widgetsCustom={!widgetsDefault(newUserPreferences.content.widgets)}
          onEditColumns={() => navigateModal('column')}
          onEditWidgets={() => navigateModal('widget')}
          onCancel={() => setOpen(false)}
          onSave={handleSave}
        />
      )}
    </Dialog>
  );
};
