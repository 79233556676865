import { Text } from '../../Atoms/Text';
import { usaCurrency, formatInfo, getDate } from '../../../utils/helpers';
import { format } from 'date-fns';

export interface DetailRowProps {
  title: string;
  value: string;
  colId: string;
  gutterBottom?: boolean;
}

const currencyRows: string[] = ['mRC', 'nRC'];
const dateRows: string[] = [
  'completionDate',
  'contractEndDate',
  'firstInvoiceReviewedByDate',
  'disconnectedDate',
  'serviceActivationDate'
];
const infoRows: string[] = [
  'iPDetails',
  'lecDemarc',
  'locationAText',
  'locationZLecCircuitId',
  'locationZLecDemarc',
  'locationZLocalLec',
  'locationALecDemarc',
  'locationALocalLec',
  'locationALecCircuitId',
  'phoneNumbers',
  'circuitIDs',
  'lecCircuitID'
];

const formatValue = (value: string, colId: string) => {
  if (currencyRows.includes(colId)) return `${usaCurrency.format(Number(value))}`;
  if (dateRows.includes(colId)) return value ? format(getDate(value), 'MM/dd/yyyy') : '';
  if (infoRows.includes(colId)) return formatInfo(value);

  return value;
};

export function DetailRow({ title, value, colId, gutterBottom = true }: DetailRowProps) {
  return (
    <div
      className={`flex${gutterBottom ? ' mb-8' : ''}`}
      data-testid={`detail-row-${title}`}>
      <Text
        className="font-sans text-grey-4 w-full"
        size="sm14">
        {title}
      </Text>
      <Text
        className="font-sans font-normal w-full"
        size="sm">
        {formatValue(value, colId)}
      </Text>
    </div>
  );
}
