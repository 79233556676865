import { ForgotFormData } from '../../components/Compounds/Modals/Login';
import { callExternalApi, CallResult } from '../callExternalApi';
import { PasswordFormData } from '../../components/Compounds/Modals/ResetPassword';

export interface ForgotPasswordApiResponse {
  data: { success: boolean } | null;
  error: null | { message: string };
}

export interface ResetPasswordResponse {
  data: object;
  message: string;
  success: boolean;
}

export async function resetPasswordApi(body: PasswordFormData): Promise<CallResult<ResetPasswordResponse>> {
  return callExternalApi<ResetPasswordResponse>(
    {
      url: 'auth/password',
      method: 'PATCH',
      headers: {
        'content-type': 'application/json'
      },
      data: JSON.stringify(body)
    },
    true
  );
}

export async function userForgotPassword(body: ForgotFormData) {
  return callExternalApi<void>(
    {
      url: 'auth/password',
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      data: JSON.stringify(body)
    },
    true
  );
}

export async function adminForgotPassword(body: ForgotFormData): Promise<ForgotPasswordApiResponse> {
  return await callExternalApi<{ success: boolean }>({
    url: 'api/admin/password',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: { redirect_url: body.redirect_url, email: body.email }
  });
}
