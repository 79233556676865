export interface MapTableFooterProps {
  handleAllClick: () => void;
}

export function MapTableFooter({ handleAllClick }: MapTableFooterProps) {
  return (
    <div>
      <button
        className="cursor-pointer text-indigo text-[12px] px-[20px] pb-[12px]"
        onClick={() => handleAllClick()}>
        All Locations
      </button>
    </div>
  );
}
