import { Dispatch, SetStateAction } from 'react';
import { Icon } from './Icon';
import { SrOnlyText } from './Text';

export interface CollapseButtonProps {
  classNames: string;
  collapse: boolean;
  setCollapse: Dispatch<SetStateAction<boolean>>;
  testId?: string;
}

export function CollapseButton({ classNames, collapse, setCollapse, testId }: CollapseButtonProps) {
  const collapseClass = !collapse ? 'rotate-90' : '';

  return (
    <button
      onClick={() => setCollapse(!collapse)}
      data-testid={testId || 'collapse-button'}
      className={`flex items-center justify-center ${classNames}`}>
      <SrOnlyText>collapse button</SrOnlyText>
      <Icon
        className={`p-1 ${collapseClass}`}
        type="dropdown"
      />
    </button>
  );
}
