import React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { MultiSelect } from '../../Compounds/Selects/MultiSelect';
import CloseIcon from '@mui/icons-material/Close';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { TextBlock } from '../../Atoms/Text';
import { Account } from '../../../types';

interface CompanySettingsBulkSaveDialogProps {
  open: boolean;
  onClose: () => void;
  accounts: Account[];
  selectedBulkAccounts: Account[];
  handleAccountSelect: (e: React.SyntheticEvent<Element, Event>, value: string[] | null) => void;
  saveAccountCust: () => void;
}

const CompanySettingsBulkSaveDialog: React.FC<CompanySettingsBulkSaveDialogProps> = ({
  open,
  onClose,
  accounts,
  selectedBulkAccounts,
  handleAccountSelect,
  saveAccountCust
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 4,
          color: (theme) => theme.palette.grey[500]
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <TextBlock className="text-xl my-2 self-start">Copy Settings</TextBlock>
        <p>Select which companies should these settings be copied to?</p>

        <div className="flex mt-5 px-1">
          <MultiSelect
            sx={{ width: '100%' }}
            selectedValues={selectedBulkAccounts.map((a) => a.name)}
            options={accounts.map((a) => a.name)}
            handleChange={handleAccountSelect}
            inputLabel="Select Accounts"
          />
        </div>

        <hr className="mt-3 h-0.5 border-t-2" />

        <div className="flex justify-between mt-5">
          <button
            onClick={onClose}
            className={DEFAULT_CANCEL_BUTTON_STYLE}>
            Close
          </button>
          <button
            onClick={saveAccountCust}
            className={DEFAULT_ADVANCE_BUTTON_STYLE}>
            Save
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CompanySettingsBulkSaveDialog;
