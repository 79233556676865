import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import {
  getUserPreferencesSubject,
  gridInitialState,
  GridPreferences,
  updateUserSettings,
  UserPreferences
} from '../../../store/User';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { DefaultInventoryGridColumnDefs } from '../../Pages/Inventory/inventoryGridColumnDefs';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { TextBlock } from '../../Atoms/Text';

export interface NewViewModalParams {
  open: boolean;
  setOpen: (state: boolean) => void;
  sourceTab: string;
}

export const NewViewModal = ({ open, setOpen, sourceTab }: NewViewModalParams) => {
  const { setSnack } = useSnackbar();
  const [userPreferences, setUserPreferences] = useState<UserPreferences>({} as UserPreferences);
  const [newName, setNewName] = useState('');

  useEffect(() => {
    const prefSub = getUserPreferencesSubject().subscribe((prefs) => {
      setUserPreferences(prefs);
    });

    return () => {
      if (prefSub) prefSub.unsubscribe();
    };
  }, []);

  const handleSubmitForm = async () => {
    const currentSettings: UserPreferences = JSON.parse(JSON.stringify(userPreferences));
    if (currentSettings && currentSettings.content) {
      let newView: GridPreferences = {
        customView: true,
        muiConfig: gridInitialState(DefaultInventoryGridColumnDefs) as GridInitialStatePro,
        disabledFields: []
      };

      const currentTabTableState = JSON.parse(
        JSON.stringify(currentSettings?.content?.[`inventory_grid_${sourceTab}`])
      );
      newView = {
        ...newView,
        ...currentTabTableState
      };

      // Add new view.
      currentSettings.content[`inventory_grid_${newName}`] = newView;
      const { data, error } = await updateUserSettings(currentSettings, `inventory_grid_${newName.replace(' ', '_')}`);
      if (data) setSnack({ message: 'Your view was created successfully', type: 'success', open: true });
      if (error)
        setSnack({ message: `There was an error creating your view: \n${error.message}`, type: 'error', open: true });

      handleClose();
    }
  };

  const handleClose = () => {
    setNewName('');
    setOpen(false);
  };

  return (
    <Dialog
      data-testid="new-view-modal"
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      fullWidth>
      <DialogTitle>
        <TextBlock
          weight="bold"
          size="lg"
          color="grey9">
          Save as Tab
        </TextBlock>
      </DialogTitle>
      <DialogContent sx={{ minHeight: 70, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="space-y-5 justify-center w-full">
          <TextField
            required
            data-testid="new-view-name-input"
            sx={{ width: '100%' }}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Tab Name *"
            size="small"
          />
        </div>
      </DialogContent>
      <hr className="mb-1 mx-6" />
      <DialogActions sx={{ marginRight: 2, pb: 2 }}>
        <button
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={handleClose}>
          Cancel
        </button>
        <button
          data-testid="submit-new-view"
          disabled={!newName}
          onClick={handleSubmitForm}
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          type="submit">
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};
