import { useRef } from 'react';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';
import { AttachmentCTAProps } from './AttachmentCTA';

export function AttachmentTableCTA({ addAttachments }: AttachmentCTAProps) {
  const addAttachmentRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <TextSpan className="flex items-center space-x-2">
        <Icon type="plus" />
        <button
          className="text-[indigo] text-sm"
          data-cy="new-attachment"
          onClick={() => addAttachmentRef.current?.click()}>
          New Attachment
        </button>
        <input
          hidden
          ref={addAttachmentRef}
          type="file"
          multiple={true}
          data-testid="attachment-input"
          onChange={(e) => addAttachments(Array.from(e.target.files as FileList))}
        />
      </TextSpan>
    </>
  );
}
