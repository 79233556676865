import { AccountHistory } from '../../../store/Accounts';
import { callExternalApi } from '../../callExternalApi';

export const getAccountHistoryApi = async (account_id: string) => {
  return await callExternalApi<AccountHistory[]>({
    url: `api/admin/user_events?account_id=${account_id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
