import { GridColDef } from '@mui/x-data-grid-pro';
import CloseIcon from '@mui/icons-material/Close';
import { BasicDataGrid } from '../Table/BasicDataGrid';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getOutsideInventoryValidationStateSubject } from '../../../store/OutsideInventory';
import {
  FileValidationResponse,
  uploadXLFile,
  ValidationSuccess
} from '../../../Api/OutsideInventory/outsideInventoryApi';
import { getProductById } from '../../../store/Product';
import { getAccountName } from '../../../store/Accounts';
import { v4 } from 'uuid';
import { TextSpan } from '../../Atoms/Text';
import { Icon } from '../../Atoms/Icon';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import { ConfirmationModal } from '../../Pages/AddInventory/ConfirmationModal';
import { ProgressHandler } from '../../../Api/Attachments/uploadAttachment';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import { Account } from '../../../types';
import { useInventory } from '../../../store/Inventory';

export interface ValidationStateSuccessData {
  product: string;
  count: number;
  account: string;
  id: string;
}

export interface ValidationSummaryParams {
  file: File | undefined;
  progressHandler: ProgressHandler;
  setUploading: Dispatch<SetStateAction<boolean>>;
  setProgress: Dispatch<SetStateAction<number>>;
  selectedAccount: Account | undefined;
}

export const ValidationSummary = ({
  file,
  progressHandler,
  setUploading,
  setProgress,
  selectedAccount
}: ValidationSummaryParams) => {
  const [validationState, setValidationState] = useState<FileValidationResponse>();
  const [showValidation, setShowValidation] = useState(false);
  const [validationStateSuccess, setValidationStateSuccess] = useState<ValidationStateSuccessData[]>();
  const [successCount, setSuccessCount] = useState<number>();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { fetchInventory } = useInventory();
  const { setSnack } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const stateSub = getOutsideInventoryValidationStateSubject().subscribe((vs) => {
      vs?.errors?.forEach((error) => (error.id = v4()));
      vs?.warnings?.forEach((error) => (error.id = v4()));
      setValidationState(vs);
      if (Object.keys(vs)?.length > 0) setShowValidation(true);

      if (vs?.success?.length) {
        const successMap = vs.success.reduce(
          (dataMap: Record<string, { count: number; account: string; id: string }>, successMsg: ValidationSuccess) => {
            const product = getProductById(successMsg.product_id);
            if (product?.name) {
              if (!dataMap[product.name]) {
                dataMap[product.name] = {
                  id: v4(),
                  count: 0,
                  account: getAccountName(successMsg.account_id) || ''
                };
              }
              dataMap[product.name].count++;
            }

            return dataMap;
          },
          {} as Record<string, { count: number; account: string; id: string }>
        );
        const displayData = Object.keys(successMap).map((key) => ({ product: key, ...successMap[key] }));
        setValidationStateSuccess(displayData);
        setSuccessCount(displayData.map((vs) => vs.count).reduce((acc, c) => acc + c));
      } else {
        setValidationStateSuccess([]);
      }
    });
    return () => {
      if (stateSub) stateSub.unsubscribe();
    };
  }, []);

  const successCols: GridColDef[] = [
    { headerName: 'Product', field: 'product', width: 250 },
    { headerName: 'Count', field: 'count', width: 250 },
    { headerName: 'Account', field: 'account', flex: 1 }
  ];

  const failureCols: GridColDef[] = [
    { headerName: 'External ID', field: 'external_id', width: 250 },
    { headerName: 'Row', field: 'row', width: 250 },
    { headerName: 'Column', field: 'column', width: 250 },
    { headerName: 'Message', field: 'error', flex: 1 }
  ];

  const warningCols: GridColDef[] = [
    {
      headerName: 'Warning Level',
      field: 'color',
      width: 100,
      renderCell: (params) => <Box sx={{ width: '100%', height: '100%', backgroundColor: params.value }}></Box>
    },
    ...failureCols
  ];

  const clear = () => {
    setValidationStateSuccess([]);
    setValidationState({} as FileValidationResponse);
  };

  const submitFile = async () => {
    if (file) {
      setUploading(true);
      setProgress(0);
      const { data, error } = await uploadXLFile(file, progressHandler, selectedAccount?.id);
      await fetchInventory(true);
      setUploading(false);

      if (data?.success) {
        setSnack({ message: 'Your inventory was saved successfully', type: 'success', open: true });
        clear();
        navigate('/inventory');
      }
      if (error) {
        setSnack({ message: error.message, type: 'error', open: true });
      }
    }
  };

  return (
    <>
      {showValidation && (
        <div data-testid="validation-summary-container">
          {!!validationStateSuccess?.length && (
            <>
              <TextSpan className="flex items-center py-3">
                <Icon
                  type="circlemark"
                  className="p-3"
                />
                {successCount} inventory are valid
              </TextSpan>
              <BasicDataGrid
                rows={validationStateSuccess || []}
                columns={successCols}
                showToolbar={true}
              />
            </>
          )}
          {!!validationState?.warnings?.length && (
            <>
              <TextSpan className="flex items-center py-3">
                <Icon
                  type="info"
                  className="p-3"
                />
                {validationState?.warnings?.length} line item warnings
              </TextSpan>
              <BasicDataGrid
                rows={validationState?.warnings || []}
                columns={warningCols}
                showToolbar={true}
              />
            </>
          )}
          {!!validationState?.errors?.length && (
            <>
              <TextSpan className="flex items-center py-3">
                <Icon
                  type="alert"
                  className="p-3"
                />
                {validationState?.errors?.length} inventory are invalid
              </TextSpan>
              <BasicDataGrid
                rows={validationState?.errors || []}
                columns={failureCols}
                showToolbar={true}
              />
            </>
          )}
          {validationState?.errors?.length === 0 && file && (
            <div className="flex flex-row justify-between my-4">
              <button
                className={`${DEFAULT_CANCEL_BUTTON_STYLE} mr-2`}
                onClick={clear}>
                Cancel
              </button>
              <>
                <button
                  className={DEFAULT_ADVANCE_BUTTON_STYLE}
                  onClick={() => setConfirmationModalOpen(true)}
                  disabled={!successCount}>
                  Continue
                </button>
                <Dialog
                  open={confirmationModalOpen}
                  onClose={() => setConfirmationModalOpen(false)}>
                  <IconButton
                    aria-label="close"
                    onClick={() => setConfirmationModalOpen(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 4,
                      color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                  </IconButton>
                  <DialogContent>
                    <ConfirmationModal
                      close={() => setConfirmationModalOpen(false)}
                      items={successCount || 0}
                      ctaAction={submitFile}
                      type="Save"
                      wrapperClassName="max-w-[30vw] min-w-[20vw]"
                    />
                  </DialogContent>
                </Dialog>
              </>
            </div>
          )}
        </div>
      )}
    </>
  );
};
