import { WidgetDef } from '../../../types';

export const HomeWidgetDefs: WidgetDef[] = [
  { widgetId: 'upcomingExpirations', headerName: 'Upcoming Expirations', index: 0, hide: false, disabled: false },
  {
    widgetId: 'status',
    headerName: 'Status',
    index: 1,
    hide: false,
    disabled: false
  },
  { widgetId: 'locations', headerName: 'Locations', index: 2, hide: false, disabled: false },
  { widgetId: 'tickets', headerName: 'Tickets', index: 3, hide: false, disabled: false },
  { widgetId: 'suppliers', headerName: 'Top Suppliers', index: 4, hide: false, disabled: false }
];
