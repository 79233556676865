import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';

interface DetailsLineItemsCTAProps {
  setIsModalOpen: (isModalOpen: boolean) => void;
}

export function DetailsLineItemsCTA({ setIsModalOpen }: DetailsLineItemsCTAProps) {
  return (
    <>
      <TextSpan className="flex items-center space-x-2">
        <Icon type="plus" />
        <button
          className="text-[indigo] text-sm"
          data-cy="new-attachment"
          onClick={() => setIsModalOpen(true)}>
          Add Line Item
        </button>
      </TextSpan>
    </>
  );
}
