import { Icon } from '../../Atoms/Icon';
import { SrOnlyText } from '../../Atoms/Text';

export type UpstackSourcedRendererProps = {
  upstackManaged: boolean;
};

export function UpstackSourcedRenderer({ upstackManaged }: UpstackSourcedRendererProps) {
  if (!upstackManaged) return <SrOnlyText>Inventory is not UPSTACK sourced</SrOnlyText>;

  return (
    <div className="mt-3">
      <Icon type="indigoCheckmark" />
      <SrOnlyText>Inventory is UPSTACK sourced</SrOnlyText>
    </div>
  );
}
