import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { fetchProductsApi } from '../Api/Products/productsApi';
import { Product } from '../types';

const productsSubject = new BehaviorSubject<Product[]>([]);
const productsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getProductSubject = (): BehaviorSubject<Product[]> => productsSubject;
export const getProductLoadingSubject = (): BehaviorSubject<boolean> => productsLoadingSubject;
export const getProductById = (id: string) => productsSubject.getValue().find((p) => p.id === id);

export interface UseProductsStoreReturn {
  fetchProducts: () => Promise<void>;
  clear: () => void;
  setProducts: (products: Product[]) => void;
}

export const useProductsStore = (): UseProductsStoreReturn => {
  const fetchProducts = async (): Promise<void> => {
    productsLoadingSubject.next(true);

    const { data, error } = await fetchProductsApi();

    if (data) productsSubject.next(data);
    if (error) addError('product', error.message);

    productsLoadingSubject.next(false);
  };

  const setProducts = (products: Product[]) => productsSubject.next(products);

  const clear = () => productsSubject.next([]);

  return {
    fetchProducts,
    clear,
    setProducts
  };
};
