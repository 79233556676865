import { GridColDef } from '@mui/x-data-grid-pro';
import { DetailViewCard } from '../../../../Compounds/CardWithTitle';
import { format } from 'date-fns';
import { BasicDataGrid } from '../../../../Compounds/Table/BasicDataGrid';
import { AccountHistory } from '../../../../../store/Accounts';

export interface AuditTrailParams {
  data: AccountHistory[];
}

export const AuditTrail = ({ data }: AuditTrailParams) => {
  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: 'Action',
      flex: 1
    },
    {
      field: 'user',
      headerName: 'Name',
      flex: 1
    },
    {
      field: 'updated_at',
      headerName: 'Date',
      sortable: true,
      valueFormatter: (value) => {
        return value ? format(value, 'PPpp') : '';
      },
      flex: 1
    }
  ];

  return (
    <DetailViewCard
      title="Audit Trail"
      enableCollapse
      collapsed>
      <BasicDataGrid
        rows={data}
        columns={columns}
        className="h-96 overflow-auto"
      />
    </DetailViewCard>
  );
};
