import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { fetchNotificationsApi, updateNotificationApi } from '../Api/Notifications/notificationsApi';
import { InventoryAsset, SupportTicketsItem, User } from '../types';
import { Comment } from '../store/Comment';
import notificationChannel from '../Api/sockets/notifications';

export enum NotificationType {
  TICKET_CLOSED = 'Ticket Closed',
  TICKET_OPEN = 'Ticket Open',
  INSTALL_UPDATE = 'Install Update',
  COMMENT_MENTION = 'Comment Mention',
  COMMENT_CREATED = 'Comment Created'
}

export interface NotificationMessage {
  account_id: string;
  after_val: string | null;
  before_val: string | null;
  entity_id: string;
  field_name: string;
  object_type: string;
  entity: InventoryAsset | SupportTicketsItem | Comment | null;
}

export interface Notification {
  portal_user: User;
  updated_by: string;
  notification_message: {
    content: NotificationMessage;
    message_type: string;
  };
  entity_id: string;
  account_id: string;
  is_read: boolean;
  is_archived: boolean;
  id: number;
  /**
   * @TODO this is temporary solution to handling missing created_at field. Field should always be a string.
   * Undo this change as part of fix in UPDEV-2092
   */
  created_at?: string;
}

export interface SortedNotifications {
  read: Notification[];
  unread: Notification[];
  archived: Notification[];
}

const notificationsSubject = new BehaviorSubject<Notification[]>([]);
const sortedNotificationsSubject = new BehaviorSubject<SortedNotifications>({} as SortedNotifications);
const notificationsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getNotificationsSubject = (): BehaviorSubject<Notification[]> => notificationsSubject;
export const getSortedNotificationsSubject = (): BehaviorSubject<SortedNotifications> => sortedNotificationsSubject;

// Leaving these empty for now.
const connectedHandler = () => {};
const disconnectedHandler = () => {};

export const clearNotificationStore = () => {
  notificationsSubject.next([]);
  sortedNotificationsSubject.next({} as SortedNotifications);
};

export const sortNotifications = (notifications: Notification[]) => {
  notifications = notifications.sort((a, b) => {
    if (a?.created_at && b?.created_at) {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    }
    return 1;
  });

  const sortedNotify = {
    unread: notifications?.filter((item) => !item.is_read && !item.is_archived) || [],
    read: notifications?.filter((item) => item.is_read && !item.is_archived) || [],
    archived: notifications?.filter((item) => item.is_archived) || []
  };

  sortedNotificationsSubject.next(sortedNotify);
};

const receivedHandler = (data: { type: string; notifications: Notification[] }) => {
  const currentNotifications = [...notificationsSubject.getValue()];
  const update = [...currentNotifications, ...data.notifications];
  notificationsSubject.next(update);
  sortNotifications(update);
};

export const createSocket = () => {
  notificationChannel({ connectedHandler, disconnectedHandler, receivedHandler });
};

export const fetchNotifications = async (page: number): Promise<void> => {
  notificationsLoadingSubject.next(true);

  const { data, error } = await fetchNotificationsApi(page);

  if (error) addError('notifications', error.message);
  if (data) {
    const currentNotifications = [...notificationsSubject.getValue()];
    const update = [...currentNotifications, ...data];
    notificationsSubject.next(update);
    sortNotifications(update);
  }

  notificationsLoadingSubject.next(false);
};

export const fetchNotificationsByEntity = async (
  entityId: string,
  page: number,
  notifications: Notification[]
): Promise<Notification[] | undefined> => {
  notificationsLoadingSubject.next(true);

  const { data, error } = await fetchNotificationsApi(page, entityId);

  notificationsLoadingSubject.next(false);

  if (error) addError('notifications', error.message);
  if (data) {
    const currentNotifications = notifications.filter((n) => n.entity_id === entityId);
    const update = [...currentNotifications, ...data];

    // Sort un-read to top.
    return update.sort((a, b) => Number(a.is_read) - Number(b.is_read));
  }
};

export const updateNotification = async (notification: Notification): Promise<void> => {
  notificationsLoadingSubject.next(true);

  const currentNotifications = [...notificationsSubject.getValue()];
  const notify = currentNotifications.find((n) => n.id === notification.id);
  if (notify) {
    notify.is_archived = notification.is_archived;
    notify.is_read = notification.is_read;
    notificationsSubject.next(currentNotifications);
    sortNotifications(currentNotifications);
  }

  const { error } = await updateNotificationApi(notification);
  if (error) addError('notifications', error.message);

  notificationsLoadingSubject.next(false);
};

export const updateMany = (notifications: Notification[]) => {
  const promises: Promise<void>[] = notifications.map((notification) => {
    return updateNotification(notification);
  });

  return Promise.allSettled(promises);
};
