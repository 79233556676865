import { useEffect, useState } from 'react';
import { User } from '../../../types';
import { PageLayout } from '../../PageLayout';
import { AddPortalUserToTeam } from '../../Compounds/AddPortalUserToTeamForm';
import {
  clearForm,
  getFormSubject,
  getFormValidSubject,
  updateForm
} from '../../../store/AddPortalUsersToTeamFormState';
import { getPortalUserSubject, usePortalUsers } from '../../../store/PortalUser';
import { useNavigate } from 'react-router-dom';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';

export const AddUserToTeamPage = () => {
  const navigate = useNavigate();
  const { addUsersToTeam } = usePortalUsers();
  const [form, setForm] = useState(getFormSubject().getValue());
  const [requestLoading, setRequestLoading] = useState(false);
  const [formValid, setFormValid] = useState(getFormValidSubject().getValue());
  const [accountUsers, setAccountUsers] = useState<User[]>([]);

  const addUsersToForm = (users: User[]) => {
    updateForm({ portal_users: users });
  };

  const getHeaderCopy = () => {
    return form.type === 'manager' ? 'Add Team Managers' : 'Add Users To Team';
  };

  const getSummaryUrl = () => {
    const routeType = form.type === 'manager' ? 'managers' : 'users';
    return `/admin/teams/add-${routeType}/summary`;
  };

  const handleSave = async () => {
    setRequestLoading(true);
    await addUsersToTeam();
    setRequestLoading(false);
    navigate(getSummaryUrl());
  };

  const handleCancel = () => {
    clearForm();
    navigate(-1);
  };

  useEffect(() => {
    const formSub = getFormSubject().subscribe((state) => {
      setForm(state);
    });
    const formValidSub = getFormValidSubject().subscribe((valid) => {
      setFormValid(valid);
    });

    const userSub = getPortalUserSubject().subscribe((users) => {
      if (form.account_id) {
        setAccountUsers(users);
      }
    });

    return () => {
      if (formSub) formSub.unsubscribe();
      if (formValidSub) formValidSub.unsubscribe();
      if (userSub) userSub.unsubscribe();
    };
  }, []);

  return (
    <PageLayout>
      {requestLoading && <ComponentSpinner />}
      {!requestLoading && (
        <AddPortalUserToTeam
          account={form.account_name}
          team={form.team_name}
          header={getHeaderCopy()}
          users={accountUsers}
          selectedUsers={form.portal_users}
          valid={formValid}
          addUsers={addUsersToForm}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </PageLayout>
  );
};
