import { InventoryAsset } from '../../../types';

export interface UpcomingExpirationsTableFooterProps {
  filteredByQuarterInventoryAssets: InventoryAsset[];
  handleMoreClick: () => void;
}

export function UpcomingExpirationsTableFooter({
  filteredByQuarterInventoryAssets,
  handleMoreClick
}: UpcomingExpirationsTableFooterProps) {
  if (filteredByQuarterInventoryAssets.length == 0) {
    return (
      <div className="text-grey-4 text-center text-[12px] pb-[24px] px-[56px]">
        Select a timeframe with upcoming expirations
      </div>
    );
  } else {
    return (
      <div>
        <button
          className="cursor-pointer text-indigo text-[12px] px-[20px] pb-[12px]"
          onClick={() => handleMoreClick()}>
          More Details
        </button>
      </div>
    );
  }
}
