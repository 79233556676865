import { InventoryAsset } from '../../../types';
import { topSuppliers } from '../../../utils/helpers';

export interface TopSuppliersTableProps {
  inventoryAssets: InventoryAsset[];
  handleSupplierClick: (name: string) => void;
}

export function TopSuppliersTable({ inventoryAssets, handleSupplierClick }: TopSuppliersTableProps) {
  const tableOverflowClass = inventoryAssets.length > 5 ? 'max-h-[230px] overflow-y-scroll' : '';
  return (
    <>
      <div className={`py-3 px-5 col-span-2 border-t ${tableOverflowClass}`}>
        <div
          className="border-b py-2"
          role="row">
          <div className="text-[12px] leading-4 flex text-grey-6 p-1">
            <div className="flex-1 text-left">Supplier</div>
            <div className="flex-none text-grey-6">Percent of Services</div>
          </div>
        </div>
        {topSuppliers(inventoryAssets).map((supplier) => (
          <div
            className="border-b py-2"
            key={supplier.name}
            role="row">
            <div className="text-[12px] leading-4 flex text-indigo p-1">
              <div className="flex-1 text-left">
                <button
                  className="cursor-pointer"
                  onClick={() => handleSupplierClick(supplier.name)}>
                  {supplier.name}
                </button>
              </div>
              <div className="flex-none text-grey-8">{supplier.percent == 0 ? '< 1' : supplier.percent}%</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
