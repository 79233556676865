import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Text, { TextSpan } from '../../Atoms/Text';
import { useEffect, useRef, useState } from 'react';
import { Account } from '../../../types';
import { AccountSelectionForm } from '../UserCreation';
import { getAccountScope, getUser } from '../../../store/User';
import { ProgressBar } from '../../Atoms';
import { VisuallyHiddenInput } from './BulkInventoryForm';
import { BulkUploadRequest, bulkUploadRequestApi } from '../../../Api/OutsideInventory/outsideInventoryApi';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { useNavigate } from 'react-router-dom';

export const BulkInventoryRequestForm = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const { accounts } = getUser();
  const [progress, setProgress] = useState(1);
  const [fileSize, setFileSize] = useState(0);
  const [fileName, setFileName] = useState('');
  const [incrementValue, setIncrementValue] = useState(0);
  const [startProgressIncrement, setStartProgressIncrement] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(
    getAccountScope()?.length === 1 ? getAccountScope()?.[0] : undefined
  );
  const [message, setMessage] = useState('');
  const { setSnack } = useSnackbar();
  const [files, setFiles] = useState<File[]>([]);
  const navigate = useNavigate();

  const progressHandler = (progressEvent: { loaded: number; total: number }) => {
    const { loaded, total } = progressEvent;
    const percentage = Math.round(((loaded / total) * 100) / 3);
    setProgress(percentage);
    setStartProgressIncrement(true);
  };

  const sumbitRequest = async () => {
    if (selectedAccount && files.length > 0) {
      setUploading(true);
      setFileSize(files[0].size);
      const req: BulkUploadRequest = {
        file: files[0],
        progressHandler,
        accountId: selectedAccount.id,
        accountName: selectedAccount.name,
        message
      };
      const { data, error } = await bulkUploadRequestApi(req);
      setUploading(false);
      if (data) {
        navigate('/inventory');
        setSnack({ message: 'Request sent', type: 'success', open: true });
      }
      if (error) setSnack({ message: error.message, type: 'error', open: true });
    } else {
      setSnack({ message: 'No account set', type: 'error', open: true });
    }
  };

  const fileChange = (files: File[]) => {
    setFiles(files);
    setFileName(files[0].name);
  };

  useEffect(() => {
    if (startProgressIncrement && incrementValue === 0) {
      const sizeInMb = fileSize / 10000;
      const increment = 66 / sizeInMb;
      setIncrementValue(Math.round(increment));
    }

    if (startProgressIncrement && incrementValue > 0 && progress < 100) {
      setTimeout(() => setProgress(progress + incrementValue > 100 ? 100 : progress + incrementValue), 500);
    }

    if (startProgressIncrement && incrementValue > 0 && progress >= 100) {
      setStartProgressIncrement(false);
      setIncrementValue(0);
    }
  }, [startProgressIncrement, progress, incrementValue]);

  return (
    <div data-testid="bulk-inventory-request-form-container">
      <div className="px-4 pb-4">
        <TextSpan>
          To add inventory in bulk, send us a spreadsheet with your details. After review, we’ll reach out to you with
          any questions and an estimated timeline.
        </TextSpan>
      </div>
      <div className="px-4 pb-8">
        <AccountSelectionForm
          accounts={accounts}
          disabled={accounts?.length === 1}
          setSelectedAccount={(v) => setSelectedAccount(v)}
          selectedAccount={selectedAccount || null}
          styles="flex items-center w-52"
          showLabel={true}
          inputStyles="h-[1.75rem]"
        />
      </div>
      <div className="flex items-center space-x-4 px-4">
        <Button
          className="flex items-center w-52"
          component="label"
          size="small"
          color="secondary"
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}>
          Select .xlsx file
          <VisuallyHiddenInput
            ref={fileInputRef}
            onChange={(e) => fileChange(Array.from(e.target.files as FileList))}
            type="file"
            accept=".xlsx"
          />
        </Button>
        <Text
          weight="medium"
          className="pl-1"
          size="sm14">
          {fileName}
        </Text>
      </div>
      <div className="px-4 pb-4">
        <textarea
          className="m-auto rounded-md py-2 px-4 text-[14px] focus:shadow-outline-blue my-3 border border-slate-300 w-1/2"
          rows={10}
          placeholder="Write a message (optional)"
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className="flex justify-end mt-4">
        <button
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          onClick={() => sumbitRequest()}
          disabled={!selectedAccount || files.length == 0}>
          Submit
        </button>
      </div>

      <div className="px-4">
        {uploading && (
          <div className="w-full flex justify-between items-center align-top">
            <div className="w-[97%]">
              <ProgressBar
                progress={progress}
                fullWidth={true}
                progressBarStyleOverride="h-full bg-indigo transition rounded"
                className="flex align-middle rounded"
              />
            </div>
            <TextSpan
              size="sm14"
              color="grey6">
              {progress}%
            </TextSpan>
          </div>
        )}
      </div>
    </div>
  );
};
