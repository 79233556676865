import { useCallback } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import { Attachment, S3Attachment } from '../../../types';
import Text from '../../Atoms/Text';
import { AttachmentRow } from './AttachmentRow';

export interface AttachmentQueueProps {
  queuedAttachments: Attachment[] | S3Attachment[];
  cancelUpload: (attachmentId: string) => void;
  retryUpload: (attachmentId: string) => void;
}

export function AttachmentQueue({ queuedAttachments, cancelUpload, retryUpload }: AttachmentQueueProps) {
  const isWorking = queuedAttachments.some((a) => a.status === 'uploading' || a.status === 'queued');

  useBeforeUnload(
    useCallback(
      (event) => {
        if (isWorking) {
          event.preventDefault();
          event.returnValue = '';
        }
      },
      [isWorking]
    )
  );

  return (
    <div className="flex flex-col w-full">
      {queuedAttachments.map((queuedAttachment) => (
        <AttachmentRow
          key={`Queued Attachment ${queuedAttachment.id}`}
          attachment={queuedAttachment}
          cancelUpload={cancelUpload}
          retryUpload={retryUpload}
        />
      ))}
      {queuedAttachments.some((queuedAttachment) => queuedAttachment.status === 'error') && (
        <Text
          className="ml-8"
          color="grey5"
          size="sm">
          If you continue to have trouble please contact support@upstack.com.
        </Text>
      )}
    </div>
  );
}
