import { useState } from 'react';
import { TextField } from '@mui/material';
import { WidgetProps } from '@rjsf/utils';
import { INPUT_STYLE } from '../../../../../utils/styleHelpers';
import { DetailRow } from '../../../DetailsDrawer/DetailRow';

export const TextInput = (props: WidgetProps) => {
  const { value: formValue, onChange } = props;
  const [dirty, setDirty] = useState(false);
  const [value, setValue] = useState<string>(formValue || '');

  const handleChange = (val: string) => {
    if (!dirty) setDirty(true);
    setValue(val);
    onChange(val);
  };

  const getHelperText = () => {
    if (isErrorState()) return `${props.label} required`;
  };

  const isErrorState = () => {
    return dirty && ((props.rawErrors && props.rawErrors.length > 0) || (value === null && props.required));
  };

  if (props.readonly) {
    if (props.formContext?.hideEmpty && !formValue) return null;

    return (
      <div>
        <DetailRow
          key={props.id}
          colId={props.id}
          title={props.uiSchema?.['ui:placeholder'] || ''}
          value={formValue || ''}
        />
      </div>
    );
  }

  return (
    <TextField
      fullWidth
      value={value}
      required={props.required}
      error={isErrorState()}
      helperText={getHelperText()}
      onChange={(e) => handleChange(e.target.value)}
      key={props.uiSchema?.['ui:placeholder']}
      label={props.uiSchema?.['ui:placeholder']}
      InputLabelProps={{ style: { backgroundColor: 'white', paddingRight: '.5rem' } }}
      InputProps={{ style: { fontSize: '.875rem' }, name: props.uiSchema?.['ui:placeholder'] }}
      className={`${INPUT_STYLE} ${props.uiSchema?.classNames}`}
    />
  );
};
