import { useEffect, useState } from 'react';
import { PageCard } from '../../Compounds/CardWithTitle';
import { PageLayout } from '../../PageLayout';
import { clearFormCreateStateSubject, getFormCreateStateSubject } from '../../../store/BulkForm';
import { BasicDataGrid } from '../../Compounds/Table/BasicDataGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { v4 } from 'uuid';
import { DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { adminUrlForRole, getUser, isTeamManager } from '../../../store/User';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../Atoms/Icon';
import { getAccountName } from '../../../store/Accounts';
import { TeamResponse, useTeamsStore } from '../../../store/Team';
import { PostTeamRequest } from '../../../Api/Admin/Team/teamApi';
import { MemberActions } from '../Admin/Tabs/MemberDropdown';

export interface BaseDisplayData {
  account: string | undefined;
  name: string | undefined;
  button?: boolean;
}

export interface DisplayData {
  successData: BaseDisplayData[];
  failureData: BaseDisplayData[];
}

export const TeamCreationSummary = () => {
  const { createRequest } = useTeamsStore();
  const [displayData, setDisplayData] = useState<DisplayData>({ successData: [], failureData: [] });
  const user = getUser();
  const navigate = useNavigate();

  useEffect(() => {
    const teamCreateStateSub = getFormCreateStateSubject().subscribe((state) => {
      const failures = state.filter((item) => item.status === 'failed');
      const success = state.filter((item) => item.status === 'success');

      const successData = success.map((r) => {
        const data = r.data as TeamResponse;
        return {
          id: data?.id,
          account: getAccountName(data?.account_id || ''),
          name: data?.name
        };
      });

      const failureData = failures.map((r) => {
        const req = r.requestBody as PostTeamRequest;
        return {
          id: v4(),
          name: req?.name,
          account: getAccountName(req?.account_id || ''),
          error: r.error,
          button: true,
          requestBody: r.requestBody
        };
      });

      setDisplayData({ successData, failureData });
    });

    return () => {
      if (teamCreateStateSub) teamCreateStateSub.unsubscribe();
      clearFormCreateStateSubject();
    };
  }, []);

  const successCols: GridColDef[] = [
    { headerName: 'Account', field: 'account', width: 150 },
    { headerName: 'Team Name', field: 'name', width: 150 },
    {
      headerName: '',
      field: 'button',
      width: 200,
      renderCell: (params) => MemberActions({ teamId: params?.row?.id?.toString() })
    }
  ];

  const failureCols: GridColDef[] = [
    { headerName: 'Account', field: 'account', width: 150 },
    { headerName: 'Team Name', field: 'name', width: 150 },
    {
      headerName: '',
      field: 'button',
      renderCell: (params) => (
        <button
          className="flex items-center"
          onClick={() => createRequest(params?.row?.requestBody)}>
          <Icon
            className="mr-2"
            type="retry"></Icon>
          Retry
        </button>
      )
    }
  ];

  const pageActions = () => (
    <button
      onClick={() => navigate(adminUrlForRole())}
      className={DEFAULT_ADVANCE_BUTTON_STYLE}
      data-cy="back-to-table-btn">
      Back To {`${isTeamManager(user) ? 'Team Settings' : 'Admin'}`}
    </button>
  );

  return (
    <PageLayout
      width="sm:w-3/4 lg:w-1/2 2xl:w-1/3"
      pageTitle="New Teams Created"
      pageActions={pageActions()}
      actionLocation="bottom"
      dataCy="new-teams-summary-page">
      <hr className="mt-4 h-0.5 border-t-2" />
      <PageCard
        childrenWrapperClass="flex justify-center"
        title={`${displayData.successData.length} teams(s) created successfully`}
        titleIcon="greenCheckmark">
        <BasicDataGrid
          rows={displayData.successData}
          columns={successCols}
          dataCy="team-summary-success-tbl"
        />
      </PageCard>

      {!!displayData.failureData.length && (
        <PageCard
          childrenWrapperClass="flex justify-center"
          title={`${displayData.failureData.length} teams(s) were not created`}
          titleIcon="alert">
          <BasicDataGrid
            rows={displayData.failureData}
            columns={failureCols}
          />
        </PageCard>
      )}
      <hr className="mt-4 h-0.5 border-t-2" />
    </PageLayout>
  );
};
