import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { PageLayout } from '../../PageLayout';
import MySettingsTab from './MySettingsTab';
import CompanySettingsTab from './CompanySettingsTab';
import { Permission } from '../../../store/GeneralStore';
import { hasPermissions } from '../../../store/User';

export const UserSettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <PageLayout
      pageTitle="Settings"
      width="sm:w-3/4 lg:w-1/2 2xl:w-1/3">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}>
        <Tab label="My Settings" />
        {hasPermissions([Permission.UPDATE_SHARED_ACCOUNT_CUSTOMIZATION]) && <Tab label="Company Settings" />}
      </Tabs>
      <hr className="mt-4 h-0.5 border-t-2" />
      {selectedTab === 0 && <MySettingsTab />}
      {hasPermissions([Permission.UPDATE_SHARED_ACCOUNT_CUSTOMIZATION]) && selectedTab === 1 && <CompanySettingsTab />}
    </PageLayout>
  );
};
