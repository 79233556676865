import { v4 } from 'uuid';
import { BehaviorSubject } from 'rxjs';
import { PortalUserData, User } from '../types';
import { getTeam } from './Team';
import { FormStateStatus } from './BulkForm';
import { AddUserToTeamPayload } from '../Api/Admin/PortalUsers/portalUsersApi';

export interface AddPortalUsersToTeamFormProps {
  portal_users: User[];
  account_id: string;
  account_name: string;
  team_id: string;
  team_name: string;
  dirty: boolean;
  valid: boolean;
  id: string;
  type: 'member' | 'manager';
}

export interface FormSubmitState {
  status: FormStateStatus;
  error?: string;
  data?: Partial<PortalUserData>;
  requestBody: AddUserToTeamPayload | PortalUserData;
}

export const defaultForm = (type: 'member' | 'manager' = 'member'): AddPortalUsersToTeamFormProps => {
  const formId = v4();
  return {
    id: formId,
    type,
    portal_users: [],
    account_id: '',
    account_name: '',
    team_id: '',
    team_name: '',
    dirty: false,
    valid: false
  };
};

const formValidSubject = new BehaviorSubject<boolean>(false);
const formSubject = new BehaviorSubject<AddPortalUsersToTeamFormProps>(defaultForm());
const formCreateStateSubject = new BehaviorSubject<FormSubmitState[]>([]);

// getters
export const getFormSubject = (): BehaviorSubject<AddPortalUsersToTeamFormProps> => formSubject;
export const getFormSubmitStateSubject = (): BehaviorSubject<FormSubmitState[]> => formCreateStateSubject;
export const getFormValidSubject = (): BehaviorSubject<boolean> => formValidSubject;

// setters
export const clearFormSubmitStateSubject = () => formCreateStateSubject.next([]);
export const updateFormsValidity = (value: boolean) => formValidSubject.next(value);
export const initAddUsersToTeamForm = (teamId: string, type: 'member' | 'manager' = 'member') => {
  const newForm = defaultForm(type);
  const team = getTeam(teamId);

  if (team) {
    newForm.team_id = team.id;
    newForm.team_name = team.name;
    newForm.account_id = team.account_id || '';
    newForm.account_name = team.account_name || '';
  }

  formSubject.next(newForm);
};

export const updateForm = (data: Partial<AddPortalUsersToTeamFormProps>) => {
  const update = {
    ...formSubject.getValue(),
    ...data,
    dirty: true
  };

  update.valid = update.portal_users.length > 0;

  formSubject.next(update);
  formValidSubject.next(update.valid);
};

export const clearForm = () => {
  formValidSubject.next(false);
  formSubject.next(defaultForm());
};
