import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { validateTokenApi } from '../../../Api/User/validateTokenApi';

export const AuthenticatePage = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const validateToken = async (token: string) => {
    const { data, headers, error } = await validateTokenApi(token);
    if (data) {
      localStorage.setItem('token', token);
      const actionCableCredentials = {
        client: headers.get('client'),
        accessToken: headers.get('access-token'),
        uid: data?.uid
      };
      localStorage.setItem('action-cable-credentials', JSON.stringify(actionCableCredentials));
      navigate('/');
      navigate(0);
    }
    if (error) navigate('/login');
  };

  useEffect(() => {
    const token = params.get('token') || '';
    if (token && token === localStorage.getItem('token')) {
      navigate('/');
    } else {
      validateToken(token);
    }
  }, []);

  return (
    <div className="text-center">
      <img
        src="/UPSTACK_logo_hrz-1.5_color.svg"
        className="m-auto h-[80px] mt-24"
        alt="UPSTACK Logo"
      />
    </div>
  );
};
