import React, { useState, useEffect } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { Supplier } from '../../../../../types';
import { TextField, Autocomplete } from '@mui/material';
import { getSupplierSubject, useSuppliersStore } from '../../../../../store/Supplier';
import { INPUT_STYLE } from '../../../../../utils/styleHelpers';
import { DetailRow } from '../../../DetailsDrawer/DetailRow';

export const SupplierSelectionInput = (props: WidgetProps) => {
  const { value: formValue, onChange } = props;
  const [searchQuery, setSearchQuery] = useState(formValue || '');
  const [dirty, setDirty] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(props.value || undefined);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const { fetchSuppliers } = useSuppliersStore();

  useEffect(() => {
    const subscription = getSupplierSubject().subscribe((suppliers) => {
      setSuppliers(suppliers);
    });

    fetchSuppliers();

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, []);

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: Supplier | string | null) => {
    if (!dirty) setDirty(true);
    if (typeof value !== 'string') {
      setSelectedSupplier(value);
      setSearchQuery(value ? value.name : '');
      onChange(value ? value.id : undefined);
    }
  };

  const handleInputChange = (e: React.SyntheticEvent<Element, Event>, value: Supplier | string | null) => {
    if (!dirty) setDirty(true);
    if (typeof value === 'string') {
      const foundSupplier = suppliers.find((supplier) => supplier.name === value);
      onChange(foundSupplier ? foundSupplier.id : value || undefined);
      setSearchQuery(value);
    }
  };

  const getHelperText = () => {
    if (isErrorState()) {
      if (props.rawErrors?.includes('must be string')) return 'Invalid format for Supplier';
      return 'Supplier is required';
    }
  };

  const getValue = () => {
    if (props.formContext?.update && !dirty) {
      const matchedSupplier = getSupplierFromFormValue();
      if (matchedSupplier) {
        setSelectedSupplier(matchedSupplier);
        setDirty(true);
      }
    }

    return selectedSupplier || searchQuery;
  };

  const isErrorState = () => {
    return dirty && ((props.rawErrors && props.rawErrors.length > 0) || (formValue === undefined && props.required));
  };

  const getSupplierFromFormValue = () => {
    return suppliers.find((supplier) => supplier.id === formValue);
  };

  if (props.readonly) {
    if (props.formContext?.hideEmpty && !formValue) return null;

    return (
      <div>
        <DetailRow
          key={props.id}
          colId={props.id}
          title={props.uiSchema?.['ui:placeholder'] || ''}
          value={getSupplierFromFormValue()?.name || formValue}
        />
      </div>
    );
  }

  return (
    <Autocomplete
      value={getValue()}
      inputValue={searchQuery}
      onInputChange={handleInputChange}
      ListboxProps={{ style: { maxHeight: 150 } }}
      onChange={handleChange}
      onBlur={() => setDirty(true)}
      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
      options={suppliers}
      getOptionLabel={(option: Supplier | string) => {
        if (typeof option === 'string') {
          return option;
        } else {
          return option.name || '';
        }
      }}
      freeSolo={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.uiSchema?.['ui:placeholder']}
          error={isErrorState()}
          helperText={getHelperText()}
          required={props.required}
          InputProps={{ ...params.InputProps, style: { fontSize: '.875rem' } }}
          className={INPUT_STYLE}
        />
      )}
      renderOption={(props, option) => (
        <li
          {...props}
          key={typeof option === 'string' ? option : option.id}
          className="text-sm14 py-1 pl-4 cursor-pointer hover:bg-grey-1">
          {typeof option === 'string' ? '' : option?.name}
        </li>
      )}
    />
  );
};

export default SupplierSelectionInput;
