import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { useSnackbar } from '../Context/SnackbarContext';
import { archiveCommentApi, createCommentApi, getCommentsApi, updateCommentApi } from '../Api/Comments/commentApi';
import { AxiosError } from 'axios';

export type Comment = {
  id: string;
  entity_id: string;
  entity_type: string;
  entity_name?: string;
  parent_comment_id?: number;
  comment_text: string;
  is_archived?: boolean;
  archived_by?: number;
  portal_user_id?: number;
  portal_user_name?: string;
  portal_user_email?: string;
  text_changed_at?: string;
  created_at?: string;
  updated_at?: string;
};

const commentsSubject = new BehaviorSubject<Comment[]>([]);
const commentsLoadingSubject = new BehaviorSubject<boolean>(false);

export const getCommentSubject = (): BehaviorSubject<Comment[]> => commentsSubject;
export const getCommentLoadingSubject = (): BehaviorSubject<boolean> => commentsLoadingSubject;

export interface UseCommentsStoreReturn {
  fetchComments: (entityId: string, entityType: string) => Promise<void>;
  createComment: (comment: Comment) => Promise<void>;
  updateComment: (comment: Comment) => Promise<void>;
  archiveComment: (comment: Comment) => Promise<void>;
  setComments: (comments: Comment[]) => void;
}

type ErrorType = null | { message: string; errorResponse?: AxiosError } | { message: string };
export const useCommentsStore = (): UseCommentsStoreReturn => {
  const { setSnack } = useSnackbar();

  const fetchComments = async (entityId: string, entityType: string): Promise<void> => {
    commentsLoadingSubject.next(true);

    const { data, error } = await getCommentsApi(entityId, entityType);

    if (data) commentsSubject.next(data);
    if (error) addError('comment', error.message);

    commentsLoadingSubject.next(false);
  };

  const createComment = async (comment: Comment) => {
    const { data, error } = await createCommentApi(comment);
    if (data) {
      const currentState = [...getCommentSubject().getValue()];
      setComments([...currentState, data]);
      setSnack({ message: 'Comment saved successfully', type: 'success', open: true });
    }

    if (error) {
      setSnack({ message: 'Error saving comment', type: 'error', open: true });
    }
  };

  const refreshComment = (data: Comment | null, success_msg: string, error: ErrorType, error_msg: string) => {
    if (data) {
      const currentState = [...getCommentSubject().getValue()];
      const updatedComments = currentState.map((com: Comment) => (com.id === data.id ? data : com));
      setComments(updatedComments);
      setSnack({ message: success_msg, type: 'success', open: true });
    }

    if (error) {
      setSnack({ message: error_msg, type: 'error', open: true });
    }
  };

  const updateComment = async (comment: Comment) => {
    const { data, error } = await updateCommentApi(comment);

    const success_msg = 'Comment updated successfully';
    const error_msg = 'Error updating comment';
    refreshComment(data, success_msg, error, error_msg);
  };

  const archiveComment = async (comment: Comment) => {
    const { data, error } = await archiveCommentApi(comment.id);

    const success_msg = 'Comment deleted successfully';
    const error_msg = 'Error deleting comment';
    refreshComment(data, success_msg, error, error_msg);
  };

  const setComments = (comments: Comment[]) => commentsSubject.next(comments);

  return {
    fetchComments,
    createComment,
    updateComment,
    archiveComment,
    setComments
  };
};
