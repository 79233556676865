import { TextSpan } from '../../Atoms/Text';
import { Icon, IconType } from '../../Atoms/Icon';

export interface CardButtonProps {
  onClick: () => void;
  icon?: IconType;
  text?: string;
  className?: string;
  title?: string;
  name?: string;
  role?: string;
  disabled?: boolean;
}

export const CardButton = ({ onClick, icon, text, className = '', disabled = false, ...props }: CardButtonProps) => (
  <button
    {...props}
    disabled={disabled}
    onClick={onClick}
    className={`${className} flex items-center space-x-1`}>
    {icon && (
      <Icon
        type={icon}
        className="p-3"
      />
    )}
    {text && (
      <TextSpan
        className="text-center mr-2"
        color="slate"
        dataTestId={props.title}
        size="xs">
        {text}
      </TextSpan>
    )}
  </button>
);
