import { NavBarTabs } from './NavBarTabs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getSelectedAccountsSubject, getUserSubject } from '../../../store/User';
import { Account, User } from '../../../types';
import { HIDE_NAV_PAGES } from '../../../utils/helpers';

export const NavBar = () => {
  const location = useLocation();
  const [showNav, setShowNav] = useState<boolean>(false);
  const name = location.pathname.replace('/', '');
  const [user, setUser] = useState<User>();
  const [selectedAccounts, setSelectedAccounts] = useState<Account[] | null>();

  useEffect(() => {
    const userSub = getUserSubject().subscribe((user) => setUser(user));
    const acctSub = getSelectedAccountsSubject().subscribe((accts) => {
      setSelectedAccounts(accts);
    });

    return () => {
      if (userSub) userSub.unsubscribe();
      if (acctSub) acctSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setShowNav(!HIDE_NAV_PAGES.includes(name));
  }, [name, user, selectedAccounts?.length]);

  if (!showNav) return <div></div>;

  return (
    <nav className="flex py-4 px-4 border-gray-200 border-b">
      <NavBarTabs />
    </nav>
  );
};
