import { Outlet, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import {
  HomePage,
  OrdersPage,
  DetailsPage,
  SupportTicketsPage,
  MyTeamPage,
  InventoryPage,
  StyleGuide,
  LoginPageDevise,
  AdminPage
} from './Pages';
import { useEffect, useState } from 'react';
import { MaintenancePage } from './Pages/Maintenance';
import { AuthenticatePage } from './Pages/AuthenticatePage/AuthenticatePage';
import { getSessionSubject, Session } from '../Api/useSession';
import { FeatureFlags, defaultFeatureFlags, getFeatureFlagSubject } from '../Api/useFeatureFlags';
import { ResetPasswordPage } from './Pages/ResetPassword/ResetPasswordPage';
import { RestrictedRoute } from './Compounds/RestrictedRoute/RestrictedRoute';
import { Permission } from '../store/GeneralStore';
import { BulkUserCreationPage } from './Pages/UserCreationPage/BulkUserCreationPage';
import { UserCreationSummaryPage } from './Pages/UserCreationPage/UserCreationSummaryPage';
import { BulkTeamCreationPage } from './Pages/TeamCreation/BulkTeamCreationPage';
import { TeamCreationSummary } from './Pages/TeamCreation/TeamCreationSummary';
import { AddUserToTeamPage } from './Pages/AddUserToTeamPage/AddUserToTeamPage';
import { AddTeamMemberSummary } from './Pages/AddTeamMemberSummary/AddTeamMemberSummary';
import { UserSettingsPage } from './Pages/UserSettings/UserSettingsPage';
import { CommissionsPage } from './Pages/Commissions/CommissionsPage';
import { AddInventoryPage, AddInventorySummary } from './Pages/AddInventory';
import { getSelectedAccountsSubject } from '../store/User';

export const ProtectedRoute = () => {
  const [session, setSession] = useState<Session | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();

  // Subscribe to user data and error states.
  useEffect(() => {
    const dataSubscription = getSessionSubject().subscribe((data) => {
      setSession(data);
    });

    return () => {
      if (dataSubscription) dataSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const userAccountsSub = getSelectedAccountsSubject().subscribe((accounts) => {
      if (!accounts?.length && ['/orders', '/support-ticketing', '/inventory'].includes(location.pathname)) {
        navigate('/');
      }
    });

    return () => {
      if (userAccountsSub) userAccountsSub?.unsubscribe();
    };
  }, [location.pathname]);

  if (!session?.authenticated) navigate('/login');

  return <Outlet />;
};

export default function AppRoutes() {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);

  useEffect(() => {
    const flags = getFeatureFlagSubject().subscribe((flags) => setFeatureFlags(flags));

    return () => {
      if (flags) flags?.unsubscribe();
    };
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={<LoginPageDevise />}
      />
      <Route
        path={'/portal/offline'}
        element={<MaintenancePage />}
      />
      <Route
        path={'/reset'}
        element={<ResetPasswordPage />}
      />
      <Route
        path={'/authenticate-user'}
        element={<AuthenticatePage />}
      />
      <Route element={<ProtectedRoute />}>
        <Route
          path={'/orders'}
          element={<OrdersPage />}
        />
        <Route
          path="/support-ticketing"
          element={
            <RestrictedRoute requiredPermissions={[[Permission.VIEW_TICKETS]]}>
              <SupportTicketsPage />
            </RestrictedRoute>
          }
        />
        {featureFlags.outside_inventory && (
          <Route
            path={'/add-inventory'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.ADD_INVENTORY_FLOW]]}>
                <AddInventoryPage />
              </RestrictedRoute>
            }
          />
        )}
        {featureFlags.outside_inventory && (
          <Route
            path={'/add-inventory/summary'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADVISOR, Permission.ADD_INVENTORY_FLOW]]}>
                <AddInventorySummary />
              </RestrictedRoute>
            }
          />
        )}
        <Route
          path={'/inventory'}
          element={<InventoryPage />}
        />
        {featureFlags.commissions && (
          <Route
            path={'/commissions'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.VIEW_COMMISSION_SYNC]]}>
                <CommissionsPage />
              </RestrictedRoute>
            }
          />
        )}
        <Route
          path={'/:type/:id'}
          element={<DetailsPage />}
        />
        <Route
          path={'/my-team'}
          element={<MyTeamPage />}
        />
        <Route
          path={'/'}
          element={<HomePage />}
        />
        <Route
          path={'/*'}
          element={<HomePage />}
        />
        <Route
          path={'/user-settings'}
          element={<UserSettingsPage />}
        />
        <Route
          path={'/account/:accountId/:type/:id'}
          element={<DetailsPage />}
        />
        <Route
          path="/account/:accountId/orders"
          element={
            <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADVISOR, Permission.VIEW_EXECUTIVE]]}>
              <OrdersPage />
            </RestrictedRoute>
          }
        />
        <Route
          path="/account/:accountId/support-ticketing"
          element={
            <RestrictedRoute
              requiredPermissions={[[Permission.VIEW_ADVISOR, Permission.VIEW_EXECUTIVE], [Permission.VIEW_TICKETS]]}>
              <SupportTicketsPage />
            </RestrictedRoute>
          }
        />
        {featureFlags.outside_inventory && (
          <Route
            path={'/account/:accountId/add-inventory'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADVISOR, Permission.ADD_INVENTORY_FLOW]]}>
                <AddInventoryPage />
              </RestrictedRoute>
            }
          />
        )}
        {featureFlags.outside_inventory && (
          <Route
            path={'/account/:accountId/add-inventory/summary'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADVISOR, Permission.ADD_INVENTORY_FLOW]]}>
                <AddInventorySummary />
              </RestrictedRoute>
            }
          />
        )}
        <Route
          path="/account/:accountId/inventory"
          element={
            <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADVISOR, Permission.VIEW_EXECUTIVE]]}>
              <InventoryPage />
            </RestrictedRoute>
          }
        />
        <Route
          path={'/account/:accountId/:type/:id'}
          element={
            <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADVISOR, Permission.VIEW_EXECUTIVE]]}>
              <DetailsPage />
            </RestrictedRoute>
          }
        />
        <Route
          path={'/account/:accountId/my-team'}
          element={
            <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADVISOR, Permission.VIEW_EXECUTIVE]]}>
              <MyTeamPage />
            </RestrictedRoute>
          }
        />
        <Route
          path={'/account/:accountId/'}
          element={
            <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADVISOR, Permission.VIEW_EXECUTIVE]]}>
              <HomePage />
            </RestrictedRoute>
          }
        />
        <>
          <Route
            path={'/admin'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADMIN, Permission.VIEW_SUPPORT]]}>
                <AdminPage />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/admin/new-user'}
            element={
              <RestrictedRoute
                requiredPermissions={[[Permission.VIEW_ADMIN, Permission.VIEW_SUPPORT], [Permission.CREATE_USERS]]}>
                <BulkUserCreationPage />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/admin/new-team'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.VIEW_ADMIN, Permission.VIEW_SUPPORT]]}>
                <BulkTeamCreationPage />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/admin/new-user/summary'}
            element={
              <RestrictedRoute
                requiredPermissions={[[Permission.VIEW_ADMIN, Permission.VIEW_SUPPORT], [Permission.CREATE_USERS]]}>
                <UserCreationSummaryPage />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/admin/new-team/summary'}
            element={
              <RestrictedRoute
                requiredPermissions={[[Permission.VIEW_ADMIN, Permission.VIEW_SUPPORT], [Permission.CREATE_USERS]]}>
                <TeamCreationSummary />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/admin/teams/add-users'}
            element={
              <RestrictedRoute
                requiredPermissions={[[Permission.VIEW_ADMIN, Permission.VIEW_SUPPORT], [Permission.CREATE_USERS]]}>
                <AddUserToTeamPage />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/admin/teams/add-users/summary'}
            element={
              <RestrictedRoute
                requiredPermissions={[[Permission.VIEW_ADMIN, Permission.VIEW_SUPPORT], [Permission.CREATE_USERS]]}>
                <AddTeamMemberSummary />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/admin/teams/add-managers'}
            element={
              <RestrictedRoute
                requiredPermissions={[[Permission.VIEW_ADMIN, Permission.VIEW_SUPPORT], [Permission.CREATE_USERS]]}>
                <AddUserToTeamPage />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/admin/teams/add-managers/summary'}
            element={
              <RestrictedRoute
                requiredPermissions={[[Permission.VIEW_ADMIN, Permission.VIEW_SUPPORT], [Permission.CREATE_USERS]]}>
                <AddTeamMemberSummary />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/team-settings'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.VIEW_TEAM]]}>
                <AdminPage />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/team-settings/new-user'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.VIEW_TEAM], [Permission.CREATE_TEAM_USERS]]}>
                <BulkUserCreationPage />
              </RestrictedRoute>
            }
          />
          <Route
            path={'/team-settings/new-user/summary'}
            element={
              <RestrictedRoute requiredPermissions={[[Permission.VIEW_TEAM], [Permission.CREATE_TEAM_USERS]]}>
                <UserCreationSummaryPage />
              </RestrictedRoute>
            }
          />
        </>
        {process.env.NODE_ENV === 'development' && (
          <Route
            path="/style-guide"
            element={<StyleGuide />}
          />
        )}
      </Route>
    </Routes>
  );
}
