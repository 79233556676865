/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

import { Notification, updateNotification } from '../../../store/Notifications';
import { Icon } from '../../Atoms/Icon';
import { TextBlock, Text } from '../../Atoms/Text';
import { DetailViewCard } from '../../Compounds/CardWithTitle/DetailViewCard';

export interface InstallNotificationsProps {
  filteredNotifications: Notification[];
  fetchMore: () => void;
}

export function InstallNotifications({ filteredNotifications, fetchMore }: InstallNotificationsProps) {
  const [showMore, setShowMore] = useState<{ [key: string]: boolean }>({});
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!listRef.current) return;

      const { scrollTop, clientHeight, scrollHeight } = listRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 30) fetchMore();
    };

    listRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      listRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMessageClick = (notification: Notification) => {
    updateNotification({
      ...notification,
      is_read: true
    });
  };

  const getMessage = (notification: Notification) => {
    const msg = notification.notification_message.content.after_val || '';
    return showMore[notification.id] ? msg : `${msg?.substring(0, 100)}...`;
  };

  return (
    <DetailViewCard
      enableCollapse
      key="inventory-notifications"
      title="Notifications">
      <ul
        className="max-h-96 overflow-auto"
        ref={listRef}>
        {filteredNotifications.map((notification) => {
          return (
            <li
              key={notification.id}
              className="px-4 py-4 mb-2">
              <div
                onClick={() => handleMessageClick(notification)}
                className="flex hover:cursor-pointer">
                <div className="self-start mt-1.5">
                  <Icon
                    size="large"
                    type={!notification.is_read ? 'notificationsDot' : 'notificationsReadDot'}
                  />
                </div>
                <div className="ml-6 border-b-2 w-full">
                  <div className="flex self-start">
                    <TextBlock
                      size="sm14"
                      weight="bold">
                      Install Update
                    </TextBlock>
                    {notification.created_at && (
                      <TextBlock
                        className="ml-auto"
                        size="sm"
                        color="grey4">
                        {format(parseISO(notification.created_at), 'PPP')}
                      </TextBlock>
                    )}
                  </div>
                  <pre className="font-sans text-sm whitespace-pre-line">{getMessage(notification)}</pre>
                  <button
                    onClick={() => setShowMore({ ...showMore, [notification.id]: !showMore[notification.id] })}
                    className="my-4">
                    <Text
                      size="sm"
                      color="indigo">
                      {showMore[notification.id] ? 'Show Less' : 'Show More'}
                    </Text>
                  </button>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </DetailViewCard>
  );
}
