import { Account, PortalUserData, UserRoles } from '../../../types';
import { callExternalApi } from '../../callExternalApi';

export type PostPortalUserRequest = {
  email: string;
  first_name: string;
  last_name: string;
  roles_attributes: UserRoles[];
  account_id?: string | null;
  team_id?: string | null;
  accounts?: Account[];
};

export async function postPortalUser(requestBody: PostPortalUserRequest) {
  const url = 'api/admin/portal_users';
  return await callExternalApi<PortalUserData>({
    url,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: requestBody
  });
}
