import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { allOperators, standardOperators } from '../../Compounds/UpstackDataGrid/helpers';

export const DefaultCommissionGridColumnDefs: GridSingleSelectColDef[] = [
  {
    field: 'created_at',
    headerName: 'Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'PPpp') : '';
    },
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: allOperators()
  },
  {
    field: 'email',
    headerName: 'Requestor',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators()
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    filterable: true,
    flex: 1,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators()
  }
];
