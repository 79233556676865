import { ReactElement, useEffect, useState } from 'react';
import { ProgressBar } from '../../Atoms/ProgressBar';
import { TextSpan } from '../../Atoms/Text';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';

export interface WizardConfiguration {
  step: number;
  childComponent: number;
  headerCopy: string;
  progressBarCopy: string;
  nextStep: number | null;
  nextBtnCopy?: string;
  nextBtnForm?: string;
  nextBtnClickHandler?: (() => void) | null;
  submitForm?: (() => Promise<void>) | null;
  formSubmissionComplete?: boolean;
  blockPrevStep?: boolean;
  prevStep: number | null;
  prevBtnCopy?: string;
  showExitBtn?: boolean;
  exitBtnCopy?: string;
  canMoveNextStep: boolean;
  canMovePrevStep: boolean;
  handleExitWizard?: () => void;
}

export interface WizardProps {
  configurations: Array<WizardConfiguration>;
  children: ReactElement[];
  useFullProgress?: boolean;
  style?: string;
  dataLoading?: boolean;
}

const progressBarOverrides = 'rounded h-full bg-indigo transition';

export const Wizard = ({ children, configurations, useFullProgress = false, style = '', dataLoading }: WizardProps) => {
  const [config, setConfig] = useState(configurations[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [currStep, setCurrStep] = useState(config.step);

  const setNextStep = () => {
    if (config.nextBtnClickHandler) config.nextBtnClickHandler();

    if (config.nextBtnForm && !config.formSubmissionComplete) {
      return setIsLoading(true);
    }

    if (config.nextStep) {
      return setCurrStep(config.nextStep);
    }

    // No next step (implicitly the last step) and exit handler present, leave the wizard
    exitWizard();
  };

  const setPrevStep = () => {
    if (config.prevStep) {
      setCurrStep(config.prevStep);
    }
  };

  const exitWizard = () => {
    if (config.handleExitWizard) config.handleExitWizard();
  };

  const handleSetConfig = () => {
    const newConfig = configurations.find((c) => c.step === currStep);

    if (newConfig) {
      setConfig(newConfig);
    }
  };

  useEffect(() => {
    handleSetConfig();
  }, [currStep]);

  useEffect(() => {
    handleSetConfig();
  }, [configurations]);

  useEffect(() => {
    const handleFormSubmission = async (submitForm: () => Promise<void>) => {
      setSubmittingForm(true);
      await submitForm();
    };
    if (config.submitForm && !submittingForm) handleFormSubmission(config.submitForm);
    if (!config.submitForm) {
      setIsLoading(false);
      setSubmittingForm(false);
    }
  }, [config.submitForm]);

  useEffect(() => {
    if (config.formSubmissionComplete) {
      setIsLoading(false);
      setSubmittingForm(false);
      setNextStep();
    }
  }, [config.formSubmissionComplete]);

  return (
    <>
      <div className="flex">
        <TextSpan className="flex text-xl my-2 self-start w-[20rem]">{config.headerCopy}</TextSpan>
        {dataLoading && <ComponentSpinner />}
      </div>
      <WizardProgressBar
        configurations={configurations}
        currStep={currStep}
        useFullProgress={useFullProgress}
      />
      <div className={isLoading ? 'hidden' : 'flex flex-col max-h-full popup-body'}>
        {children[config.childComponent]}
      </div>
      <div className={isLoading ? 'hidden' : 'mt-auto'}>
        <hr className="mt-5 mb-5" />
        <WizardButtonContainer
          config={config}
          exitWizard={exitWizard}
          setNextStep={setNextStep}
          setPrevStep={setPrevStep}
        />
      </div>
      <div className={isLoading ? '' : 'hidden'}>
        <ComponentSpinner />
      </div>
    </>
  );
};

interface WizardProgressBarProps {
  configurations: WizardConfiguration[];
  currStep: number;
  useFullProgress: boolean;
}

const WizardProgressBar = ({ configurations, currStep, useFullProgress }: WizardProgressBarProps) => {
  const getProgress = (): number => {
    // progress bar only fills to 90% if useFullProgress == false
    const maxFill = useFullProgress ? 100 : 90;
    return (maxFill / configurations.length) * currStep;
  };

  const getProgressBarTitles = () => {
    return configurations.map((c) => {
      return (
        <TextSpan
          color={c.step === currStep ? 'black' : 'grey5'}
          key={`wizard-title-${c.step}`}
          weight={c.step === currStep ? 'bold' : 'normal'}>
          {c.progressBarCopy}
        </TextSpan>
      );
    });
  };

  return (
    <div className="my-2">
      <ProgressBar
        progress={getProgress()}
        className="w-full rounded"
        progressBarStyleOverride={progressBarOverrides}
      />
      <div className="flex justify-between my-2">{getProgressBarTitles()}</div>
    </div>
  );
};

interface WizardButtonContainerProps {
  config: WizardConfiguration;
  setNextStep: () => void;
  setPrevStep: () => void;
  exitWizard: () => void;
}

const WizardButtonContainer = ({ config, exitWizard, setNextStep, setPrevStep }: WizardButtonContainerProps) => {
  return (
    <div className="flex justify-between">
      <div>
        {config.showExitBtn && (
          <button
            className={DEFAULT_CANCEL_BUTTON_STYLE}
            data-cy="exit-btn"
            onClick={exitWizard}>
            {config.exitBtnCopy || 'Exit'}
          </button>
        )}
      </div>
      <div className="flex space-x-4">
        {config.prevStep && !config.blockPrevStep && (
          <button
            className={DEFAULT_CANCEL_BUTTON_STYLE}
            data-cy="cancel-back-btn"
            onClick={setPrevStep}
            disabled={!config.canMovePrevStep}>
            {config.prevBtnCopy || 'Back'}
          </button>
        )}
        <button
          data-testid="wizard-button"
          form={config.nextBtnForm}
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          data-cy="next-continue-btn"
          onClick={setNextStep}
          disabled={!config.canMoveNextStep}>
          {config.nextBtnCopy || 'Continue'}
        </button>
      </div>
    </div>
  );
};
