import { callExternalApi } from '../callExternalApi';

export const deleteAttachment = (attachmentId: string) => {
  return callExternalApi<void>({
    url: `api/files/${attachmentId}`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json'
    }
  });
};
