import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { GridColumnVisibilityModel, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { fetchUserInformation, fetchUserPreferencesApi } from '../Api/User/fetchUserInformation';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { setDataDogUser } from '../datadog';
import { datadogLogs } from '@datadog/browser-logs';
import { updateUsersApi } from '../Api/User/updateUsersApi';
import {
  Account,
  AuthenticatedAdmin,
  AuthenticatedAdvisor,
  AuthenticatedClient,
  AuthenticatedUser,
  User,
  WidgetId
} from '../types';
import { Session, getSessionSubject, useSession } from '../Api/useSession';
import { Permission } from './GeneralStore';
import { useSnackbar } from '../Context/SnackbarContext';
import { addError } from './Error';
import { updateSettingsApi } from '../Api/User/updateUserSettingsApi';
import { HomeWidgetDefs } from '../components/Pages/HomePage/HomeWidgetDefs';
import { TicketsWidgetDefs } from '../components/Pages/SupportTickets/TicketsWidgetDefs';
import { InventoryWidgetDefs } from '../components/Pages/Inventory/InventoryWidgetDefs';
import { DefaultInventoryGridColumnDefs } from '../components/Pages/Inventory/inventoryGridColumnDefs';
import { DefaultOrderGridColumnDefs } from '../components/Pages/Orders/OrderGridColumnDefs';
import { DefaultTicketGridColumnDefs } from '../components/Pages/SupportTickets/SupportTicketGridColDefs';
import { getColumnsByProduct } from '../components/Compounds/UpstackDataGrid/inventoryUtils';
import { initializePendo } from '../pendo';
import { createSettingsApi } from '../Api/User/CreateUserSettingsApi';
import { SKIP_ACCOUNT_ROLES, userInSkipRoles } from './Role';
import { useSearchParams } from 'react-router-dom';
import { getTabs } from '../utils/helpers';
import { updateTableStateApi } from '../Api/User/dataGridStateApi';

export interface Preferences {
  id: string;
  index: number;
  enabled: boolean;
  value?: string;
}

export interface GridPreferences {
  muiConfig: GridInitialStatePro;
  disabledFields: string[];
  customView?: boolean;
  id?: string;
}

export interface WidgetPreferences {
  widgetId: WidgetId;
  headerName: string;
  index: number;
  hide: boolean;
  disabled: boolean;
}

export interface Widgets {
  tickets: WidgetPreferences[];
  home: WidgetPreferences[];
  inventory: WidgetPreferences[];
}

type InventoryGridKeys = {
  [key in `inventory_grid_${string}`]?: GridPreferences;
};

export interface UserPreferencesContent extends InventoryGridKeys {
  notifications: Preferences[];
  historic: Preferences[];
  order_grid: GridPreferences;
  user_grid: GridPreferences;
  team_grid: GridPreferences;
  account_grid: GridPreferences;
  ticket_grid: GridPreferences;
  commission_grid: GridPreferences;
  inventory_grid_All: GridPreferences;
  widgets: Widgets;
  selectedAccounts: Account[];
  comments?: boolean;
}

export interface UserPreferences {
  content: UserPreferencesContent;
  id?: number;
  portal_user_id?: number;
}

export interface UseSharedUserReturn {
  clear: () => void;
  accountScope: Account[] | undefined;
  fetchUser: () => Promise<void>;
  fetchUserPreferences: () => Promise<void>;
  updateUser: () => Promise<void>;
}
export const defaultUserData: User = {
  email: '',
  role: undefined,
  account_name: '',
  accounts: [],
  id: '',
  account_id: '',
  created_at: '',
  updated_at: '',
  current_sign_in_at: 'last login date',
  impersonated: false,
  authenticated: false,
  roles: [],
  permissions: [],
  first_name: '',
  last_name: ''
};

export const gridInitialState = (cols?: GridSingleSelectColDef[]) => {
  const visibility: GridColumnVisibilityModel = {};
  if (cols) cols.forEach(({ field }: GridSingleSelectColDef) => (visibility[field] = true));

  return {
    pinnedColumns: {},
    columns: {
      columnVisibilityModel: visibility,
      orderedFields: cols ? cols.map(({ field }) => field) : [],
      dimensions: {}
    },
    preferencePanel: {
      open: false
    },
    filter: {
      filterModel: {
        items: [],
        logicOperator: 'and',
        quickFilterValues: [],
        quickFilterLogicOperator: 'and'
      }
    },
    sorting: {
      sortModel: []
    },
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 50
      }
    }
  };
};

const _defaultUserPreferences: UserPreferences = {
  content: {
    notifications: [
      {
        id: 'install_updates_email',
        index: 0,
        enabled: true
      },
      {
        id: 'support_tickets_email',
        index: 1,
        enabled: true
      }
    ],
    historic: [
      {
        id: 'disconnects',
        index: 0,
        value: '12',
        enabled: true
      },
      {
        id: 'cancellations',
        index: 1,
        value: 'None',
        enabled: true
      }
    ],
    order_grid: {
      muiConfig: gridInitialState(DefaultOrderGridColumnDefs) as GridInitialStatePro,
      disabledFields: []
    },
    user_grid: {
      muiConfig: gridInitialState() as GridInitialStatePro,
      disabledFields: []
    },
    team_grid: {
      muiConfig: gridInitialState() as GridInitialStatePro,
      disabledFields: []
    },
    account_grid: {
      muiConfig: gridInitialState() as GridInitialStatePro,
      disabledFields: []
    },
    ticket_grid: {
      muiConfig: gridInitialState(DefaultTicketGridColumnDefs) as GridInitialStatePro,
      disabledFields: []
    },
    commission_grid: {
      muiConfig: gridInitialState() as GridInitialStatePro,
      disabledFields: []
    },
    inventory_grid_All: {
      muiConfig: gridInitialState(DefaultInventoryGridColumnDefs) as GridInitialStatePro,
      disabledFields: []
    },
    widgets: {
      home: [...HomeWidgetDefs],
      tickets: [...TicketsWidgetDefs],
      inventory: [...InventoryWidgetDefs]
    },
    selectedAccounts: []
  }
};

export const getDefaultPreferences = (): UserPreferences => JSON.parse(JSON.stringify(_defaultUserPreferences));

export type WidgetKey = keyof typeof _defaultUserPreferences.content.widgets;
export type PrefKey = keyof UserPreferencesContent;

export enum UserRole {
  CLIENT = 'CLIENT',
  ADVISOR = 'ADVISOR',
  ADMIN = 'ADMIN',
  UNSUPPORTED = 'UNSUPPORTED'
}

const userSubject = new BehaviorSubject<User>(defaultUserData);
const userLoadingSubject = new BehaviorSubject<boolean>(false);
const userSavingSubject = new BehaviorSubject<boolean>(false);
const userPreferencesSubject = new BehaviorSubject<UserPreferences>(getDefaultPreferences());
const customViewSubject = new BehaviorSubject<string[]>([]);
const selectedAccountsSubject = new BehaviorSubject<Account[] | null>([]);
const emitErrorState = (message?: string): void => addError('user', message);

export const getUserSubject = (): BehaviorSubject<User> => userSubject;
export const getUserLoadingSubject = (): BehaviorSubject<boolean> => userLoadingSubject;
export const getUserSavingSubject = (): BehaviorSubject<boolean> => userSavingSubject;
export const getUserPreferencesSubject = (): BehaviorSubject<UserPreferences> => userPreferencesSubject;
export const getSelectedAccountsSubject = (): BehaviorSubject<Account[] | null> => selectedAccountsSubject;
export const getCustomViewSubject = (): BehaviorSubject<string[]> => customViewSubject;

export const getUser = (): User => userSubject.value;

export const shouldSeeComments = () => {
  return (
    getUser()?.roles?.some(({ name }) => name && SKIP_ACCOUNT_ROLES.includes(name)) ||
    getUserPreferencesSubject().getValue().content.comments
  );
};

export const getPreferencesByKey = (
  param: PrefKey
): Preferences[] | GridPreferences | Widgets | undefined | boolean | Account[] => {
  const settings = userPreferencesSubject.getValue();
  const prefs = settings.content[param];

  if (!prefs && param.includes('inventory_grid')) {
    const newGridPrefs = {
      muiConfig: gridInitialState(getColumnsByProduct(param.replace('inventory_grid_', ''))) as GridInitialStatePro,
      disabledFields: [...settings.content['inventory_grid_All'].disabledFields]
    };
    const updatedSettings = {
      ...settings,
      content: {
        ...settings.content,
        [param]: newGridPrefs
      }
    };
    updateUserSettings(updatedSettings, 'Inventory Grid');
    return newGridPrefs;
  }

  return prefs;
};
export const getCurrentPreferences = (): UserPreferences => userPreferencesSubject.getValue();
export const setPreferences = (prefs: UserPreferences) => userPreferencesSubject.next(prefs);
export const isTeamManager = (user?: User): boolean => !!user?.roles?.some((role) => role.name == 'Team Manager');
export const isAdmin = (user?: User): boolean => !!user?.roles?.some((role) => role.name == 'Admin');
export const isSupport = (user?: User): boolean => !!user?.roles?.some((role) => role.name == 'Support');
export const isAdvisor = (user?: User): user is AuthenticatedAdvisor =>
  (user?.permissions?.map((permission) => permission?.name || permission) || []).includes(Permission.VIEW_ADVISOR);
export const isExecutive = (user?: User): user is AuthenticatedAdmin =>
  (user?.permissions?.map((permission) => permission?.name || permission) || []).includes(Permission.VIEW_EXECUTIVE);
export const hasPermissions = (permissions: string[]): boolean => {
  const userPermissions = getUser()?.permissions?.map((permission) => permission.name) || [];
  return permissions.every((permission) => userPermissions.includes(permission));
};
export const isClient = (user?: User): user is AuthenticatedClient => user?.role === UserRole.CLIENT;
export const isOnlyCommissionsRole = (user?: User): boolean =>
  user?.roles?.length === 1 && !!user?.roles?.every((role) => role.name === 'Commissions');

export const isVisibleWidgetByPermissions = (widgetId: WidgetId, user?: User) => {
  const userPermissions = (user?.permissions || []).map((permission) => permission?.name || permission);
  switch (widgetId) {
    case 'tickets':
      return [Permission.VIEW_TICKETS].filter((p) => !userPermissions.includes(p)).length == 0;
    case 'upcomingExpirations':
      return !['Sephora USA', 'STEVEN MADDEN LTD'].includes(user?.account_name || ''); // TODO: temporary changes
    default:
      return true;
  }
};

export const columnsDefault = (config: GridInitialStatePro) => {
  const defaultCols = getDefaultPreferences().content?.inventory_grid_All?.muiConfig?.columns;
  return JSON.stringify(config?.columns) === JSON.stringify(defaultCols);
};

export const widgetsDefault = (w: Widgets) => {
  const defaultWidgets = getDefaultPreferences().content.widgets;
  return JSON.stringify(w) === JSON.stringify(defaultWidgets);
};

export const updateUserSettings = async (preferences: UserPreferences, type?: string) => {
  const { error, data } = await updateSettingsApi(preferences, type);
  if (error) emitErrorState(error.message);
  if (data) {
    const customViewNames = getTabs(data.content, 'inventory_grid_', true);
    customViewSubject.next(customViewNames);
    userPreferencesSubject.next(data);
  }
  return { data, error };
};

export const updateTableState = async (preferences: UserPreferences, type?: string) => {
  const { error, data } = await updateTableStateApi(preferences, type);
  if (error) emitErrorState(error.message);
  if (data) userPreferencesSubject.next(data);
  return { data, error };
};

export const updateUserData = async (first_name?: string, last_name?: string) => {
  const user = getUser();
  const { data, error } = await updateUsersApi(
    { first_name: first_name || user.first_name, last_name: last_name || user.last_name },
    user.id
  );
  if (data) {
    userSubject.next({
      ...userSubject.value,
      first_name: data.first_name,
      last_name: data.last_name
    } as AuthenticatedUser);
    emitErrorState();
  }
  if (error) {
    emitErrorState(error?.message);
  }
  return { data, error };
};

export const createUserSettings = async (preferences: UserPreferences) => {
  const { error, data } = await createSettingsApi(preferences);
  if (error) emitErrorState(error.message);
  if (data) userPreferencesSubject.next(data);
  return { data, error };
};

export const adminUrlForRole = (): string => (isTeamManager(getUser()) ? '/team-settings' : '/admin');

export const updateAccountContext = async (accounts: Account[]) => {
  selectedAccountsSubject.next(accounts);
  const currentPreferences = getCurrentPreferences();
  if (currentPreferences.id) {
    currentPreferences.content.selectedAccounts = accounts;
    await updateUserSettings(currentPreferences);
  }
};

export const setAccountByUrl = async (accountId: string) => {
  if (!getSelectedAccountIds()?.includes(accountId)) {
    const acct = getUser()?.accounts.find((acc) => acc.id === accountId);
    if (acct) {
      if (userInSkipRoles(getUser().roles.map((r) => r.name))) {
        await updateAccountContext([acct]);
      } else {
        await updateAccountContext([...(getSelectedAccountsSubject().getValue() || []), acct]);
      }
    }
  }
};

export const getAccountScope = (): Account[] | undefined => {
  return getSelectedAccountsSubject().getValue() || undefined;
};

export const getSelectedAccountIds = () => getAccountScope()?.map((acc) => acc.id);

export const getAccounts = (): Account[] | undefined => {
  const { accounts } = getUser();
  return accounts;
};

export const getPrimaryAccount = (): Account[] | undefined => {
  const user = getUser();
  return user.account_id && user.account_name ? [{ id: user?.account_id, name: user?.account_name }] : undefined;
};

export const clearUserData = () => userSubject.next(defaultUserData);

export const useSharedUser = (): UseSharedUserReturn => {
  const [session, setSession] = useState<Session | undefined>(undefined);
  const { clearSession } = useSession();
  const { setSnack } = useSnackbar();
  const [params, setParams] = useSearchParams();

  useEffect(() => {
    const sessionSub = getSessionSubject().subscribe((session) => setSession(session));

    return () => {
      if (sessionSub) sessionSub.unsubscribe();
    };
  }, []);

  const fetchUserPreferences = async () => {
    userLoadingSubject.next(true);

    const { data, error } = await fetchUserPreferencesApi();
    if (data) {
      const mergedPreferences = {
        ...getDefaultPreferences(),
        id: data.id,
        portal_user_id: data.portal_user_id,
        content: {
          ...getDefaultPreferences().content,
          ...data.content,
          widgets: {
            ...getDefaultPreferences().content.widgets,
            ...data.content.widgets
          }
        }
      };
      const customViewNames = getTabs(mergedPreferences.content, 'inventory_grid_', true);
      customViewSubject.next(customViewNames);
      userPreferencesSubject.next(mergedPreferences);
    }
    if (error) emitErrorState(error?.message);

    userLoadingSubject.next(false);
  };

  const fetchUser = async () => {
    userLoadingSubject.next(true);
    const { data, error } = await fetchUserInformation();
    if (data) {
      const role = UserRole[data?.role?.toUpperCase() as UserRole] || UserRole.UNSUPPORTED;
      setDataDogUser({ ...data, role } as User);
      if (process.env.NODE_ENV !== 'test') initializePendo({ ...data, role } as User);
      userSubject.next({ ...data, role, authenticated: true } as AuthenticatedUser);
      emitErrorState();
      await fetchUserPreferences();
      await setUserAccounts();
    }

    if (error) {
      datadogLogs.logger.error('Error when fetching user: ', error);
      const errorMsg = error?.message?.includes('401')
        ? 'Session expired, please login again.'
        : 'Something went wrong on our end. Please login again.';

      setSnack({
        type: 'error',
        message: errorMsg,
        open: true,
        duration: 5000
      });

      emitErrorState(error?.message);
      await clearSession();
    }

    userLoadingSubject.next(false);
  };

  const setUserAccounts = async () => {
    const user = userSubject.getValue();
    const prefs = userPreferencesSubject.getValue();
    let defaultAccounts = prefs?.content.selectedAccounts;
    if (user.accounts.length === 1 && defaultAccounts?.length === 0) defaultAccounts = user.accounts;

    const accountId = params.get('accountId');
    if (defaultAccounts && !accountId) {
      selectedAccountsSubject.next(defaultAccounts);
    } else if (accountId) {
      await setAccountByUrl(accountId);
      params.delete('accountId');
      setParams(params, { replace: true });
    }
  };

  const updateUser = async () => {
    const user = getUser();
    const { data, error } = await updateUsersApi({ tos_read: true }, user.id);
    if (data) {
      userSubject.next({ ...userSubject.value, tos_read: true } as AuthenticatedUser);
      emitErrorState();
    }
    if (error) {
      emitErrorState(error?.message);
    }
  };

  useEffect(() => {
    if (session?.authenticated) fetchUser();
  }, [session?.authenticated]);

  const clear = () => userSubject.next(defaultUserData);

  return { clear, accountScope: getAccountScope(), fetchUser, fetchUserPreferences, updateUser };
};
