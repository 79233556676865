import { useEffect, useState } from 'react';
import { SrOnlyText, TextBlock, TextSpan } from '../../Atoms/Text';
import { Icon } from '../../Atoms/Icon';
import { capitalizeText } from '../../../utils/helpers';
import { Customizations } from '../../../store/AccountCustomization';
import { GridPreferences, WidgetPreferences, Widgets, getDefaultPreferences } from '../../../store/User';
import { DefaultInventoryGridColumnDefs } from '../../Pages/Inventory/inventoryGridColumnDefs';

type ColumnTypes = 'column' | 'widget';

export interface DisableCustomizationsFormProps {
  type: ColumnTypes;
  header: string;
  subheader: string;
  gridPrefs?: GridPreferences;
  widgets?: Widgets;
  saveHandler: (customizations: Customizations) => void;
  className?: string;
}

const getRowItemStyle = (hidden: boolean) => {
  if (hidden) return 'hidden';
  return 'p-2 border-2 rounded my-4 flex flex-row justify-between items-center min-h-[40px] w-full shadow-md';
};

export const DisableCustomizationsForm = ({
  className,
  gridPrefs,
  widgets,
  header,
  subheader,
  type,
  saveHandler
}: DisableCustomizationsFormProps) => {
  const defaultPrefs = getDefaultPreferences();
  const [columnCustomizations, setColumnCustomizations] = useState<GridPreferences>(
    defaultPrefs.content.inventory_grid_All
  );
  const [widgetCustomizations, setWidgetCustomizations] = useState<Widgets>(defaultPrefs.content.widgets);

  useEffect(() => {
    if (gridPrefs) {
      setColumnCustomizations({ ...gridPrefs });
    }

    if (widgets) {
      setWidgetCustomizations({ ...widgets });
    }
  }, [gridPrefs, widgets]);

  const handleClick = (id: string) => {
    if (gridPrefs) {
      const updatedCustomizations = { ...columnCustomizations };
      if (updatedCustomizations.disabledFields.includes(id)) {
        updatedCustomizations.disabledFields = updatedCustomizations.disabledFields.filter((field) => field !== id);
      } else {
        updatedCustomizations.disabledFields = [...updatedCustomizations.disabledFields, id];
      }
      saveHandler(updatedCustomizations);
    }

    if (widgets) {
      const updatedCustomizations = { ...widgetCustomizations };
      Object.keys(updatedCustomizations).forEach((widget) => {
        const matchingCol = findMatchingCol(id, updatedCustomizations[widget as keyof Widgets]);
        if (matchingCol) matchingCol.disabled = !matchingCol.disabled;
      });
      saveHandler(updatedCustomizations);
    }
  };

  const findMatchingCol = (id: string, customizations: WidgetPreferences[]) => {
    return customizations.find((col) => col.widgetId === id);
  };

  return (
    <div className={className}>
      <div className="flex flex-col items-center">
        <div className="py-4 border-b-2 w-full">
          <TextBlock
            color="black"
            size="xxl">
            {header}
          </TextBlock>
        </div>
        <div className="py-6 w-full">
          <TextBlock
            color="black"
            size="base">
            {subheader}
          </TextBlock>
        </div>
        <div className="pb-4 border-b-2 w-full">
          <TextBlock
            color="black"
            size="lg">
            {capitalizeText(type)}s
          </TextBlock>
        </div>
        <div className="relative pb-4 border-b-2 box-border mt-4 mb-2 max-h-[40vh] w-full overflow-auto">
          {gridPrefs && (
            <div data-cy="edit-column-list-container">
              {DefaultInventoryGridColumnDefs.map((col) => {
                return (
                  <div
                    key={col.field}
                    className={getRowItemStyle(col.field === 'details')}>
                    <TextSpan
                      className="px-2"
                      dataTestId={col.headerName}>
                      {col.headerName}
                    </TextSpan>
                    <button
                      data-testid={`${col.field}-visibility-button`}
                      data-cy={`${col.field}-visibility-button`}
                      onClick={() => handleClick(col.field)}
                      className="px-3 py-1 border border-grey-3 box-border rounded flex flex-row justify-between items-center w-30">
                      <Icon
                        type={columnCustomizations.disabledFields.includes(col.field || '') ? 'disabled' : 'enabled'}
                      />
                      <SrOnlyText>{`${columnCustomizations.disabledFields.includes(col.field || '') ? 'Enable' : 'Disable'} ${col.field} column`}</SrOnlyText>
                      <TextSpan
                        className="ml-1"
                        size="sm"
                        weight="bold">
                        {columnCustomizations.disabledFields.includes(col.field || '') ? 'Disabled' : 'Enabled'}
                      </TextSpan>
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {widgets && (
            <>
              {widgetCustomizations.home.map((cust) => {
                return (
                  <div
                    key={cust.widgetId}
                    className={getRowItemStyle(false)}>
                    <TextSpan className="px-2">{cust.headerName}</TextSpan>
                    <button
                      onClick={() => handleClick(cust.widgetId)}
                      className="px-3 py-1 border border-grey-3 box-border rounded flex flex-row justify-between items-center w-30">
                      <Icon type={cust.disabled ? 'disabled' : 'enabled'} />
                      <SrOnlyText>{`${cust.disabled ? 'Enable' : 'Disable'} ${cust.headerName} column`}</SrOnlyText>
                      <TextSpan
                        className="ml-1"
                        size="sm"
                        weight="bold">
                        {columnCustomizations.disabledFields.includes(cust.headerName) ? 'Disabled' : 'Enabled'}
                      </TextSpan>
                    </button>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
