import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { INPUT_STYLE } from '../../../utils/styleHelpers';
import { UserTeam } from '../../../types';
import { AddTeamLink } from '../TeamCreation/AddTeamLink';

export interface TeamSelectionFormProps {
  teams: UserTeam[];
  selectedTeam: UserTeam | null;
  setSelectedTeam: (team: UserTeam | null) => void;
  size?: 'small' | 'medium' | undefined;
  styles?: string;
  disabled?: boolean;
  showLabel?: boolean;
  inputStyles?: string;
  styleOverride?: object;
  selectedAccountId?: string | null;
  handleTeamCreate?: () => void;
}

export const TeamSelectionForm = ({
  teams,
  selectedTeam,
  setSelectedTeam,
  size = 'small',
  styles,
  disabled = false,
  showLabel = true,
  inputStyles = '',
  styleOverride,
  selectedAccountId,
  handleTeamCreate
}: TeamSelectionFormProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const handleChange = (e: React.SyntheticEvent<Element, Event>, value: UserTeam | null) => {
    setSelectedTeam(value);
  };

  useEffect(() => {
    setShowDropdown(true);
  }, []);

  return (
    <div className={styles || ''}>
      {showDropdown && (
        <Autocomplete
          data-testid="team-selection-input"
          data-cy="user-team-dropdown"
          value={selectedTeam || undefined}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disabled={disabled}
          disableClearable
          className="w-full"
          inputValue={searchQuery}
          onInputChange={(e, val: string) => setSearchQuery(val)}
          ListboxProps={{ style: { maxHeight: 150 } }}
          onChange={handleChange}
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          options={teams}
          getOptionLabel={(option: UserTeam) => option.name}
          size={size || 'small'}
          sx={styleOverride}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{ ...params.InputProps, style: { fontSize: '.875rem' } }}
              label={showLabel ? 'Team' : ''}
              className={inputStyles || INPUT_STYLE}
              data-cy="team-input"
              error={!!selectedAccountId && !selectedTeam}
              required={!!selectedAccountId}
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
              className="text-sm14 py-1 pl-4"
              key={`${option.id}-${option.name}`}>
              {option.name}
            </li>
          )}
        />
      )}
      {!!selectedAccountId && (
        <AddTeamLink
          accountId={selectedAccountId}
          handleTeamCreate={handleTeamCreate}
        />
      )}
    </div>
  );
};
