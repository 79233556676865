import { useState, useEffect, MutableRefObject } from 'react';
import { BehaviorSubject } from 'rxjs';
import { addError } from './Error';
import { GridApiPro } from '@mui/x-data-grid-pro';

export interface TeamMember {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  imgUrl: string;
  phone: string;
  title: string;
}

export interface MyTeam {
  owner: TeamMember;
  success_manager: TeamMember;
}

export interface AdminState {
  selectedTab: number;
}

export interface GeneralState {
  myTeam: MyTeam;
  admin: AdminState;
  gridRefs: { [page: string]: MutableRefObject<GridApiPro> };
}

export interface UseGeneralStoreReturn {
  updateState: (data: Partial<GeneralState>) => void;
  clear: () => void;
  value: GeneralState;
}

export const defaultState: GeneralState = {
  myTeam: <MyTeam>{
    owner: {},
    success_manager: {}
  },
  admin: {
    selectedTab: Number(localStorage.getItem('adminTab')) || 0
  },
  gridRefs: {}
};

export enum InventoryPermission {
  ADD_ATTACHMENTS_INVENTORY = 'Add attachments to an inventory asset',
  ADD_INVENTORY_FLOW = 'Add inventory flow',
  CREATE_OUTSIDE_INVENTORY = 'Add outside inventory',
  EDIT_OUTSIDE_INVENTORY = 'Edit outside inventory',
  DELETE_OUTSIDE_INVENTORY = 'Delete outside inventory',
  ADD_TICKETS_ATTACHMENTS = 'Add attachments to an existing ticket'
}

export enum SupportTicketPermission {
  VIEW_TICKETS = 'View support ticket page',
  VIEW_TICKET_DETAILS = 'View support ticket details',
  CREATE_TICKETS_FROM_TICKETS = 'Create support tickets from support ticketing page',
  CREATE_TICKETS_FROM_INVENTORY = 'Create support tickets from the inventory page',
  VIEW_TICKETS_ON_INVENTORY = 'View support tickets on inventory page',
  ADD_TICKETS_ATTACHMENTS = 'Add attachments to an existing ticket',
  ADD_ATTACHMENTS_INVENTORY = 'Add attachments to an inventory asset',
  EDIT_TICKETS_FROM_TICKETS = 'Edit support tickets from the support ticketing page'
}

export enum AdminPermission {
  VIEW_ADMIN = 'View/access admin icon/areas',
  CREATE_USERS = 'Create Users',
  EDIT_USERS = 'Edit Users',
  VIEW_USERS = 'View Users',
  VIEW_USERS_ACCESS_LOG = 'View user access log',
  SOFT_DELETE_USERS = 'Soft Delete Users',
  IMPERSONATION = 'Impersonation',
  ASSIGN_TEAM = 'Assign Team',
  CREATE_ACCOUNT_CUSTOMIZATION = 'Create Account Customization',
  BULK_OUTSIDE_INVENTORY = 'Validate and upload bulk outside inventory'
}

export enum AdvisorPermission {
  VIEW_ADVISOR = 'View and interact with change client functionality',
  BULK_OUTSIDE_INVENTORY = 'Validate and upload bulk outside inventory'
}

export enum SdmPermission {
  VIEW_ADVISOR = 'View and interact with change client functionality',
  BULK_OUTSIDE_INVENTORY = 'Validate and upload bulk outside inventory'
}

export enum CsmPermission {
  VIEW_ADVISOR = 'View and interact with change client functionality',
  BULK_OUTSIDE_INVENTORY = 'Validate and upload bulk outside inventory'
}

export enum SupportPermission {
  VIEW_SUPPORT = 'View/access support icon/areas',
  CREATE_USERS = 'Create Users',
  EDIT_USERS = 'Edit Users',
  VIEW_USERS = 'View Users',
  VIEW_USERS_ACCESS_LOG = 'View user access log',
  SOFT_DELETE_USERS = 'Soft Delete Users',
  IMPERSONATION = 'Impersonation',
  ASSIGN_TEAM = 'Assign Team',
  CREATE_ACCOUNT_CUSTOMIZATION = 'Create Account Customization',
  BULK_OUTSIDE_INVENTORY = 'Validate and upload bulk outside inventory',
  UPDATE_SHARED_ACCOUNT_CUSTOMIZATION = 'Update Shared Account Customization'
}

export enum ExecutivePermission {
  VIEW_EXECUTIVE = 'View/access executive icon/areas',
  BULK_OUTSIDE_INVENTORY = 'Validate and upload bulk outside inventory'
}

export enum TeamManagerPermission {
  VIEW_TEAM = 'View/access team icon/areas',
  CREATE_TEAM_USERS = 'Create Team Users',
  EDIT_TEAM_USERS = 'Edit Team Users',
  VIEW_TEAM_USERS = 'View Team Users',
  SOFT_DELETE_TEAM_USERS = 'Soft Delete Team Users',
  TEAM_IMPERSONATION = 'Team Impersonation'
}

export enum CommissionsPermission {
  CREATE_COMMISSION_SYNC = 'Create Commission Sync Job',
  VIEW_COMMISSION_SYNC = 'View Commission Sync Job'
}

export const Permission = {
  ...InventoryPermission,
  ...SupportTicketPermission,
  ...AdminPermission,
  ...AdvisorPermission,
  ...TeamManagerPermission,
  ...SupportPermission,
  ...ExecutivePermission,
  ...CommissionsPermission
};

const subject = new BehaviorSubject<GeneralState>(defaultState);

export const updateState = (update: Partial<GeneralState>) => {
  const currentState = { ...subject.getValue() };
  subject.next({ ...currentState, ...update });

  if (update?.admin && 'selectedTab' in update.admin) {
    localStorage.setItem('adminTab', String(update?.admin?.selectedTab));
  }
};

export const updateGridRef = (page: string, ref: MutableRefObject<GridApiPro>) => {
  const currentStateUpdate = { ...subject.getValue() };

  currentStateUpdate.gridRefs = {
    ...currentStateUpdate.gridRefs,
    [page]: ref
  };

  subject.next(currentStateUpdate);
};

export const getState = () => subject.getValue();

export const getGeneralStoreSubject = (): BehaviorSubject<GeneralState> => subject;

const emitErrorState = (message?: string): void => addError('general', message);

export const useGeneralStore = () => {
  const [currentState, setCurrentState] = useState(defaultState);

  useEffect(() => {
    const subscription = subject.subscribe((message: GeneralState) => {
      setCurrentState(message);
    });

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [subject]);

  const updateState = (val: Partial<GeneralState>) => {
    const update = { ...currentState, ...val };
    subject.next(update);
  };
  const setErrorMessageData = (message: string | undefined) => emitErrorState(message);

  const clear = () => subject.next(defaultState);

  return { currentState, updateState, clear, setErrorMessageData };
};
