import { useEffect, useState } from 'react';
import { InventoryAsset } from '../../../types';
import { Text, Pie, PieChart, Label } from 'recharts';
import { generateDoughnutData } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { BORDER_STYLE } from '../../../utils/styleHelpers';
import { getInventoryLoadingSubject } from '../../../store/Inventory';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { TextBlock } from '../../Atoms/Text';
import {
  UserPreferences,
  getCurrentPreferences,
  getDefaultPreferences,
  getUserPreferencesSubject,
  setPreferences
} from '../../../store/User';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { v4 } from 'uuid';

export const statusOrder = [
  'Pending Install',
  'Installed',
  'Active',
  'Pending Disconnect',
  'Disconnected',
  'Cancelled'
];

export interface StatusWidgetProps {
  inventoryAssets: InventoryAsset[];
  isHome?: boolean;
}

const CustomizedLabel = ({ cx, cy, text }: { cx: number; cy: number; text: string }) => {
  return (
    <Text
      x={cx}
      y={cy}
      fill="black"
      fontWeight="500"
      fontSize="14px"
      textAnchor="start"
      dominantBaseline="central">
      {text}
    </Text>
  );
};

const StatusText = ({
  text,
  color,
  cancelled,
  disconnected,
  onClick
}: {
  text: string;
  color: string;
  cancelled?: string;
  disconnected?: string;
  onClick: () => void;
}) => {
  const isNotAll = color !== 'none';
  const textStyle = isNotAll ? '' : 'text-indigo';

  const period = (value: string) => {
    return <div className="pl-3.5 text-left text-grey-4">{value == 'All' ? 'All Time' : `Last ${value} Months`}</div>;
  };
  return (
    <div
      aria-label={text}
      className="grey-8 text-[10px] font-medium leading-3 flex flex-col gap-x-1.5 content-start"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') onClick();
      }}
      role="button"
      tabIndex={0}>
      <div className="flex flex-row gap-x-1.5">
        {isNotAll && (
          <span
            className="inline-flex w-2 h-2 rounded-full"
            style={{ backgroundColor: color }}
          />
        )}
        <span className={textStyle}>{text}</span>
      </div>
      {text.includes('Cancelled') && period(cancelled || '')}
      {text.includes('Disconnected') && period(disconnected || '')}
    </div>
  );
};

interface CategoricalChartState {
  name: string;
}

export function StatusWidget({ inventoryAssets, isHome }: StatusWidgetProps) {
  const data = generateDoughnutData(inventoryAssets);
  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  const navigate = useNavigate();
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState<UserPreferences>(getDefaultPreferences());

  useEffect(() => {
    const settingsSub = getUserPreferencesSubject().subscribe((preferences) => setSettings(preferences));
    const loadingSub = getInventoryLoadingSubject().subscribe((loading) => setInventoryLoading(loading));

    return () => {
      if (settingsSub) settingsSub.unsubscribe();
      if (loadingSub) loadingSub.unsubscribe();
    };
  }, []);

  const filterStatus = async (statusName: string) => {
    const filter: GridFilterItem = {
      id: v4(),
      field: 'status',
      operator: 'is',
      value: statusName
    };

    const update: UserPreferences = JSON.parse(JSON.stringify(getCurrentPreferences()));
    if (update.content.inventory_grid_All.muiConfig.filter?.filterModel) {
      update.content.inventory_grid_All.muiConfig.filter.filterModel.items = [filter];
    }

    setPreferences(update);
    if (isHome) navigate('/inventory');
  };

  const chartClick = (status: string) => () => {
    filterStatus(status);
  };

  const pieChartClick = (chartState: CategoricalChartState) => {
    filterStatus(chartState.name);
  };

  const correctData = data
    .filter((item) => item.value > 0)
    .sort((a, b) => statusOrder.indexOf(a.name) - statusOrder.indexOf(b.name));
  const isCorrectData = correctData.length > 0;
  let gridStyles = isHome ? 'w-full' : 'min-w-64 h-52';
  gridStyles += isCorrectData && !inventoryLoading ? ' grid grid-cols-2 gap-1' : '';

  return (
    <div
      className={`${BORDER_STYLE} ${gridStyles} pt-3 pr-2`}
      data-cy="status-widget">
      <div className="col-span-2 text-sm font-medium tracking-[0.6px] text-grey-5 text-center leading-[15px]">
        STATUS
      </div>
      {!isCorrectData && !inventoryLoading && (
        <div className={`flex ${isHome ? 'h-32' : 'h-44'} items-center justify-center`}>
          <TextBlock size="sm14">No data available</TextBlock>
        </div>
      )}
      {inventoryLoading && (
        <div className={`flex ${isHome ? 'h-32' : 'h-44'} items-center justify-center`}>
          <ComponentSpinner />
        </div>
      )}
      {!inventoryLoading && isCorrectData && (
        <>
          <div className="grid grid-cols-1 auto-rows-min pb-6 gap-1 m-auto">
            {correctData.map((item) => (
              <StatusText
                color={item.fill}
                key={item.name}
                onClick={chartClick(item.name)}
                text={`${item.value} ${item.name}`}
                cancelled={settings?.content?.historic?.find((s) => s['id'] == 'cancellations')?.value}
                disconnected={settings?.content?.historic?.find((s) => s['id'] == 'disconnects')?.value}
              />
            ))}
            <StatusText
              color="none"
              key="all-assets"
              onClick={chartClick('')}
              text="View All Assets"
            />
          </div>
          <div className="text-center text-sm widget-container pb-4">
            <PieChart
              width={132}
              height={132}>
              <Pie
                dataKey="value"
                data={data}
                cy={60}
                innerRadius={50}
                onClick={pieChartClick}
                outerRadius={63}>
                <Label
                  content={
                    <CustomizedLabel
                      cx={58}
                      cy={55}
                      text={String(total)}
                    />
                  }
                />
                <Label
                  content={
                    <CustomizedLabel
                      cx={47}
                      cy={72}
                      text="Assets"
                    />
                  }
                />
              </Pie>
            </PieChart>
          </div>
        </>
      )}
    </div>
  );
}
