export const LoadingIconPage = () => {
  return (
    <div className="loading-page">
      <p className="saving">
        <span className="loading-dot-1">.</span>
        <span className="loading-dot-2">.</span>
        <span className="loading-dot-3">.</span>
        <span className="loading-dot-4">.</span>
        <span className="loading-dot-5">.</span>
      </p>
    </div>
  );
};

export const LoadingIconSection = () => {
  return (
    <div className="loading-section">
      <p className="saving">
        <span className="loading-dot-1">.</span>
        <span className="loading-dot-2">.</span>
        <span className="loading-dot-3">.</span>
        <span className="loading-dot-4">.</span>
        <span className="loading-dot-5">.</span>
      </p>
    </div>
  );
};
