import { useEffect, useState } from 'react';
import { TextBlock, TextSpan } from '../../Atoms/Text';
import { useSession } from '../../../Api/useSession';
import { Icon } from '../../Atoms/Icon';
import { DEFAULT_PASSWORD_VALIDATION, validatePassword } from '../../../utils/helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DEFAULT_ADVANCE_BUTTON_STYLE, RAISED_DIALOG, INPUT_STYLE } from '../../../utils/styleHelpers';

export interface PasswordFormData {
  password: string;
  password_confirmation: string;
  reset_password_token: string;
}

export function ResetPasswordModal() {
  const { resetPassword } = useSession();
  const [formData, setFormData] = useState<PasswordFormData>({
    password: '',
    password_confirmation: '',
    reset_password_token: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [strength, setStrength] = useState('');
  const [validation, setValidation] = useState(structuredClone(DEFAULT_PASSWORD_VALIDATION));
  const [search] = useSearchParams();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const res = await resetPassword(formData);

    if (res?.error) {
      let message = res.error.message;
      if (message.includes('401')) message = 'Reset token expired, please retry forgot password email submission.';
      setErrorMessage(message);
    } else navigate('/login');
  };

  const handleFormChange = (name: string, value: string) => {
    const update = { ...formData, [name]: value };
    setFormData(update);

    const { errorMsg, strengthUpdate, validationUpdate } = validatePassword(update);
    setErrorMessage(errorMsg);
    setStrength(strengthUpdate);
    setValidation(validationUpdate);
  };

  useEffect(() => {
    const resetToken = search.get('reset_password_token');
    if (resetToken) {
      setFormData({ ...formData, reset_password_token: resetToken });
    }
  }, []);

  return (
    <div className="flex justify-center h-full">
      <div className="grid place-items-center">
        <div className={RAISED_DIALOG}>
          <div className="p-1">
            <div className="w-full">
              <img
                alt="UPSTACK Logo"
                className="m-auto w-36"
                src="/UPSTACK_logo_with_text.svg"
              />
            </div>
            <div className="my-6 border-t"></div>
            <TextBlock
              className="text-center font-medium"
              size="md"
              weight="bold">
              Password Reset
            </TextBlock>
            <form className="bg-white px-1 pt-6 mb-1">
              <div>
                <label
                  className="text-gray-700 text-sm"
                  htmlFor="password">
                  New Password
                </label>
                <input
                  className={`mb-4 ${INPUT_STYLE}`}
                  id="password"
                  onChange={(e) => handleFormChange('password', e.target.value.trim())}
                  type="password"
                />
                {strength && (
                  <TextBlock
                    className="mt-2"
                    color="grey4"
                    size="sm">
                    Password strength: {strength}
                  </TextBlock>
                )}
              </div>
              <div className="my-6">
                <TextBlock size="sm">Your password must contain:</TextBlock>
                {Object.values(validation).map((row) => (
                  <div
                    className="flex"
                    key={row.text}>
                    <Icon
                      className="p-3"
                      type={row.valid ? 'greenCheckmark' : 'alert'}
                    />
                    <TextSpan
                      className="ml-2 self-center"
                      size="sm">
                      {row.text}
                    </TextSpan>
                  </div>
                ))}
              </div>
              <div className="mb-8">
                <label
                  className="text-gray-700 text-sm"
                  htmlFor="confirmPassword">
                  Confirm New Password
                </label>
                <input
                  className={`mb-3 ${INPUT_STYLE}`}
                  id="confirmPassword"
                  onChange={(e) => handleFormChange('password_confirmation', e.target.value.trim())}
                  type="password"
                />
                <TextBlock
                  className="mt-2"
                  color="red"
                  size="sm">
                  {errorMessage}
                </TextBlock>
              </div>
              <div className="mt-8">
                <button
                  className={`${DEFAULT_ADVANCE_BUTTON_STYLE} w-full`}
                  onClick={handleSubmit}
                  type="button">
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
