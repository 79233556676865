import * as React from 'react';
import { Icon, IconType } from '../../Atoms/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TextSpan, Text, TextColor, TextSize } from '../../Atoms/Text';

export interface MenuOption {
  title: string;
  fontColor?: TextColor;
  onClick: () => void;
  icon?: IconType;
  dataCy?: string;
  fontSize?: TextSize;
}

export interface MenuProps {
  buttonText?: string;
  options: MenuOption[];
  icon?: IconType;
  slotProps?: object;
  buttonTextColor?: TextColor;
  anchorOriginVertical?: number | 'bottom' | 'top';
  transformOriginVertical?: number | 'bottom' | 'top';
}

export default function DropdownMenu({
  buttonText,
  options,
  icon,
  slotProps,
  buttonTextColor,
  anchorOriginVertical,
  transformOriginVertical
}: MenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <button
        className="flex items-center space-x-2"
        id="menu-button"
        data-cy={`menu-button-${icon}`}
        aria-controls={open ? 'menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <Icon type={icon as IconType} />
        {buttonText && (
          <TextSpan
            className="text-xs"
            color={buttonTextColor || 'grey5'}>
            {buttonText}
          </TextSpan>
        )}
      </button>
      <Menu
        slotProps={slotProps}
        id="menu"
        anchorOrigin={{
          vertical: anchorOriginVertical || 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: transformOriginVertical || 'top',
          horizontal: 'left'
        }}
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'menu-button'
        }}>
        {options.map((option) => (
          <MenuItem
            key={option.title}
            onClick={option.onClick}>
            <div
              className="flex w-full grow space-x-2 overflow-hidden"
              data-cy={option.dataCy}>
              {option?.icon && <Icon type={option.icon} />}
              <Text
                size={option?.fontSize || 'sm'}
                color={(option?.fontColor as TextColor) || 'grey5'}>
                {option.title}
              </Text>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
