import { useEffect, useState } from 'react';
import { IconButton, Dialog, DialogContent } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CloseIcon from '@mui/icons-material/Close';
import { DEFAULT_CANCEL_BUTTON_STYLE, DEFAULT_ADVANCE_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { SrOnlyText, TextSpan } from '../../Atoms/Text';
import { ConfirmationModal } from '../../Pages/AddInventory/ConfirmationModal';
import { OutsideInventoryForm } from './OutsideInventoryForm';
import {
  addForm,
  clearForm,
  getOutsideinventoryFormSubject,
  getOutsideInventoryFormValidSubject,
  OutsideInventoryFormProps,
  removeForm,
  updateForm,
  submitCreateForm
} from '../../../store/OutsideInventory';
import { useNavigate, useLocation } from 'react-router-dom';
import { useOutsideInventorySchemasStore } from '../../../store/OutsideInventorySchema';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { Icon } from '../../Atoms/Icon';

export const OutsideInventoryBulkManualForm = () => {
  const [productForms, setProductForms] = useState<OutsideInventoryFormProps[]>([]);
  const [allFormsValid, setAllFormsValid] = useState(false);
  const [formsSubmitting, setFormsSubmitting] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { fetchOutsideInventorySchemas } = useOutsideInventorySchemasStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const formSub = getOutsideinventoryFormSubject().subscribe((forms) => setProductForms(forms));
    const formsValidSub = getOutsideInventoryFormValidSubject().subscribe((valid) => setAllFormsValid(valid));

    clearForm();
    fetchOutsideInventorySchemas();

    return () => {
      if (formSub) formSub.unsubscribe();
      if (formsValidSub) formsValidSub.unsubscribe();
    };
  }, []);

  const submitForms = async () => {
    setFormsSubmitting(true);
    await submitCreateForm();
    clearForm();
    setFormsSubmitting(false);
    navigate(`${location.pathname}/summary`);
  };

  const cancelForms = () => {
    clearForm();
    navigate(-1);
  };

  const renderForms = () => {
    return productForms.map((form) => (
      <div
        key={form.id}
        className="flex flex-row justify-between items-start">
        <OutsideInventoryForm
          formId={form.id}
          updateForm={updateForm}
          formData={form.formData}
          validateForm={true}
        />
        {productForms.length > 1 && (
          <IconButton
            sx={{
              margin: 'auto'
            }}
            color="warning"
            onClick={() => removeForm(form.id)}>
            <RemoveCircleIcon />
            <SrOnlyText>Remove Inventory</SrOnlyText>
          </IconButton>
        )}
      </div>
    ));
  };

  return (
    <>
      {formsSubmitting && <ComponentSpinner />}
      {!formsSubmitting && (
        <>
          <div className="px-4 border-b-2">
            {renderForms()}
            <button
              className="flex space-x-2 items-center my-2"
              onClick={() => addForm()}>
              <Icon
                type="plus"
                className="p-2"
              />
              <TextSpan
                color="indigo"
                size="sm14">
                Add Additional Inventory
              </TextSpan>
            </button>
          </div>
          <div className="flex flex-row justify-between my-4">
            <button
              className={`${DEFAULT_CANCEL_BUTTON_STYLE} mr-2`}
              onClick={cancelForms}>
              Cancel
            </button>
            <>
              <button
                className={DEFAULT_ADVANCE_BUTTON_STYLE}
                onClick={() => setConfirmationModalOpen(true)}
                disabled={!allFormsValid}>
                Continue
              </button>
              <Dialog
                open={confirmationModalOpen}
                onClose={() => setConfirmationModalOpen(false)}>
                <IconButton
                  aria-label="close"
                  onClick={() => setConfirmationModalOpen(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 4,
                    color: (theme) => theme.palette.grey[500]
                  }}>
                  <CloseIcon />
                </IconButton>
                <DialogContent>
                  <ConfirmationModal
                    close={() => setConfirmationModalOpen(false)}
                    items={productForms.length}
                    ctaAction={submitForms}
                    type="Save"
                    wrapperClassName="max-w-[30vw] min-w-[20vw]"
                  />
                </DialogContent>
              </Dialog>
            </>
          </div>
        </>
      )}
    </>
  );
};
