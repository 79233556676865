import { InventoryAsset } from '../../../types';

const mockToday = new Date();

const mockInventoryData: InventoryAsset[] = [
  {
    address: '351 E EVELYN AVE Mountain View CA',
    addressInfo: {
      address: '351 E EVELYN AVE',
      locationName: '351 E',
      city: 'Mountain View',
      state: 'CA',
      zip: '94041',
      country: 'US',
      latitude: 37.3860521,
      longitude: -122.0838511,
      number: '1'
    },
    bandwidth: '10G',
    billingFrequency: 'monthly',
    cageRackID: 'Cage/Rack ID',
    circuitIDs: 'Some Circuit ID',
    circuitType: 'MPLS',
    completionDate: '2021-08-18',
    serviceActivationDate: '2021-08-18',
    connectivityA: 'Connectivity_A__c',
    connectivityB: 'Connectivity_B__c',
    contractEndDate: new Date(mockToday.setMonth(mockToday.getMonth() + 8)).toISOString().split('T')[0],
    disconnectedDate: '2022-08-18',
    equipmentManufacturer: 'Bills manufacturer',
    equipmentType: 'Some Equipment Type',
    firstInvoiceReviewedByDate: '2022-10-01',
    id: '8025G00000kkwf7QAA',
    iPDetails: '127.0.0.1/8',
    kwRack: 130.0,
    lecCircuitID: 'LEC Circuit ID',
    lecDemarc: 'LEC_Demarc__c',
    accessProvider: 'Local_LEC__c',
    locationContact: {
      name: 'John Doe',
      phone: '(866) 330-1111',
      email: 'john.doe@example.com'
    },
    locationALecCircuitId: 'Location_A_LEC_Circuit_ID__c',
    locationALecDemarc: 'Location_A_LEC_Demarc__c',
    locationALocalLec: 'Location_A_Local_LEC__c',
    locationAText: 'Store 045 - 40 Delaware Ave. Albany NY 12210',
    locationId: '0015G00001XlffMQAR',
    locationZLecCircuitId: 'Location_Z_LEC_Circuit_ID__c',
    locationZLecDemarc: 'Location_Z_LEC_Demarc__c',
    locationZLocalLec: 'Location_Z_Local_LEC__c',
    locationZText: 'Store 049 - 400 Maryland Ave. Albany NY 12210',
    mRC: 980.51,
    nRC: 980.23,
    orderType: 'New Service',
    phoneNumbers: '(123) 456-7890',
    product: {
      id: '01t5G000005r2KrQAI',
      family: 'Network',
      name: 'Network'
    },
    providerAccountNumber: '0015G00001XlffMQAR',
    supplierOrderNumber: '0015G00001XlffMQAK',
    rackType: '42U Standard',
    status: 'Active',
    supplier: {
      id: '0015G00001tV4w3QAC',
      name: 'Comcast'
    },
    term: '12 Months',
    equipmentDetails: 'Details',
    accessSpeed: '1',
    portSpeed: '1',
    disconnectedReason: '',
    accessType: '',
    'UPSTACK Managed': true
  },
  {
    address: '361 E EVELYN AVE Mountain View CA',
    addressInfo: {
      address: '361 E EVELYN AVE',
      locationName: '351 E',
      city: 'Mountain View',
      state: 'CA',
      zip: '94041',
      country: 'US',
      latitude: 37.3860521,
      longitude: -122.0838511,
      number: '1'
    },
    bandwidth: '10G',
    billingFrequency: 'Monthly',
    cageRackID: 'Cage/Rack ID',
    circuitIDs: 'Some Circuit ID',
    circuitType: 'MPLS',
    completionDate: '2022-08-18',
    serviceActivationDate: '2021-08-18',
    contractEndDate: new Date(mockToday.setMonth(mockToday.getMonth() + 5)).toISOString().split('T')[0],
    iPDetails: '127.0.0.1/8',
    kwRack: 130.0,
    lecCircuitID: 'LEC Circuit ID',
    locationContact: {
      name: 'Jane Doe',
      phone: '444-444-4444',
      email: 'jane.doe@example.com'
    },
    locationALecCircuitId: 'Location_A_LEC_Circuit_ID__c',
    locationALecDemarc: 'Location_A_LEC_Demarc__c',
    locationALocalLec: 'Location_A_Local_LEC__c',
    locationAText: 'Store 045 - 40 Delaware Ave. Albany NY 12210',
    locationId: '0015G00001XlffMQAR',
    locationZLecCircuitId: 'Location_Z_LEC_Circuit_ID__c',
    locationZLecDemarc: 'Location_Z_LEC_Demarc__c',
    locationZLocalLec: 'Location_Z_Local_LEC__c',
    locationZText: 'Store 049 - 400 Maryland Ave. Albany NY 12210',
    connectivityA: 'Connectivity_A__c',
    connectivityB: 'Connectivity_B__c',
    equipmentManufacturer: 'Bills manufacturer',
    equipmentType: 'Some Equipment Type',
    firstInvoiceReviewedByDate: '2022-10-01',
    disconnectedDate: '2023-10-01',
    lecDemarc: 'LEC_Demarc__c',
    accessProvider: 'Local_LEC__c',
    id: '8025G00000kkwf7QAB',
    mRC: 980.51,
    nRC: 980.23,
    orderType: 'New Service',
    phoneNumbers: '111-111-1111',
    product: {
      family: 'Network',
      id: '01t5G000005r2LXQAY',
      name: 'Network'
    },
    providerAccountNumber: '0015G00001XlffMQAR',
    supplierOrderNumber: '0015G00001XlffMQAK',
    rackType: '42U Standard',
    status: 'Pending Install',
    supplier: {
      id: '0015G00001tV4w3QAC',
      name: 'Zayo'
    },
    term: '12 Months',
    equipmentDetails: 'Details',
    accessSpeed: '1',
    portSpeed: '1',
    disconnectedReason: '',
    accessType: '',
    'UPSTACK Managed': true
  },
  {
    address: '371 E EVELYN AVE Mountain View CA',
    addressInfo: {
      address: '371 E EVELYN AVE',
      locationName: '351 E',
      city: 'Mountain View',
      state: 'CA',
      zip: '94041',
      country: 'US',
      latitude: 37.3860521,
      longitude: -122.0838511,
      number: '1'
    },
    bandwidth: '10G',
    billingFrequency: 'Monthly',
    cageRackID: 'Cage/Rack ID',
    circuitIDs: 'Some Circuit ID',
    circuitType: 'MPLS',
    completionDate: '2022-08-18',
    serviceActivationDate: '2021-08-18',
    contractEndDate: new Date(mockToday.setMonth(mockToday.getMonth() + 9)).toISOString().split('T')[0],
    iPDetails: '127.0.0.1/8',
    kwRack: 130.0,
    lecCircuitID: 'LEC Circuit ID',
    locationContact: {
      name: 'Jane Doe',
      phone: '444-444-4444',
      email: 'jane.doe@example.com'
    },
    locationALecCircuitId: 'Location_A_LEC_Circuit_ID__c',
    locationALecDemarc: 'Location_A_LEC_Demarc__c',
    locationALocalLec: 'Location_A_Local_LEC__c',
    locationAText: 'Store 045 - 40 Delaware Ave. Albany NY 12210',
    locationId: '0015G00001XlffMQAR',
    locationZLecCircuitId: 'Location_Z_LEC_Circuit_ID__c',
    locationZLecDemarc: 'Location_Z_LEC_Demarc__c',
    locationZLocalLec: 'Location_Z_Local_LEC__c',
    locationZText: 'Store 049 - 400 Maryland Ave. Albany NY 12210',
    connectivityA: 'Connectivity_A__c',
    connectivityB: 'Connectivity_B__c',
    equipmentManufacturer: 'Bills manufacturer',
    equipmentType: 'Some Equipment Type',
    firstInvoiceReviewedByDate: '2022-10-01',
    disconnectedDate: '2023-10-01',
    lecDemarc: 'LEC_Demarc__c',
    accessProvider: 'Local_LEC__c',
    providerAccountNumber: '0015G00001XlffMQAT',
    supplierOrderNumber: '0015G00001XlffMQAK',
    id: '8025G00000kkwf7QAC',
    mRC: 980.51,
    nRC: 980.23,
    orderType: 'New Service',
    phoneNumbers: '111-111-1111',
    product: {
      family: 'Network',
      id: '01t5G000005r2LXQAY',
      name: 'Network'
    },
    rackType: '42U Standard',
    status: 'Cancelled',
    supplier: {
      id: '0015G00001tV4w3QAC',
      name: 'Zayo'
    },
    term: '12 Months',
    equipmentDetails: 'Details',
    accessSpeed: '1',
    portSpeed: '1',
    disconnectedReason: '',
    accessType: '',
    'UPSTACK Managed': false
  },
  {
    address: '381 E EVELYN AVE Mountain View CA',
    addressInfo: {
      address: '381 E EVELYN AVE',
      locationName: '351 E',
      city: 'Mountain View',
      state: 'CA',
      zip: '94041',
      country: 'US',
      latitude: 37.3860521,
      longitude: -122.0838511,
      number: '1'
    },
    bandwidth: '10G',
    billingFrequency: 'Monthly',
    cageRackID: 'Cage/Rack ID',
    circuitIDs: 'Some Circuit ID',
    circuitType: 'MPLS',
    completionDate: '2022-08-18',
    serviceActivationDate: '2021-08-18',
    contractEndDate: new Date(mockToday.setMonth(mockToday.getMonth() + 1)).toISOString().split('T')[0],
    iPDetails: '127.0.0.1/8',
    kwRack: 130.0,
    lecCircuitID: 'LEC Circuit ID',
    locationContact: {
      name: 'Jane Doe',
      phone: '444-444-4444',
      email: 'jane.doe@example.com'
    },
    locationALecCircuitId: 'Location_A_LEC_Circuit_ID__c',
    locationALecDemarc: 'Location_A_LEC_Demarc__c',
    locationALocalLec: 'Location_A_Local_LEC__c',
    locationAText: 'Store 045 - 40 Delaware Ave. Albany NY 12210',
    locationId: '0015G00001XlffMQAR',
    locationZLecCircuitId: 'Location_Z_LEC_Circuit_ID__c',
    locationZLecDemarc: 'Location_Z_LEC_Demarc__c',
    locationZLocalLec: 'Location_Z_Local_LEC__c',
    locationZText: 'Store 049 - 400 Maryland Ave. Albany NY 12210',
    connectivityA: 'Connectivity_A__c',
    connectivityB: 'Connectivity_B__c',
    equipmentManufacturer: 'Bills manufacturer',
    equipmentType: 'Some Equipment Type',
    firstInvoiceReviewedByDate: '2022-10-01',
    disconnectedDate: '2023-10-01',
    lecDemarc: 'LEC_Demarc__c',
    accessProvider: 'Local_LEC__c',
    id: '8025G00000kkwf7QAR',
    mRC: 980.51,
    nRC: 980.23,
    orderType: 'New Service',
    phoneNumbers: '111-111-1111',
    product: {
      family: 'Network',
      id: '01t5G000005r2LXQAY',
      name: 'Network'
    },
    providerAccountNumber: '0015G00001XlffMQAR',
    supplierOrderNumber: '0015G00001XlffMQAK',
    rackType: '42U Standard',
    status: 'Disconnected',
    supplier: {
      id: '0015G00001tV4w3QAC',
      name: 'Zayo'
    },
    term: '12 Months',
    equipmentDetails: 'Details',
    accessSpeed: '1',
    portSpeed: '1',
    disconnectedReason: '',
    accessType: '',
    'UPSTACK Managed': true
  },
  {
    address: '391 E EVELYN AVE Mountain View CA',
    addressInfo: {
      address: '391 E EVELYN AVE',
      locationName: '351 E',
      city: 'Mountain View',
      state: 'CA',
      zip: '94041',
      country: 'US',
      latitude: 37.3860521,
      longitude: -122.0838511,
      number: '1'
    },
    bandwidth: '10G',
    billingFrequency: 'Monthly',
    cageRackID: 'Cage/Rack ID',
    circuitIDs: 'Some Circuit ID',
    circuitType: 'MPLS',
    completionDate: '2022-08-18',
    serviceActivationDate: '2021-08-18',
    contractEndDate: new Date(mockToday.setMonth(mockToday.getMonth() + 4)).toISOString().split('T')[0],
    iPDetails: '127.0.0.1/8',
    kwRack: 130.0,
    lecCircuitID: 'LEC Circuit ID',
    locationContact: {
      name: 'Jane Doe',
      phone: '444-444-4444',
      email: 'jane.doe@example.com'
    },
    locationALecCircuitId: 'Location_A_LEC_Circuit_ID__c',
    locationALecDemarc: 'Location_A_LEC_Demarc__c',
    locationALocalLec: 'Location_A_Local_LEC__c',
    locationAText: 'Store 045 - 40 Delaware Ave. Albany NY 12210',
    locationId: '0015G00001XlffMQAR',
    locationZLecCircuitId: 'Location_Z_LEC_Circuit_ID__c',
    locationZLecDemarc: 'Location_Z_LEC_Demarc__c',
    locationZLocalLec: 'Location_Z_Local_LEC__c',
    locationZText: 'Store 049 - 400 Maryland Ave. Albany NY 12210',
    connectivityA: 'Connectivity_A__c',
    connectivityB: 'Connectivity_B__c',
    equipmentManufacturer: 'Bills manufacturer',
    equipmentType: 'Some Equipment Type',
    firstInvoiceReviewedByDate: '2022-10-01',
    disconnectedDate: '2023-10-01',
    lecDemarc: 'LEC_Demarc__c',
    accessProvider: 'Local_LEC__c',
    id: '8025G00000kkwf7QAT',
    mRC: 980.51,
    nRC: 980.23,
    orderType: 'New Service',
    phoneNumbers: '111-111-1111',
    product: {
      family: 'Network',
      id: '01t5G000005r2LXQAY',
      name: 'Network'
    },
    providerAccountNumber: '0015G00001XlffMQAR',
    supplierOrderNumber: '0015G00001XlffMQAK',
    rackType: '42U Standard',
    status: '',
    supplier: {
      id: '0015G00001tV4w3QAC',
      name: 'Zayo'
    },
    term: '12 Months',
    equipmentDetails: 'Details',
    accessSpeed: '1',
    portSpeed: '1',
    disconnectedReason: '',
    accessType: '',
    'UPSTACK Managed': false
  },
  {
    address: '341 E EVELYN AVE Mountain View CA',
    addressInfo: {
      address: '341 E EVELYN AVE',
      locationName: '351 E',
      city: 'Mountain View',
      state: 'CA',
      zip: '94041',
      country: 'US',
      latitude: 37.3860521,
      longitude: -122.0838511,
      number: '1'
    },
    bandwidth: '10G',
    billingFrequency: 'Monthly',
    cageRackID: 'Cage/Rack ID',
    circuitIDs: 'Some Circuit ID',
    circuitType: 'MPLS',
    completionDate: '2022-08-18',
    serviceActivationDate: '2021-08-18',
    contractEndDate: new Date(mockToday.setMonth(mockToday.getMonth() + 11)).toISOString().split('T')[0],
    iPDetails: '127.0.0.1/8',
    kwRack: 130.0,
    lecCircuitID: 'LEC Circuit ID',
    locationContact: {
      name: 'Jane Doe',
      phone: '444-444-4444',
      email: 'jane.doe@example.com'
    },
    mRC: 980.51,
    nRC: 980.23,
    locationALecCircuitId: 'Location_A_LEC_Circuit_ID__c',
    locationALecDemarc: 'Location_A_LEC_Demarc__c',
    locationALocalLec: 'Location_A_Local_LEC__c',
    locationAText: 'Store 045 - 40 Delaware Ave. Albany NY 12210',
    locationId: '0015G00001XlffMQAR',
    locationZLecCircuitId: 'Location_Z_LEC_Circuit_ID__c',
    locationZLecDemarc: 'Location_Z_LEC_Demarc__c',
    locationZLocalLec: 'Location_Z_Local_LEC__c',
    locationZText: 'Store 049 - 400 Maryland Ave. Albany NY 12210',
    connectivityA: 'Connectivity_A__c',
    connectivityB: 'Connectivity_B__c',
    equipmentManufacturer: 'Bills manufacturer',
    equipmentType: 'Some Equipment Type',
    firstInvoiceReviewedByDate: '2022-10-01',
    disconnectedDate: '2023-10-01',
    lecDemarc: 'LEC_Demarc__c',
    accessProvider: 'Local_LEC__c',
    providerAccountNumber: '0015G00001XlffMQAP',
    supplierOrderNumber: '0015G00001XlffMQAK',
    id: '8025G00000kkwf7QBT',
    orderType: 'New Service',
    phoneNumbers: '111-111-1111',
    product: {
      family: 'Network',
      id: '01t5G000005r2LXQAY',
      name: 'Network'
    },
    rackType: '42U Standard',
    status: 'Disconnected',
    supplier: {
      id: '0015G00001tV4w3QAC',
      name: 'Zayo'
    },
    term: '12 Months',
    equipmentDetails: 'Details',
    accessSpeed: '1',
    portSpeed: '1',
    disconnectedReason: '',
    accessType: '',
    'UPSTACK Managed': true
  }
];

export { mockInventoryData };
