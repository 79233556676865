import { UserPreferences } from '../../store/User';
import { AuthenticatedUser } from '../../types';
import { callExternalApi, CallResult } from '../callExternalApi';

const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export async function fetchUserInformation(): Promise<CallResult<AuthenticatedUser>> {
  return callExternalApi<AuthenticatedUser>({
    url: `${API_SERVER_URL}/api/user`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
}

export async function fetchUserPreferencesApi(): Promise<CallResult<UserPreferences>> {
  return callExternalApi<UserPreferences>({
    url: `${API_SERVER_URL}/api/preferences/user_customizations`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
}
