import { DetailViewCard } from '../../../Compounds/CardWithTitle';
import { Team } from '../../../../store/Team';
import { TeamManagerGridColumnDefs } from '../ColumnDefs/TeamManagerGridColumnDefs';
import { useNavigate } from 'react-router-dom';
import { adminUrlForRole } from '../../../../store/User';
import { TextColor } from '../../../Atoms/Text';
import DropdownMenu from '../../../Compounds/Menu/DropdownMenu';
import { MemberActions } from './MemberDropdown';
import { initAddUsersToTeamForm } from '../../../../store/AddPortalUsersToTeamFormState';
import { BasicDataGrid } from '../../../Compounds/Table/BasicDataGrid';

export interface TeamDetailsProps {
  data: Team;
}

export function TeamDetails({ data }: TeamDetailsProps) {
  const navigate = useNavigate();

  const managerMenuOptions = [
    {
      title: 'Create New User',
      fontColor: 'indigo' as TextColor,
      onClick: () => navigate(`${adminUrlForRole()}/new-user`)
    },
    {
      title: 'Promote Existing User',
      fontColor: 'indigo' as TextColor,
      onClick: () => {
        initAddUsersToTeamForm(data.id, 'manager');
        navigate('/admin/teams/add-managers');
      }
    }
  ];

  const managerActions = () => {
    return (
      <DropdownMenu
        icon="plus"
        buttonText="Add Team Manager"
        buttonTextColor="indigo"
        options={managerMenuOptions}
        slotProps={{
          paper: {
            style: {
              width: 175
            }
          }
        }}
      />
    );
  };

  return (
    <div className="w-11/12 m-auto">
      <DetailViewCard
        title={'Team Managers'}
        cardActions={managerActions()}
        enableCollapse={false}>
        <BasicDataGrid
          rows={data.team_managers}
          columns={TeamManagerGridColumnDefs}
          dataCy="team-detail-team-managers-table"
        />
      </DetailViewCard>
      <DetailViewCard
        title={'Team Members'}
        cardActions={MemberActions({ teamId: data.id })}
        enableCollapse={false}>
        <BasicDataGrid
          rows={data.team_members}
          columns={TeamManagerGridColumnDefs}
          dataCy="team-detail-team-members-table"
        />
      </DetailViewCard>
    </div>
  );
}
