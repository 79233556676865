import { callExternalApi } from '../../callExternalApi';
import { PortalUserData } from '../../../types';

export const putPortalUser = async (portalUser: PortalUserData) => {
  const url = `api/admin/portal_users/${portalUser.id}`;
  const uniqRoles = portalUser.roles.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

  const modifiedPortalUser = {
    ...portalUser,
    roles_attributes: uniqRoles
  };

  return await callExternalApi<PortalUserData>({
    url,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: { portal_user: modifiedPortalUser }
  });
};
