import { DetailViewCard } from '../CardWithTitle';
import { useEffect, useState } from 'react';
import {
  getLineItemLoadingSubject,
  getLineItemSubject,
  getLineItemTotal,
  LineItem,
  useLineItemsStore
} from '../../../store/LineItem';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { DetailsLineItemsCTA } from '../DetailsLineItemsCTA/DetailsLineItemsCTA';
import { LineItemModal } from './LineItemModal';
import { BasicDataGrid } from '../../Compounds/Table/BasicDataGrid';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { Text } from '../../Atoms/Text';
import { getProductById } from '../../../store/Product';
import { Icon } from '../../Atoms/Icon';

export interface DetailsLineItemsProps {
  id: string;
  mrc: number;
}

export function DetailsLineItems({ id, mrc }: DetailsLineItemsProps) {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [lineItems, setLineItems] = useState<LineItem[]>([]);
  const { fetchLineItems, createLineItem, deleteLineItem, updateLineItem } = useLineItemsStore();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [editedLineItem, setEditedLineItem] = useState<LineItem | null>(null);

  useEffect(() => {
    const lineItemSub = getLineItemSubject().subscribe((lineItems) => setLineItems(lineItems));
    const lineItemLoadingSub = getLineItemLoadingSubject().subscribe((loading) => setIsFetching(loading));

    return () => {
      if (lineItemSub) lineItemSub.unsubscribe();
      if (lineItemLoadingSub) lineItemLoadingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (id) fetchLineItems(id);
  }, [id]);

  const cardActions = () => {
    const handleModalOpen = () => {
      if (collapsed) setCollapsed(false);
      setIsModalOpen(true);
    };
    return <DetailsLineItemsCTA setIsModalOpen={handleModalOpen} />;
  };

  const handleSend = (lineItem: LineItem) => {
    createLineItem({
      id: '',
      outside_inventory_id: id,
      product_id: lineItem.product_id,
      description: lineItem.description,
      mrc: lineItem.mrc,
      nrc: lineItem.nrc
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDelete = (id: string) => {
    setIsDeleting(true);
    setEditedLineItem(null);
    deleteLineItem(id);
    setIsDeleting(false);
  };

  const handleEdit = (row: LineItem) => {
    setEditedLineItem(row);
  };

  const handleCancelEdit = () => {
    setEditedLineItem(null);
  };

  const handleUpdateLineItem = (lineItem: LineItem) => {
    updateLineItem(lineItem);
    setEditedLineItem(null);
  };

  const renderLineItems = () => {
    const columns: GridColDef[] = [
      {
        field: 'productDisplay',
        headerName: 'Product',
        valueGetter: (_value, row) => {
          if (row.id === 'total') return 'Total';
          const p = getProductById(row?.product_id);
          return `${p?.family}-${p?.name}`;
        },
        width: 180
      },
      {
        field: 'description',
        headerName: 'Description',
        width: 180,
        renderCell: (params) => {
          return params.row.id === 'total' ? '' : `${params.value}`;
        }
      },
      {
        field: 'mrc',
        headerName: 'Monthly Cost',
        renderCell: (params) => {
          return params.row.id === 'total' ? `$${params.row.totalCost}` : `$${params.value || 0}`;
        }
      },
      {
        field: 'nrc',
        headerName: 'One Time Cost'
      },
      {
        field: 'action',
        sortable: false,
        headerName: 'Actions',
        renderCell: (params: GridCellParams) => {
          if (params.row.id === 'total') return null;
          return (
            <div className="flex space-x-3">
              <button
                disabled={isDeleting}
                onClick={() => handleEdit(params.row)}>
                <Text
                  size="sm"
                  color="indigo">
                  Edit
                </Text>
              </button>
              <button
                disabled={isDeleting}
                onClick={() => handleDelete(params.row.id)}>
                <Text
                  size="sm"
                  color="indigo">
                  Delete
                </Text>
              </button>
            </div>
          );
        },
        hideable: false,
        flex: 1
      }
    ];

    const totalCost = getLineItemTotal();
    const totalDelta = Math.abs(totalCost - mrc);
    const totalRow = { id: 'total', productDisplay: 'Total', totalCost, mrc: '', nrc: '', action: null };

    return (
      <div>
        <BasicDataGrid
          rows={[...lineItems, totalRow]}
          columns={columns}
        />
        {totalDelta > 0 && (
          <div className="mt-4 p-3 border-t">
            <div className="flex items-start">
              <Icon
                type="info"
                className="p-2 mr-2"
              />
              <div className="flex flex-col text-xs">
                <strong>Line items do not match record total</strong>
                <div className="mt-2">
                  The total monthly cost for all line items has a difference of{' '}
                  <strong>${totalDelta.toFixed(2)} </strong>
                  than the amount entered in this record’s inventory details.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <DetailViewCard
        cardActions={cardActions()}
        collapsed={collapsed}
        onCollapseClick={setCollapsed}
        enableCollapse
        title={`Line Items (${lineItems?.length || 0})`}
        childrenWrapperClass="overflow-x-auto">
        <div className="font-sans text-black">
          {isFetching && <ComponentSpinner />}
          {!isFetching && !lineItems?.length && <div className="text-center">No line items</div>}
          {!isFetching && !!lineItems?.length && renderLineItems()}
        </div>
      </DetailViewCard>
      {editedLineItem && (
        <LineItemModal
          lineItem={editedLineItem || ({} as LineItem)}
          handleCancel={handleCancelEdit}
          handleSend={handleUpdateLineItem}
          isModalOpen={!!editedLineItem}
        />
      )}
      <LineItemModal
        lineItem={{ id: '', outside_inventory_id: id, product_id: '', description: '', mrc: 0, nrc: 0 }}
        handleCancel={handleCancel}
        handleSend={handleSend}
        isModalOpen={isModalOpen}
      />
    </>
  );
}
