import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Account, InventoryAsset } from '../../../types';
import { INPUT_STYLE } from '../../../utils/styleHelpers';
import { AccountSelectionForm } from '../UserCreation/AccountSelectionForm';
import { getAccounts } from '../../../store/User';

export interface ServiceSelectionFormProps {
  onSubmit: (selectedService: InventoryAsset | null) => void;
  selectedService: InventoryAsset | null;
  selectedAccount: Account | undefined;
  setSelectedAccount: (acct: Account | undefined) => void;
  services: InventoryAsset[];
}

export const ServiceSelectionForm = ({
  onSubmit,
  selectedService,
  services,
  selectedAccount,
  setSelectedAccount
}: ServiceSelectionFormProps) => {
  const [serviceCategory, setServiceCategory] = useState<string>(selectedService?.product?.family || '');
  const [serviceCategoryOptions, setServiceCategoryOptions] = useState<string[]>();
  const [productSearchQuery, setProductSearchQuery] = useState('');
  const [detailsSearchQuery, setDetailsSearchQuery] = useState('');
  const [disableServiceDetail, setDisableServiceDetail] = useState<boolean>(true);
  const accounts = getAccounts();

  const generateServiceCategoryOptions = () => {
    const cat = services.reduce(
      (categories: string[], service: InventoryAsset): string[] => {
        const { family = 'Other' } = service.product;
        if (!categories.includes(family)) categories.push(family);

        return categories;
      },
      ['']
    );
    setServiceCategoryOptions(cat);
  };

  const handleSelectCategory = (e: React.SyntheticEvent<Element, Event>, value: string | null): void => {
    setServiceCategory(value || '');
  };

  const handleSelectServiceInputChange = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    setDetailsSearchQuery(value || '');
  };

  const handleSelectProductInputChange = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    setProductSearchQuery(value || '');
  };

  const handleSelectService = (e: React.SyntheticEvent<Element, Event>, value: InventoryAsset | null): void => {
    e.stopPropagation();
    const selectedService = services.find((service) => service.id === value?.id);
    if (selectedService) onSubmit(selectedService);
  };

  const handleAccountChange = (acct: Account | undefined) => {
    setSelectedAccount(acct);
    setServiceCategory('');
    onSubmit(null);
  };

  useEffect(() => {
    generateServiceCategoryOptions();
  }, [services?.length]);

  useEffect(() => {
    setDisableServiceDetail(serviceCategory === '');
  }, [serviceCategory]);

  return (
    <form>
      <div className="flex flex-col space-y-3 justify-between min-h-28 my-2 pb-2">
        <AccountSelectionForm
          accounts={accounts || []}
          disabled={accounts?.length === 1}
          setSelectedAccount={handleAccountChange}
          selectedAccount={selectedAccount || null}
          styles="mb-7 w-full mt-4"
          showLabel={true}
          inputStyles="h-[1.75rem]"
          size="medium"
        />
        <div className="pb-4">
          <Autocomplete
            value={serviceCategory}
            id="serviceCategoryList"
            disabled={!selectedAccount || !serviceCategoryOptions?.length || !services.length}
            onInputChange={handleSelectProductInputChange}
            inputValue={productSearchQuery}
            onChange={handleSelectCategory}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            options={serviceCategoryOptions || []}
            getOptionLabel={(option: string) => option}
            componentsProps={{ paper: { onMouseDown: (e) => e.stopPropagation() } }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Service..."
                data-cy="service-category-list"
                aria-label="service category list"
                InputProps={{ ...params.InputProps, style: { fontSize: '.875rem' }, name: 'Service' }}
                className={INPUT_STYLE}
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option}
                data-testid={option}
                className="text-sm14 py-1 pl-4 cursor-pointer hover:bg-grey-1">
                {option}
              </li>
            )}
          />
        </div>
        <div>
          <Autocomplete
            value={selectedService}
            id="serviceDetailList"
            disabled={disableServiceDetail}
            onInputChange={handleSelectServiceInputChange}
            inputValue={detailsSearchQuery}
            onChange={handleSelectService}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            options={services}
            getOptionLabel={(option: InventoryAsset) => {
              if (option === null) return '';
              if (option.addressInfo.number) return `${option.addressInfo.number} - ${option.address}`;
              return option.address || option.id;
            }}
            componentsProps={{ paper: { onMouseDown: (e) => e.stopPropagation() } }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Service Details"
                data-cy="service-detail-list"
                aria-label="service detail list"
                InputProps={{ ...params.InputProps, style: { fontSize: '.875rem' }, name: 'Service Details' }}
                className={INPUT_STYLE}
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                key={typeof option === 'string' ? option : option.id}
                data-testid={
                  option.addressInfo.number ? `${option.addressInfo.number} - ${option.address}` : option.address
                }
                data-cy="service-detail-list-option"
                className="text-sm14 py-1 pl-4 cursor-pointer hover:bg-grey-1">
                {option.addressInfo.number ? `${option.addressInfo.number} - ${option.address}` : option.address}
              </li>
            )}
          />
        </div>
      </div>
    </form>
  );
};
