import { useEffect, useState } from 'react';
import { Account, PortalUserData, UserRoles, UserTeam } from '../../../../types';
import { defaultAccount, getAccountSubject } from '../../../../store/Accounts';
import { getRoles, getRoleSubject, SKIP_ACCOUNT_ROLES, useRoles } from '../../../../store/Role';
import { formatSupportTicketDate } from '../../../../utils/helpers';
import { AccountSelectionForm, RoleSelectionForm, TeamSelectionForm } from '../../../Compounds/UserCreation';
import { DetailViewCard } from '../../../Compounds/CardWithTitle';
import { Text } from '../../../Atoms/Text';
import {
  getPortalUserUpdateSubject,
  updatePortalUser,
  getPortalUsersLoadingSubject
} from '../../../../store/PortalUser';
import { INPUT_STYLE } from '../../../../utils/styleHelpers';
import { isTeamManager, getUser } from '../../../../store/User';
import { Team, useTeamsStore, getTeamsSubject } from '../../../../store/Team';
import { ComponentSpinner } from '../../../Compounds/Loading/ComponentSpinner';
import { MultiSelect } from '../../../Compounds/Selects/MultiSelect';
import { getAcctCustByAccountId } from '../../../../store/AccountCustomization';
import { combineLatest } from 'rxjs';

export const EditUserForm = () => {
  const { rolesLoading } = useRoles();
  const teamManager = isTeamManager(getUser());
  const [userData, setUserData] = useState<PortalUserData>();
  const [roles, setRoles] = useState<UserRoles[]>(getRoles());
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>();
  const [displayAccounts, setDisplayAccounts] = useState<Account[]>([]);
  const [secondaryAccounts, setSecondaryAccounts] = useState<Account[]>([]);
  const [selectedSecondaryAccounts, setSelectedSecondaryAccounts] = useState<Account[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [portalUsersLoading, setPortalUsersLoading] = useState<boolean>(false);
  const [displayAccount, setDisplayAccount] = useState<boolean>(false);
  const [teams, setTeams] = useState<Team[]>([]);
  const [accountTeams, setAccountTeams] = useState<Team[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<UserTeam | null>(null);
  const { fetchTeams } = useTeamsStore();

  const handleAccountChange = (value: Account | undefined) => {
    if (value) {
      const updatedUserData = {
        ...userData,
        account_id: value.id,
        account_name: value.name
      };
      updatePortalUser(updatedUserData as PortalUserData);
    }

    setSelectedAccount(value);
  };

  const handleRolesUpdate = (selected: string[]) => {
    const filteredRoles = roles.filter((role) => selected.includes(role.name || ''));
    setSelectedRoles(filteredRoles.map((role) => role.name).filter((name): name is string => name !== undefined));
    const updatedUserData = {
      ...userData,
      roles: filteredRoles
    };
    updatePortalUser(updatedUserData as PortalUserData);
  };

  const handleChange = (name: keyof PortalUserData, value: string) => {
    const updatedUserData = {
      ...userData,
      [name]: value.trim()
    };

    updatePortalUser(updatedUserData as PortalUserData);
  };

  const handleTeamChange = (value: UserTeam | null) => {
    if (value) {
      const updatedUserData = {
        ...userData,
        team_id: Number(value.id),
        team_name: value.name
      };
      updatePortalUser(updatedUserData as PortalUserData);
    }

    setSelectedTeam(value);
  };

  const handleSecondaryAccountChange = (e: React.SyntheticEvent<Element, Event>, value: string[] | null) => {
    const filteredAccounts = displayAccounts.filter((a) => value?.includes(a.name)) || [];
    setSelectedSecondaryAccounts(filteredAccounts);
    const updatedUserData = {
      ...userData,
      accounts: filteredAccounts
    };
    updatePortalUser(updatedUserData as PortalUserData);
  };

  useEffect(() => {
    const dataSubscription = combineLatest([
      getTeamsSubject(),
      getRoleSubject(),
      getAccountSubject(),
      getPortalUsersLoadingSubject(),
      getPortalUserUpdateSubject()
    ]).subscribe(([teams, roles, accounts, usersLoading, userUpdate]) => {
      setTeams(teams);
      setRoles(roles);
      setDisplayAccounts(accounts.sort((a, b) => (a.name > b.name ? 1 : -1)));
      setPortalUsersLoading(usersLoading);
      setUserData(userUpdate);

      if (userUpdate?.account_id && userUpdate?.account_name) {
        setSelectedAccount({ id: userUpdate.account_id, name: userUpdate.account_name });
        const acctTeams = selectedAccount?.id ? teams.filter((t) => t.account_id === userUpdate?.account_id) : teams;
        setAccountTeams(acctTeams);
      }
      if (userUpdate?.team_id) {
        const team = teams.find((t) => Number(t.id) === userUpdate.team_id);
        setSelectedTeam({ id: team?.id, name: team?.name } as UserTeam);
      }
    });

    fetchTeams();

    return () => {
      if (dataSubscription) dataSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const acctTeams = selectedAccount?.id ? teams.filter((t) => t.account_id === selectedAccount?.id) : teams;
    setAccountTeams(acctTeams);

    const subAccounts = getAcctCustByAccountId(selectedAccount?.id)?.content?.sub_accounts || [];
    setSecondaryAccounts(subAccounts);
  }, [selectedAccount, accountTeams.length]);

  useEffect(() => {
    const currentRoles = userData?.roles
      ?.map((role) => role?.name)
      .filter((name): name is string => name !== undefined);
    setSelectedRoles(currentRoles || []);
  }, [userData?.roles, userData?.id, userData?.roles?.length]);

  useEffect(() => {
    const hide = selectedRoles.some((role) => SKIP_ACCOUNT_ROLES.join(', ').includes(role));
    setDisplayAccount(!hide && !teamManager);
  }, [selectedRoles.length]);

  useEffect(() => {
    if (userData && userData.account_id) {
      setSelectedAccount({ id: userData.account_id, name: userData.account_name } as Account);
    } else {
      setSelectedAccount(undefined);
    }

    if (userData && !!userData?.accounts?.length) {
      setSelectedSecondaryAccounts(userData.accounts);
    }
  }, [userData?.account_id, userData?.id]);

  useEffect(() => {
    setSelectedTeam(null);
    if (userData && userData.team_id) {
      const team = accountTeams.find((t) => Number(t.id) === userData.team_id);
      setSelectedTeam({ id: team?.id, name: team?.name } as UserTeam);
    }
  }, [userData?.team_id, userData?.id, accountTeams]);

  if (portalUsersLoading) return <ComponentSpinner />;

  return (
    <>
      <DetailViewCard
        key="admin"
        title="Details">
        <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}>
          <div
            className="flex mb-8"
            data-testid="detail-row-id">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              User ID
            </Text>
            <Text
              className="font-sans font-normal w-full"
              size="sm">
              {userData?.id}
            </Text>
          </div>
          <div
            className="flex mb-8"
            data-testid="detail-row-first_name">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              First Name
            </Text>
            <div className="w-full">
              <input
                onChange={(e) => handleChange('first_name', e?.target?.value)}
                type="text"
                id="firstName"
                data-cy="first-name"
                name="first_name"
                aria-label="first_name"
                className={INPUT_STYLE}
                value={userData?.first_name || ''}
              />
            </div>
          </div>
          <div
            className="flex mb-8"
            data-testid="detail-row-last_name">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              Last Name
            </Text>
            <div className="w-full">
              <input
                onChange={(e) => handleChange('last_name', e?.target?.value)}
                type="text"
                id="lastName"
                data-cy="last-name"
                name="last_name"
                aria-label="last_name"
                className={INPUT_STYLE}
                value={userData?.last_name || ''}
              />
            </div>
          </div>
          <div
            className="flex mb-8"
            data-testid="detail-row-email">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              Email
            </Text>
            <div className="w-full">
              <input
                onChange={(e) => handleChange('email', e?.target?.value)}
                type="text"
                id="email"
                data-cy="email"
                name="email"
                aria-label="email"
                className={INPUT_STYLE}
                value={userData?.email || ''}
              />
            </div>
          </div>
          {displayAccount && (
            <div
              className="flex mb-8"
              data-testid="detail-row-account">
              <Text
                className="font-sans text-grey-4 w-3/5"
                size="sm14">
                Account
              </Text>
              <div className="w-full">
                <AccountSelectionForm
                  accounts={[defaultAccount, ...displayAccounts]}
                  selectedAccount={selectedAccount || defaultAccount}
                  setSelectedAccount={handleAccountChange}
                  size="small"
                />
              </div>
            </div>
          )}
          {!!secondaryAccounts.length && (
            <div className="flex mb-8">
              <Text
                className="font-sans text-grey-4 w-3/5"
                size="sm14">
                Accounts
              </Text>
              {teamManager ? (
                <div className="w-fill">
                  <MultiSelect
                    sx={{ width: '22.5rem' }}
                    options={secondaryAccounts.map((a) => a.name)}
                    selectedValues={selectedSecondaryAccounts.map((a) => a.name)}
                    inputLabel={'Add Secondary Accounts'}
                    handleChange={handleSecondaryAccountChange}
                  />
                </div>
              ) : (
                <Text
                  className="font-sans font-normal w-full"
                  size="sm">
                  {selectedSecondaryAccounts.map((acct) => acct.name).join(', ')}
                </Text>
              )}
            </div>
          )}
          <div
            className="flex mb-8"
            data-testid="detail-row-updated_at">
            <Text
              className="font-sans text-grey-4 w-3/5"
              size="sm14">
              Last Login
            </Text>
            <Text
              className="font-sans font-normal w-full"
              size="sm">
              {formatSupportTicketDate(userData?.updated_at || '')}
            </Text>
          </div>
          {displayAccount && (
            <div
              className="flex mb-8"
              data-testid="detail-row-team">
              <Text
                className="font-sans text-grey-4 w-3/5"
                size="sm14">
                Team
              </Text>
              <div className="w-full">
                <TeamSelectionForm
                  selectedTeam={selectedTeam}
                  setSelectedTeam={handleTeamChange}
                  size="small"
                  styles="w-full"
                  teams={accountTeams}
                />
              </div>
            </div>
          )}
        </form>
      </DetailViewCard>
      <DetailViewCard
        key="admin_roles"
        title="Roles">
        {rolesLoading ? (
          <ComponentSpinner />
        ) : (
          <RoleSelectionForm
            selectedRoles={selectedRoles}
            updateRoles={handleRolesUpdate}
            drawer={true}
            size="small"
          />
        )}
      </DetailViewCard>
    </>
  );
};
