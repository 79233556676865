import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

type ErrorDomainKeys =
  | 'inventory'
  | 'order'
  | 'commission'
  | 'comment'
  | 'line-item'
  | 'support-ticket'
  | 'dashboard'
  | 'grid'
  | 'general'
  | 'portal-user'
  | 'account-customization'
  | 'role'
  | 'product'
  | 'account'
  | 'user'
  | 'session'
  | 'supplier'
  | 'configuration'
  | 'attachments'
  | 's3-attachments'
  | 'outside-inventory'
  | 'outside-inventory-schema'
  | 'notifications'
  | 'teams'
  | 'my-team-page'
  | 'shared-account-customization';
type ErrorsByDomain = Record<ErrorDomainKeys, string | undefined>;

export interface UseErrorStoreReturn {
  errorMessageSubscription: typeof userErrorMessageSubject;
  errorsByDomain: ErrorsByDomain;
}

const defaultErrorByDomain: ErrorsByDomain = {
  inventory: undefined,
  order: undefined,
  commission: undefined,
  comment: undefined,
  'line-item': undefined,
  'support-ticket': undefined,
  dashboard: undefined,
  grid: undefined,
  general: undefined,
  'portal-user': undefined,
  'account-customization': undefined,
  role: undefined,
  product: undefined,
  account: undefined,
  user: undefined,
  session: undefined,
  supplier: undefined,
  configuration: undefined,
  attachments: undefined,
  's3-attachments': undefined,
  'outside-inventory': undefined,
  'outside-inventory-schema': undefined,
  notifications: undefined,
  teams: undefined,
  'my-team-page': undefined,
  'shared-account-customization': undefined
};

const defaultUserErrorMessage = undefined;

const errorByDomainSubject = new BehaviorSubject<ErrorsByDomain>(defaultErrorByDomain);
const userErrorMessageSubject = new BehaviorSubject<string | undefined>(defaultUserErrorMessage);

const updateErrorMessage = (message: string): void => userErrorMessageSubject.next(message);
export const getErrorMessageSubject = () => userErrorMessageSubject;
export const getErrorByDomainSubject = () => errorByDomainSubject;
export const addError = (domain: ErrorDomainKeys, message: string | undefined): void => {
  errorByDomainSubject.next({ ...errorByDomainSubject.value, [domain]: message });
};
export const resetErrorStore = (): void => {
  errorByDomainSubject.next(defaultErrorByDomain);
  userErrorMessageSubject.next(defaultUserErrorMessage);
};

export const useErrorStore = (): UseErrorStoreReturn => {
  const [errorsByDomain, setErrorsByDomain] = useState<ErrorsByDomain>(defaultErrorByDomain);

  const handleErrorsByDomainUpdate = (): void => {
    const errorsArray = Object.keys(errorsByDomain).reduce((msgs: string[], key: string): string[] => {
      const currError = errorsByDomain[key as unknown as ErrorDomainKeys];
      if (currError && !msgs.includes(currError)) msgs.push(currError);

      return msgs;
    }, []);
    const errors = errorsArray.join(', \n');

    if (errors) updateErrorMessage(errors);
  };

  useEffect(() => {
    const domainErrorSub = errorByDomainSubject.subscribe((errors) => {
      setErrorsByDomain(errors);
    });

    return () => {
      if (domainErrorSub) domainErrorSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    handleErrorsByDomainUpdate();
  }, [JSON.stringify(errorsByDomain)]);

  return { errorMessageSubscription: userErrorMessageSubject, errorsByDomain };
};
