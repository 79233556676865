import { ContactUserData } from '../../../types';
import { callExternalApi, CallResult } from '../../callExternalApi';

export const fetchContacts = async () => {
  return await callExternalApi<ContactUserData[]>({
    url: 'api/admin/portal_users/contacts/',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export async function fetchSfUsersApi(): Promise<CallResult<ContactUserData[]>> {
  return await callExternalApi<ContactUserData[]>({
    url: 'api/admin/portal_users/sfusers',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
}
