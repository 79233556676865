import { UserPreferences } from '../../store/User';

export const createSettingsApi = async (settings: UserPreferences) => {
  const token = localStorage.getItem('token');

  if (token) {
    const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/preferences/user_customizations`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(settings),
      keepalive: true
    });
    const data = await response.json();

    return { data, error: null };
  }

  return { data: null, error: { message: 'Not authenticated or missing token' } };
};
