import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { DetailViewCard } from '../../../../Compounds/CardWithTitle';
import { format } from 'date-fns';
import { BasicDataGrid } from '../../../../Compounds/Table/BasicDataGrid';
import { User } from '../../../../../types';
import { DetailCTARenderer } from '../../../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { updateState } from '../../../../../store/GeneralStore';
import { Link } from 'react-router-dom';
import { adminUrlForRole } from '../../../../../store/User';
import { Icon } from '../../../../Atoms/Icon';
import { TextSpan } from '../../../../Atoms/Text';

export interface UsersParams {
  users: User[];
}
export const Users = ({ users }: UsersParams) => {
  const columns: GridColDef[] = [
    {
      field: 'details',
      headerName: '',
      sortable: false,
      width: 65,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const onClick = () => updateState({ admin: { selectedTab: 0 } });
        return (
          <DetailCTARenderer
            data={params}
            value={''}
            valueFormatted={''}
            onClick={onClick}
          />
        );
      },
      hideable: false,
      disableReorder: true
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      valueGetter: (row, value) => `${value.first_name} ${value.last_name}`
    },
    {
      field: 'updated_at',
      headerName: 'Last Login',
      sortable: true,
      valueFormatter: (value) => {
        return value ? format(value, 'PPpp') : '';
      },
      flex: 1
    }
  ];

  const userActions = () => {
    const state = {
      prevpath: location.pathname
    };

    return (
      <Link
        role="link"
        title="New Team"
        className="flex space-x-2 items-center"
        data-cy="account-details-new-user"
        state={state}
        to={`${adminUrlForRole()}/new-user`}>
        <Icon
          type="plus"
          className="p-2"
        />
        <TextSpan
          color="indigo"
          size="sm14">
          New User
        </TextSpan>
      </Link>
    );
  };

  return (
    <DetailViewCard
      title="Users"
      enableCollapse
      collapsed
      cardActions={userActions()}>
      <BasicDataGrid
        rows={users}
        columns={columns}
        className="h-96 overflow-auto"
      />
    </DetailViewCard>
  );
};
