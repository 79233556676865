import { Attachment, S3Attachment } from '../../../types';
import { AttachmentRowTemplate } from './AttachmentRowTemplate';

export interface AttachmentRowProps {
  attachment: Attachment | S3Attachment;
  cancelUpload: (attachmentId: string) => void;
  retryUpload: (attachmentId: string) => void;
}

export function AttachmentRow({ attachment, retryUpload, cancelUpload }: AttachmentRowProps) {
  switch (attachment.status) {
    case 'uploading':
      return (
        <AttachmentRowTemplate
          id={attachment.id}
          statusIcon="greyCheckmark"
          fileName={attachment.file.name}
          statusMessage="uploading"
          progress={attachment.progress}
          handleAttachmentIcon="cancel"
          handleAttachmentOnClick={cancelUpload}
        />
      );
    case 'success':
      return (
        <AttachmentRowTemplate
          id={attachment.id}
          statusIcon="greenCheckmark"
          fileName={attachment.file.name}
          statusMessage="attached successfully"
        />
      );
    case 'limited':
      return (
        <AttachmentRowTemplate
          id={attachment.id}
          statusIcon="alert"
          fileName={attachment.file.name}
          statusMessage="— this file exceeds our 100MB size limit"
          handleAttachmentIcon="cancel"
          handleAttachmentOnClick={cancelUpload}
        />
      );
    case 'unsupported':
      return (
        <AttachmentRowTemplate
          id={attachment.id}
          statusIcon="alert"
          fileName={attachment.file.name}
          statusMessage="— this file type is not supported"
          handleAttachmentIcon="cancel"
          handleAttachmentOnClick={cancelUpload}
        />
      );
    case 'canceled':
      return (
        <AttachmentRowTemplate
          id={attachment.id}
          statusIcon="alert"
          fileName={attachment.file.name}
          statusMessage="— this file upload was canceled"
          handleAttachmentIcon="retry"
          handleAttachmentOnClick={retryUpload}
        />
      );
    default:
      return (
        <AttachmentRowTemplate
          id={attachment.id}
          statusIcon="alert"
          fileName={attachment.file.name}
          statusMessage="— something went wrong on our end."
          handleAttachmentIcon="retry"
          handleAttachmentOnClick={retryUpload}
        />
      );
  }
}
