import { Autocomplete, TextField } from '@mui/material';
import { Product } from '../../../types';
import { INPUT_STYLE } from '../../../utils/styleHelpers';

export interface ProductSelectionFormProps {
  product: Product | null;
  allProducts: Product[];
  productSearchQuery: string;
  classNames?: string;
  disabled?: boolean;
  handleInputChange: (e: React.SyntheticEvent<Element, Event>, value: string | null) => void;
  handleProductSelectionChange: (e: React.SyntheticEvent<Element, Event>, value: Product | null) => void;
  size?: 'medium' | 'small';
}

export const ProductSelectionForm = ({
  product,
  allProducts,
  productSearchQuery,
  classNames,
  disabled = false,
  handleInputChange,
  handleProductSelectionChange,
  size = 'medium'
}: ProductSelectionFormProps) => {
  return (
    <div className={classNames}>
      <Autocomplete
        value={product}
        disabled={disabled}
        onInputChange={handleInputChange}
        inputValue={productSearchQuery}
        onChange={handleProductSelectionChange}
        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
        options={allProducts}
        getOptionLabel={(option: Product) => `${option.family} - ${option.name}`}
        size={size}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Product"
            InputProps={{ ...params.InputProps, style: { fontSize: '.875rem' }, name: 'Product' }}
            className={INPUT_STYLE}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            key={`${option.family} - ${option.name}`}
            className="text-sm14 py-1 pl-4 cursor-pointer hover:bg-grey-1">
            {`${option.family} - ${option.name}`}
          </li>
        )}
      />
    </div>
  );
};
