import { PortalUserData } from '../../../types';
import { callExternalApi } from '../../callExternalApi';

export const fetchPortalUsers = async (accountId?: string) => {
  let url = 'api/admin/portal_users';
  if (accountId) url += `?account_id=${accountId}`;
  return await callExternalApi<PortalUserData[]>({
    url: url,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const fetchPortalUser = async (id: string) => {
  const { data, error } = await callExternalApi<PortalUserData>({
    url: `api/admin/portal_users/${id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
  return { data, error };
};
