import { useEffect, useState } from 'react';
import { Drawer } from '../../Atoms/Drawer';
import { Text } from '../../Atoms/Text';
import { CardButton } from '../Buttons/CardButton';
import { CustomizeDashboardWidgets } from '../CustomizeDashboardWidgets';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import {
  UserPreferences,
  WidgetKey,
  getDefaultPreferences,
  getUserPreferencesSubject,
  updateUserSettings
} from '../../../store/User';
import { useSnackbar } from '../../../Context/SnackbarContext';
export interface CustomizeDashboardDrawerProps {
  title: string;
  className?: string;
}

export const CustomizeDashboardDrawer = ({ title, className }: CustomizeDashboardDrawerProps) => {
  const [show, setShow] = useState(false);
  const lowerTitle = title === 'Support' ? 'tickets' : (title.toLocaleLowerCase() as WidgetKey);
  const [userPreferences, setUserPreferences] = useState<UserPreferences>({ ...getDefaultPreferences() });
  const { setSnack } = useSnackbar();
  const savedUserPreferences = [...getUserPreferencesSubject().getValue().content.widgets[lowerTitle]];
  const currentUserPreferences = [...userPreferences.content.widgets[lowerTitle]];
  const disabledSaveButton = JSON.stringify(savedUserPreferences) === JSON.stringify(currentUserPreferences);

  useEffect(() => {
    const settingsSub = getUserPreferencesSubject().subscribe((prefs) => {
      const savedPrefs = { ...prefs };

      savedPrefs.content = {
        ...savedPrefs.content,
        widgets: {
          ...savedPrefs.content.widgets,
          [lowerTitle]: savedPrefs.content.widgets[lowerTitle].map((w) => ({ ...w }))
        }
      };

      setUserPreferences(savedPrefs);
    });

    return () => {
      if (settingsSub) settingsSub.unsubscribe();
    };
  }, []);

  const handleReset = () => {
    const update = { ...userPreferences };

    update.content = {
      ...update.content,
      widgets: {
        ...update.content.widgets,
        [lowerTitle]: update.content.widgets[lowerTitle].map((w) => ({ ...w, hide: false }))
      }
    };

    setUserPreferences(update);
  };

  const handleCancel = () => {
    const update = { ...userPreferences };

    update.content = {
      ...update.content,
      widgets: {
        ...update.content.widgets,
        [lowerTitle]: savedUserPreferences
      }
    };
    setUserPreferences(update);
    setShow(false);
  };

  const handleSave = async () => {
    const update = { ...userPreferences };

    updateUserSettings(update, `${title} Widget`);

    setShow(false);
    setSnack({
      type: 'success',
      message: 'Settings saved.',
      open: true
    });
  };

  return (
    <div className={className}>
      <CardButton
        data-testid="dashboard_customize"
        icon={'customize'}
        text={'Customize'}
        title="dashboard_customize"
        onClick={() => setShow(!show)}
      />
      <Drawer
        className="text-left"
        show={show}
        setShow={handleCancel}
        title={title}
        subtitle={<Text size="lg">Customize Widgets</Text>}
        description="Show and hide widgets, or change their order by dragging.">
        <CustomizeDashboardWidgets
          userPreferences={userPreferences}
          drawer={true}
          widgetKey={lowerTitle}
          setUserPreferences={(v) => setUserPreferences(v as UserPreferences)}
        />
        <div className="flex pt-4 items-center border-t w-auto min-h-[4.25rem] -mx-6 -mb-6">
          <button
            className={`${DEFAULT_CANCEL_BUTTON_STYLE} text-indigo mr-auto ml-6`}
            onClick={handleReset}>
            Reset to Default
          </button>
          <div className="flex space-x-4 mr-6">
            <button
              className={DEFAULT_CANCEL_BUTTON_STYLE}
              onClick={handleCancel}>
              Cancel
            </button>
            <button
              className={DEFAULT_ADVANCE_BUTTON_STYLE}
              disabled={disabledSaveButton}
              onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
