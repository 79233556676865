/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { InventoryAsset } from '../../../types';
import { UpstackInventoryDetails } from './UpstackInventoryDetails';
import { OutsideInventoryDetails } from './OutsideInventoryDetails';

export interface InventoryDetailsProps {
  data: InventoryAsset;
}

export function InventoryDetails({ data }: InventoryDetailsProps) {
  return (
    <>{data['UPSTACK Managed'] ? <UpstackInventoryDetails data={data} /> : <OutsideInventoryDetails asset={data} />}</>
  );
}
