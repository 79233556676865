import { useState } from 'react';
import { TextColor } from '../../Atoms/Text';
import DropdownMenu from '../Menu/DropdownMenu';
import { ContactUsForm } from '../ContactUs/ContactUsForm';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';

export const PortalSupportMenu = () => {
  const [open, setOpen] = useState(false);
  const menuOptions = [
    { fontColor: 'grey9' as TextColor, onClick: () => {}, title: 'Portal Support' },
    {
      fontColor: 'indigo' as TextColor,
      onClick: () =>
        window.open(`https://care.upstack.com/custom-login?token=${localStorage.getItem('token')}`, '_blank'),
      title: 'Knowledge Base'
    },
    { fontColor: 'indigo' as TextColor, onClick: () => setOpen(true), title: 'Contact Portal Support' }
  ];

  const onClose = async () => {
    setOpen(false);
  };

  return (
    <div
      className="fixed right-3 bottom-3 z-50"
      data-testid="portal-support-menu">
      <DropdownMenu
        anchorOriginVertical="top"
        transformOriginVertical="bottom"
        icon="questionIcon"
        buttonText=""
        options={menuOptions}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: -1,
              ml: 0.7,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 110,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            },
            style: {
              width: 200
            }
          }
        }}
      />
      <Dialog
        open={open}
        sx={{
          height: '50rem'
        }}
        onClose={() => setOpen(false)}
        data-testid="edit-account-settings-modal">
        <DialogContent sx={{ padding: '2rem', width: '24rem' }}>
          <ContactUsForm onClose={onClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
