import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { GridPreferences, UserPreferences } from '../../../store/User';
import { CustomizeTableColumns } from '../../Compounds/CustomizeTableColumns/CustomizeTableColumns';
import { CustomizeDashboardWidgets } from '../../Compounds/CustomizeDashboardWidgets/CustomizeDashboardWidgets';
import { Dispatch, SetStateAction } from 'react';

export interface SettingsFormProps {
  customizations: UserPreferences;
  title: string;
  onBack: () => void;
  onSave: () => void;
  setNewUserPreferences: Dispatch<SetStateAction<UserPreferences>>;
}

export const SettingsForm = ({ customizations, title, onBack, onSave, setNewUserPreferences }: SettingsFormProps) => {
  const handleSetGridPreferences = (prefs: GridPreferences) => {
    const update = { ...customizations };
    update.content.inventory_grid_All = prefs;
    setNewUserPreferences(update);
  };

  return (
    <>
      <div className="flex flex-col px-3 max-w-xl m-auto py-3">
        <DialogTitle>{`${title} ${title === 'Inventory' ? 'Grid' : ''} Settings`}</DialogTitle>
        <DialogContent>
          <div className="flex flex-col items-center h-96">
            <div className="flex flex-col">
              {title === 'Inventory' && (
                <CustomizeTableColumns
                  gridPreferences={customizations.content.inventory_grid_All}
                  setGridPreferences={(v) => handleSetGridPreferences(v as GridPreferences)}
                  type="user"
                />
              )}
              {title === 'Widgets' && (
                <CustomizeDashboardWidgets
                  userPreferences={customizations}
                  setUserPreferences={(v) => setNewUserPreferences(v as UserPreferences)}
                  drawer={false}
                  widgetKey="home"
                />
              )}
            </div>
          </div>
        </DialogContent>
      </div>
      <DialogActions className="flex mx-4 mb-3 space-x-4">
        <button
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={onBack}>
          Back
        </button>
        <button
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          onClick={onSave}>
          Set
        </button>
      </DialogActions>
    </>
  );
};
