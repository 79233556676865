import { Link, useSearchParams } from 'react-router-dom';
import { Icon } from '../../../Atoms/Icon';
import { TextSpan } from '../../../Atoms/Text';
import { PageCard } from '../../../Compounds/CardWithTitle';
import { useEffect, useState } from 'react';
import {
  GridPreferences,
  adminUrlForRole,
  getDefaultPreferences,
  getPreferencesByKey,
  getUserPreferencesSubject
} from '../../../../store/User';
import { Team, getTeamsLoadingSubject } from '../../../../store/Team';
import { DetailsDrawer } from '../../../Compounds/DetailsDrawer';
import { getMetaData } from '../../../Compounds/DetailsDrawer/detailsDrawersHelpers';
import { GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { DefaultTeamGridColumnDefs } from '../ColumnDefs/TeamGridColumnDefs';
import { getGridColumns } from '../../../Compounds/UpstackDataGrid/helpers';
import { UpstackDataGrid } from '../../../Compounds/UpstackDataGrid/UpstackDataGrid';

export interface TeamTabProps {
  teams: Team[];
}

export const TeamTab = ({ teams }: TeamTabProps) => {
  const page = 'Teams';
  const [teamData, setTeamData] = useState<Team>();
  const [teamsLoading, setTeamsLoading] = useState<boolean>(false);
  const [search] = useSearchParams();
  const detailId = search.get('detailId');
  const [teamColumns, setTeamColumns] = useState<GridSingleSelectColDef[]>();
  const [gridSettings, setGridSettings] = useState<GridPreferences>(
    getDefaultPreferences().content['team_grid'] as GridPreferences
  );

  useEffect(() => {
    const loadingSubscription = getTeamsLoadingSubject().subscribe((loading) => setTeamsLoading(loading));
    const settingsSub = getUserPreferencesSubject().subscribe(() => {
      const gridPrefs = getPreferencesByKey('team_grid') as GridPreferences;
      if (gridPrefs) {
        setGridSettings(gridPrefs);
      }
    });

    return () => {
      if (loadingSubscription) loadingSubscription.unsubscribe();
      if (settingsSub) settingsSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (teams.length > 0) {
      const currentData = teams.find((t) => t.id.toString() === detailId);
      setTeamData(currentData);
      setTeamColumns(getGridColumns(teams, DefaultTeamGridColumnDefs, 'team_grid'));
    }
  }, [detailId, teams.length]);

  const cardActions = () => {
    const state = {
      prevpath: location.pathname
    };
    return (
      <Link
        role="link"
        title="New Team"
        className="flex space-x-2 items-center"
        data-cy="new-team"
        state={state}
        to={`${adminUrlForRole()}/new-team`}>
        <Icon
          type="plus"
          className="p-2"
        />
        <TextSpan
          color="indigo"
          size="sm14">
          New Team
        </TextSpan>
      </Link>
    );
  };

  return (
    <>
      <PageCard
        dataCy="admin-teams-tab"
        childrenWrapperClass="min-h-80 max-w-[60vw] min-w-full"
        title={page}
        cardActions={cardActions()}>
        <UpstackDataGrid
          title="Teams"
          rows={teams}
          columns={teamColumns}
          loadingData={teamsLoading}
          page="team_grid"
          gridSettings={gridSettings}
          showSearch={true}
          dataCy="admin-teams-table"
        />
      </PageCard>
      <DetailsDrawer
        showButtons={false}
        data={teamData}
        showDrawer={!!detailId}
        {...getMetaData('team')}
      />
    </>
  );
};
