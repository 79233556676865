import { useSession } from '../../../Api/useSession';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';

export const LogOutButton = () => {
  const { clearSession } = useSession();
  const handleLogOut = () => clearSession();

  return (
    <button
      className="flex space-x-1 mr-6"
      onClick={handleLogOut}>
      <Icon
        type="logoutSmall"
        className="self-center"
      />
      <TextSpan
        className="text-xs"
        color="grey5">
        Log Out
      </TextSpan>
    </button>
  );
};
