import { Notification } from '../../store/Notifications';
import { callExternalApi, CallResult } from '../callExternalApi';

export const fetchNotificationsApi = async (
  page: number = 1,
  entityId?: string
): Promise<CallResult<Notification[]>> => {
  return await callExternalApi<Notification[]>({
    url: entityId
      ? `api/notifications?page=${page}&per_page=100&entity_id=${entityId}`
      : `api/notifications?page=${page}&per_page=100`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};

export const updateNotificationApi = async (notification: Notification) => {
  return await callExternalApi<CallResult<Notification>>({
    url: `api/notifications/${notification.id}`,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: {
      is_read: notification.is_read,
      is_archived: notification.is_archived
    }
  });
};
