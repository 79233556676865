import { useState } from 'react';
import Popup from 'reactjs-popup';
import { LoadingIconSection } from '../../../loadingIcon';
import { Icon } from '../../Atoms/Icon';
import { SrOnlyText, TextBlock, TextSpan } from '../../Atoms/Text';
import PropTypes from 'prop-types';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';

export interface DeleteBtnProps {
  copyMap: {
    default: string;
    deleting: string;
    error: string;
  };
  deleteItem: (itemId: string) => Promise<boolean | undefined>;
  deleteItemId: string;
  deleteTriggerDescription: string;
  onSuccess?: () => void;
}

const deleteConfirmDialogStyle = {
  borderRadius: '2px',
  border: '2x solid #F7F7F7',
  width: '450px',
  height: 'auto'
};

interface PopupContentProps {
  close: () => void;
  copy: string;
  hasError: boolean;
  isDeleting: boolean;
  handleDeleteItem: () => Promise<void>;
}

const PopupContent: React.FC<PopupContentProps> = ({ close, copy, hasError, isDeleting, handleDeleteItem }) => (
  <div className="p-6 text-center align-middle">
    <TextBlock className={`text-md font-normal w-full ${hasError ? 'text-red' : 'text-grey-8'}`}>{copy}</TextBlock>
    {!isDeleting && (
      <div className="flex justify-center space-x-6 pt-2">
        <button
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={close}>
          Cancel
        </button>
        <button
          data-testid="confirm-delete"
          data-cy="confirm-usr-delete-btn"
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          onClick={handleDeleteItem}>
          Delete
        </button>
      </div>
    )}
    {isDeleting && <LoadingIconSection />}
  </div>
);

PopupContent.propTypes = {
  close: PropTypes.func.isRequired,
  copy: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  handleDeleteItem: PropTypes.func.isRequired
};

const buttonText = (onSuccess: (() => void) | undefined, setOpen: () => void, deleteTriggerDescription: string) => {
  return (
    <button
      className="flex text-grey-6 font-sans text-[12px] items-center"
      aria-label="delete item button"
      onClick={() => setOpen()}
      data-cy="delete-usr-btn">
      <Icon
        className="px-3 pb-1"
        type="trash"
      />
      {onSuccess && (
        <TextSpan
          className="text-xs"
          color="grey5">
          Delete User
        </TextSpan>
      )}
      <SrOnlyText>{deleteTriggerDescription}</SrOnlyText>
    </button>
  );
};

export function DeleteBtn({ copyMap, deleteItem, deleteItemId, deleteTriggerDescription, onSuccess }: DeleteBtnProps) {
  const [copy, setCopy] = useState(copyMap.default);
  const [isDeleting, setIsDeleting] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDeleteItem = async () => {
    setIsDeleting(true);
    setCopy(copyMap.deleting);
    const success = await deleteItem(deleteItemId);
    setIsDeleting(false);

    if (!success) {
      setCopy(copyMap.error);
      setHasError(true);
    } else {
      setCopy(copyMap.default);
      if (onSuccess) onSuccess();
    }
  };

  return (
    <Popup
      open={open}
      modal={true}
      contentStyle={deleteConfirmDialogStyle}
      lockScroll={true}
      onClose={() => setOpen(false)}
      trigger={buttonText(onSuccess, () => setOpen(true), deleteTriggerDescription)}>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (close) => (
          <PopupContent
            close={close}
            copy={copy}
            hasError={hasError}
            isDeleting={isDeleting}
            handleDeleteItem={handleDeleteItem}
          />
        )
      }
    </Popup>
  );
}
