import { Attachment } from '../../types';
import { callExternalApi } from '../callExternalApi';

export const fetchAttachments = async (parentId: string) => {
  return callExternalApi<Attachment[]>({
    url: `api/files?parent_id=${parentId}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
