import { callExternalApi } from '../../callExternalApi';
import { PortalUserData } from '../../../types';

export interface AddUserToTeamPayload {
  userId: string;
  teamId: number;
}

export const addUserToTeamApi = async ({ userId, teamId }: AddUserToTeamPayload) => {
  const url = `api/admin/portal_users/${userId}/assign_team`;
  return await callExternalApi<PortalUserData>({
    url,
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    data: { team_id: teamId }
  });
};
