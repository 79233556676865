import { useEffect, useState } from 'react';
import { InventoryAsset } from '../../../types';
import { Pie, PieChart } from 'recharts';
import { generateSuppliersDoughnutData } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { BORDER_STYLE } from '../../../utils/styleHelpers';
import { getInventoryLoadingSubject } from '../../../store/Inventory';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { TextBlock } from '../../Atoms/Text';
import { CollapseButton } from '../../Atoms/CollapseButton';
import { UserPreferences, getCurrentPreferences, setPreferences } from '../../../store/User';
import { GridFilterItem, GridLogicOperator } from '@mui/x-data-grid-pro';
import { v4 } from 'uuid';
import { TopSuppliersTable } from './TopSuppliersTable';
import { Tooltip } from '@mui/material';

export interface SuppliersWidgetProps {
  inventoryAssets: InventoryAsset[];
  isHome?: boolean;
}

const SupplierNameText = ({
  text,
  color,
  isHome = false,
  onClick
}: {
  text: string;
  color: string;
  isHome?: boolean;
  onClick: () => void;
}) => {
  const MAX_SUPPLIER_LENGTH = isHome ? 30 : 20;
  return (
    <div
      aria-label={text}
      className="grey-8 text-[10px] font-medium leading-3 flex flex-col gap-x-1.5 content-start"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') onClick();
      }}
      role="button"
      tabIndex={0}>
      <div className="flex flex-row gap-x-1.5">
        <span
          className="inline-flex w-2 h-2 rounded-full"
          style={{ backgroundColor: color }}
        />
        <Tooltip
          title={text}
          placement="top">
          <span>{text.length > MAX_SUPPLIER_LENGTH ? `${text.slice(0, MAX_SUPPLIER_LENGTH)}...` : text}</span>
        </Tooltip>
      </div>
    </div>
  );
};

interface CategoricalChartState {
  name: string;
}

export function SuppliersWidget({ inventoryAssets, isHome }: SuppliersWidgetProps) {
  const data = generateSuppliersDoughnutData(inventoryAssets);
  const navigate = useNavigate();
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<boolean>(false);

  useEffect(() => {
    const loadingSub = getInventoryLoadingSubject().subscribe((loading) => setInventoryLoading(loading));

    return () => {
      if (loadingSub) loadingSub.unsubscribe();
    };
  }, []);

  const filterSupplier = async (supplierName: string) => {
    let filters: GridFilterItem[] = [
      {
        id: v4(),
        field: 'supplier.name',
        operator: 'is',
        value: supplierName
      }
    ];
    const isOtherClicked = supplierName === 'Other';
    if (isOtherClicked) {
      const supplierNames = inventoryAssets
        .map((a) => a.supplier?.name)
        .filter((name) => {
          return name != '' && name != undefined && name != null && !data.map((a) => a['name']).includes(name);
        });
      filters = supplierNames.map((name) => {
        return {
          id: v4(),
          field: 'supplier.name',
          operator: 'is',
          value: name
        };
      });
    }

    const update: UserPreferences = JSON.parse(JSON.stringify(getCurrentPreferences()));
    if (update.content.inventory_grid_All.muiConfig.filter?.filterModel) {
      update.content.inventory_grid_All.muiConfig.filter.filterModel.items = filters;
      if (isOtherClicked) {
        update.content.inventory_grid_All.muiConfig.filter.filterModel.logicOperator = 'or' as GridLogicOperator;
      }
    }

    setPreferences(update);
    if (isHome) navigate('/inventory');
  };

  const chartClick = (supplierName: string) => () => {
    filterSupplier(supplierName);
  };

  const pieChartClick = (chartState: CategoricalChartState) => {
    filterSupplier(chartState.name);
  };

  const isCorrectData = data.length > 0;
  let gridStyles = isHome ? 'w-full' : 'min-w-64 h-52';
  gridStyles += isCorrectData && !inventoryLoading ? ' grid grid-cols-2 gap-1' : '';

  return (
    <div className={`${BORDER_STYLE} ${gridStyles} pt-3 ${!isHome && 'pr-2'}`}>
      <div className="col-span-2 text-sm font-medium tracking-[0.6px] text-grey-5 text-center leading-[15px]">
        TOP SUPPLIERS
      </div>
      {!isCorrectData && !inventoryLoading && (
        <div className={`flex ${isHome ? 'h-32' : 'h-44'} items-center justify-center`}>
          <TextBlock size="sm14">No data available</TextBlock>
        </div>
      )}
      {inventoryLoading && (
        <div className={`flex ${isHome ? 'h-32' : 'h-44'} items-center justify-center`}>
          <ComponentSpinner />
        </div>
      )}
      {!inventoryLoading && isCorrectData && (
        <>
          <div className="grid grid-cols-1 auto-rows-min pb-6 gap-1 m-auto">
            {data.map((item) => (
              <SupplierNameText
                color={item.fill}
                key={item.name}
                onClick={chartClick(item.name)}
                text={item.name}
                isHome={isHome}
              />
            ))}
          </div>
          <div className="text-center text-sm widget-container pb-4">
            <PieChart
              width={132}
              height={132}>
              <Pie
                dataKey="percent"
                data={data}
                cx="50%"
                cy="50%"
                onClick={pieChartClick}
                outerRadius={63}></Pie>
            </PieChart>
          </div>
          {isHome && (
            <div className="col-span-2">
              <div className="flex flex-row gap-x-[5px] col-span-2 border-t">
                <div className="w-full mx-3.5 text-left my-3 text-indigo text-sm">See All Suppliers</div>
                <CollapseButton
                  classNames="px-6 border-l"
                  testId="suppliersWidget"
                  collapse={collapse}
                  setCollapse={setCollapse}
                />
              </div>
              {collapse && (
                <TopSuppliersTable
                  inventoryAssets={inventoryAssets}
                  handleSupplierClick={filterSupplier}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
