import Popup from 'reactjs-popup';
import { useState } from 'react';
import { CreateTeamForm } from './CreateTeamForm';
import { TextSpan } from '../../Atoms/Text';

const contentStyle = {
  borderRadius: '10px',
  border: '2px solid #F7F7F7',
  width: '360px',
  padding: '28px'
};

interface AddTeamLinkProps {
  accountId: string;
  handleTeamCreate?: () => void;
}

export function AddTeamLink({ accountId, handleTeamCreate }: AddTeamLinkProps) {
  const [open, setOpen] = useState(false);

  const onClose = async () => {
    setOpen(false);
  };

  return (
    <>
      <button
        className="flex items-center space-x-1"
        onClick={() => setOpen(true)}>
        <TextSpan
          className="text-xs"
          color="indigo"
          dataTestId="create-team-link">
          Add Team
        </TextSpan>
      </button>
      <Popup
        closeOnDocumentClick={false}
        contentStyle={contentStyle}
        modal={true}
        open={open}
        onClose={onClose}>
        <CreateTeamForm
          accountId={accountId}
          handleTeamCreate={handleTeamCreate}
          onClose={onClose}
        />
      </Popup>
    </>
  );
}
