import { WidgetId } from '../../../types';
import { DraggableCard } from './DraggableCard';

export interface DraggableWidgetCardProps {
  widgetId: WidgetId;
  index: number;
  headerName: string;
  hide: boolean;
  setWidgetVisible: (key: WidgetId, visible: boolean) => void;
}

export function DraggableWidgetCard({ widgetId, index, headerName, hide, setWidgetVisible }: DraggableWidgetCardProps) {
  return (
    <DraggableCard
      draggableId={widgetId}
      index={index}
      label={headerName}
      checked={!hide}
      onChange={() => setWidgetVisible(widgetId, hide)}
    />
  );
}
