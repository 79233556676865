import { UserRoles } from '../../../../types';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DetailCTARenderer } from '../../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { updateState } from '../../../../store/GeneralStore';

export const TeamManagerGridColumnDefs: GridColDef[] = [
  {
    field: 'detailCTA',
    headerName: '',
    width: 60,
    renderCell: (params) => {
      const onClick = () => updateState({ admin: { selectedTab: 0 } });
      return (
        <DetailCTARenderer
          data={params.row}
          value={''}
          valueFormatted={''}
          onClick={onClick}
        />
      );
    },
    filterable: false,
    disableReorder: true
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    valueGetter: (value, row) => {
      const firstName = row.first_name;
      const lastName = row.last_name;
      return `${firstName} ${lastName}`;
    },
    description: 'Drag to move column'
  },
  {
    field: 'roles',
    headerName: 'Roles',
    flex: 1,
    valueGetter: (value, row) => {
      const array: UserRoles[] = row?.roles;
      return array === undefined ? '' : array.map((role) => role.name).join(', ');
    },
    description: 'Drag to move column'
  }
];
