import { BehaviorSubject } from 'rxjs';
import { UserRoles } from '../types';
import { useEffect, useState } from 'react';
import { fetchRoles as apiFetchRoles } from '../Api/Admin/Roles';
import { addError } from './Error';

export const SKIP_ACCOUNT_ROLES = [
  'Admin',
  'Advisor',
  'Support',
  'Service Delivery Manager',
  'Customer Success Manager',
  'Executive'
];

export const userInSkipRoles = (userRoles: string[]) => {
  return userRoles.some((role) => SKIP_ACCOUNT_ROLES.includes(role));
};

export interface UseRolesReturn {
  setRolesData: (accounts: UserRoles[]) => void;
  fetchRoles: () => Promise<void>;
  clear: () => void;
  roles: UserRoles[];
  rolesLoading: boolean;
}

const defaultRolesData: UserRoles[] = [];
const subject = new BehaviorSubject<UserRoles[]>(defaultRolesData);
const emitErrorState = (message?: string): void => addError('role', message);

export const getRoleSubject = (): BehaviorSubject<UserRoles[]> => subject;
export const getRoles = (): UserRoles[] => subject.value;

export const useRoles = (): UseRolesReturn => {
  const [rolesLoading, setRolesLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<UserRoles[]>(defaultRolesData);

  const fetchRoles = async (): Promise<void> => {
    setRolesLoading(true);
    const { data, error } = await apiFetchRoles();

    if (data) {
      emitErrorState();
      setRolesData(data);
    }

    if (error) {
      setRolesData(defaultRolesData);
      emitErrorState(error.message);
    }

    setRolesLoading(false);
  };

  useEffect(() => {
    const rolesSub = subject.subscribe((roles) => setRoles(roles));

    return () => {
      if (rolesSub) rolesSub.unsubscribe();
    };
  }, []);

  const setRolesData = (roles: UserRoles[]) => subject.next(roles);
  const clear = () => setRolesData(defaultRolesData);

  return { setRolesData, fetchRoles, clear, roles, rolesLoading };
};
