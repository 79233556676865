import { useEffect, useState } from 'react';
import { PortalUserData } from '../../../../types';
import { DetailViewCard } from '../../../Compounds/CardWithTitle';
import { TextSpan } from '../../../Atoms/Text';
import { Icon } from '../../../Atoms/Icon';
import { postAdvisorMail } from '../../../../Api/Admin/PortalUsers/postAdvisorMail';
import { PortalUserDeleteBtn } from '../../../Compounds/DeleteBtn/PortalUserDeleteBtn';
import { UserEventsSection } from '../../../Compounds/UserCreation/UserEventsSection';
import { ForgotFormData } from '../../../Compounds/Modals/Login';
import { datadogLogs } from '@datadog/browser-logs';
import { ImpersonationApi } from '../../../../Api/Admin/Impersonations/impersonationApi';
import { adminForgotPassword } from '../../../../Api/User/passwordApi';
import DropdownMenu from '../../../Compounds/Menu/DropdownMenu';
import { useSnackbar } from '../../../../Context/SnackbarContext';
import { EditUserForm } from './EditUserForm';
import { isTeamManager, getUser, isOnlyCommissionsRole } from '../../../../store/User';

export interface AdminDetailsProps {
  userData: PortalUserData;
  setShow: (show: boolean) => void;
}

const SHOW_MORE_ROLES = ['Advisor', 'Customer Success Manager', 'Service Delivery Manager'];

export function UserDetails({ userData, setShow }: AdminDetailsProps) {
  const [showMore, setShowMore] = useState<boolean>(false);
  const { createImpersonate } = ImpersonationApi();
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false);
  const { setSnack } = useSnackbar();
  const { exitImpersonate } = ImpersonationApi();

  useEffect(() => {
    const roles = userData?.roles?.map((role) => role?.name || role);
    setIsAdminUser(roles?.includes('Admin'));

    userData?.roles?.forEach(({ name }) => {
      if (name && SHOW_MORE_ROLES.includes(name)) setShowMore(true);
    });
  }, [userData?.id]);

  const handleForgot = async () => {
    const origin = window.location.origin;
    const update = { ...userData, redirect_url: `${origin}/reset` };
    const { error } = await adminForgotPassword(update as ForgotFormData);
    if (error) {
      datadogLogs.logger.error('Admin ForgotPassword error: ', error);
    } else {
      setSnack({ message: 'Your email has been sent successfully', type: 'success', open: true });
    }
  };

  const impersonate = async () => {
    if (userData) {
      const { error } = await createImpersonate(userData.id, isTeamManager(getUser()));
      if (error) {
        datadogLogs.logger.error('User impersonation error: ', error);
      } else {
        window.location.pathname = isOnlyCommissionsRole(userData) ? '/commissions' : '/';
      }
    }
  };

  const resetImpersonate = async () => {
    if (userData) {
      const { error } = await exitImpersonate(userData.id);
      if (error) {
        datadogLogs.logger.error('Exit user impersonation error: ', error);
      }
    }
  };

  const sendWelcomeEmail = async () => {
    const { error } = await postAdvisorMail({ userId: userData.id });
    if (!error) setSnack({ message: 'Your email has been sent successfully', type: 'success', open: true });
  };

  const menuOptions = [
    {
      title: 'Send Welcome Email',
      onClick: sendWelcomeEmail
    }
  ];

  return (
    <>
      <div className="flex space-x-4 items-center">
        <button
          className="flex space-x-1"
          onClick={handleForgot}>
          <Icon type="key" />
          <TextSpan
            className="text-xs"
            color="grey5">
            Reset Password
          </TextSpan>
        </button>
        {!isAdminUser && (
          <button
            className="text-grey-6 font-sans text-[12px]"
            onClick={impersonate}>
            <Icon
              className="px-3 pb-1 box-border inline "
              type="impersonate"
            />
            <TextSpan
              className="text-xs"
              color="grey5">
              Impersonate
            </TextSpan>
          </button>
        )}
        <button
          className="text-grey-6 font-sans text-[12px]"
          onClick={resetImpersonate}>
          <Icon
            className="px-3 pb-1 box-border inline "
            type="refresh"
          />
          <TextSpan
            className="text-xs"
            color="grey5">
            Reset Impersonate
          </TextSpan>
        </button>
        <PortalUserDeleteBtn
          portalUserId={userData?.id}
          setShow={setShow}
        />
        {showMore && (
          <DropdownMenu
            icon="more"
            buttonText="More"
            options={menuOptions}
          />
        )}
      </div>
      <EditUserForm />
      <DetailViewCard
        key="admin_events"
        title="History">
        <UserEventsSection userData={userData} />
      </DetailViewCard>
    </>
  );
}
