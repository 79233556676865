import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE, RAISED_DIALOG } from '../../../utils/styleHelpers';
import { TextBlock } from '../../Atoms/Text';

interface ExitWizardConfirmationProps {
  handleClose: () => void;
  handleProceed: () => void;
}

export const ExitWizardConfirmation = ({ handleClose, handleProceed }: ExitWizardConfirmationProps) => {
  return (
    <div
      data-cy="form-exit-confirm-modal"
      className={`flex flex-col justify-between w-72 mx-auto ${RAISED_DIALOG}`}>
      <TextBlock
        dataTestId="exit-copy"
        className="mx-auto w-[65%] text-center">
        Are you sure you wish to exit?
        <br />
        Your progress will be lost.
      </TextBlock>
      <div className="flex justify-center space-x-8 mt-10">
        <button
          data-cy="wizard-back-btn"
          className={DEFAULT_CANCEL_BUTTON_STYLE}
          onClick={handleProceed}>
          Back
        </button>
        <button
          data-cy="wizard-exit-btn"
          className={DEFAULT_ADVANCE_BUTTON_STYLE}
          onClick={handleClose}>
          Exit
        </button>
      </div>
    </div>
  );
};
