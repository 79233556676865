import { useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { SupportTicketsItem } from '../types';
import { getSelectedAccountIds, getUser } from './User';
import { Permission } from './GeneralStore';
import { CaseRequest, fetchTickets as apiFetchTickets, postTicket } from '../Api/Tickets';
import { formatSupportTicketDate, getTicketClosedDate } from '../utils/helpers';
import { addError } from './Error';

export interface UseSupportTicketReturn {
  setTicketsData: (tickets: SupportTicketsItem[]) => void;
  fetchTickets: (forceRefresh?: boolean) => Promise<void>;
  clear: () => void;
  tickets: SupportTicketsItem[];
}

export const OPEN_STATUS = [
  'Unassigned',
  'Assigned',
  'On Hold',
  'In Progress',
  'Escalated',
  'Waiting Customer Confirmation'
];

const defaultTicketData: SupportTicketsItem[] = [];

const subject = new BehaviorSubject<SupportTicketsItem[]>(defaultTicketData);
const loadingSubject = new BehaviorSubject<boolean>(false);
export const newTicketCreatedSubject = new BehaviorSubject<boolean>(false);
const newTicketProcessingSubject = new BehaviorSubject<boolean>(false);

const emitErrorState = (message?: string): void => addError('support-ticket', message);

export const getTicketLoadingSubject = (): BehaviorSubject<boolean> => loadingSubject;
export const getNewTicketProcessingSubject = (): BehaviorSubject<boolean> => newTicketProcessingSubject;
export const getNewTicketCreatedSubject = (): BehaviorSubject<boolean> => newTicketCreatedSubject;
export const getTicketSubject = (): BehaviorSubject<SupportTicketsItem[]> => subject;

export const getTickets = (): SupportTicketsItem[] => subject.value;
export const getTicketById = (id: string): SupportTicketsItem | undefined =>
  subject.value.find((ticket) => ticket.id === id);
export const getTicketsByAssetId = (id: string): SupportTicketsItem[] =>
  subject.value.filter((ticket) => ticket.Associated_Inventory__c === id);

export const addTicket = async (ticketPayload: CaseRequest): Promise<SupportTicketsItem | void> => {
  newTicketProcessingSubject.next(true);
  const { data, error } = await postTicket(ticketPayload);

  if (data) {
    emitErrorState();
    const selectedAccounts = getSelectedAccountIds();
    if (ticketPayload?.AccountId && selectedAccounts?.includes(ticketPayload?.AccountId)) {
      subject.next([
        {
          ...data[0],
          dateTimeOpened: formatSupportTicketDate(data[0].dateTimeOpened),
          dateTimeClosed: getTicketClosedDate(data[0])
        },
        ...getTickets()
      ]);
    }

    newTicketCreatedSubject.next(true);
    newTicketProcessingSubject.next(false);

    return data[0];
  }

  if (error) {
    emitErrorState(error.message);
    newTicketProcessingSubject.next(false);
  }
};

export const useSupportTickets = (): UseSupportTicketReturn => {
  const [tickets, setTickets] = useState(defaultTicketData);
  const fetchTickets = async (forceRefresh = false): Promise<void> => {
    if (loadingSubject.getValue()) return;
    if (subject.getValue().length > 0 && !forceRefresh) return;

    const userPermissions = (getUser()?.permissions || []).map((permission) => permission?.name || permission);
    if (!userPermissions.includes(Permission.VIEW_TICKETS)) return;
    loadingSubject.next(true);

    const { data, error } = await apiFetchTickets(getSelectedAccountIds());
    if (data) {
      emitErrorState();
      const ticketsData: SupportTicketsItem[] = data.map((ticket) => ({
        ...ticket,
        ticketType: ticket.ticketType == 'General Support' ? 'Billing Inquiry' : ticket.ticketType,
        dateTimeClosed: getTicketClosedDate(ticket),
        dateTimeOpened: formatSupportTicketDate(ticket.dateTimeOpened)
      }));
      setTicketsData(ticketsData);
      setTickets(ticketsData);
    }

    if (error) {
      setTicketsData(defaultTicketData);
      emitErrorState(error.message);
    }

    loadingSubject.next(false);
  };

  const setTicketsData = (tickets: SupportTicketsItem[]) => subject.next(tickets);
  const clear = () => subject.next(defaultTicketData);

  return { setTicketsData, fetchTickets, clear, tickets };
};
