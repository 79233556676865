import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Text, { TextSpan } from '../../Atoms/Text';
import { downloadTemplate, uploadFileValidation } from '../../../store/OutsideInventory';
import { useEffect, useRef, useState } from 'react';
import { Account } from '../../../types';
import { AccountSelectionForm } from '../UserCreation/AccountSelectionForm';
import { getAccountScope, getUser } from '../../../store/User';
import { ValidationSummary } from './ValidationSummary';
import { ProgressBar } from '../../Atoms';
import { CloudDownloadOutlined } from '@mui/icons-material';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

export const BulkInventoryForm = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const { accounts } = getUser();
  const [progress, setProgress] = useState(1);
  const [fileSize, setFileSize] = useState(0);
  const [fileName, setFileName] = useState('');
  const [incrementValue, setIncrementValue] = useState(0);
  const [startProgressIncrement, setStartProgressIncrement] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(
    getAccountScope()?.length === 1 ? getAccountScope()?.[0] : undefined
  );

  const progressHandler = (progressEvent: { loaded: number; total: number }) => {
    const { loaded, total } = progressEvent;
    const percentage = Math.round(((loaded / total) * 100) / 3);
    setProgress(percentage);
    setStartProgressIncrement(true);
  };

  const validateFile = async (files: File[]) => {
    const file = files[0];
    if (file) {
      setUploading(true);
      setFileSize(file.size);
      setFileName(file.name);
      await uploadFileValidation(file, progressHandler, selectedAccount?.id || '');
      setUploading(false);
    }
  };

  useEffect(() => {
    if (startProgressIncrement && incrementValue === 0) {
      const sizeInMb = fileSize / 10000;
      const increment = 66 / sizeInMb;
      setIncrementValue(Math.round(increment));
    }

    if (startProgressIncrement && incrementValue > 0 && progress < 100) {
      setTimeout(() => setProgress(progress + incrementValue > 100 ? 100 : progress + incrementValue), 500);
    }

    if (startProgressIncrement && incrementValue > 0 && progress >= 100) {
      setStartProgressIncrement(false);
      setIncrementValue(0);
    }
  }, [startProgressIncrement, progress, incrementValue]);

  return (
    <div data-testid="bulk-inventory-form-container">
      <div className="px-4 pb-8">
        <AccountSelectionForm
          accounts={accounts}
          disabled={accounts?.length === 1}
          setSelectedAccount={(v) => setSelectedAccount(v)}
          selectedAccount={selectedAccount || null}
          styles="flex items-center w-52"
          showLabel={true}
          inputStyles="h-[1.75rem]"
        />
      </div>
      <div className="flex items-center space-x-4 px-4 pb-6">
        <Button
          className="flex items-center w-52"
          component="label"
          size="small"
          color="secondary"
          variant="contained"
          tabIndex={-1}
          onClick={() => downloadTemplate()}
          startIcon={<CloudDownloadOutlined />}>
          Download Template
        </Button>
      </div>
      <div className="flex items-center space-x-4 px-4">
        <Button
          className="flex items-center w-52"
          component="label"
          size="small"
          color="secondary"
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}>
          Select .xlsx file
          <VisuallyHiddenInput
            ref={fileInputRef}
            onChange={(e) => validateFile(Array.from(e.target.files as FileList))}
            type="file"
            accept=".xlsx"
          />
        </Button>
        <Text
          weight="medium"
          className="pl-1"
          size="sm14">
          {fileName}
        </Text>
      </div>
      <div className="px-4">
        {uploading && (
          <div className="w-full flex justify-between items-center align-top">
            <div className="w-[97%]">
              <ProgressBar
                progress={progress}
                fullWidth={true}
                progressBarStyleOverride="h-full bg-indigo transition rounded"
                className="flex align-middle rounded"
              />
            </div>
            <TextSpan
              size="sm14"
              color="grey6">
              {progress}%
            </TextSpan>
          </div>
        )}
      </div>
      <div className="px-4 py-2">
        {!uploading && selectedAccount && (
          <ValidationSummary
            file={fileInputRef.current?.files?.[0]}
            progressHandler={progressHandler}
            setUploading={setUploading}
            selectedAccount={selectedAccount}
            setProgress={setProgress}
          />
        )}
      </div>
    </div>
  );
};
