import { callExternalApi } from '../../callExternalApi';

export type AdvisorRequest = {
  userId: string;
};

export type AdvisorResponse = {
  userId?: string;
};

export async function postAdvisorMail(requestBody: AdvisorRequest) {
  const url = 'api/send-advisor-email';
  return await callExternalApi<AdvisorResponse>({
    url,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: { userId: requestBody['userId'] }
  });
}
