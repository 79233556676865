import { useEffect, useState } from 'react';
import { Card, CardProps } from '../../Atoms/Card';
import { CollapseButton } from '../../Atoms/CollapseButton';
import { Text } from '../../Atoms/Text';
import classNames from 'classnames';
import { Icon, IconType } from '../../Atoms/Icon';

export interface CardWithTitleProps extends CardProps {
  title: string;
  titleIcon?: IconType;
  enableCollapse?: boolean;
  titlePadding?: string;
  headerContent?: JSX.Element | JSX.Element[];
  childrenWrapperClass?: string;
  cardActions?: JSX.Element | JSX.Element[];
  className?: string;
  collapsed: boolean;
  dataCy?: string;
  onCollapseClick?: (collapse: boolean) => void;
}

export function CardWithTitle({
  title,
  enableCollapse = false,
  onCollapseClick,
  titlePadding = 'p-5',
  headerContent,
  children,
  className,
  childrenWrapperClass = '',
  titleIcon,
  cardActions,
  collapsed,
  dataCy,
  ...props
}: CardWithTitleProps) {
  const [collapse, setCollapse] = useState(collapsed);

  useEffect(() => {
    setCollapse(collapsed);
  }, [collapsed]);

  return (
    <Card
      {...props}
      data-cy={dataCy || ''}
      className={classNames('my-6', { [className as string]: true })}>
      <div className="flex">
        <div className={`flex ${titlePadding} `}>
          {titleIcon && (
            <Icon
              className="mr-3"
              type={titleIcon}
            />
          )}
          <Text
            className="font-semibold"
            color="grey9"
            size="sm">
            {title}
          </Text>
          {headerContent}
        </div>
        <div className="ml-auto self-center px-6">{cardActions}</div>
        {enableCollapse && (
          <CollapseButton
            classNames="border-l px-6"
            collapse={collapse}
            setCollapse={(state) => {
              setCollapse(state);
              if (onCollapseClick) onCollapseClick(state as boolean);
            }}
          />
        )}
      </div>
      <>{!collapse && <div className={classNames('border-t p-5', { [childrenWrapperClass]: true })}>{children}</div>}</>
    </Card>
  );
}
