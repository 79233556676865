import { SupportTicketsItem } from '../../../types';
import { Text } from '../../Atoms/Text';
import { Link } from 'react-router-dom';
import { getMetaData } from '../DetailsDrawer/detailsDrawersHelpers';
import { AttachmentCTA } from '../AttachmentCTA';

export interface TicketCreatedFormProps {
  addAttachments: (files: File[]) => void;
  data: SupportTicketsItem;
  headerCopy: string;
  bodyCopy: string;
  ctaCopy: string;
  link?: {
    title: string;
    to: string;
  };
}

export const TicketCreatedForm = ({
  addAttachments,
  data,
  headerCopy,
  bodyCopy,
  ctaCopy,
  link
}: TicketCreatedFormProps) => {
  return (
    <>
      <Text
        className="text-[22px] py-2"
        color="grey9"
        weight="medium">
        {headerCopy}
      </Text>
      <Text
        className="py-2"
        color="grey5">
        {bodyCopy}
      </Text>
      {link && (
        <Link
          state={{
            data,
            ...getMetaData('support')
          }}
          className="ml-auto"
          title={link.title}
          to={link.to}>
          <Text
            className="py-2 text-[13px]"
            color="indigo">
            {ctaCopy}
          </Text>
        </Link>
      )}
      {!link && <AttachmentCTA addAttachments={addAttachments} />}
    </>
  );
};
