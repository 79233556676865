import { useEffect, useState } from 'react';
import { InventoryAsset } from '../../../types';
import { getInventoryLoadingSubject } from '../../../store/Inventory';
import { ComponentSpinner } from '../Loading/ComponentSpinner';
import { UserPreferences, getCurrentPreferences, setPreferences } from '../../../store/User';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { v4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { MapWidget } from '../../Compounds/MapWidget';

export interface SummativeWidgetProps {
  inventoryAssets: InventoryAsset[];
}

export function SummativeWidget({ inventoryAssets }: SummativeWidgetProps) {
  const navigate = useNavigate();
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [isWithMap, setIsWithMap] = useState<boolean>(false);

  useEffect(() => {
    const loadingSub = getInventoryLoadingSubject().subscribe((loading) => setInventoryLoading(loading));

    return () => {
      if (loadingSub) loadingSub.unsubscribe();
    };
  }, []);

  const locationsCount = inventoryAssets.length
    ? inventoryAssets
        .filter((asset) => !!asset.address && !!asset.addressInfo.latitude && !!asset.addressInfo.longitude)
        .map((asset) => asset.address)
        .filter((x, i, a) => a.indexOf(x) == i).length
    : 0;
  const servicesCount = inventoryAssets?.filter((asset) => asset.status == 'Active').length;
  const installsCount = inventoryAssets?.filter((asset) => asset.status == 'Pending Install').length;
  const divStyles = 'widget-container text-center flex-grow flex-shrink w-1/3';
  const pStyles = 'text-xs pt-2 font-medium tracking-wide text-grey-5 leading-5';
  const spanStyles = 'text-indigo text-xl leading-loose font-medium hover:cursor-pointer hover:drop-shadow-md';
  const borderStyles = 'border-r-[1px] border-gray-200 pr-3';

  const filterStatus = (statusName: string) => {
    const filter: GridFilterItem = {
      id: v4(),
      field: 'status',
      operator: 'is',
      value: statusName
    };
    const update: UserPreferences = JSON.parse(JSON.stringify(getCurrentPreferences()));
    if (update.content.inventory_grid_All.muiConfig.filter?.filterModel) {
      update.content.inventory_grid_All.muiConfig.filter.filterModel.items = [filter];
    }
    setPreferences(update);
    navigate('/inventory');
  };

  const handleMapView = () => {
    setIsWithMap(!isWithMap);
  };

  return (
    <div className="default-widget my-8 border border-gray-200 rounded-lg flex flex-row justify-between">
      <div
        className={`${divStyles} ${borderStyles}`}
        data-cy="summative-widget">
        <p className={pStyles}>LOCATIONS</p>
        {inventoryLoading && <ComponentSpinner />}
        {!inventoryLoading && (
          <span
            data-testid="locationsCount"
            className={spanStyles}
            onClick={() => handleMapView()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleMapView();
              }
            }}
            role="button"
            tabIndex={0}>
            {locationsCount ? locationsCount : '-'}
          </span>
        )}
      </div>
      <div className={`${divStyles} ${borderStyles}`}>
        <p className={pStyles}>ACTIVE SERVICES</p>
        {inventoryLoading && <ComponentSpinner />}
        {!inventoryLoading && (
          <span
            className={spanStyles}
            data-testid="servicesCount"
            onClick={() => filterStatus('Active')}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                filterStatus('Active');
              }
            }}
            role="button"
            tabIndex={0}>
            {servicesCount ? servicesCount : '-'}
          </span>
        )}
      </div>
      <div className={divStyles}>
        <p className={pStyles}>PENDING INSTALLS</p>
        {inventoryLoading && <ComponentSpinner />}
        {!inventoryLoading && (
          <span
            data-testid="installsCount"
            className={spanStyles}
            onClick={() => filterStatus('Pending Install')}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                filterStatus('Pending Install');
              }
            }}
            role="button"
            tabIndex={0}>
            {installsCount ? installsCount : '-'}
          </span>
        )}
      </div>
      {isWithMap && (
        <MapWidget
          inventoryAssets={inventoryAssets}
          isOnlyFull={true}
          handleFullClose={handleMapView}
          isHome={true}
        />
      )}
    </div>
  );
}
