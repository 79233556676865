const DEFAULT_SELECT_CLASSES = [
  'w-full',
  'p-1',
  'text-gray-900',
  'border',
  'border-gray-300',
  'rounded-lg',
  'focus:border-blue-500'
].join(' ');

export type SelectMenuOption = { value: string; text: string };

export interface SelectMenuProps {
  defaultValue?: string;
  handleSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  options: SelectMenuOption[];
  selectClasses?: string;
  handleClick?: (e: React.SyntheticEvent) => void;
  handleFocus?: (e: React.SyntheticEvent) => void;
  handleBlur?: (e: React.SyntheticEvent) => void;
  dataCy?: string;
}

export const SelectMenu = ({
  name,
  options,
  handleSelect,
  defaultValue,
  selectClasses,
  handleClick,
  handleBlur,
  handleFocus,
  dataCy
}: SelectMenuProps) => {
  return (
    <select
      name={name}
      className={`${DEFAULT_SELECT_CLASSES}  ${selectClasses}`}
      data-cy={dataCy}
      onClick={handleClick}
      defaultValue={defaultValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleSelect}>
      {options.map(({ value, text }) => (
        <option
          key={value}
          value={value}>
          {text}
        </option>
      ))}
    </select>
  );
};
