import { User } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const pendo = (window as any).pendo;

export const initializePendo = (user: User) => {
  if (!pendo) return;

  const roles = user.roles.map((r) => r.name).join(', ');
  const options = {
    visitor: {
      id: user.id,
      email: user.email,
      full_name: `${user.first_name || ''} ${user.last_name || ''}`,
      role: roles
    }
  };
  if (user.account_id) {
    Object.assign(options, { account: { id: user.account_id, name: user.account_name } });
  }
  pendo?.initialize(options);
};
