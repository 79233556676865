import { Component, ReactElement, ReactNode } from 'react';
import { getUser } from '../../../store/User';

interface PermissibleRenderProps {
  children: ReactElement;
  requiredPermissions: string[];
}

export class PermissibleRender extends Component<PermissibleRenderProps> {
  checkPermissions(): boolean {
    const { requiredPermissions } = this.props;
    const permissions = getUser().permissions.map((permission) => permission?.name || permission);
    return requiredPermissions.filter((p) => !permissions.includes(p)).length === 0;
  }

  render(): ReactNode {
    return this.checkPermissions() ? this.props.children : null;
  }
}
