import { GridCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { getDate, usaCurrency } from '../../../utils/helpers';
import { format } from 'date-fns';
import { allOperators, standardOperators } from '../../Compounds/UpstackDataGrid/helpers';
import { DetailCTARenderer } from '../../Compounds/UpstackDataGrid/CellRenderers/DetailCTARenderer';
import { getAccountName } from '../../../store/Accounts';

export const DefaultOrderGridColumnDefs: GridSingleSelectColDef[] = [
  {
    field: 'details',
    headerName: 'Details',
    sortable: false,
    width: 65,
    filterable: false,
    type: 'singleSelect',
    valueOptions: [],
    renderCell: (params: GridCellParams) => (
      <DetailCTARenderer
        data={params}
        value={''}
        valueFormatted={''}
      />
    ),
    hideable: false,
    disableReorder: true
  },
  {
    field: 'id',
    headerName: 'Id',
    sortable: true,
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'address',
    headerName: 'Location Address',
    sortable: true,
    width: 300,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'account_id',
    headerName: 'Account',
    sortable: true,
    width: 300,
    type: 'singleSelect',
    valueOptions: [],
    valueFormatter: (value) => {
      return getAccountName(value);
    },
    disableReorder: true,
    filterable: true,
    valueGetter: (value, row) => getAccountName(row?.account_id),
    filterOperators: standardOperators()
  },
  {
    field: 'supplier.name',
    headerName: 'Supplier',
    sortable: true,
    valueGetter: (value, row) => row?.supplier?.name,
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    width: 100,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'productDisplay',
    headerName: 'Product',
    width: 200,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'bandwidth',
    headerName: 'Bandwidth',
    sortable: true,
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'mRC',
    headerName: 'Order Amount',
    sortable: true,
    valueFormatter: (value) => {
      return `${usaCurrency.format(value)}`;
    },
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'nRC',
    headerName: 'One-Time Cost',
    sortable: true,
    valueFormatter: (value) => {
      return `${usaCurrency.format(value)}`;
    },
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'orderType',
    headerName: 'Order Type',
    sortable: true,
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'term',
    headerName: 'Term',
    sortable: true,
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    filterOperators: standardOperators(),
    description: 'Drag to move column'
  },
  {
    field: 'start_date',
    headerName: 'Order Start Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(value, 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  },
  {
    field: 'contractEndDate',
    headerName: 'Order End Date',
    sortable: true,
    valueFormatter: (value) => {
      return value ? format(getDate(value), 'MM/dd/yyyy') : '';
    },
    valueGetter: (value) => {
      return getDate(value).getTime();
    },
    filterOperators: allOperators(),
    width: 150,
    filterable: true,
    type: 'singleSelect',
    valueOptions: [],
    description: 'Drag to move column'
  }
];
