import { PageLayout } from '../../PageLayout';
import { OutsideInventoryBulkManualForm } from '../../Compounds/OutsideInventoryForm/OutsideInventoryBulkManualForm';
import { useEffect, useState } from 'react';
import { defaultFeatureFlags, FeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';
import { TextBlock } from '../../Atoms/Text';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { Permission } from '../../../store/GeneralStore';
import { PageCard } from '../../Compounds/CardWithTitle';
import { useProductsStore } from '../../../store/Product';
import { useAccounts } from '../../../store/Accounts';
import { userInSkipRoles } from '../../../store/Role';
import { BulkInventoryForm } from '../../Compounds/BulkInventoryUpload/BulkInventoryForm';
import { getUser } from '../../../store/User';
import { BulkInventoryRequestForm } from '../../Compounds/BulkInventoryUpload/BulkInventoryRequestForm';

export const AddInventoryPage = () => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);
  const { fetchProducts } = useProductsStore();
  const { fetchAccounts } = useAccounts();

  useEffect(() => {
    const flags = getFeatureFlagSubject().subscribe((flags) => setFeatureFlags(flags));
    fetchProducts();
    fetchAccounts();

    return () => {
      if (flags) flags?.unsubscribe();
    };
  }, []);

  return (
    <PageLayout
      pageTitle="Add Inventory"
      className="max-w-[98vw]">
      <TextBlock size="md">Manage inventory not currently in UPSTACK, by adding it to the portal.</TextBlock>
      <>
        {featureFlags.outside_inventory_bulk && (
          <PermissibleRender requiredPermissions={[Permission.BULK_OUTSIDE_INVENTORY]}>
            <>
              <PageCard title="Bulk Upload">
                <BulkInventoryForm />
              </PageCard>
            </>
          </PermissibleRender>
        )}
        {!userInSkipRoles(getUser().roles.map((r) => r.name)) && (
          <PageCard title="Bulk Upload Request">
            <BulkInventoryRequestForm />
          </PageCard>
        )}
        <PageCard
          title="Add Manually"
          enableCollapse={false}>
          <OutsideInventoryBulkManualForm />
        </PageCard>
      </>
    </PageLayout>
  );
};
