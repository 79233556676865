import { BehaviorSubject } from 'rxjs';
import { v4 } from 'uuid';
import { PostPortalUserRequest } from '../Api/Admin/PortalUsers/postPortalUser';
import { PortalUserData } from '../types';
import { UserForm } from '../components/Pages/UserCreationPage/UserForm';
import { getObjValidity } from '../utils/helpers';
import { TeamForm } from '../components/Pages/TeamCreation/TeamForm';
import { PostTeamRequest } from '../Api/Admin/Team/teamApi';
import { TeamResponse } from './Team';
import { UserFormProps } from './PortalUser';
import { getDefaultPreferences } from './User';

export interface TeamFormProps extends PostTeamRequest {
  dirty: boolean;
  valid: boolean;
  id: string;
  ui_element: React.ReactElement;
}

export interface formCreateState {
  status: FormStateStatus;
  error?: string;
  data?: Partial<PortalUserData | TeamResponse>;
  requestBody: PostPortalUserRequest | PostTeamRequest;
}

export enum FormStateStatus {
  FAILED = 'failed',
  SUCCESS = 'success',
  SUBMITTING = 'submitting'
}

export const defaultForm = (type: string) => {
  const userId = v4();
  const teamId = v4();
  const forms = {
    user: {
      first_name: '',
      last_name: '',
      email: '',
      account_id: '',
      accounts: [],
      roles_attributes: [],
      dirty: false,
      valid: false,
      id: userId,
      userCustomizations: getDefaultPreferences(),
      ui_element: <UserForm formId={userId} />
    },
    team: {
      dirty: false,
      valid: false,
      name: '',
      account_id: '',
      id: teamId,
      ui_element: <TeamForm formId={teamId} />
    }
  };

  return forms[type as keyof typeof forms];
};

export const requiredKeys = {
  user: ['first_name', 'last_name', 'email', 'roles_attributes'],
  team: ['name']
};

const formsValidSubject = new BehaviorSubject<boolean>(false);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formsSubject = new BehaviorSubject<any[]>([]);
const formCreateStateSubject = new BehaviorSubject<formCreateState[]>([]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFormsSubject = (): BehaviorSubject<any[]> => formsSubject;
export const getFormCreateStateSubject = (): BehaviorSubject<formCreateState[]> => formCreateStateSubject;
export const clearFormCreateStateSubject = () => formCreateStateSubject.next([]);
export const getFormsValidSubject = (): BehaviorSubject<boolean> => formsValidSubject;

export const updateFormsValidity = (value: boolean) => formsValidSubject.next(value);

export const addForm = (type: string) => {
  const currentValue = [...formsSubject.getValue()];
  const newForm = defaultForm(type);
  formsSubject.next([...currentValue, newForm]);
};

export const removeForm = (id: string) => {
  const update = [...formsSubject.getValue()];
  const index = update.findIndex((item) => item?.id === id);
  if (index > -1) update.splice(index, 1);
  formsSubject.next(update);
};

export const updateForm = (id: string, data: Partial<UserFormProps | TeamFormProps>, type: string) => {
  const update = [...formsSubject.getValue()];
  const index = update.findIndex((item) => item?.id === id);

  if (index > -1) {
    update[index] = { ...update[index], ...data, dirty: true };
    update[index].valid = getObjValidity(update[index], requiredKeys[type as keyof typeof requiredKeys]).valid;

    const allFormsValid = update.every((form) => form.valid === true);

    formsValidSubject.next(allFormsValid);
    formsSubject.next(update);
  }
};

export const clearForm = () => {
  formsValidSubject.next(false);
  formsSubject.next([]);
};
