import React, { createContext, useContext, useState } from 'react';

interface FetchingContextType {
  isFetching: boolean;
  setFetching: (isFetching: boolean) => void;
}

const FetchingContext = createContext<FetchingContextType>({} as FetchingContextType);

interface IFetchingProviderProps {
  children?: React.ReactNode;
}

export const FetchingProvider = ({ children }: IFetchingProviderProps) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  return (
    <FetchingContext.Provider value={{ isFetching, setFetching: setIsFetching }}>{children}</FetchingContext.Provider>
  );
};

export const useFetching = () => {
  const { isFetching, setFetching } = useContext(FetchingContext);

  return { isFetching, setFetching };
};
