import { createRef, useEffect, useState } from 'react';
import Form from '@rjsf/core';
import { RegistryWidgetsType, RJSFSchema, UiSchema } from '@rjsf/utils';
import { InventoryAsset, OutsideInventorySchema, Product } from '../../../types';
import { DetailViewCard } from '../../Compounds/CardWithTitle';
import {
  CurrencyInput,
  DateInput,
  JSONSchemaForm,
  ProductAddressInput,
  ShowAdvancedButton,
  SupplierSelectionInput,
  TermSelectionInput,
  TextInput
} from '../../Compounds/JSONSchemaForm';
import { ComponentSpinner } from '../../Compounds/Loading/ComponentSpinner';
import {
  getOutsideinventoryFormSubject,
  getOutsideInventoryFormValidSubject,
  initUpdateForm,
  submitUpdateForm,
  updateForm,
  updateFormProduct
} from '../../../store/OutsideInventory';
import { Icon } from '../../Atoms/Icon';
import { TextSpan } from '../../Atoms/Text';
import { DEFAULT_ADVANCE_BUTTON_STYLE, DEFAULT_CANCEL_BUTTON_STYLE } from '../../../utils/styleHelpers';
import { getOutsideInventorySchemaSubject } from '../../../store/OutsideInventorySchema';
import { getProductSubject } from '../../../store/Product';
import { DetailRow } from '../../Compounds/DetailsDrawer/DetailRow';
import { shouldSeeComments } from '../../../store/User';
import { useInventory } from '../../../store/Inventory';
import { useSnackbar } from '../../../Context/SnackbarContext';
import { ProductSelectionForm } from '../../Compounds/ProductSelectionForm';
import { Permission } from '../../../store/GeneralStore';
import { PermissibleRender } from '../../Compounds/PermissibleRender';
import { FormStateStatus } from '../../../store/BulkForm';
import { clearForm } from '../../../store/AddPortalUsersToTeamFormState';
import { S3AttachmentTable } from '../../Compounds/AttachmentTable/S3AttachmentTable';
import { DetailsComments } from '../../Compounds/DetailsComments/DetailsComments';
import { DetailsLineItems } from '../../Compounds/DetailsLineItems/DetailsLineItems';
import { defaultFeatureFlags, FeatureFlags, getFeatureFlagSubject } from '../../../Api/useFeatureFlags';

export interface OutsideInventoryDetailsProps {
  asset: InventoryAsset;
}

export const OutsideInventoryDetails = ({ asset }: OutsideInventoryDetailsProps) => {
  const [inventoryLoading, setInventoryLoading] = useState<boolean>(false);
  const [allFormsValid, setAllFormsValid] = useState(false);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [productSearchQuery, setProductSearchQuery] = useState('');
  const [product, setProduct] = useState<Product | null>();
  const [allSchemas, setAllSchemas] = useState<OutsideInventorySchema[]>([]);
  const [schema, setSchema] = useState<OutsideInventorySchema>();
  const [readOnly, setReadOnly] = useState(true);
  const [formData, setFormData] = useState<InventoryAsset>({ ...asset });
  const [formId, setFormId] = useState('');
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFeatureFlags);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = createRef<Form<any, RJSFSchema, any>>();
  const { fetchInventory } = useInventory();
  const { setSnack } = useSnackbar();

  const widgets: RegistryWidgetsType = {
    SupplierSelectionInput: SupplierSelectionInput,
    TermSelectionInput: TermSelectionInput,
    ProductAddressInput: ProductAddressInput,
    TextInput: TextInput,
    DateInput: DateInput,
    CurrencyInput: CurrencyInput,
    ShowAdvancedButton: ShowAdvancedButton
  };

  useEffect(() => {
    const productSub = getProductSubject().subscribe((products) => setAllProducts(products));
    const schemasSub = getOutsideInventorySchemaSubject().subscribe((schemas) => setAllSchemas(schemas));
    const formSub = getOutsideinventoryFormSubject().subscribe((forms) => {
      setFormId(forms[0].id);
      setFormData(forms[0].formData);
    });
    const formsValidSub = getOutsideInventoryFormValidSubject().subscribe((valid) => setAllFormsValid(valid));
    const flags = getFeatureFlagSubject().subscribe((flags) => setFeatureFlags(flags));

    initUpdateForm(formData);

    return () => {
      if (productSub) productSub.unsubscribe();
      if (schemasSub) schemasSub.unsubscribe();
      if (formSub) formSub.unsubscribe();
      if (formsValidSub) formsValidSub.unsubscribe();
      if (flags) flags?.unsubscribe();
      clearForm();
    };
  }, []);

  useEffect(() => {
    if (asset.id !== formData.id) {
      initUpdateForm(asset);
      setProduct(findProduct(asset.product_id || ''));
    }
  }, [asset.id]);

  useEffect(() => {
    if (allSchemas.length) {
      const matchedSchema = findSchema(formData.outside_inventory_schema_id || '');
      if (matchedSchema) setSchema(matchedSchema);
    }
  }, [allSchemas]);

  useEffect(() => {
    if (allProducts.length) {
      const matchedProduct = findProduct(formData.product_id || '');
      if (matchedProduct) {
        setProduct(matchedProduct);
        setProductSearchQuery(`${matchedProduct?.family} - ${matchedProduct?.name}`);
      }
    }
  }, [allProducts]);

  useEffect(() => {
    if (product && product.id !== asset.product_id) {
      const matchedSchema = updateFormProduct(formId, product, allSchemas, '');
      if (matchedSchema) setSchema(matchedSchema);
    } else {
      setSchema(findSchema(asset.outside_inventory_schema_id as string));
      updateForm(formId, asset, false);
    }
  }, [product]);

  const findSchema = (id: string) => allSchemas.find((schema) => schema.id === id);
  const findProduct = (id: string) => allProducts.find((prod) => prod.id === id);

  const handleChange = (form: InventoryAsset | null) => {
    const update: InventoryAsset | null = { ...formData, ...form };
    const formValid = formRef.current?.validateForm();
    updateForm(formId, update, true, formValid);
  };

  const formatUiSchemaForUpdate = (): UiSchema => {
    const updateSchema: UiSchema = { ...schema?.schema.ui_schema };

    updateSchema['ui:options']?.order?.forEach((field) => {
      if (updateSchema[field]['ui:widget'] === 'hidden') return;
      updateSchema[field]['ui:style'] = { width: '100%', margin: '2rem 0', padding: '0 1rem 0 0' };
    });

    return updateSchema;
  };

  const handleInputChange = (_e: React.SyntheticEvent<Element, Event>, value: string | null) => {
    setProductSearchQuery(value || '');
  };

  const handleProductSelectionChange = (_e: React.SyntheticEvent<Element, Event>, value: Product | null) => {
    setProduct(value);
  };

  const onUpdate = async () => {
    setInventoryLoading(true);
    const result = await submitUpdateForm();
    if (result.find((res) => res.id === formId)?.status === FormStateStatus.SUCCESS) {
      setSnack({ message: 'Inventory updated successfully', type: 'success', open: true });
      await fetchInventory(true);
    } else {
      setSnack({ message: 'Inventory not updated', type: 'error', open: true });
    }
    setReadOnly(true);
    setInventoryLoading(false);
  };

  const generateCardActions = () => (
    <PermissibleRender requiredPermissions={[Permission.EDIT_OUTSIDE_INVENTORY]}>
      <div className="flex space-x-2 items-center">
        <button
          onClick={() => setReadOnly(!readOnly)}
          className="flex space-x-2 items-center">
          <Icon
            type="edit"
            className="p-2"
          />
          <TextSpan
            color="indigo"
            size="sm14">
            Edit
          </TextSpan>
        </button>
      </div>
    </PermissibleRender>
  );

  return (
    <>
      <DetailViewCard
        collapsed={false}
        enableCollapse
        key="outside-inventory"
        cardActions={generateCardActions()}
        title="Details">
        <div className="overflow-auto max-h-[55vh] min-h-[30vh]">
          <>
            {inventoryLoading && (
              <div className="mt-32">
                <ComponentSpinner />
              </div>
            )}
            {!inventoryLoading && schema !== undefined && (
              <>
                {readOnly && (
                  <div className="w-full pr-4 mb-6">
                    <DetailRow
                      key="source"
                      colId="source"
                      title="Record Source"
                      value="External"
                      gutterBottom={false}
                    />
                  </div>
                )}
                {readOnly && product !== undefined && (
                  <div className="w-full pr-4">
                    <DetailRow
                      key={product?.id}
                      colId={product?.id || 'product'}
                      title="Product"
                      value={`${product?.family} - ${product?.name}`}
                      gutterBottom={false}
                    />
                  </div>
                )}
                {!readOnly && product !== undefined && (
                  <ProductSelectionForm
                    product={product}
                    allProducts={allProducts}
                    productSearchQuery={productSearchQuery}
                    handleInputChange={handleInputChange}
                    handleProductSelectionChange={handleProductSelectionChange}
                    classNames="mt-2 mb-4 w-full"
                  />
                )}
                <JSONSchemaForm
                  formKey={formData.id}
                  schema={schema.schema.validation_schema}
                  uiSchema={formatUiSchemaForUpdate()}
                  formData={formData}
                  onChangeHandler={handleChange}
                  rjsfProps={{
                    widgets: widgets,
                    idPrefix: 'outside_inventory_update_form',
                    idSeparator: `_${formId}_`,
                    showErrorList: false,
                    readonly: readOnly,
                    ref: formRef,
                    formContext: { styleOverride: { width: 'full' }, update: true, hideEmpty: true }
                  }}
                />
              </>
            )}
          </>
        </div>
      </DetailViewCard>
      {!readOnly && (
        <div className="flex flex-row justify-end mt-4">
          <button
            className={`${DEFAULT_CANCEL_BUTTON_STYLE} mr-2`}
            onClick={() => setReadOnly(true)}>
            Cancel
          </button>
          <button
            disabled={!allFormsValid}
            className={`${DEFAULT_ADVANCE_BUTTON_STYLE} ml-2`}
            onClick={() => onUpdate()}>
            Save
          </button>
        </div>
      )}
      {formData?.id && (
        <DetailsLineItems
          id={formData.id}
          mrc={formData.mRC}
        />
      )}
      {formData?.id && (
        <S3AttachmentTable
          id={formData.id}
          entityType="OutsideInventory"
        />
      )}
      {featureFlags.comments && shouldSeeComments() && formData?.id && (
        <DetailsComments
          id={formData.id}
          entityType="OutsideInventory"
          entityAccountId={formData.account_id || ''}
        />
      )}
    </>
  );
};
