import { OutsideInventorySchema } from '../../types';
import { callExternalApi } from '../callExternalApi';

export const fetchOutsideInventorySchemasApi = async () => {
  return await callExternalApi<OutsideInventorySchema[]>({
    url: 'api/outside_inventory_schema',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  });
};
