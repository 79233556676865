import { WidgetProps } from '@rjsf/utils';

export const ShowAdvancedButton = (props: WidgetProps) => {
  const { value: formValue, onChange } = props;

  const handleClick = () => {
    const newVal = !formValue;
    onChange(newVal);
  };

  const getButtonText = () => {
    return formValue ? 'Hide Advanced Fields' : 'Show Advanced Fields';
  };

  if (props.readonly) return null;

  return (
    <button
      className="px-4 py-2 bg-indigo-light text-grey-8"
      onClick={handleClick}>
      {getButtonText()}
    </button>
  );
};
