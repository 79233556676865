import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { WidgetProps } from '@rjsf/utils';
import { DetailRow } from '../../../DetailsDrawer/DetailRow';

export const DateInput = (props: WidgetProps) => {
  const { value: formValue, onChange } = props;
  const [value, setValue] = useState<Date | null>(formValue === '' ? null : formValue);
  const [dirty, setDirty] = useState(false);

  const handleChange = (val: Date | null) => {
    if (!dirty) setDirty(true);
    if (val === null || (val instanceof Date && isNaN(Date.parse(val?.toString())))) {
      setValue(null);
      onChange(null);
    } else {
      setValue(val);
      onChange(val?.toISOString().split('T')[0]);
    }
  };

  const getHelperText = () => {
    if (isErrorState()) return `${props.label} required`;
  };

  const isErrorState = () => {
    return dirty && ((props.rawErrors && props.rawErrors.length > 0) || (value === null && props.required));
  };

  if (props.readonly) {
    if (props.formContext?.hideEmpty && !formValue) return null;

    return (
      <div>
        <DetailRow
          key={props.id}
          colId={props.id}
          title={props.uiSchema?.['ui:placeholder'] || ''}
          value={formValue || ''}
        />
      </div>
    );
  }

  return (
    <DatePicker
      value={value}
      slotProps={{
        textField: {
          required: props.required,
          error: isErrorState(),
          helperText: getHelperText(),
          name: props.uiSchema?.['ui:placeholder']
        }
      }}
      onChange={handleChange}
      label={props.uiSchema?.['ui:placeholder']}
    />
  );
};
