import { useEffect, useState } from 'react';
import { Icon } from '../../Atoms/Icon';
import { Tooltip } from '../../Atoms/Tooltip';

export interface AttachmentDownloadBtnProps {
  attachmentId: string;
  name?: string;
  downloadAttachment: (attachmentId: string) => Promise<string | null | undefined>;
}

export function AttachmentDownloadBtn({ attachmentId, name = '', downloadAttachment }: AttachmentDownloadBtnProps) {
  const defaultCopy = 'Download';
  const [isDownloading, setIsDownloading] = useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [copy, setCopy] = useState(defaultCopy);
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    if (isDownloading) return setCopy('Downloading');

    if (errorText) return setCopy('Download Failed');

    return setCopy(defaultCopy);
  }, [isDownloading, errorText]);

  const handleDownloadAttachment = async () => {
    setIsDownloading(true);
    const attachment = await downloadAttachment(attachmentId);
    setIsDownloading(false);
    if (attachment) {
      generateLinkAndDownload(attachment);
    } else {
      setErrorText(`Error downloading ${name}. Please try again`);
    }
  };

  const generateLinkAndDownload = (href: string) => {
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
  };

  return (
    <button
      onMouseEnter={() => setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
      className={errorText ? 'text-red' : 'text-indigo'}
      onClick={() => handleDownloadAttachment()}>
      <ButtonCopy
        copy={copy}
        showRetry={!!errorText}
      />
      <ButtonTooltip
        show={!!errorText && tooltip}
        text={errorText as string}
      />
    </button>
  );
}

interface ButtonCopyProps {
  copy: string;
  showRetry: boolean;
}

const ButtonCopy = ({ copy, showRetry }: ButtonCopyProps) => {
  return (
    <div className="flex align-middle">
      {copy}
      {showRetry && (
        <Icon
          className="p-2 inline-block scale-[75%]"
          type="retry"
        />
      )}
    </div>
  );
};

interface ButtonTooltipProps {
  show: boolean;
  text: string;
}

const ButtonTooltip = ({ show, text }: ButtonTooltipProps) => {
  return (
    <div className="absolute right-5">
      <Tooltip
        show={show}
        value={text}
      />
    </div>
  );
};
