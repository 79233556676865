import { PortalUserData } from '../../../types';
import { callExternalApi } from '../../callExternalApi';

interface ImpersonationApiResponse {
  data: PortalUserData | null;
  error: null | { message: string };
}

export interface ImpersonationApiReturn {
  createImpersonate: (id: string, isTeamManager: boolean) => Promise<ImpersonationApiResponse>;
  exitImpersonate: (id?: string) => Promise<ImpersonationApiResponse>;
}

export const ImpersonationApi = (): ImpersonationApiReturn => {
  const createImpersonate = async (id: string, isTeamManager: boolean) => {
    const { data, error } = await callExternalApi<PortalUserData>({
      url: 'api/admin/impersonation',
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      data: { id }
    });
    localStorage.setItem('impersonated_user_id', id);
    if (isTeamManager) localStorage.setItem('is_impersonator_team_manager', 'true');
    return { data, error };
  };

  const exitImpersonate = async (id?: string) => {
    const { data, error } = await callExternalApi<PortalUserData>({
      url: id ? `api/admin/impersonation?id=${id}` : 'api/admin/impersonation',
      method: 'DELETE',
      headers: {
        'content-type': 'application/json'
      }
    });
    localStorage.removeItem('impersonated_user_id');
    localStorage.removeItem('is_impersonator_team_manager');
    return { data, error };
  };

  return { createImpersonate, exitImpersonate };
};
