import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const ComponentSpinner = ({ label = '', sx }: { label?: string; sx?: object }) => {
  return (
    <div
      style={{ color: 'rgb(156 163 175)', ...sx }}
      className="flex w-full justify-center items-center p-1">
      <Typography
        sx={{ marginRight: '1rem' }}
        variant="h6">
        {label}
      </Typography>
      <CircularProgress
        size={30}
        color="inherit"
      />
    </div>
  );
};
