import { Autocomplete, TextField } from '@mui/material';

export interface MultiSelectParams {
  options: string[];
  selectedValues: string[];
  inputLabel: string;
  handleChange: (e: React.SyntheticEvent<Element, Event>, value: string[] | null) => void;
  sx?: object;
}

export const MultiSelect = ({ options, selectedValues, handleChange, inputLabel, sx }: MultiSelectParams) => {
  return (
    <Autocomplete
      sx={{ width: 300, display: 'flex', ...sx }}
      freeSolo
      multiple
      className="w-full"
      size="small"
      id={`multi-${inputLabel}`}
      disableClearable
      disableCloseOnSelect
      value={selectedValues}
      onChange={handleChange}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputLabel}
          InputProps={{
            ...params.InputProps,
            type: 'search'
          }}
        />
      )}
    />
  );
};
