import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import App from './App';
import reportWebVitals from './reportWebVitals';
import initializeDataDog from './datadog';
import { FetchingProvider } from './Context/FetchingContext';
import { SnackbarProvider } from './Context/SnackbarContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2E01A4'
    },
    secondary: {
      main: '#7f7f7f'
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'sans-serif',
      fontSize: '14px'
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    // @ts-expect-error - this isn't in the TS because DataGird is not exported from `@mui/material`
    MuiDataGridPro: {
      styleOverrides: {
        row: {
          '&.Mui-selected': {
            backgroundColor: 'grey',
            color: 'yellow',
            '&:hover': {
              backgroundColor: 'purple'
            }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'lightgrey'
          },
          '&:hover': {
            backgroundColor: 'lightgrey'
          }
        }
      }
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <FetchingProvider>
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </FetchingProvider>
  </BrowserRouter>
);

reportWebVitals();
initializeDataDog();
