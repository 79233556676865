import axios, { AxiosHeaders, AxiosResponseHeaders } from 'axios';
import { LoginFormData } from '../../components/Compounds/Modals/Login';
import { User } from '../../types';

export type LoginResponse =
  | {
      data: User;
      error: null;
      headers: AxiosResponseHeaders | Partial<AxiosHeaders>;
    }
  | {
      data: null;
      error: {
        message: string;
      };
      headers: null;
    };

export const loginUser = async (body: LoginFormData): Promise<LoginResponse> => {
  const config = {
    url: 'auth/sign_in',
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: JSON.stringify(body)
  };
  try {
    const { data, headers } = await axios({ ...config, baseURL: `${process.env.REACT_APP_API_SERVER_URL}/` });
    return { data: data.data, headers, error: null };
  } catch (error) {
    return { data: null, error: { message: (error as Error).message }, headers: null };
  }
};
