import { LoginModal } from '../../Compounds/Modals/Login';
import { PageLayout } from '../../PageLayout';

export const LoginPageDevise = () => {
  return (
    <PageLayout>
      <LoginModal />
    </PageLayout>
  );
};
